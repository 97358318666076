<template>
    <div v-if="otherMembershipsList.length>0">
        <p class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4 px-4">
            {{$t('common.otherMemberships')}}
        </p>
        <div class="px-4">
            <div v-for="(membership, i) in otherMembershipsList" :key="i">
                <img v-if="membership && membership.logo" :src="membership.logo"
                     width="72" height="72" @click="showMembership(membership.organisation)"/>
            </div>
        </div>
        <wallet-other-details :membership-organisation="selectedOrganisationId"/>
    </div>
</template>

<script>
import RouterServices from "@/utilities/services/router/routerServices";
import WalletOtherDetails from "@/components/wallet/walletOtherDetails";

export default {
    name: "walletOther",
    components: {WalletOtherDetails},
    data: () => ({
        selectedOrganisationId: null,
    }),
    computed: {
        otherMemberships(){ return this.$store.state.otherMemberships},
        otherMembershipsList(){ return this.otherMemberships &&
            Object.values(this.otherMemberships).filter(membership => membership?.id !=='notFound');},
    },
    created(){
        if ( this.$route.query.otherMemberships ) window.history.back();
    },
    methods: {
        showMembership(membershipId){
            this.selectedOrganisationId = membershipId;
            RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                'otherMemberships', membershipId);
        }
    },
}
</script>

<style scoped>

</style>