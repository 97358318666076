<template>
    <div v-if="userChildList.length>0"
         :class="[{'px-5 text-center pb-15 mb-15': !configs || !configs.hideDivider}]">
        <divider-with-text v-if="!configs || !configs.hideDivider"
                           class="mb-1 mb-4">{{$t('profile.otherPersons')}}</divider-with-text>
        <div v-for="(childUser, i) in userList" :key="i" class="d-inline-block">
            <div v-if="childUser && childUser.uid!==user.uid"
                 @click="change(childUser.uid)">
                <profile-user-avatar :child-user="childUser"
                                     :size="avatarSize">
                </profile-user-avatar>
                <div class="fontInter font12 font-weight-medium space1
                            firstName text-truncate mx-1 mt-1 text-uppercase">
                    {{childUser.firstName}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import DividerWithText from "@/utilities/components/displays/dividerWithText";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService';
    import ProfileUserAvatar from "@/components/profile/profileUserAvatar";

    export default {
        components: { ProfileUserAvatar, DividerWithText},
        name: "profile-user-bar",
        props: ['configs'],
        computed: {
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            user(){ return this.$store.getters.user },
            userMaster(){ return this.$store.state.realtime.userMaster },
            userChilds(){ return this.$store.state.realtime.userChilds },
            userChildList() {
                return ObjectHandlingService.getListFromObject(
                    JSON.parse(JSON.stringify(this.userChilds)));
            },
            userList(){ return this.userChilds?
                [this.userMaster].concat(this.userChildList) :
                [[this.userMaster] ];
            },
            avatarSize(){
                return this.configs && this.configs.avatarSize ? this.configs.avatarSize : 64;
            }
        },
        methods: {
            change(uid){
                if ( uid === this.user.uid ) { return }
                this.$store.dispatch('changeUser', {uid: uid});
            },
        },
    }
</script>

<style scoped>
    .firstName{width: 96px;}
</style>