<template>
    <div>
        <div class="text-center mt-15">
            <img src="@/assets/oym_logo-typo-thin_final.svg" class="mt-6" height="36"
                 alt="On your marks">
        </div>
        <p class="text-center mt-5">{{$t('common.maintenance')}}</p>
    </div>
</template>

<script>
    export default {
        name: "offline"
    }
</script>

<style scoped>

</style>