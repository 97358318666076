<template>
    <div class="qrContainer mx-auto" :style="{width: size+10+'px'}">
        <qrcode-vue class="mb-0" :value="qrValue" :size="size" level="H" />
    </div>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'

    export default {
        components: {QrcodeVue},
        name: "qr-code-display",
        props: ['qrValue', 'size'],
    }
</script>

<style scoped>
    .qrContainer{border: solid 5px #ffffff;
                 background-color: #ffffff; position: relative}
</style>