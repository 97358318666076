<template>
    <div>
    <!-- Title
        <p v-if="configEl.title.i18n"
            :class="configEl.title.css">
            {{translate(configEl.title.i18n)}}
        </p>-->
        <div v-show="!loginService.waitingForReply"
             :class="configEl.container.css">
        <!-- Form -->
            <reset-pw-form :status="status"
                           :loginService="loginService"
                           :configEl="configEl"
                           :oobCode="oobCode"
                           @resetAndLoginCompleted="resetAndLoginCompleted"
                           @passwordMailSent="passwordMailSent"
                           @setLinkIsExpired="setLinkIsExpired"
                           @back="back">
            </reset-pw-form>
        <!-- alert messages -->
            <alert-message v-if="status.linkMailRequested ||
                                (oobCode && status.linkIsExpired) ||
                                 status.resetAndLoginCompleted"
                           :config-prop="configEl.alert"
                           :module-config="moduleConfig">
            <!-- pw reset completed -->
                <span v-if="status.resetAndLoginCompleted">
                    {{$t(configEl.alert.resetCompletedMsg.i18n)}}
                    <v-card-actions class="mt-0">
                        <v-divider></v-divider>
                        <btn-text config-package="ok"
                                  @click="back()">
                        </btn-text>
                    </v-card-actions>
                </span>
            <!-- resend pw reset link -->
                <span v-else-if="status.linkMailRequested && !oobCode">
                    {{$t(configEl.alert.resendLinkText.i18n)}}
                </span>
            <!-- pw reset link expired -->
                <span v-else-if="status.linkIsExpired">
                    {{$t(configEl.alert.linkIsExpired.i18n)}}
                </span>
            <!-- action: resend link -->
                <v-card-actions v-if="status.linkIsExpired || (status.linkMailRequested && !oobCode)"
                                class="mt-3">
                    <v-divider></v-divider>
                    <btn-text @click="resendPasswordMail()"
                              :config-prop="configEl.resendLinkBtn">
                    </btn-text>
                </v-card-actions>
            <!-- sub alert after repeated send of link -->
                <alert-message :config-prop="configEl.subAlert"
                               :module-config="moduleConfig">
                    {{$t(configEl.subAlert.i18n)}}
                </alert-message>
            </alert-message>
        <!-- cancel button, always available -->
            <btn-text v-if="!status.resetAndLoginCompleted"
                      :config-prop="configEl.cancelBtn"
                      config-package="cancel"
                      @click="back()">
            </btn-text>
        </div>
    <!-- progress circle -->
        <div :class="[configEl.container.css, 'text-center']">
            <v-progress-circular indeterminate color="primary"
                                 v-show="loginService.waitingForReply">
            </v-progress-circular>
        </div>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import BtnText from "@/utilities/components/controls/btnText";
    import {RESET_PW_CONFIG} from '@/utilities/components/authentication/resetPWConfig';
    import ResetPwForm from "@/utilities/components/authentication/resetPWForm";
    export default {
        components: {
            ResetPwForm,
            BtnText,
            AlertMessage},
        name: "reset-pw",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            status: {
                linkIsExpired: false,
                linkMailRequested: false,
                resetAndLoginCompleted: false,
            },
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.resetPW',
        // config
            configDefault: RESET_PW_CONFIG,
        }),
        computed: {
            oobCode() { return this.$route.query.oobCode },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        created(){
            this.verifyOobCode();
        },
        methods: {
            resendPasswordMail(){
                this.loginService.sendPasswordMail();
                this.showSubAlert();
            },
            async verifyOobCode(){
                if ( !this.oobCode ) {
                    this.status.linkIsExpired = true;
                    return;
                }
                this.status.linkIsExpired = await this.loginService.oobCodeExpired(this.oobCode, 'PWReset');
            },
            resetAndLoginCompleted(){
                this.status.resetAndLoginCompleted = true;
                this.$router.push('/');
            },
            passwordMailSent(){
                this.status.linkMailRequested = true;
            },
            setLinkIsExpired(){
                this.status.linkIsExpired = true;
            },
            back(){
                this.$emit('back');
            },
            showSubAlert(){
                this.configDefault.subAlert.show = true;
                setTimeout(()=>{
                    this.configDefault.subAlert.show = false;
                }, this.configDefault.subAlert.timeout)
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },

    }
</script>

<style scoped>

</style>