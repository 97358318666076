//Version 28.12.22 17h35
import {Mother} from "@/utilities/models/mother";
import {i18n} from '@/plugins/i18n';

export class Voucher extends Mother {
    constructor(dataObject, organisationId, styles) {
        super();
        this.title = '';
        this.description = '';
        this.infoLink = '';
        this.issueWithLicenseId = '';
        this.issueFrom = '';
        this.issueUntil = '';
        this.organisationId = organisationId ? organisationId:''; // issuing organisation
        this.validUntil = ''; // last day of validity in format '2022-12-31'
        this.value = null; // can be eur amount or percentage in the format 0.1 for 10%
        this.currency = 'EUR' // is not used if showValue != 'percent'
        this.onlyOnline = true;
        this.showValue = 'absolute'; // notAtAll / absolute / percent
        this.benefitKey = 'free' // also used for i18n (e.g.shopVoucher.free): free, discount, specialPrice, other
                                 // if not defined/default: free (to be compatible with 2022 vouchers)
        this.benefitText = '' // is user defined benefit text (no circle shown)
        this.isThirdParty = false;
        this.thirdPartyInformation = null; // => VoucherThirdPartyInformation: used if voucher
        //    is not redeemableInOYM
        this.noChangeWarning = false;
        this.styles = {
            color: '',
            dark: '',
        }
        this.assignedTo = organisationId ? {organisations: {[organisationId]: true }} : {}
        // database collection
        this.local.fireBaseCollection = 'vouchers';
        this.local.storageFileName = '';
        // set data
        if(dataObject) this.setFromDataObject(dataObject)
        if(styles) this.styles = styles
    }

    setFromDataObject(dataObject) {
        super.setFromDataObject(dataObject);
        if ( this.isThirdParty ) this.handleIsThirdPartyChanged(true, dataObject.thirdPartyInformation);
        if ( this.showValue === 'percent' ) this.value = this.value > 0 ? this.value * 100 : this.value;
    }

    copy(voucherToBeCopied, todaysDateString){
        const voucherCopy = JSON.parse(JSON.stringify(voucherToBeCopied))
        this.setFromDataObject(voucherCopy)
        if ( this.issueFrom <= todaysDateString ) this.issueFrom = '';
        if ( this.issueUntil <= todaysDateString ) this.issueUntil = '';
        if ( this.isThirdParty ) this.issueWithLicenseId = '';
        this.ts = 0
        this.tsLastUpdate = 0
        this.createdBy = ''
        this.lastUpdatedBy = ''
        this.title = i18n.t('adminVoucher.copyOf') + ' ' + this.title
    }

    getBenefitText(){
        // attention for changes: is duplicated in shopItemsVouchersCard.vue
        return this.benefitKey === 'other' ? this.benefitText : 'shopVoucher.' + this.benefitKey;
    }

    getDynStyleTitle(){
        return {
            width: this.showValue === 'notAtAll' ? '100%' : '66%',
            color: this.styles.textColor ? this.styles.textColor : ''
        }
    }

    handleIsThirdPartyChanged(to, thirdPartyInformation){
        if ( to ) {
            this.thirdPartyInformation = new VoucherThirdPartyInformation(thirdPartyInformation);
            if ( !thirdPartyInformation ) this.issueWithLicenseId = '';
        } else {
            this.thirdPartyInformation = null;
            if (this.benefitKey !== 'discount') this.benefitKey = 'discount'
        }
    }

    handleShowValueChanged(to){
        if ( to === 'percent' ) { this.currency = '' }
        else if ( to === 'notAtAll' ) { this.currency = ''; this.value = null}
    }

    handleBenefitKeyChanged(to){
        if ( to !== 'other' ) this.benefitText = '';
    }

}

export class VoucherThirdPartyInformation {
    constructor(dataObject) {
        this.issuerCode = dataObject?.issuerCode ?? ''; // code that is used to redeem voucher in third party systems
        this.externalDescriptionLink = dataObject?.externalDescriptionLink ?? '';
        this.redeemInformation = dataObject?.redeemInformation ?? ''; // text to describe how to redeem
        this.permittedOrganisationId = dataObject?.permittedOrganisationId ?? [];
        //this.requestUserDataForRedeem = dataObject?.requestUserDataForRedeem ?? false
    }
}
