var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{class:[{'error mb-3 pl-3 pt-2 pb-3': _vm.isMissing}],staticStyle:{"border-radius":"6px"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[(_vm.isMissing)?_c('v-col',{staticClass:"shrink pr-3"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-alert")])],1):_vm._e(),_c('v-col',{staticClass:"grow"},[_c('v-row',{class:[{'white--text': _vm.configEl.dark || _vm.isMissing}],attrs:{"no-gutters":"","align":"center","justify":"center"},on:{"click":function($event){return _vm.startEdit()}}},[_c('v-col',{staticClass:"grow"},[_c('div',{class:[{'white--text': _vm.configEl.dark || _vm.isMissing},
                                      {'grey--text text--darken-1': !_vm.isMissing},
                                      'caption']},[(_vm.configEl.labelUppercase)?_c('span',[_vm._v(_vm._s(_vm.getLabel(_vm.dataField.i18n)))]):_c('span',[_vm._v(_vm._s(_vm.getLabel(_vm.dataField.i18n)))])]),_c('div',[(_vm.dataField.type === 'switch')?_c('div',[(_vm.getCurrentValue()==='-')?_c('span',[(_vm.dataField.switchLabels)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.dataField.switchLabels.off)))]):_c('span',[_vm._v(_vm._s(_vm.$t('utilities.components.common.off')))])]):_c('span',[(_vm.dataField.switchLabels)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.dataField.switchLabels.on)))]):_c('span',[_vm._v(_vm._s(_vm.$t('utilities.components.common.on')))])])]):(_vm.dataField.type === 'pdfUpload')?_c('div',[(_vm.modelField && _vm.modelField.pdfName)?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{attrs:{"href":_vm.modelField.pdfUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.modelField.pdfName))])]):_c('div',[_vm._v("-")])]):((_vm.dataField.type !== 'confirmCheck' && !_vm.dataField.custom) ||
                                            (_vm.dataField.type !== 'confirmCheck' && _vm.dataField.custom && _vm.getCurrentValue()!=='-'))?_c('div',[_vm._v(" "+_vm._s(_vm.getCurrentValue())+" ")]):_c('div',[_vm._t("default")],2)])]),_c('v-col',{staticClass:"shrink pr-4 pl-3"},[_c('v-icon',{attrs:{"color":_vm.isMissing?'white':'',"disabled":_vm.dataField.disabled}},[_vm._v(" mdi-pencil")])],1)],1)],1)],1),_c('full-screen-dialog',{attrs:{"parameter":"singleLineEdit","value":_vm.routeParameter,"config-prop":_vm.configEl.fullScreenDialog}},[_c('toolbar',{attrs:{"title":_vm.translate(_vm.dataField.i18n),"show-save":false},on:{"save":_vm.save}}),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.waitingForReply),expression:"!waitingForReply"}],staticClass:"pt-5 pb-15"},[_vm._t("editWindowTop"),(_vm.rules)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.dataField.type==='text')?_c('v-text-field',{ref:_vm.dataField.fieldName,class:_vm.configEl.css,attrs:{"label":_vm.getLabel(_vm.dataField.i18n),"type":_vm.dataField.type,"rules":_vm.rules.getRulesArrayFromList(
                                            _vm.localValue,
                                            _vm.dataField.rules),"clearable":""},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):_vm._e(),(_vm.dataField.type === 'date')?_c('date-picker',{class:_vm.configEl.css,attrs:{"value":_vm.localValue,"label":_vm.getLabel(_vm.dataField.i18n),"rules":_vm.rules.getRulesArrayFromList(
                                            _vm.localValue,
                                            _vm.dataField.rules)},on:{"changed":function (value){_vm.localValue = value}}}):_vm._e(),(_vm.dataField.type==='radioGroup')?_c('v-radio-group',{staticClass:"mt-0",class:_vm.dataField.css,attrs:{"row":_vm.dataField.row},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},_vm._l((_vm.dataField.options),function(option,i){return _c('v-radio',{key:i,attrs:{"label":_vm.translate(option.label),"value":option.value}})}),1):_vm._e(),(_vm.dataField.type==='autoComplete')?_c('v-autocomplete',{ref:_vm.dataField.fieldName,class:_vm.dataField.css,attrs:{"items":_vm.dataField.items,"item-text":_vm.dataField.itemText,"item-value":_vm.dataField.itemValue,"label":_vm.getLabel(_vm.dataField.i18n),"rules":_vm.rules.getRulesArrayFromList(
                                            _vm.localValue,
                                            _vm.dataField.rules),"multiple":_vm.dataField.multiple,"clearable":_vm.dataField.hideClearable},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):_vm._e(),_vm._v(" "),(_vm.dataField.type==='switch')?_c('v-switch',{class:_vm.configEl.css,attrs:{"label":_vm.getLabel(_vm.dataField.i18n),"rules":_vm.rules.getRulesArrayFromList(
                                            _vm.localValue,
                                            _vm.dataField.rules)},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}):_vm._e(),(_vm.dataField.type==='pdfUpload')?_c('div',[_c('pdf-upload',{attrs:{"unique-id":_vm.dataField.fieldName,"name-of-currently-saved-pdf":_vm.localPdf?_vm.localPdf.pdfName:''},on:{"delete":_vm.setEmptyLocalPdf},model:{value:(_vm.selectedPdf),callback:function ($$v) {_vm.selectedPdf=$$v},expression:"selectedPdf"}})],1):_vm._e(),(_vm.dataField.type==='confirmCheck')?_c('single-line-edit-confirm-check',{attrs:{"targetValue":_vm.targetValue,"dataValue":_vm.localValue},on:{"changed":function (value){this$1.localValue = value; }}},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","fab":"","dark":""},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1):_vm._e()],2),_c('alert-message',{staticClass:"mx-5",attrs:{"config-prop":_vm.configEl.errorAlert,"config-package":"error","module-config":_vm.moduleConfig}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]),_c('progress-dialog',{attrs:{"waiting-for-reply":_vm.waitingForReply}}),_c('single-line-edit-email',{attrs:{"open":_vm.openEmailChangeInfo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }