<template>
    <full-screen-dialog parameter="voucherDetails" :value="voucherId">
        <toolbar :title="title" :show-save="false"></toolbar>
        <div v-if="voucher && organisations">
            <img v-if="getOrganisation(voucher.organisationId) && getOrganisation(voucher.organisationId).short"
                 height="36px" :alt="getOrganisation(voucher.organisationId).short" class="mt-5 ml-7"
                 :src="getOrganisation(voucher.organisationId).logoDark">
            <shop-item-vouchers-card class="mx-5 mt-2" :voucher="voucher"/>
            <voucher-details-data-list v-if="voucher" :voucher="voucher" :hide-redeem-information="true" class="pa-5"/>
        </div>
    </full-screen-dialog>
</template>

<script>
import FullScreenDialog from "@/utilities/components/dialogs/fullScreenDialog";
import Toolbar from "@/utilities/components/controls/toolbar";
import VoucherDetailsDataList from "@/components/voucher/voucherDetailsDataList";
import ShopItemVouchersCard from "@/components/shop/shopItem/shopItemVouchersCard";
export default {
    name: "shopItemVouchersDetails",
    components: {ShopItemVouchersCard, VoucherDetailsDataList, Toolbar, FullScreenDialog},
    props: ['voucher', 'organisations'],
    computed: {
        voucherId(){ return this.voucher ? this.voucher.id : 'undefined'},
        voucherIdFromPath(){ return this.$route.query.voucherId},
        title(){ return this.$i18n.t('common.voucher') + ' Info'}
    },
    methods: {
        getOrganisation(orgId){ return this.organisations?.[orgId]},
    },
}
</script>

<style scoped>

</style>