// Version 1.0 2022-04-12 - DH

import Vue from "vue";

Vue.filter('arrToString',(arr)=> {
    if(!arr) return ''
    let str = ''
    arr.forEach(v => {
        if(typeof v === 'string'){
            str += str.length === 0 ? v : ', ' + v
        }
    })
    return str
})
Vue.filter('licenseTitlesFromArray',arr => {
    if(!arr) return ''
    let str = ''
    arr.forEach(v => {
        if(v !== undefined) str += str.length === 0 ? v.title : ', ' +v.title
        else str += str.length === 0 ? 'undef ' : ', ' + 'undef '
    })
    return str
})

Vue.filter('getFieldFromMapOfArray',(arr, map, field)=> {
    if(!arr || !map || !field) return ''
    let str = ''
    arr.forEach(v => {
        if(map[v] && map[v][field]) str += str.length === 0 ? map[v][field] : ', ' + map[v][field]
        else str += str.length === 0 ? v : ', ' + v
    })
    return str
})