var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[{'fontPassDate': _vm.$vuetify.breakpoint.xsOnly},
                  {'font18': _vm.$vuetify.breakpoint.smAndUp},
                  {'font-weight-bold': _vm.userPass.style.textDate.isBold},
                  'cardDateTop', _vm.textColor]},[_vm._v(" "+_vm._s(_vm.userPass.style.textDate.value)+" ")]),_c('img',{staticClass:"cardLogo",attrs:{"height":"24%","alt":"Logo","src":_vm.logo}}),_c('div',{class:[{'titleDyn fontPassText': _vm.$vuetify.breakpoint.xsOnly},
                  {'titleFix font20': _vm.$vuetify.breakpoint.smAndUp},
                  'lineHeight-1-0 mt-1 text-left pl-2', _vm.textColor]},[_c('div',{class:[{'fontPassTextCust': _vm.$vuetify.breakpoint.xsOnly},
                      {'font18 textWithFix': _vm.$vuetify.breakpoint.smAndUp},
                       'space05 text-uppercase text-no-wrap']},[_c('div',{class:[{'my-6': !_vm.userPass.style.text2.value},
                      {'my-4': !_vm.userPass.style.text3.value},
                      {'my-2': !_vm.userPass.style.text4.value}]}),(_vm.userPass.style.text1.value)?_c('div',{class:[{'font-weight-black space1': _vm.userPass.style.text1.isBold}, 'text-truncate']},[_vm._v(" "+_vm._s(_vm.userPass.style.text1.value)+" ")]):_vm._e(),(_vm.userPass.style.text2.value)?_c('div',{class:[{'font-weight-black space1': _vm.userPass.style.text2.isBold}, 'text-truncate'],staticStyle:{"padding-top":"2px"}},[_vm._v(" "+_vm._s(_vm.userPass.style.text2.value)+" ")]):_vm._e(),(_vm.userPass.style.text3.value)?_c('div',{class:[{'font-weight-black space1': _vm.userPass.style.text3.isBold}, 'text-truncate'],staticStyle:{"padding-top":"2px"}},[_vm._v(" "+_vm._s(_vm.userPass.style.text3.value)+" ")]):_vm._e(),(_vm.userPass.style.text4.value)?_c('div',{class:[{'font-weight-black space1': _vm.userPass.style.text4.isBold}, 'text-truncate'],staticStyle:{"padding-top":"2px"}},[_vm._v(" "+_vm._s(_vm.userPass.style.text4.value)+" ")]):_vm._e()])]),_c('div',{class:[{'fontPassUserName': _vm.$vuetify.breakpoint.xsOnly},
                  {'font12': _vm.$vuetify.breakpoint.smAndUp},
                  'nameFooter font10 lineHeight-1-2 text-uppercase text-truncate',
                  _vm.textColor]},[_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }