<template>
    <full-screen-dialog parameter="profileImage" value="edit"
                        :config-prop="configEl.fullScreenDialog">
        <toolbar :title="translate(configEl.i18n)"
                 :show-save="false"
                 @save="save">
        </toolbar>
        <div style="width: 100%" v-if="show" v-show="!waitingForReply" class="pb-15">
            <image-upload-crop :image="newValue" collection="profileImages" @imageDeleted="deleteImage"
                               @selected="getImage" @getCropped="getCropped"
                                :delete-btn-color="configEl.deleteBtn.color">
                <v-btn v-if="!waitingForReply"
                       @click="save()" class="mx-5"
                       color="primary" fab dark>
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </image-upload-crop>
        </div>
    <!-- Block Window while saving -->
        <v-dialog v-model="waitingForReply" width="300">
            <v-card class="text-center pa-5">
                <p>{{$t('utilities.components.images.saving')}}</p>
                <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
            </v-card>
        </v-dialog>
    </full-screen-dialog>
</template>

<script>
    // Version 1.1 2022-01-18
    import FullScreenDialog from "@/utilities/components/dialogs/fullScreenDialog";
    import Toolbar from "@/utilities/components/controls/toolbar";
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import ImageUploadCrop from "@/utilities/components/images/tools/imageUploadCrop";
    import FirebaseStorage from "@/utilities/services/firebase/firebaseStorage";
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";

    export default {
        components: {
            //ImageUploadCrop,
            ImageUploadCrop,
            Toolbar,
            FullScreenDialog},
        name: "profile-image-edit",
        props: {
            user: {default: null},
            storageSourcePath: {default: ''},
            storageFolder: {default: ''},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            show: true, // used to clear value on dummy input when image is deleted!!!
            newValue: null,
            newValueSave: null,
            waitingForReply: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.images.profileImageEdit',
        // define here, which parameter should be customizeable
            configDefault: {
                i18n: 'utilities.components.images.profile',
                fullScreenDialog: {}
            },
        }),
        created(){
            this.setNewValueFromUser();
        },
        computed: {
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            save(){
                if ( this.newValue.url === '' ) {
                    this.deleteImageFromServer();
                } else {
                    this.saveNewOrChanged();
                }

            },
                async saveNewOrChanged(){
                    this.waitingForReply = true;
                    let dataObject = {
                        image: {
                            crop: this.newValue.crop,
                            urlMin: '',
                            url: '',
                        }
                    };
                    dataObject = await this.setUrlInImage(dataObject, true);
                    dataObject = await this.setUrlInImage(dataObject, false);
                    if ( dataObject.image.url || dataObject.image.urlMin ) {
                        await FirebaseWrite.update('users', this.user.uid, dataObject)
                        this.user.image = dataObject.image;
                        this.back();
                    }
                    this.waitingForReply = false;
                },
                async setUrlInImage(dataObject, min){
                    const jpeg = min ? 'Min.jpeg' : '.jpeg';
                    const param = min ? 'urlMin' : 'url';
                    if ( this.newValue[param].indexOf('data:image/jpeg') !== -1 ) {
                        dataObject.image[param] = await FirebaseStorage.addFile(
                            this.storageFolder + '/' + this.user.uid + jpeg,
                            this.newValue[param]
                        );
                        dataObject.image[param] = dataObject.image[param].replace(this.storageSourcePath, '');
                        dataObject.image[param] = dataObject.image[param].split('?')[1];
                    } else {
                        dataObject.image[param] = this.user.image[param];
                    }
                    return dataObject;
                },
                async deleteImageFromServer(){
                    this.waitingForReply = true;
                    await FirebaseStorage.deleteFile('/' + this.storageFolder + '/' + this.user.uid + '.jpeg');
                    await FirebaseStorage.deleteFile('/' + this.storageFolder + '/' + this.user.uid + 'Min.jpeg');
                    await FirebaseWrite.update('users', this.user.uid, {image: this.newValue});
                    this.user.image = JSON.parse(JSON.stringify(this.newValue));
                    this.back();
                    this.waitingForReply = false;
                },
            back(){
                if ( this.$route.query.profileImage === 'edit' ) {
                    window.history.back();
                }
            },
            getImage(image){
                this.newValue.url = image;
            },
            getCropped(image, coordinates) {
                if ( JSON.stringify(coordinates) !== this.newValue.crop ) {
                    this.newValue.urlMin = image;
                    console.log("CROP: ", coordinates);
                    this.newValue.crop = JSON.stringify(coordinates);
                }
            },
            deleteImage(){
                this.show = false;
                this.newValue = {
                    url: '',
                    urlMin: '',
                    crop: '',
                };
                setTimeout(()=>{this.show = true}, 100)
            },
            setNewValueFromUser(){
                this.newValue = JSON.parse(JSON.stringify(this.user.image));
                if ( this.newValue.url ) {
                    this.newValue.url = this.user.uid + '.jpeg?' + this.newValue.url;
                }
                if ( this.newValue.urlMin ) {
                    this.newValue.urlMin = this.user.uid + 'Min.jpeg?' + this.newValue.urlMin;
                }
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
        watch: {
            '$route.query.profileImage'(to){
                if ( to === 'edit' ) {
                    this.show = false;
                    this.setNewValueFromUser();
                    setTimeout(()=>{this.show = true}, 100)
                }
            }
        }
    }
</script>

<style scoped>

</style>