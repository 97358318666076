<template>
    <v-dialog v-model="dialog"
              :retain-focus="false"
              persistent
              fullscreen>
        <v-card tile class="pa-0"
                :color="configEl.card.color">
            <slot></slot>
        </v-card>
    </v-dialog>
</template>

<script>
    //Version 1.2 2022-03-03
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "full-screen-dialog",
        props: {
            parameter: {default: 'fullScreenDialog'},
            value: {default: 'true'},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            dialog: false,
       // define the path where the component can be found in the directory
            path: 'utilities.components.dialogs.fullScreenDialog',
        // define here, which parameter should be customizeable
            configDefault: {
                card: {
                    color: ''
                }
            },
        }),
        computed: {
            routeKey(){
                return this.$route.query[this.parameter]
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        created(){
            if ( this.routeKey === this.value ) {
                window.history.back();
            }
        },
        methods: {
            openOrClose(to, from){
                if ( to === this.value && !from ) {
                    this.dialog = true;
                } else if ( from === this.value && !to ) {
                    this.dialog = false;
                }
            }
        },
        watch: {
            routeKey(to, from){
                this.openOrClose(to, from);
            }
        }
    }
</script>

<style scoped>

</style>