<template>
    <v-dialog v-model="dialog"
              persistent
              fullscreen>
        <v-card tile class="pa-0 bgdGreyLight" color="#ECEFF1">
            <toolbar :title="$t('profile.addPerson')"
                     :show-save="false"
                     @save="save">
            </toolbar>
            <div style="max-width: 540px" class="mx-auto pb-15">
                <alert-message :config-prop="{show: true}" class="mx-4 mt-4 body-2">
                    {{$t('profile.childInfo')}}
                    <ul>
                        <li>{{$t('profile.childList1')}}</li>
                        <li>{{$t('profile.childList2')}}</li>
                        <li>{{$t('profile.childList3')}}</li>
                        <li>{{$t('profile.childList4')}}</li>
                        <li>{{$t('profile.childList5')}}</li>
                    </ul>
                </alert-message>
                <dynamic-form ref="dynForm" :data="newUser" class="px-5 mb-3"
                              :field-configs="userFormConfig"
                              :config-prop="dynFormConifg">
                </dynamic-form>
                <v-form ref="form" lazy-validation v-if="rules" @submit.prevent>
                    <v-checkbox v-if="childUnderAged"
                                v-model="ageConfirmed"
                                :rules="[rules.isTrue('','', true)]"
                                :label="$t('profile.confirmChildAge')"
                                class="mx-5">
                    </v-checkbox>
                </v-form>
                <alert-message :config-prop="errorAlert"
                               config-package="error"
                               class="mx-5">
                    {{errorMsg}}
                </alert-message>
                <btn-text @click="save()" :config-prop="{color: 'secondary', icon: 'mdi-check', block: true}"
                        class="mx-4 mb-15">
                    {{$t('profile.save')}}
                </btn-text>
            </div>
        </v-card>
    <!-- Block Window while saving -->
        <progress-dialog :waiting-for-reply="waitingForReply">
        </progress-dialog>
    </v-dialog>
</template>

<script>
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
    import Toolbar from "@/utilities/components/controls/toolbar";
    import {User} from "@/utilities/models/user/user";
    import DynamicForm from "@/utilities/components/form/dynamicForm";
    import {FormRules} from "@/utilities/services/form/formRules";
    import {USER_FORM_CONFIG} from '@/config/forms/userFormConfig';
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import ProgressDialog from "@/utilities/components/dialogs/progressDialog";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService';
    import TimeStamp from "../../utilities/services/timeStamp/timeStamp";
    import CountriesService from '@/utilities/services/countries/countriesService';
    import BtnText from "@/utilities/components/controls/btnText";

    export default {
        components: {
            BtnText,
            ProgressDialog,
            AlertMessage,
            DynamicForm,
            Toolbar},
        name: "profile-multi-user-add",
        data: () => ({
            dialog: true,
            newUser: null,
            rules: new FormRules(),
            userFormConfig: null,
            dynFormConifg: {
                basic: {
                    outlined: false,
                    dense: false
                },
                date: {
                    outlined: false,
                }
            },
            errorAlert: {
                show: false
            },
            errorMsg: '',
            waitingForReply: false,
            childId: null,
            ageConfirmed: false,
        }),
        computed: {
            user(){ return this.$store.getters.user},
            userMaster() { return this.$store.state.realtime.userMaster },
            parentUser(){ return this.userMaster ? new User(this.userMaster) : this.user; },
            userChilds(){ return this.$store.state.realtime.userChilds },
            userChildList() {
                return ObjectHandlingService.getListFromObject(
                    JSON.parse(JSON.stringify(this.userChilds)));
            },
            childUnderAged(){
                console.log (" this.newUser.birthday",  this.newUser.birthday);
                console.log (" this.newUser.birthday",  TimeStamp.checkIfAgeIsReached(this.newUser.birthday, 14));
                return this.newUser.birthday !== '' &&
                       !TimeStamp.checkIfAgeIsReached(this.newUser.birthday, 14)
            },
        },
        created(){
            this.setUser();
            this.setUserConfigForForm();
        },
        methods: {
            setUser(){
                this.childId = this.parentUser.getNextChild();
                const newUserUid = this.parentUser.uid + '_' + this.childId;
                this.newUser = new User({
                    uid: newUserUid,
                    id: newUserUid,
                    email: this.parentUser.email,
                    language: this.parentUser.language,
                    parentConfirmRequired: false,
                });
            },
            setUserConfigForForm(){
                this.userFormConfig = JSON.parse(JSON.stringify(USER_FORM_CONFIG));
                /*this.userFormConfig.birthday.rules = [{ruleName: 'required',
                    messageText: 'utilities.services.formRules.pleaseEnter',
                    active: true}];*/
                this.userFormConfig.birthday.rules = [{ruleName: 'required',
                    messageText: 'utilities.services.formRules.pleaseEnter',
                    active: true},
                    {ruleName: 'dateInFuture',
                        messageText: '',
                        active: true,
                        dateString: ''},
                    {ruleName: 'maxAge',
                        messageText: '',
                        active: true,
                        dateString: '',
                        maxAge: 18},
                ];
                delete this.userFormConfig.language;
                this.userFormConfig.nationality.items = CountriesService.getCountryListSortedWithPriority(this.$i18n.locale)
                this.userFormConfig.nationality.itemText = this.$i18n.locale;
            },
            async save(){
            // form correctly filled
                this.$refs.form.validate();
                if ( !this.$refs.dynForm.$refs.form.validate() ||
                    !this.$refs.form.validate()) { return }
            // check for duplicates
                if ( this.isDuplicateUser() ) { return }
            // set child in user
                this.parentUser.childs.push(this.childId);
            // save user
                this.waitingForReply = true;
                const reply = await FirebaseWrite.batch([
                    {action: 'set', collection: 'users', documentId: this.newUser.uid,
                     data: this.newUser.computeDatabaseObject()},
                    {action: 'update', collection: 'users', documentId: this.parentUser.uid,
                     data: {childs: this.parentUser.childs}}
                ]);
                this.waitingForReply = false;
                if ( reply.successful ) {
                    this.$store.dispatch('setMasterUser');
                    window.history.back();
                } else {
                    this.errorAlert.show = true;
                    this.errorMsg = reply.content;
                }
            },
                isDuplicateUser(){
                    let isDuplicate = false;
                    this.userChildList.forEach( (userChild) => {
                        if ( userChild.firstName === this.newUser.firstName &&
                             userChild.birthday === this.newUser.birthday) {
                            this.errorAlert.show = true;
                            this.errorMsg = this.$i18n.t('profile.personExisting');
                            isDuplicate = true;
                        }
                    });
                    return isDuplicate;
                }
        },
    }
</script>

<style scoped>

</style>