<template>
    <div @click="changeValue()" class="circle ml-4" :style="styles"
         v-ripple="!disabled">
        <v-icon v-if="value"
            color="white" large>mdi-check</v-icon>
    </div>
</template>

<script>
export default {
    name: "shop-cart-event-vouchers-check",
    props: ['value', 'disabled'],
    computed: {
        styles(){
            return this.disabled ?
                    this.value ? {border: 'solid 2px grey', backgroundColor: 'grey', color: 'white'} :
                                {border: 'solid 2px grey', backgroundColor: '#f2f2f2'} :
                    this.value ? {border: 'solid 2px #4CAF50', backgroundColor: '#4CAF50', color: 'white'} :
                                {border: 'solid 2px grey', backgroundColor: 'white'}
        }
    },
    methods: {
        changeValue(){
            if ( this.disabled ) return
            const newValue = !this.value
            //this.value = !this.value
            this.$emit('input', newValue)
        }
    },
}
</script>

<style scoped>
    .circle{ border-radius: 20px; height: 40px; width: 40px;}
</style>