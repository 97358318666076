<template>
    <div :class="configEl.container.css">
        <v-btn @click="back()"
               :color="configEl.color"
               fab
               large
               dark >
            <v-icon>{{configEl.icon}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    // Version 1.1 2022-03-12

    import ComponentConfigService from '@/utilities/services/components/componentConfigService';

    export default {
        name: "fab-btn-back",
        props: {
            handleBackInComponent: {default: false},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.fabBtnBack',
        // define here, which parameter should be customizeable
            configDefault: {
                container: {
                    css: 'fabBtnBottomRight'
                },
                color: 'primary',
                icon: 'mdi-arrow-left',
            },
        }),
        computed: {
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            back(){
                if ( this.handleBackInComponent ) {
                    this.$emit('back');
                } else {
                    window.history.back();
                }

            },

        },
    }
</script>

<style scoped>
    .fabBtnBottomRight {position: fixed; bottom: 15px; right: 15px}
</style>