// Version 1.1 2022-01-18
export let RESET_PW_CONFIG = {
    container: {
        css: 'px-5',
    },
    title: {
        i18n: 'utilities.components.auth.resetPW',
        css: 'text-h6 text-center'
    },
    passwordRule: {
        activated: true, // also configure in PW Reset
    },
    alert: {
        resendLinkText: {
            i18n: 'utilities.components.auth.resendLinkText'
        },
        linkIsExpired: {
            i18n: 'utilities.components.auth.linkIsExpired',
        },
        resetCompletedMsg: {
            i18n: 'utilities.components.auth.resetCompletedMsg',
        },
        css: '',
        show: true,
    },
    alertError: {
        show: true,
        css: 'text-left mt-5',
    },
    subAlert: {
        css: 'mt-3',
        show: false,
        color: 'grey',
        text: false,
        icon: 'mdi-email-fast',
        dense: true,
        timeout: 4000,
        i18n: 'utilities.components.auth.emailSent',
    },
    email: {
        i18n: 'utilities.components.common.email',
        outlined: true,
        dense: true,
        required: {
            i18n: 'utilities.services.formRules.pleaseEnter',
        },
    },
    password: {
        i18n: 'utilities.components.auth.password',
        outlined: true,
        dense: true,
        css: '',
        required: {
            i18n: 'utilities.components.auth.passwordRequired',
        },
    },
    form: {
        i18n: 'utilities.components.auth.linkIsExpired',
        css: '',
        infoStart: {
            i18n: 'utilities.components.auth.requestLinkInfo'
        },
        info: {
            i18n: 'utilities.components.auth.resetPWInfo'
        },
    },
    sendLinkBtn: {
        i18n: 'utilities.components.common.sendLink',
        block: true,
    },
    resendLinkBtn: {
        i18n: 'utilities.components.common.resendLink',
    },
    saveAndLoginBtn: {
        i18n: 'utilities.components.common.saveAndLogin',
        block: true,
    },
    cancelBtn: {
        block: true,
        css: 'mt-3',
        icon: '',
        i18n: 'utilities.components.common.back',
    }
};