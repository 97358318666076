<template>
    <v-list-item @click="startEdit()" v-ripple style="border-radius: 6px"
                :class="[{'error white--text': !userComplete()},
                         {'bgdGreyLight': userComplete()}]">
        <v-list-item-action >
            <v-icon :color="userComplete()?'':'white'">
                mdi-file
            </v-icon>
        </v-list-item-action>
        <v-list-item-content class=" "
                 :class="[{'white--text': !userComplete()},
                         {'grey--text text--darken-2': userComplete()},
                         'body-1']">
            {{$t('profile.parentAuth')}}
        </v-list-item-content>
        <v-list-item-action>
            <v-icon v-if="!userComplete()"
                    color="white">
                mdi-alert
            </v-icon>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    import RouterServices from '@/utilities/services/router/routerServices';
    export default {
        name: "profile-edit-parent-confirm-list-item",
        computed: {
            parentConfirmStore() { return this.$store.state.realtime.parentConfirm },
        },
        methods: {
            startEdit(){
                RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                    'documentEdit', 'parentConfirm');
            },
            userComplete(){
                return this.parentConfirmStore &&
                     this.parentConfirmStore.fileName &&
                    (this.parentConfirmStore.review.status === null ||
                        this.parentConfirmStore.review.status === true);
            }
        },
    }
</script>

<style scoped>

</style>