<template>
    <div>
        <alert-message :config-prop="config.alertMessage" class="mx-4 mt-4 mb-4">
            <div v-if="shopEvent.data.requiredPassEventIds !== undefined &&
                       shopEvent.data.requiredPassEventIds.length > 1">
                {{$t('shopEvent.licenseMissingMessageMultiple')}}
            </div>
            <div v-else class="body-2">
                {{$t('shopEvent.licenseMissingMessage')}}
            </div>
            <div class="font-weight-bold">
                {{getRequiredPassName()}}
            </div>
        </alert-message>
    </div>
</template>

<script>
    import AlertMessage from "@/utilities/components/displays/alertMessage";

    export default {
        components: {AlertMessage},
        name: "shop-event-required-licenses-existing",
        props: ['shopEvent'],
        computed: {
            scrollTo(){ return this.$store.state.local.scrollTo; },
        },
        data: () => ({
            config: {
                alertMessage: {
                    show: true,
                },
                buyNowBtn: {
                    small: true,
                    color: 'secondary',
                }
            }
        }),
        methods: {
            getRequiredPassName(){
                return this.shopEvent?.getRequiredPassName()
            }
        },
    }
</script>

<style scoped>

</style>