import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
import {
    conditionComparator,
    conditionsUser,
    conditionTypes
} from "@/modules/createAndUpdateLicense/constants/conditionConstants";
import {dateParamTypes} from "@/modules/createAndUpdateLicense/constants/dateConstants";
import {
    MULTI_LICENSE_CONDITION_TYPES, MULTI_LICENSE_OPERATORS
} from "@/modules/createAndUpdateLicense/components/conditions/conditionTypes/multiLicenseConditions/model/models";

export default class ConditionCheckService{

    checkConditionUser(conditions, user, userLicensesRaw){
        let checkResult = this.checkForNullOrUndefined(conditions, user, userLicensesRaw)
        if(!checkResult.result) return checkResult

        this.prepareCheckResult(conditions, checkResult)
        this.userLicenses = this.flattenUserLicenses(userLicensesRaw)
        const today = TimeStamp.getTodaysDateString()
        this.multiLicenseConditionMessage = ''

        // console.log('checker')
        Object.keys(conditions).forEach(key => {
            // conditions User block
            if(conditions[key].type === conditionTypes.USER){
                // user AGE
                if(conditions[key].param === conditionsUser.AGE){
                    switch (conditions[key].comparator){
                        case conditionComparator.LESS:
                            if(user.birthday >= conditions[key].value) {
                                checkResult.user.age.result = false
                                checkResult.user.age.errorMsg = 'user too young'
                            }
                            return
                        case conditionComparator.GREATER:
                            if(user.birthday <= conditions[key].value) {
                                checkResult.user.age.result = false
                                checkResult.user.age.errorMsg = 'user too old'
                            }
                            return
                        case conditionComparator.LESS_OR_EQUAL:
                            if(user.birthday > conditions[key].value) {
                                checkResult.user.age.result = false
                                checkResult.user.age.errorMsg = 'user too young'
                            }
                            return
                        case conditionComparator.GREATER_OR_EQUAL:
                            if(user.birthday < conditions[key].value) {
                                checkResult.user.age.result = false
                                checkResult.user.age.errorMsg = 'user too old'
                            }
                            return
                    }
                }
                //user SEX
                if(conditions[key].param === conditionsUser.SEX){
                    if(user.sex === conditions[key].value) {
                        checkResult.user.sex.result = true;
                        checkResult.user.sex.errorMsg = '';
                    }
                }
                //user COUNTRY
                if(conditions[key].param === conditionsUser.COUNTRY){
                    if(user.nationality === conditions[key].value) {
                        checkResult.user.country.result = true;
                        checkResult.user.country.errorMsg = '';
                    }
                }
                //user USER_PASS
                if(conditions[key].param === conditionsUser.USER_PASS){
                    if(user.user_passes && user.user_passes[conditions[key].value]){
                        if(user.user_passes[conditions[key].value].from <= today && today <= user.user_passes[conditions[key].value].until) {
                            checkResult.user.userPass.result = true
                            checkResult.user.userPass.errorMsg = ''
                        } else if (user.user_passes[conditions[key].value].from <= today && today >= user.user_passes[conditions[key].value].until){
                            checkResult.user.userPass.errorMsg = 'user pass expired'
                        } else {
                            checkResult.user.userPass.errorMsg = 'user pass not yet valid'
                        }
                    }
                }
                // user USER_LICENSES
                if(conditions[key].param === conditionsUser.LICENSE){
                    if(!this.userLicenses[conditions[key].value] && conditions[key].comparator === conditionComparator.EQUAL){
                        checkResult.user.license.result = false
                        checkResult.user.license.errorMsg = `missing licenseId: ${conditions[key].value}`
                    } else if (this.userLicenses[conditions[key].value] && conditions[key].comparator === conditionComparator.NOT){
                        checkResult.user.license.result = false
                        checkResult.user.license.errorMsg = `should not have licenseId: ${conditions[key].value}`
                    }
                }
            }
            // conditions Date block
            if(conditions[key].type === conditionTypes.DATE){
                if(conditions[key].param === dateParamTypes.NOW){
                    switch (conditions[key].comparator){
                        case conditionComparator.LESS:
                            if(today >= conditions[key].value) {
                                checkResult.date.result = false
                                checkResult.date.errorMsg = 'license expired'
                            }
                        return;
                        case conditionComparator.GREATER:
                            if(today <= conditions[key].value) {
                                checkResult.date.result = false
                                checkResult.date.errorMsg = 'license not yet valid'
                            }
                        return;
                        case conditionComparator.LESS_OR_EQUAL:
                            if(today > conditions[key].value) {
                                checkResult.date.result = false
                                checkResult.date.errorMsg = 'license expired'
                            }
                        return;
                        case conditionComparator.GREATER_OR_EQUAL:
                            if(today < conditions[key].value) {
                                checkResult.date.result = false
                                checkResult.date.errorMsg = 'license not yet valid'
                            }
                        return;
                    }
                }
            }
            // new MultiLicenseCondition
            if(conditions[key].type === MULTI_LICENSE_CONDITION_TYPES.GROUP){
                const myResult = this.multiGroupLicenseConditionChecker(conditions[key])
                if(!myResult) {
                    checkResult.user.license.result = false
                    checkResult.user.license.errorMsg = this.multiLicenseConditionMessage
                    checkResult.result = false
                }
                //console.log(myResult)
            }
        })

        // late user.length check to get date check - optimize
        if(Object.keys(user).length === 0) {
            return {
                result: false,
                date: checkResult.date,
                user:{
                    age:{result: false, errorMsg: 'missing user'},
                    sex:{result: false, errorMsg: 'missing user'},
                    country:{result: false, errorMsg: 'missing user'},
                    userPass:{result:false, errorMsg:'missing user'},
                    license:{result:false, errorMsg:'missing user'},
                }
            }
        }

        this.checkCheckResult(checkResult)
        return checkResult
    }

    multiGroupLicenseConditionChecker(group){
        let groupResults = []

        Object.keys(group.groups).forEach(groupMember => {
            if(group.groups[groupMember].type === MULTI_LICENSE_CONDITION_TYPES.GROUP){
                groupResults.push(this.multiGroupLicenseConditionChecker(group.groups[groupMember]))
            } else if(group.groups[groupMember].type === MULTI_LICENSE_CONDITION_TYPES.LICENSES) {
                groupResults.push(this.multiLicenseConditionChecker(group.groups[groupMember]))
            }
        })

        return this.multiLicenseGroupResultChecker(group.operator, groupResults) // must be boolean!
    }

    multiLicenseGroupResultChecker(operator, groupResults){

        switch (operator) {
            case MULTI_LICENSE_OPERATORS.ALL_OF_THEM:
                return groupResults.reduce((a,b)=> a && b)
            case MULTI_LICENSE_OPERATORS.NONE_OF_THEM:
                return groupResults.reduce((a,b) => !a && !b)
            case MULTI_LICENSE_OPERATORS.AT_LEAST_ONE:
                return groupResults.reduce((a,b)=> a || b)
            default:
                console.error('unknown operator: ', operator)
                return false
        }
    }

    multiLicenseConditionChecker(licenseGroup){
        let result = true

        switch (licenseGroup.operator) {
            case MULTI_LICENSE_OPERATORS.ALL_OF_THEM:
                for (const id of licenseGroup.licenseIds) {
                    if(!this.userLicenses[id]) {
                        this.multiLicenseConditionMessage += 'should have ' + id
                        result = false
                        break
                    }
                }
                break
            case MULTI_LICENSE_OPERATORS.NONE_OF_THEM:
                for (const id of licenseGroup.licenseIds) {
                    if(this.userLicenses[id]) {
                        this.multiLicenseConditionMessage += 'should not have ' + id
                        result = false
                        break
                    }
                }
                break
            case MULTI_LICENSE_OPERATORS.AT_LEAST_ONE:
                result = false
                licenseGroup.licenseIds.forEach(id => {
                    if(this.userLicenses[id]) result = true
                })
                break
            default:
                break
        }

        //console.log(licenseGroup)
        //console.log(this.userLicenses)

        return result
    }






    prepareCheckResult(conditions, checkResult){
        Object.keys(conditions).forEach(key => {
            if(conditions[key].type === conditionTypes.USER){
                if(conditions[key].param === conditionsUser.SEX) {
                    checkResult.user.sex.result = false
                    checkResult.user.sex.errorMsg = 'no matching sex'
                }
                if(conditions[key].param === conditionsUser.COUNTRY) {
                    checkResult.user.country.result = false
                    checkResult.user.country.errorMsg = 'no matching country'
                }
                if(conditions[key].param === conditionsUser.USER_PASS) {
                    checkResult.user.userPass.result = false
                    checkResult.user.userPass.errorMsg = 'missing user pass'
                }
            }
        })
    }

    // cheap but works
    checkCheckResult(checkResult){
        if( !checkResult.date.result
            || !checkResult.user.userPass.result
            || !checkResult.user.sex.result
            || !checkResult.user.age.result
            || !checkResult.user.license.result
            || !checkResult.user.country.result
        ) {
            checkResult.result = false
        }
    }

    flattenUserLicenses(userLicenses){
        let obj = {};
        if(userLicenses){
            Object.keys(userLicenses).forEach(key => {
                if(typeof userLicenses[key] === "object"){
                    Object.keys(userLicenses[key]).forEach(licenseKey => {
                        obj[licenseKey] = userLicenses[key][licenseKey]
                    })
                }
            })
        }
        return obj
    }
    checkForNullOrUndefined(conditions, user, userLicenses) {
        let checkResult = {}
        if(!conditions){
            checkResult.result = false
            checkResult.reason = 'conditions failed: not a valid object'
            return checkResult
        }
        if(!user) {
            checkResult.result = false
            checkResult.reason = 'user failed: not a valid object'
            return checkResult
        }
        if(!userLicenses) {
            checkResult.result = false
            checkResult.reason = 'userLicensesRaw failed: not a valid object'
            return checkResult
        }
        return {
            result:true,
            date: {result: true, errorMsg: ''},
            user: {
                age: {result: true, errorMsg: ''},
                sex: {result: true, errorMsg: ''},
                country: {result: true, errorMsg: ''},
                userPass: {result: true, errorMsg: ''},
                license: {result: true, errorMsg: ''},
            },
        }
    }
}
