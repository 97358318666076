<template>
    <v-row cols="12" class="body-2 rounded-lg elevation-2 " no-gutters align="center" @click="clicked()"
           :style="{backgroundColor: getStyles().color}">
        <v-col :class="[textColor(getStyles()), 'grow px-3']" :style="getDynStyle()">
            <!-- title -->
            <div  class="mt-2 fontInter font-weight-black space05 text-uppercase font16">
                {{$t(getBenefitText())}}*
            </div>
            <div class="fontInter font16 space03 lineHeight-1-0">{{voucher.title}}</div>
            <div class="caption my-2">{{$t('common.validUntil')}}
                {{ voucher.validUntil | europeanDateFormat}}</div>
        </v-col>
        <v-col class="shrink" v-if="voucher.showValue!=='notAtAll'">
            <div :class="[{'white black--text': !getStyles().darkCircle},
                    {'black white--text': getStyles().darkCircle},
                    'priceTag priceTagRound elevation-10 lineHeight-1-0 text-center my-2 mr-2']">
                <!--<div class="text-uppercase font10" style="padding-top: 10px">
                    <span v-if="voucher.showValue==='percent'">{{$t('shopVoucher.discount')}}</span>
                    <span v-else>
                        <span v-if="!voucher.upTo">{{$t('common.worth')}}</span>
                        <span v-else>{{$t('common.upTo')}}</span>
                    </span>
                </div>-->
                <div class="font24 pt-4 font-weight-black">
                    <span v-if="voucher.showValue!=='percent'">
                        €</span>{{voucher.value}}<span v-if="voucher.showValue==='percent'">%</span>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>

export default {
    // V2023
    name: "shop-item-vouchers-card",
    props: ['voucher'],
    methods: {
        textColor(styles){
            return styles.textColor ? '' :
                styles.dark ? 'lightGrey--text' : 'primary--text';
        },
        getStyles(){
            return this.voucher.styles.color ? this.voucher.styles :
                this.$store.state.organisations[this.voucher.organisationId].styles
        },
        getDynStyle(){
            return this.getStyles().textColor?{color: this.getStyles().textColor}:''
        },
        clicked(){
            this.$emit('click', this.voucher);
        },
        getBenefitText(){
            // attention for changes: is also methods in voucher.js
            return this.voucher.benefitKey === 'other' ? this.voucher.benefitText : 'shopVoucher.' + this.voucher.benefitKey;
        }
    },
}
</script>

<style scoped>
    .priceTag{ border-radius: 50% }
    .priceTagRound {
        width: 56px; height: 56px;
    }
</style>