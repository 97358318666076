export const dateInputTypes = {
    datePicker: 'DATEPICKER',
}

export const dateParamTypes = {
    NOW:'NOW',
}


Object.freeze(dateParamTypes);
Object.freeze(dateInputTypes);