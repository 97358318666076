<template>
    <full-screen-dialog parameter="otherMemberships" :value="membershipOrganisation">
        <toolbar :title="$t('common.otherMemberships')"
                 :show-save="false">
        </toolbar>
        <div v-if="membership" class="pb-15 mx-auto" style="max-width: 480px" >
            <!--<div class="text-center pt-5 pb-3">
                <img :src="membership.logo" width="124" height="124"/>
            </div>-->
            <v-row class="px-4 pt-5 pb-3">
                <v-col>
                    <img :src="membership.logo" width="124" height="124" alt="Logo"/>
                </v-col>
                <v-col class="text-right">
                    <qr-code-display :qrValue="membership.id" :size="108"
                                     class="d-inline-block"/>
                </v-col>
            </v-row>
            <div class="px-4 pb-5">
                <v-card flat class="rounded-lg">
                    <v-row no-gutters>
                        <v-col cols="9" class="text-truncate pa-3">
                            <div class="text-truncate mb-n2">{{membership.firstName}}</div>
                            <div class="text-truncate text-h6 text-uppercase">{{membership.lastName}}</div>
                            <div>{{membership.birthday}} / {{membership.ageGroup}}</div>
                            <div class="grey d-inline-block font18 mr-3 font-weight-bold white--text
                                        darken-2 rounded px-2">
                                {{membership.country}}
                            </div>
                        </v-col>
                        <v-col v-if="membership.others" cols="3" class="pr-3 pt-3 text-right">
                            <img width="100%" style="max-width: 96px;"
                                 class="rounded"
                                 :src="getImage(membership.others)">
                        </v-col>
                    </v-row>
                    <div v-if="membership.userValidities.length > 0" class="px-3 pb-1">
                        <v-divider class="pb-2"/>
                        <v-row align="center" no-gutters class="pb-1"
                               v-for="(validity, i) in membership.userValidities" :key="i">
                            <v-col class="shrink pr-3">
                                <v-icon v-if="validity.status" color="success">mdi-check</v-icon>
                                <v-icon v-else color="error">mdi-alert</v-icon>
                            </v-col>
                            <v-col>
                                {{validity.label[$i18n.locale]}}
                            </v-col>
                            <v-col class="text-right">
                                <span v-if="validity.validUntil">{{validity.validUntil}}</span>
                                <span v-else>-</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <v-card v-for="(membershipDetails, i) in membership.memberships" :key="i"
                        flat class="rounded-lg px-3 pb-3 pt-2 mt-3">
                    <div class="text-h6">
                        {{membershipDetails.label[$i18n.locale]}} {{membershipDetails.membershipNr}}
                    </div>
                    <v-divider class="mt-1 mb-2"/>
                    <div v-for="(detail, i) in membershipDetails.details" :key="i">
                        <div class="">
                            {{detail.label[$i18n.locale]}} {{detail.value}}
                        </div>
                    </div>
                    <div>
                        {{$t('common.validUntil')}}:
                        <span v-if="membershipDetails.validUntil">{{membershipDetails.validUntil}}</span>
                        <span v-else>{{$t('common.unlimited')}}</span>
                    </div>
                </v-card>
            </div>
        </div>
    </full-screen-dialog>
</template>

<script>
import FullScreenDialog from "@/utilities/components/dialogs/fullScreenDialog";
import Toolbar from "@/utilities/components/controls/toolbar";
import QrCodeDisplay from "@/utilities/components/qrCode/qrCodeDisplay";

export default {
    name: "walletOtherDetails",
    components: {QrCodeDisplay, Toolbar, FullScreenDialog},
    props: ['membershipOrganisation'],
    computed: {
        membership(){ return this.$store.state.otherMemberships[this.membershipOrganisation]},
    },
    methods: {
        getImage(others){
            const path = this.$store.state.config.storage.profileImages.indexOf('dev')!==-1 ? '-test':'-app'
            return "https://firebasestorage.googleapis.com/v0/b/"+ this.membership.organisation +
                path + ".appspot.com/o/passfotos%2F" + this.membership.id + '.jpeg?' + others
        }
    },
}
</script>

<style scoped>

</style>