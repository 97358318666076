var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-4 mr-2"},[_vm._l((_vm.cards),function(card,i){
var _obj;
return _c('span',{key:i},[(_vm.showCard(card))?_c('wallet-card',{class:[( _obj = {}, _obj[_vm.floatingClasses[card.id]] = _vm.$vuetify.breakpoint.xsOnly, _obj ),
                              {'mb-2': _vm.$vuetify.breakpoint.xsOnly },
                              {'mb-4': _vm.$vuetify.breakpoint.smAndUp },
                              {'archive': _vm.archive }],style:(_vm.getStyle(card)),attrs:{"section":_vm.section,"document":card,"floating-class":_vm.floatingClasses[card.id],"hide-corner-tag":_vm.hideCornerTag}},[(_vm.cardList==='userLicenses')?_c('wallet-events-content',{attrs:{"userEvent":card}}):_vm._e(),(_vm.cardList==='userPasses')?_c('wallet-passes-content',{attrs:{"userPass":card}}):_vm._e(),(_vm.cardList==='userVouchers')?_c('wallet-vouchers-content',{attrs:{"userVoucher":card}}):_vm._e()],1):_vm._e()],1)}),_c('div',{staticClass:"clearFloat"}),(_vm.walletZoomed.section)?_c('div',{class:[{'placeholderBoxDyn': _vm.$vuetify.breakpoint.xsOnly},
                 {'placeholderBoxFix': _vm.$vuetify.breakpoint.smAndUp},
                  'bgdGreyLight']}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }