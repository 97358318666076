<template>
    <div>
        <div v-if='missingOnly && !consentStatus && initLoadCompleted' class="px-4 error--text body-2 py-3 font-weight-bold">
            {{$t(headerText)}}
        </div>
        <div v-if="!refresh">
            <div v-for="(consent, i) in consentsList" :key="i">
                <div v-if="!missingOnly && organisations && (i===0 || consent.organisationId !== consentsList[i-1].organisationId)"
                    class="px-3 pt-3 pb-1 white mb-3">
                    <img v-if="getOrganisation(consent.organisationId) && getOrganisation(consent.organisationId).short"
                         height="42px" :alt="getOrganisation(consent.organisationId).short"
                         :src="getOrganisation(consent.organisationId).logoDark">
                </div>
                <v-row no-gutters align="center" justify="center"
                       class="px-3 " style="border-radius: 6px; letter-spacing: 0">
                    <v-col class="grow">
                        <single-line-edit :data-field="consent.field"
                                          :data-document="userConsents"
                                          :targetValue="consent.targetValue"
                                          collection="user_consents"
                                          :config-prop="{dark: !consent.value, showRequiredAlert: true}"
                                          :set-update-ts="true"
                                          @updated="updated">
                            <div class="white--text lineHeight-1-2"
                                 v-if="!$route.query.singleLineEdit && consent.value < consent.targetValue">
                                {{$t('consents.accept')}}
                            </div>
                            <span v-if="$route.query.singleLineEdit===getRouteParameter(consent.field) ||
                                        consent.value > consent.targetValue"
                                  class="pb-3">
                                {{$t('consents.generic1')}}
                                <span @click.stop>
                                    <a :href="consent.field.link"
                                       target="_blank">{{$t(consent.field.i18n)}}</a>
                                </span>
                                {{$t('consents.generic2')}}
                            </span>
                            <div v-if="!missingOnly && consent.value > consent.targetValue"
                                 class="caption font-weight-medium secondary--text text--lighten-2">
                                {{getDateTimeString(consent.value)}}
                            </div>
                        </single-line-edit>
                    </v-col>
                </v-row>
                <v-divider v-if="!missingOnly" class="mt-2 mb-3 mx-3"></v-divider>
            </div>
        </div>
        <v-divider v-if="missingOnly&&!this.headerInfo" class="mx-3"></v-divider>
    </div>
</template>

<script>
import SingleLineEdit from "@/utilities/components/controls/singleLineEdit";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
import {ShoppingCart} from "@/services/shop/shoppingCart";
export default {
    name: "user-consents-list",
    components: {SingleLineEdit},
    props: ['missingOnly', 'requiredConsents', 'consentStatus', 'organisations',
            'headerInfo', 'organisationId', 'queryParameter', 'blockShoppingCartRefresh'],
    computed: {
        userConsents(){ return this.$store.state.userConsents},
        headerText(){ return !this.headerInfo ? 'consents.missingHeader' : this.headerInfo }
    },
    data: () => ({
        consentsList: [],
        refresh: false,
        blockRefresh: 0,
        initLoadCompleted: false,
    }),
    created(){
        this.init();
    },
    methods: {
        getRouteParameter(consentField){
            return consentField.queryParameter?consentField.fieldName + '_' + consentField.queryParameter :
                consentField.fieldName
        },
        async init(){
            await this.userConsents.verifyConsent(this.requiredConsents, this.organisationId);
            this.refreshConsent()
        },
        updated(dataDocument){
            setTimeout(()=>{this.refresh = true}, 100)
            setTimeout(()=>{this.refreshConsent(dataDocument)}, 100)
            if ( !this.blockShoppingCartRefresh ) {
                this.$store.state.shoppingCart = this.$store.state.shoppingCart ? new ShoppingCart() : null
                sessionStorage.removeItem('shoppingCart')
            }
        },
        refreshConsent(dataDocument){
            this.consentsList = this.userConsents?.getConsentList(this.missingOnly, this.requiredConsents,
                                                                  this.organisationId, this.queryParameter)
                .sort((a, b) => a.organisationId > b.organisationId ? 1 : -1);
            this.refresh = false;
            this.$emit('consentRefreshed', this.consentsList.length === 0);
            if ( dataDocument ) {
                this.blockRefresh = 2; //2 because consent is set to null and then refilled with 100ms delay
                this.userConsents.setConsents(dataDocument.consents)
            }
            this.initLoadCompleted = true;
        },
        getDateTimeString(ts){
            return TimeStamp.getLocalStringFromGMTts(ts)
        },
        getOrganisation(orgId){ return this.organisations?.[orgId]},
    },
    watch: {
        'userConsents.consents': {
            handler(to, from){
                to && !from && this.blockRefresh === 0 ? this.refreshConsent() :
                                                         this.blockRefresh > 0 ? this.blockRefresh-- : null
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>