<template>
    <div>
        <div :class="configEl.label.css">
            {{translate(label)}}
        </div>
        <div :class="configEl.value.css">
            <slot></slot>
        </div>
        <v-divider v-if="configEl.divider.show"
                :class="configEl.divider.css"></v-divider>
    </div>
</template>

<script>
    // Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "line-with-label",
        props: {
            label: {default: null},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.displays.lineWithLabel',
        // config
            configDefault: {
                label: {
                    css: 'caption grey--text text-uppercase text--darken-1'
                },
                value: {
                    css: ''
                },
                divider: {
                    show: true,
                    css: 'mt-2 mb-3'
                }
            }
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>