<template>
    <div style="max-width: 600px" class="mx-auto">
        <v-badge color="error"
                 overlap
                 top
                 style="width: 100%"
                 :value="shoppingCart.getNumberOfItems()>0"
                 :content="shoppingCart.getNumberOfItems()">
            <btn-text :config-prop="configs.cartBtn"
                      @click="gotoShoppingCart()"
                      :disabled="!shoppingCart || shoppingCart.getNumberOfItems()===0">
                {{$t('shop.gotoShoppingCart')}}
            </btn-text>
        </v-badge>
    </div>
</template>

<script>
    import BtnText from "@/utilities/components/controls/btnText";

    export default {
        components: {BtnText},
        name: "shop-cart-btn",
        computed: {
            shoppingCart(){
                return this.$store.state.shoppingCart;
            }
        },
        data: () => ({
            configs: {
                cartBtn: {
                    color: 'secondary',
                    block: true,
                    icon: 'mdi-cart-arrow-right'
                }
            }
        }),
        methods: {
            gotoShoppingCart(){this.$emit('gotoShoppingCart');}
        },
    }
</script>

<style scoped>

</style>