<template>
    <v-list-item class="bgdGreyLight">
        <v-list-item-icon>
            <v-icon>{{icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-subtitle>
                <span v-if="uppercase">{{title.toUpperCase()}}</span>
                <span v-else>{{title}}</span>
            </v-list-item-subtitle>
            <v-list-item-title>
                <slot></slot>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        name: "wallet-detail-item",
        props: {
            icon: {default: ''},
            title: {default: ''},
            uppercase: {default: true},
        },
    }
</script>

<style scoped>

</style>