export class ConfigStripe {
    constructor(){
        // for all instances of this module in the application
        /* this.componentName not usable on this level!!!
           this.locales not usable on this level!!!*/
        this.content = {
            stripe: { // = root !!!
            },
        };
        this.utilities = {
            components: {
                dialogs: {
                    confirmDialog: {
                        default: {
                        },
                        delete: {
                        },
                        confirm: {
                        },
                        save: {
                        },
                    }
                }
            }
        };
    }
}