<template>
    <v-dialog v-model="dialog" fullscreen>
    <!-- logo -->
        <v-card class="text-center bgdGreyMedium" color="#CFD8DC">
            <img src="@/assets/oym_logo_final_black.svg" class="mt-15"
                 :width="$vuetify.breakpoint.xsOnly?'200px':'300px'"/>
            <div class="body-2 px-10 mt-5">
                Möchtest du On Your Marks in deiner Rolle als Spieler oder als Mitglied eines
                Veranstalter-Teams nutzen?
            </div>
            <v-row no-gutters class="mt-5">
                <v-col cols="12" sm="6" class="mb-10">
                    <div @click="select(false)"
                         class="d-inline-block px-5 buttonBorders"
                         v-ripple>
                        <v-icon large class="pb-0"
                                style="zoom: 3"
                                color="primary" >
                            mdi-account
                        </v-icon>
                        <div class="text-h6" style="margin-top: -10px">SPIELER</div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <div @click="select(true)"
                         class="d-inline-block px-5 buttonBorders"
                         v-ripple>
                        <v-icon large style="zoom: 3" color="primary">mdi-badge-account</v-icon>
                        <div  class="text-h6">
                            VERANSTALTER
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "home-selection",
        data: () => ({
            dialog: true,
        }),
        methods: {
            select(isOrganizer){
                this.$store.state.local.userMode.isOrganizer = isOrganizer;
                this.$store.state.local.userMode.selected = true;
                if ( isOrganizer ) {
                    this.$router.push('organisation');
                }
            }
        },
    }
</script>

<style scoped>
    .buttonBorders{border-radius: 10px}
</style>