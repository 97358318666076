<template>
    <div :class="[configEl.css, 'text-center']">
        <div v-show="!loginService.waitingForReply">
            <!--<p :class="configEl.title.css">{{translate(configEl.title.i18n)}}</p>-->
            <v-form ref="form" lazy-validation v-if="rules" @submit.prevent>
                <v-text-field v-model="loginService.email"
                              persistent-hint
                              :hint="translate(configEl.email.i18n)"
                              @keyup="keyPressed($event)"
                              :rules="[rules.required(loginService.email,
                                        $t(configEl.email.required.i18n),true),
                                       rules.email(loginService.email,'',true)]"
                              :outlined="configEl.email.outlined"
                              :dense="configEl.email.dense"
                              clearable>
                </v-text-field>
                <v-text-field v-if="loginService.userPasswortStatus==='set'"
                              v-model="loginService.password"
                              persistent-hint
                              :hint="translate(configEl.password.i18n)"
                              @keyup="keyPressed($event)"
                              :rules="[rules.required(loginService.password,
                                        $t(configEl.password.required.i18n), true)]"
                              :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPW ? 'text' : 'password'"
                              @click:append="showPW = !showPW"
                              :outlined="configEl.password.outlined"
                              :dense="configEl.password.dense"
                              clearable>
                </v-text-field>
            <!-- confirm button -->
                <v-row no-gutters class="mt-1"
                       align="center"
                       justify="center"
                       v-if="loginService.userPasswortStatus!=='notSet'">
                    <v-col class="grow pb-2">
                        <slot></slot>
                    </v-col>
                    <v-col class="shrink px-3 pb-2">
                        {{$t('utilities.components.common.or')}}
                    </v-col>
                    <v-col class="grow pb-2">
                        <btn-text :config-prop="configEl.btn.config"
                                  @click="processSubmit()">
                            {{btnText}}
                        </btn-text>
                    </v-col>
                </v-row>
            <!-- alert message password set -->
                <alert-message v-else :config-prop="configEl.initPassword">
                    {{translate(configEl.initPassword.i18n)}}
                </alert-message>
            </v-form>
        <!-- error message -->
            <alert-message v-if="loginService.errorI18n"
                         config-package="error"
                        :config-prop="configEl.errorAlert">
                {{errorMessage}}
            </alert-message>
        </div>
    <!-- progress circle -->
        <v-progress-circular indeterminate color="primary"
                             v-show="loginService.waitingForReply">
        </v-progress-circular>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import {FormRules} from "@/utilities/services/form/formRules";
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import BtnText from "@/utilities/components/controls/btnText";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import RouterServices from '@/utilities/services/router/routerServices';

    export default {
        components: {
            AlertMessage,
            BtnText,
        },
        name: "login",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            rules: new FormRules(),
            showPW: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.login',
        // config
            configDefault: {
                css: '',
                title: {
                    i18n: 'utilities.components.auth.login',
                    css: 'text-h6',
                },
                email: {
                    i18n: 'utilities.components.common.email',
                    outlined: true,
                    dense: true,
                    required: {
                        i18n: 'utilities.services.formRules.pleaseEnter',
                    }
                },
                password: {
                    i18n: 'utilities.components.auth.password',
                    outlined: true,
                    dense: true,
                    required: {
                        i18n: 'utilities.components.auth.passwordRequired',
                    }
                },
                btn: {
                    next: {
                        i18n: 'utilities.components.common.next'
                    },
                    login: {
                        i18n: 'utilities.components.auth.login'
                    },
                    config: {
                        color: 'primary',
                        block: true
                    },
                },
                initPassword: {
                    show: true,
                    i18n: 'utilities.components.auth.initPassword',
                    css: 'text-left',
                },
                errorAlert: {
                    show: true,
                    css: 'text-left mt-5',
                }
            }
        }),
        computed: {
            btnText(){
                return this.translate(this.loginService.userPasswortStatus==='unverified'?
                        this.configEl.btn.next.i18n :
                        this.configEl.btn.login.i18n);
            },
            errorMessage(){
                return this.loginService.getErrorMessage(this.$i18n.t(this.loginService.errorI18n));
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
            keyPressed(e){
                if ( e.keyCode === 13 ) { this.processSubmit() } else { this.loginService.resetErrorMsg() }
            },
            async processSubmit(){
                if( !this.$refs.form.validate() ) { return }
                if ( this.loginService.userPasswortStatus === 'unverified' ) {
                    this.loginService.verifyEmail();
                } else {
                    let reply = await this.loginService.login();
                    if ( reply.successful && this.loginService.isEmailVerified ) {
                        this.loginService.checkAndSetAppUser();
                    // route to '/' in anywhere else
                        if ( this.$route.path !== '/' && this.$route.path !== '/myPurchases' ) {
                            this.$router.replace('/')
                        } else if (
                            this.$route.path !== '/myPurchases' &&
                            (Object.keys(this.$route.query).length > 0 && this.$route.query.tab !== '2' ||
                            Object.keys(this.$route.query).length > 1 && this.$route.query.tab === '2')
                        ) {
                            RouterServices.clearRouteAllParameter( this.$router, this.$route, true )
                        }
                    } else if (reply.successful && !this.loginService.isEmailVerified) {
                        this.loginService.waitingForReply = true;
                        await this.loginService.checkAdminVerification();
                        this.loginService.waitingForReply = false;
                        if ( !this.loginService.isEmailVerified ) {
                            RouterServices.pushRouteMultipleQueryParameter(
                                this.$router, this.$route, {mode: 'verifyEmail', oobCode: 'dummy'}
                            );
                        }
                    }
                }
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>