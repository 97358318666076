<template>
    <v-dialog v-model="open" width="340" persistent>
        <v-card v-if="!errorAlert.show && !logoutInProgress" class="text-left pb-5" >
            <v-card-title class="px-5">
                {{$t('utilities.components.auth.emailChangeTitle')}}
            </v-card-title>
            <v-card-text class="px-5">
                <p>{{$t('utilities.components.auth.emailChangeText')}}</p>
                <ul>
                    <li class="mb-1">{{$t('utilities.components.auth.emailChangeText1')}}</li>
                    <li class="mb-1">{{$t('utilities.components.auth.emailChangeText2')}}</li>
                    <li>{{$t('utilities.components.auth.emailChangeText3')}}</li>
                </ul>
            </v-card-text>
            <btn-text @click="logoutAndSendMail()" class="mx-5"
                      :config-prop="{block: true}">
                {{$t('utilities.components.auth.emailChangeButton')}}
            </btn-text>
        </v-card>
        <v-card v-else-if="logoutInProgress" class="px-5 py-15 text-center">
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
        </v-card>
        <v-card v-else>
            <!-- error message -->
            <alert-message  :config-prop="errorAlert"
                            config-package="error" class="mb-0">
                {{$t('utilities.components.auth.emailChangeError')}}
                <div class="text-right mt-5">
                    <logout :login-service="loginService"></logout>
                </div>
            </alert-message>
        </v-card>
    </v-dialog>
</template>

<script>
    //Version 1.0 2022-01-18
    import BtnText from "./btnText";
    import AlertMessage from "../displays/alertMessage";
    import Logout from "../authentication/logout";

    export default {
        components: {
            Logout,
            AlertMessage,
            BtnText},
        name: "single-line-edit-email",
        props: ['open'],
        computed: {
            loginService(){ return this.$store.state.loginService;}
        },
        data: () => ({
            errorAlert: {
                show: false,
            },
            logoutInProgress: false,
        }),
        methods: {
            async logoutAndSendMail(){
                this.logoutInProgress = true;
                const reply = await this.loginService.requestVerificationMail();
                this.logoutInProgress = false;
                if ( reply.successful ) {
                    this.loginService.logout();
                    this.$router.push('/');
                } else {
                    this.errorAlert.show = true;
                }
            }
        },
    }
</script>

<style scoped>

</style>