<template>
    <div>
        <div v-for="(msgLine, i) in msgLines" :key="i" style="overflow-x: hidden">
            <span v-for="(word, i) in msgLine.words" :key="i" style="max-width: 10%; position: relative">
                <span v-if="!word.isLink">{{ word.value }} </span>
                <span v-else>
                    <a :href="word.link" :target="word.target">{{ word.value }}</a>{{ word.lastDigit }} </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "messages-manual-text",
    props: ['change'],
    data: () => ({
        msgText: '',
        msgLines: [],
        currentUrl: '',
    }),
    created(){ this.init() },
    methods: {
        init(){
            this.currentUrl = window.location.href.split('#')[0]
            this.msgText = this.$i18n.locale==='de' ? this.change.new : this.change.old
            this.setMsgArray()
        },
        setMsgArray(){
            const msgLines = this.msgText.split('\n')
            this.msgLines = msgLines.map(
                line => ({words: this.getWordsObjects(line)})
            )
        },
        getWordsObjects(line){
            const words = line.split(' ')
            const wordObjects = []
            words.forEach( (word) => {
                wordObjects.push(this.getWordObject(word))
            });
            return wordObjects
        },
        getWordObject(word){
            const lastDigit = word.substring(word.length-1)
            const isLink = this.wordIsLink(word)
            const value = isLink ? this.getCleanedLink(word, lastDigit) : word
            return {
                isLink: isLink,
                value: isLink ? value.replace('//www.', 'www.') : value,
                link: isLink ? value : '',
                lastDigit: lastDigit,
                target: isLink ? value.indexOf(this.currentUrl) === 0 ? '' : '_blank' : ''
            }
        },
        wordIsLink(word){
            return (word.substring(0,7) === 'http://' && word.length>7) ||
                   (word.substring(0,8) === 'https://' && word.length>8) ||
                    (word.substring(0,4) === 'www.' && word.length>4)
        },
        getCleanedLink(word, lastDigit){
            const toBeCleaned = ['.', ';', ',', ':', '!', '?']
            const wordTemp = word.substring(0,4) === 'www.' ? '//' + word : word
            return toBeCleaned.includes(lastDigit) ? wordTemp.substring(0, wordTemp.length - 1) : wordTemp
        }
    },
}
</script>

<style scoped>

</style>