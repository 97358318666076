<template>
    <div v-if="user.parentConfirmRequired">
    <!-- display view -->
        <profile-edit-parent-confirm-list-item>
        </profile-edit-parent-confirm-list-item>
    <!-- edit view -->
        <full-screen-dialog parameter="documentEdit"
                            value="parentConfirm"
                            :config-prop="{card: {color: '#ECEFF1'}}">
            <toolbar :title="$t('profile.parentAuth')"
                     :show-save="false"
                     @save="save">
            </toolbar>
            <div v-show="!waitingForReply" class="pb-15">
        <!-- file name -->
                <v-row no-gutters class="px-4 pt-6" align="center" justify="center">
                    <v-col class="grow">
                        <div class="d-inline-block upload-btn-wrapper">
                            <span v-if="fileName">{{fileName}}</span>
                            <btn-text v-else :config-prop="{icon: 'mdi-upload'}">
                                {{$t('utilities.components.common.upload')}}
                            </btn-text>
                            <input type="file" id="file" ref="file" @change="getFileName" accept=".pdf" />
                        </div>
                    </v-col>
                    <v-col class="shrink">
                        <!-- delete button -->
                        <v-btn v-if="parentConfirmStore && fileName" icon
                               @click="deletePDF()">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-4 mx-4"></v-divider>
                <v-card-actions class="px-4">
                    <v-btn v-if="showOpenButton"
                           @click="viewPDF()"
                           color="#B0BEC5" fab >
                        <v-icon>mdi-file-eye</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="showSave"
                           @click="save()"
                           color="primary" fab dark>
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
                <!--<div class="px-4 pt-6 pb-3">
                    <span v-if="fileName">{{fileName}}</span>
                    <span v-else>{{$t('profile.noFile')}}</span>
                </div>-->
        <!-- action buttons -->
                <!--<v-card-actions class="px-4">

                    <div class="d-inline-block upload-btn-wrapper">
                        <btn-text class="d-inline-block">
                            {{$t(fileBtnText)}}
                        </btn-text>
                        <input type="file" id="fileX" ref="fileX" @change="getFileName" accept=".pdf" />
                    </div>
                    <v-spacer></v-spacer>

                    <btn-text v-if="showOpenButton"
                              class="d-inline-block mr-5">
                        <a class="white--text" style="text-decoration: none"
                           :href="parentConfirmStore.fileLink"
                           target="_blank">{{$t('utilities.components.common.open')}}</a>
                    </btn-text>

                    <v-btn v-if="parentConfirmStore && fileName" icon
                            @click="deletePDF()">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-card-actions>-->
            </div>
        <!-- error message -->
            <alert-message :config-prop="errorAlert"
                           config-package="error"
                           class="mx-5 mt-3 mb-15">
                {{errorMsg}}
            </alert-message>
        <!-- Block Window while saving -->
            <progress-dialog :waiting-for-reply="waitingForReply"
                             :config-prop="{text: 'document'}">
            </progress-dialog>
        </full-screen-dialog>
    </div>
</template>

<script>
    import FullScreenDialog from "@/utilities/components/dialogs/fullScreenDialog";
    import Toolbar from "@/utilities/components/controls/toolbar";
    //import BtnText from "@/utilities/components/controls/btnText";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import {ParentConfirm} from "@/models/user/parentConfirm";
    import ProfileEditParentConfirmListItem from "./profileEditParentConfirmListItem";
    import BtnText from "../../utilities/components/controls/btnText";
    import ProgressDialog from "../../utilities/components/dialogs/progressDialog";

    export default {
        components: {
            ProgressDialog,
            BtnText,
            ProfileEditParentConfirmListItem,
            AlertMessage,
            //BtnText,
            Toolbar, FullScreenDialog
        },
        name: "profile-edit-parent-confirm",
        data: () => ({
            parentConfirm: null,
            waitingForReply: false,
            errorMsg: '',
            fileName: '',
            errorAlert: {
                show: false,
            },
        }),
        computed: {
            user() { return this.$store.getters.user },
            parentConfirmStore() { return this.$store.state.realtime.parentConfirm },
            fileBtnText(){
                return this.fileName ?
                    'utilities.components.common.edit' :
                    'utilities.components.common.selectFile';
            },
            showOpenButton(){
                return this.parentConfirmStore &&
                       this.parentConfirmStore.fileLink &&
                       this.parentConfirmStore.originalName === this.fileName;
            },
            showSave(){
                return !this.waitingForReply &&
                    ( this.fileName ||
                     (!this.fileName && this.parentConfirmStore)
                    )
            }
        },
        created(){
            this.setParentConfirm();
        },
        methods: {
            setParentConfirm(){
                this.parentConfirm = new ParentConfirm(this.parentConfirmStore)
            },
            init(){
                this.resetError();
                if( this.parentConfirmStore ){
                    this.parentConfirm.setFromDataObject(this.parentConfirmStore);
                }
                this.fileName = this.parentConfirm.originalName;
            },
            back(){
                window.history.back();
            },
            viewPDF(){
            /*<a class="white--text" style="text-decoration: none"
            :href="parentConfirmStore.fileLink"
                target="_blank">{{$t('utilities.components.common.open')}}</a>

                let routeData = this.$router.resolve({name: 'StreamConsole', query: {id: spielId}});*/
                window.open(this.parentConfirmStore.fileLink, '_blank');
            },
            async save(){
                this.resetError();
                this.waitingForReply = true;
            // save
                const reply = await this.parentConfirm.editPDFDocument(
                    this.fileName,
                    this.user.uid,
                    this.$refs.file.files[0],
                    this.parentConfirmStore
                );
            // process reply
                console.log ("reply", reply);
                if ( !this.fileName ) {
                    //this.$store.dispatch('unbindRealtimeProperty', 'parentConfirm');
                    this.parentConfirm = new ParentConfirm();
                    this.$refs.file.value = null;
                }
                this.waitingForReply = false;
                if ( reply.successful ) {
                    window.history.back();
                } else {
                    this.setError(reply)
                }
            },
            deletePDF(){
                this.fileName = '';
            },
            setError(reply){
                this.fileName = '';
                this.errorAlert.show = true;
                this.errorMsg = reply.content;
                this.parentConfirm = new ParentConfirm(this.parentConfirmStore);
                this.$refs.file.value = null;
            },
            resetError(){
                this.errorAlert.show = false;
                this.errorMsg = '';
            },
        // File Selection on input tag
            getFileName() {
                this.resetError();
                if (this.$refs.file.files.length > 0) {
                    this.fileName = this.$refs.file.files[0].name;
                } else {
                    this.fileName = '';
                }
            },
        },
        watch: {
            '$route.query.documentEdit'(to){
                if ( to === 'parentConfirm' ) { this.init(); }
            },
            parentConfirmStore(){
                if ( this.parentConfirmStore ) {
                    this.init();
                }
            },
        }
    }
</script>

<style scoped>
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
</style>