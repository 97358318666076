// Version 1.2  2022-02-16
export let AUTHENTICATION_CONFIG = {
    css: '',
// root of authentication
    root: {
        css: '',
        width: '350px',
        login: {
            css: 'py-5',
        },
        btnForgotPW: {
            i18n: 'utilities.components.auth.forgotPW',
            block: true,
            css: '',
            color: '',
            elevation: 0,
            text: true,
        },
        btnSignup: {
            i18n: 'utilities.components.auth.signUp',
            block: true,
            css: ''
        },
        bottomNavigation: {
            css: 'white' // important, otherwise footer is transparent
        },
    },
// sections in authentication.vue
    signUp: {
        css: '',
        width: '350px',
        header: {
            i18n: 'utilities.components.auth.signUp',
            css: 'px-10'
        },
    },
    verification: {
        css: '',
        width: '350px',
        header: {
            i18n: 'utilities.components.auth.verifyEmail',
            css: 'px-10'
        },
    },
    resetPW: {
        css: '',
        width: '350px',
        header: {
            i18n: 'utilities.components.auth.resetPW',
            css: 'px-10'
        },
    },
    undoEmailChange: {
        css: '',
        width: '350px',
        header: {
            i18n: 'utilities.components.auth.undoEmailChange',
            css: 'px-10'
        },
    },

// configs to be passed into the sub-components
    loginConfig: {
        css: 'py-5',
    },
    resetPWConfig: {
        container: {
            css: '',
        },
        form: {
            css: 'pt-5'
        },
        cancelBtn: {
            color: ''
        }
    },
    signUpConfig: {
        css: 'py-5',
    },
    verificationConfig: {
        css: 'py-5',
    },
    undoEmailChangeConfig: {
        css: 'py-5'
    }
}