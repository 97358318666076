<template>
    <div>
        <text-switch class="mt-5 text-center body-2"
                     :options="$store.state.config.languageOptions"
                     :current="$i18n.locale"
                     @selected="changeLanguage">
        </text-switch>
        <authentication :login-service="loginService" :config-prop="authConfig">
        <!-- logo -->
            <div class="text-center pt-15 pb-5">
                <img src="@/assets/oym_logo_final_black.svg"
                     :width="$vuetify.breakpoint.xsOnly?'200px':'300px'"/>
            </div>
        </authentication>
        <div style="max-width: 450px" class="mx-auto mt-n10" v-if="!$route.query.authentication && !$route.query.mode">
            <alert-message :config-prop="{show: true, color: '#048ba8'}" class="mx-5">
                {{$t('admin.infoText')}}
                <btn-text :config-prop="{block: true, color: '#048ba8', icon: 'mdi-arrow-right'}"
                        class="mt-2" @click="goToAdmin()">
                    {{$t('admin.btn')}}</btn-text>
            </alert-message>
        </div>

        <!-- bottom navigation -->
        <div v-if="!$route.query.authentication"
             class="bgdGreyLight footerBar text-center py-3 body-2 elevation-2">
            <router-link to="/imprint">{{$t('utilities.components.common.imprint')}}</router-link>
            <!--<router-link to="/termsOfUse" class="px-5">{{$t('utilities.services.user.termsOfUse')}}</router-link>-->
            <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_TermsV2.pdf"
               class="px-5" target="_blank">{{$t('utilities.services.user.termsOfUse')}}</a>
            <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_TermsV2.pdf"
               class="px-5" target="_blank">{{$t('utilities.services.user.termsOfUse')}}</a>
            <!--<router-link to="/privacy">{{$t('utilities.components.common.privacy')}}</router-link>-->
            <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_PrivacyV2.pdf" target="_blank">
                {{$t('utilities.components.common.privacy')}}</a>
            <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_PrivacyV2.pdf" target="_blank">
                    {{$t('utilities.components.common.privacy')}}</a>
        </div>
   </div>
</template>

<script>
    import Authentication from "@/utilities/components/authentication/authentication";
    import {USER_FORM_CONFIG} from '@/config/forms/userFormConfig';
    import TextSwitch from "../utilities/components/controls/textSwitch";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import BtnText from "@/utilities/components/controls/btnText";
    export default {
        components: {
            BtnText,
            AlertMessage,
            TextSwitch,
            Authentication},
        name: "authentication-view",
        props: ['loginService'],
        data: () => ({
            authConfig: {
                css: 'bgdGreyLight',
            // root of authentication
                root: {
                    css: 'bgdGreyLight',
                    bottomNavigation: {
                        css: 'bgdGreyLight', // important, otherwise footer is transparent
                    }
                },
            // configuration of sub elements
                signUpConfig: {
                    user: null,
                    css: 'bgdGreyLight py-5',
                    btnCancel: {
                        config: {
                            color: 'white',
                        },
                    },
                    supportEmail: 'support@onyourmarks.cc'
                },
                resetPWConfig: {
                    container: {
                        css: 'bgdGreyLight',
                    },
                    cancelBtn: {
                        color: 'white'
                    }
                },
                verificationConfig: {
                    css: 'bgdGreyLight py-5'
                },
                undoEmailChangeConfig: {
                    css: 'bgdGreyLight py-5'
                },
            }
        }),
        created(){
            this.setUserConfigForSignUp();
        },
        methods: {
            setUserConfigForSignUp(){
                this.authConfig.signUpConfig.user = JSON.parse(JSON.stringify(USER_FORM_CONFIG));
                delete this.authConfig.signUpConfig.user.language;
                //delete this.authConfig.signUpConfig.user.nationality;
            },
            changeLanguage(localeNew){
                this.$i18n.locale = localeNew;
            },
            goToAdmin(){
                window.open('https://www.onyourmarks.cc/admin/#/', '_blank')
            }
        },
    }
</script>

<style scoped>
    .footerBar{ position: fixed; bottom: 0; left: 0; right: 0}
</style>