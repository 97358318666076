<template>
    <div class="printContainerMulti transparent">
        <div :style="'width: '+printableWidth+'cm;'" class="printArea">
        <!-- content -->
            <slot></slot>
        <!-- navigation box -->
            <div class="btnBox">
                <v-btn fab dark bottom right
                       color="primary" class="btnBack"
                       @click="back()">
                    <v-icon >mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn fab dark bottom right
                       color="primary" class="btnPrint"
                       @click="openPrint()">
                    <v-icon >mdi-printer</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    // Version 1.1 2022-03-12
    export default {
        name: "print-container",
        methods: {
            back(){
                window.history.back();
            },
            openPrint(){
                try {
                    // Print for Safari browser
                    document.execCommand('print', false, null);
                    window.print()
                } catch {
                    window.print()
                }
            }
        },
        props: {
            printableWidth: {default: 18},
        },
    }
</script>

<style scoped>
    .printArea{margin: auto; overflow-x: hidden; overflow-y: hidden;}
    .btnBox {position: fixed; width: 26cm; top: 0; left: 0;
             height: 300px; right: 0; margin: auto}
    .btnBack {position: absolute; right: 15px; top: 15px; z-index: 15}
    .btnPrint {position: absolute; right: 90px; top: 15px; z-index: 15}
    @media print{@page {size: portrait; margin: 0}}
/* use pageBreak in the slot to break to another page*/
    .pageBreak {
        page-break-after: always;
    }
    .printContainerMulti { position: relative; width: 100%;
        z-index: 20; overflow: auto;
        /*font-family: verdana, arial, helvetica;*/}

</style>