<template>
    <div>
        <v-speed-dial v-if="user && userMaster && userChildList.length>0"
                      v-model="fab"
                      direction="left"
                      :open-on-hover="$vuetify.breakpoint.smAndUp"
                      transition="slide-x-reverse-transition">
            <template v-slot:activator>
                <profile-user-avatar v-model="fab" :child-user="user"
                                     :size="44" :disable-border="!fab"
                                     class="mx-3">
                </profile-user-avatar>
                <div v-if="fab"
                     class="fontInter font12 font-weight-medium space05
                           firstName text-truncate mt-1 text-uppercase">
                    {{user.firstName}}
                </div>
            </template>

            <div v-for="(childUser, i) in userList" :key="i">
                <div v-if="childUser.uid!==user.uid"
                     @click="change(childUser.uid)"
                     class="text-center">
                    <profile-user-avatar :child-user="childUser"
                                         :size="44" emptyColor="#e2e2e2">
                    </profile-user-avatar>
                    <div class="fontInter font12 font-weight-medium space05
                            firstName text-truncate mx-1 mt-1 text-uppercase">
                        {{childUser.firstName}}
                    </div>
                </div>
            </div>
        </v-speed-dial>
    </div>
</template>

<script>
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService';
    import ProfileUserAvatar from "@/components/profile/profileUserAvatar";

    export default {
        components: {
            ProfileUserAvatar,
        },
        name: "profile-user-speed-dial",
        data: () => ({
            fab: false,
        }),
        computed: {
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            user(){ return this.$store.getters.user },
            userMaster(){ return this.$store.state.realtime.userMaster },
            userChilds(){ return this.$store.state.realtime.userChilds },
            userChildList() {
                return ObjectHandlingService.getListFromObject(
                    JSON.parse(JSON.stringify(this.userChilds)));
            },
            userList(){ return this.userChilds?
                [this.userMaster].concat(this.userChildList) :
                [[this.userMaster] ];
            },
        },
        methods: {
            change(uid){
                if ( uid === this.user.uid ) { return }
                this.$store.dispatch('changeUser', {uid: uid });
            },
        },
        watch: {
            fab(){
                this.$emit('usermenu', this.fab);
            }
        }

    }
</script>

<style scoped>
    .firstName{width: 66px;}
</style>