// Version 2.5 2022-02-04, 2022-02-17, 2022-02-28, 2022-03-12, 2022-03-21

export const localesUtilities = {
    en: {
        components: {
            controls: {
                plusMinus: {
                },
                btn: {
                }
            },
            dialogs: {
                confirmDialog: {
                }
            },
            common: {
                on: 'Yes',
                off: 'No',
                ok: 'ok',
                yes: 'yes',
                no: 'no',
                or: 'or',
                confirm: 'confirm',
                delete: 'delete',
                save: 'save',
                saveAndLogin: 'Save and log in',
                cancel: 'cancel',
                cancelDelete: 'cancel delete',
                next: 'next',
                back: 'back',
                sendLink: 'send link',
                resendLink: 'resend link',
                email: 'E-mail-address',
                undefinedError: 'There is an error. Please contact us for further assistance!',
                imprint: 'Imprint',
                privacy: 'Privacy',
                privacyLong: 'privacy policy',
                open: 'open',
                edit: 'edit',
                selectFile: 'Select file',
                upload: 'Upload',
                savingDocument: 'The document is saved!',
                savingData: 'Data is being saved!',
            },
            auth: {
                signUp: 'New account',
                createAccount: 'Create account',
                login: 'Log in',
                logout: 'Log out',
                forgotPW: 'forgot password?',
                verifyEmail: 'verify e-mail',
                logoutConfirm: 'Do you really want to log out?',
                emailSent: 'We have sent you a new email!',
                password: 'Password',
                passwordConfirm: 'Confirm Password',
                passwordRequired: 'Please enter a password!',
                resetPW: 'Reset Password',
                linkIsExpired: 'The link to reset the password has expired, ' +
                'please click the button to request a new link!',
                resendLinkText: 'An email was sent to the specified address. If you don\' get it in the next few minutes, ' +
                'please check your spam folder or click the button below to request a new one.',
                resetPWInfo: 'Please enter a new password for the following account',
                requestLinkInfo: 'Please enter your email address and click \'Send link\'. ' +
                'We will then send you a link to this address to reset the password!',
                resetCompletedMsg: 'The password has been changed and you are logged in!',
                initPassword: 'This is your first login! We have sent you an email, ' +
                'please click the link in it to set your password and log in!',
                verificationExpired: 'The link to confirm your registration has expired.',
                sendNewVerification: 'send new link',
                emailVerified: 'Thank you, your account is confirmed, please login with email and password to start!',
                startApp: 'go to log in',
                verificationFailed: 'An unexpected error occurred while confirming your email. Please try again!',
                signInSuccessful: 'Thank you, you have successfully registered! Please confirm your email address ' +
                'and you can start right away. To do this we have sent you an email to the following address:',
                wrongEmail1: 'If you haven\'t received an email, you can resend it here:',
                wrongEmail2: 'If you mistyped the e-mail address, please click restart:',
                wrongEmail3: 'We have sent you a new email. If you don\'t get it again, it may be because of ' +
                'a problem at your e-mail provider. Please then send an email to the following address and ' +
                'we will confirm your email manually: ',
                restart: 'Start new',
                wrongPasswordConfirmation: 'The passwords do not match!',
                //This operation is sensitive and requires recent authentication. Log in again before retrying this request.
                emailChangeTitle: 'Your email has been changed',
                emailChangeText: 'Your account email address has been changed. ' +
                'Please carry out the following actions:',
                emailChangeText1: 'Click the button below to send a confirmation email to ' +
                'your new address and to log out.',
                emailChangeText2: 'Click the link in the email to your new address to ' +
                'confirm it and log in again.',
                emailChangeText3: 'Important: We have also sent you an e-mail to your old address. ' +
                'Inside is a link to undo the email change. Please use this link if you have made ' +
                'a typo or have not received an e-mail to the new address!',
                emailChangeButton: 'send mail and log out',
                emailChangeError: 'There has been an error. Please undo the change using the link in ' +
                'the email to your old address, close the browser and log in again with your old address!',
                undoEmailChange: 'Undo E-mail change',
                undoEmailChangeConfirm: 'Are you sure you want to undo your email change?',
                undoEmailChangeSuccess: 'Your account has been successfully reset to the ' +
                'following email address: ',
                undoEmailChangeExpired: 'Sorry, the reset link has expired. ' +
                'Your account can no longer be reset to the old email address.',
                undoEmailChangeInProgress: 'I\'m trying to reset your email address!',
                changePW: 'Do you really want to change your password? After your confirmation ' +
                'we will send you an e-mail with a link to change your password.',
                pwLinkSend: 'E-mail sent to \'%{email}\'!',
                changePWTitle: 'Change password',
            },
            images: {
                profile: 'Profile photo',
                selectImage: 'Upload photo',
                changeImage: 'Upload another photo',
                loading: 'The photo is loading!',
                saving: 'The photo is saved!',
                invalidFileFormat: 'The image has an invalid file format. ' +
                'Please only upload JPEG\'s!'
            },
            pdfUpload: {
                btnAdd: 'Select PDF',
                btnChange: 'Change PDF',
                selected: 'Selected PDF: ',
            }
        },
        services: {
            user: {
                firstName: 'First name',
                lastName: 'Last name',
                userName: 'User name',
                birthday: 'Birthday',
                club: 'Club',
                nationality: 'Nationality',
                sex: 'Gender',
                MEN: 'male',
                WOMAN: 'female',
                DIVERSE: 'diverse',
                termsOfUse: 'Terms of use',
                termsOfUse1: 'I have read the ',
                termsOfUse2: ' of this website and I agree to them.',
                privacyApp: 'data protection and privacy terms',
                privacyApp1: 'I have read the ',
                privacyApp2: ' of this website and I agree to them.',
                emailOptIn: 'I agree to receive in-app-notifications and emails about new features, promotions, offers ' +
                    'from On Your Marks, other Number One Solution Services, their clients or partners (optional).',
                statutes: 'statutes',
                statutes1: 'I have read the ',
                statutes2: ' of this organization and I agree to them.',
                privacyOrg: 'data protection and privacy terms',
                privacyOrg1: 'I have read the ',
                privacyOrg2: ' of this organisation and I agree to them.',
                covid: 'Covid-19 regulations',
                covid1: 'I have read the ',
                covid2: ' of this organization and I agree to them.',
                language: 'Language',
                languageApp: 'App-Language',
            },
            formRules: {
                passwordRules:'Password must contain at least 8 characters, one lower case, ' +
                'one upper case, one digit and at least one special case.',
                //'\nSpecial cases are: ! * # , ; ? + - _ . = ~ ^ % ( ) { } [ ] | : " / < > $ §',
                required:'required',
                minOne:'You need at least one item',
                priceNotLessZero:'Price can not be less than zero',
                vatNotNegative:'VAT can not be less than zero',
                nameRequired:'Name for license is required',
                email: 'Please enter valid email adress!',
                vatNotLessZero: 'Price can not be less than zero',
                existingDate:'Date outside definition',
                numberGreaterZero:'Has to be greater zero',
                betweenMinMax:'Value must be between %{min} and %{max}',
                stringMinLength: '{min} characters minimum!',
                pleaseEnter: 'Please enter!',
                pleaseSelect: 'Please select!',
                pleaseConfirm: 'Please confirm!',
                minAge: 'You have to be at least %{minAge} years old to use this app!',
                maxAge: 'You can only add people under %{maxAge} years old to your account!',
                dateInFuture: 'The date cannot be in the future!',
            },
            firebase: {
                login: {
                    error: {
                        'auth/user-not-found': 'There is no account corresponding to this e-mail-address. ' +
                                                'The account may have been deleted or not yet created.',
                        'auth/invalid-email': 'The provided value for the email user property is invalid. It must be a string email address.',
                        'auth/wrong-password': 'Wrong password provided!',
                        'auth/too-many-requests': 'Access to this account has been temporarily disabled due to numerous failed ' +
                                                  'login attempts. You can recover it by resetting your password, ' +
                                                  'or you can try again later.',
                        'auth/email-already-in-use': 'The email address is already in use by another account.',
                        'auth/requires-recent-login': 'This operation is sensitive and requires recent authentication. ' +
                                                      'Log out and in again before retrying this request.'
                    }
                }
            }
        },
    },
    de: {
        components: {
            controls: {
                plusMinus: {
                },
                btn: {
                }
            },
            dialogs: {
                confirmDialog: {
                }
            },
            common: {
                on: 'Ja',
                off: 'Nein',
                ok: 'ok',
                yes: 'ja',
                no: 'nein',
                or: 'oder',
                confirm: 'bestätigen',
                delete: 'löschen',
                save: 'speichern',
                saveAndLogin: 'Speichern und anmelden',
                cancel: 'abbrechen',
                cancelDelete: 'löschen abbrechen',
                next: 'weiter',
                back: 'zurück',
                sendLink: 'Link senden',
                resendLink: 'Neuen Link senden',
                email: 'E-Mail-Addresse',
                undefinedError: 'Es ist leider ein Fehler aufgetreten. Bitte kontaktiere uns für weitere Hilfe!',
                imprint: 'Impressum',
                privacy: 'Datenschutz',
                privacyLong: 'Datenschutzbestimmungen',
                open: 'öffnen',
                edit: 'ändern',
                selectFile: 'Datei wählen',
                upload: 'Hochladen',
                savingDocument: 'Das Dokument wird gespeichert!',
                savingData: 'Die Daten werden gespeichert!',
            },
            auth: {
                signUp: 'Neues Konto',
                createAccount: 'Konto anlegen',
                login: 'Anmelden',
                logout: 'Abmelden',
                forgotPW: 'Passwort vergessen?',
                verifyEmail: 'E-mail bestätigen',
                logoutConfirm: 'Möchtest du dich wirklich abmelden?',
                emailSent: 'Wir haben ein neues e-Mail an dich gesendet!',
                password: 'Passwort',
                passwordConfirm: 'Passwort bestätigen',
                passwordRequired: 'Bitte gib ein Passwort ein!',
                resetPW: 'Passwort zurücksetzen',
                linkIsExpired: 'Der Link zum Zurücksetzen des Passworts ist leider abgelaufen, ' +
                'bitte klicke auf den Button um einen neuen Link anzufordern!',
                resendLinkText: 'Es wurde ein eMail an die angegebene Adresse gesendet. ' +
                'Falls du es nicht spätestens in ein paar Minuten erhältst, prüfe bitte deinen Spam Ordner ' +
                'oder klicke auf den Button um ein neues Mail anzufordern.',
                resetPWInfo: 'Bitte gib das neue Passwort für folgendes Konto ein',
                requestLinkInfo: 'Bitte gib deine E-Mail-Adresse ein und klicke auf \'Link senden\'. Wir senden dir dann einen ' +
                'Link an diese Adresse, mit dem du dein Passwort zurücksetzen kannst!',
                resetCompletedMsg: 'Das Passwort wurde geändert und du wurdest angemeldet!',
                initPassword: 'Das ist deine erste Anmeldung! Wir haben dir ein e-Mail geschickt, bitte klicke auf den ' +
                'Link darin um dein Passwort zu setzen und dich anzumelden!',
                verificationExpired: 'Der Link zur Bestätigung deiner Anmeldung ist leider abgelaufen.',
                sendNewVerification: 'neuen link senden',
                emailVerified: 'Vielen Dank, dein Konto ist bestätigt, bitte melde dich mit e-Mail und Passwort an ' +
                                'um zu starten!',
                startApp: 'zur Anmeldung',
                verificationFailed: 'Bei der Bestätigung deines eMail ist leider ein unerwarteter ' +
                        'Fehler aufgetreten. Probiere es bitte nochmals!',
                signInSuccessful: 'Vielen Dank, du hast dich erfolgreich registriert! ' +
                                  'Bitte bestätige noch deine e-Mail-Adresse und dann kann es gleich losgehen. ' +
                                  'Wir haben dir dazu eine e-Mail an folgende Adresse gesendet:',
                wrongEmail1: 'Falls du keine E-Mail bekommen hast kannst du sie hier nochmals senden:',
                wrongEmail2: 'Falls du dich bei der eMail-Adresse vertippt hast, klicke bitte auf neu starten:',
                wrongEmail3: 'Wir haben dir eine neue E-Mail gesendet. Falls du sie wieder nicht bekommst kann es ' +
                'an deinem E-mail Provider liegen. Bitte sende dann ein E-Mail an die folgende Adresse, wir ' +
                'bestätigen dann deine E-mail manuell: ',
                restart: 'Neu starten',
                wrongPasswordConfirmation: 'Die Passwörter stimmen nicht überein!',
                emailChangeTitle: 'Deine Email wurde geändert',
                emailChangeText: 'Die E-mail-Adresse deines Kontos wurde geändert. Folgende Schritte sind ' +
                'noch notwendig:',
                emailChangeText1: 'Klicke auf den nachstehenden Button um ein Bestätigungsmail an deine ' +
                'neue Adresse zu senden und dich abzumelden.',
                emailChangeText2: 'Klicke auf den Link im Mail an deine neue Adresse um diese zu bestätigen ' +
                'und dich wieder anzumelden.',
                emailChangeText3: 'Wichtig: Wir haben dir auch eine E-mail an deine alte Adresse gesendet.' +
                ' Darin ist ein Link zum Rückgängig machen der E-mail-Änderung. Bitte nutze diesen Link ' +
                'unbedingt falls du dich vertippt hast oder keine E-mail an die neue ' +
                'Adresse erhalten hast!',
                emailChangeButton: 'mail senden und abmelden',
                emailChangeError: 'Es ist ein Fehler aufgetreten. Bitte mach die Änderung mit dem Link ' +
                'im E-mail an deine alte Adresse rückgängig, schließe den Browser und melde dich wieder ' +
                'mit deiner alten Adresse an!',
                undoEmailChange: 'E-mail Änderung',
                undoEmailChangeConfirm: 'Möchtest du dein E-Mail-Änderung wirklich rückgängig machen?',
                undoEmailChangeSuccess: 'Dein Konto wurde erfolgreich auf folgende ' +
                                        'E-Mail-Adresse zurückgesetzt: ',
                undoEmailChangeExpired: 'Der Link zum Zurücksetzen ist leider abgelaufen. Dein Konto kann ' +
                                        'nicht mehr auf die alte E-Mail-Adresse zurückgesetzt werden.',
                undoEmailChangeInProgress: 'Ich versuche deine E-Mail-Adresse zurückzusetzen!',
                changePW: `Möchtest du wirklich dein Passwort ändern? Falls ja schicken wir dir nach deiner 
                Bestätigung eine E-Mail mit einem Link, der dich zu Passwortänderung weiterleitet. 
                Außerdem wirst du abgemeldet.`,
                pwLinkSend: 'E-mail an \'%{email}\' gesendet!',
                changePWTitle: 'Passwort ändern',
            },
            images: {
                profile: 'Profilbild',
                selectImage: 'Foto hochladen',
                changeImage: 'Anderes Foto hochladen',
                loading: 'Das Bild wird geladen!',
                saving: 'Das Foto wird gespeichert!',
                invalidFileFormat: 'Das Bild hat ein ungültiges Dateiformat. ' +
                'Bitte nur JPEG\'s hochladen!'
            },
            pdfUpload: {
                btnAdd: 'PDF wählen',
                btnChange: 'PDF ändern',
                selected: 'Gewähltes PDF: ',
            }
        },
        services: {
            user: {
                firstName: 'Vorname',
                lastName: 'Nachname',
                userName: 'Benutzername',
                birthday: 'Geburtstag',
                club: 'Stammverein',
                nationality: 'Nationalität',
                sex: 'Geschlecht',
                MEN: 'männlich',
                WOMAN: 'weiblich',
                DIVERSE: 'divers',
                termsOfUse: 'Nutzungsbedingungen',
                termsOfUse1: 'Ich habe die ',
                termsOfUse2: ' dieser Webseite gelesen und stimme ihnen zu.',
                privacyApp: 'Datenschutzbedingungen ',
                privacyApp1: 'Ich habe die ',
                privacyApp2: ' dieser Webseite gelesen und stimme ihr zu.',
                emailOptIn: 'Ich stimme dem Empfang von In-App-Benachrichtigungen und E-Mails über neue Features, Aktionen und Angebote ' +
                    'von On Your Marks, anderen Services von Number One Solutions und ihren Auftraggeber_innen und Partner_innen zu (optional).',
                statutes: 'Statuten',
                statutes1: 'Ich habe die ',
                statutes2: ' gelesen und stimme ihnen zu.',
                privacyOrg: 'Datenschutzbedingungen',
                privacyOrg1: 'Ich habe die ',
                privacyOrg2: ' dieser Organisation gelesen und stimme ihnen zu.',
                covid: 'Covid-19 Bestimmungen',
                covid1: 'Ich habe die ',
                covid2: ' dieser Organisation gelesen und stimme ihnen zu.',
                language: 'Sprache',
                languageApp: 'App-Sprache',
            },
            formRules: {
                passwordRules:'Das Passwort muss zumindest 8 Zeichen lang sein, ' +
                'einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
                //\nALs Sonderzeichen gelten: ! * # , ; ? + - _ . = ~ ^ % ( ) { } [ ] | : " / < > $ §',
                required:'Pflichtfeld',
                minOne:'Es muss mindestens ein Item zu Verfügung stehen',
                priceNotLessZero:'Preis kann nicht weniger als null sein',
                vatNotNegative:'MWST darf nicht kleiner null',
                nameRequired:'Lizenz benötigt einen Namen',
                email:'Bitte gültige E-Mail-Adresse eingeben!',
                numberGreaterZero:'Null ist nicht erlaubt',
                betweenMinMax:'Wert muss zwischen %{min} und %{max} liegen',
                existingDate:'Datum liegt außerhalb des des definierten Zeitraums',
                vatNotLessZero: 'MWST darf nicht null',
                stringMinLength: 'Bitte mindestens {min} Zeichen eingeben!',
                pleaseEnter: 'Bitte eingeben!',
                pleaseSelect: 'Bitte wählen!',
                pleaseConfirm: 'Bitte bestätigen!',
                minAge: 'Du musst mindestens %{minAge} Jahre alt sein um diese App nutzen zu dürfen!',
                maxAge: 'Du kannst nur Personen deinem Konto hinzufügen, die unter %{maxAge} Jahre alt sind!',
                dateInFuture: 'Das Datum darf nicht in der Zukunft liegen!',
            },
            firebase: {
                login: {
                    error: {
                        'auth/user-not-found': 'Zu dieser E-Mail-Adresse gibt es noch kein Konto.',
                        'auth/invalid-email': 'Bitte gültige E-Mail-Adresse eingeben!',
                        'auth/wrong-password': 'Das Passwort ist falsch!',
                        'auth/too-many-requests': 'Der Zugriff auf dieses Konto wurde aufgrund zahlreicher fehlgeschlagener ' +
                                                'Anmeldeversuche vorübergehend deaktiviert. Du kannst es wiederherstellen, ' +
                                                'indem du dein Passwort zurücksetzt, oder du kannst es später erneut versuchen.',
                        'auth/email-already-in-use': 'Diese E-Mail-Addresse wird bereits von einem anderen ' +
                                                'Konto benutzt.',
                        'auth/requires-recent-login': 'Das Ändern der E-Mail Adresse ist vertraulich und ' +
                        'erfordert eine kürzlich erfolgte Authentifizierung. ' +
                        'Melden Sie sich bitte ab und wieder an, bevor Sie diese Anfrage wiederholen.'
                    }
                }
            }
        },
    }
};