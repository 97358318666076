<template>
    <div>
        <div v-if="licenseList.length>0"
             class="pt-3 mx-4">
            <v-row>
                <v-col class="grow pr-2">
                    <div class="fontInter font-weight-bold primary--text font18 lineHeight-1-2 pt-1">
                        {{leadLicense.title}}
                    </div>
                    <v-card-subtitle v-if="validityText"
                                     class="pa-0">
                        {{validityText}}
                    </v-card-subtitle>
                    <v-select v-if="!licenseIsInWallet"
                              :disabled="!consentStatus || !requiredInformationStatus"
                              outlined dense hide-details
                              v-model="selectedLicenseId"
                              style="min-width: 130px"
                              class="mt-1"
                              clearable
                              :items="licenseList"
                              item-text="subtitle"
                              item-value="id"
                              :placeholder="$t('common.pleaseSelect')"
                              @input="handleSelectInput">
                    </v-select>
                    <v-text-field v-else readonly class="mt-1"
                                   outlined dense hide-details
                                   style="min-width: 100px; text-align: center"
                                   :value="licenseIsInWallet">
                    </v-text-field>
                    <v-text-field v-if="requiresUserText" hide-details
                                  :clearable="licenseInWallet===null"
                                  :readonly="licenseInWallet!==null"
                                  v-model="userText" outlined dense class="mt-5"
                                 :label="fieldSettings.fieldSettings.userText[$i18n.locale]"/>
                    <alert-message v-if="leadLicense.licenseDescription" :config-prop="infoAlert"
                                   class="caption mt-2">
                        {{leadLicense.licenseDescription}}
                    </alert-message>
                </v-col>
                <v-col class="shrink text-right pt-5">
                    <shop-item-price :base-price="basePrice"
                                     :discount="discount"
                                     :price-type="fieldSettings.priceType">
                    </shop-item-price>
                    <shop-item-cart-btn class="mt-2 mb-1"
                                        :disabled="!consentStatus ||
                                                   !requiredInformationStatus ||
                                                    !organisationIsActive"
                                        @itemClicked="processItemClick()"
                                        :license-is-in-cart="licenseIsInCart"
                                        :license-is-in-wallet="licenseIsInWallet">
                    </shop-item-cart-btn>
                </v-col>
            </v-row>
        </div>
        <v-divider class="mt-5"/>
        <v-snackbar v-model="snackbarSelected" color="error">
            <span v-if="!selectedLicenseId">{{$t('shop.pleaseSelect')}}</span>
            <span v-else-if="!userTextStatus">{{fieldSettings.fieldSettings.userText[$i18n.locale]}}</span>
        </v-snackbar>
    </div>
</template>

<script>
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import ConditionCheckService from "@/modules/_services/conditionCheckService";
    import LicensePriceService from "@/modules/_services/licensePriceService";
    import ShopItemCartBtn from "../shopItemCartBtn";
    import ShopItemPrice from "../shopItemPrice";
    import AlertMessage from "@/utilities/components/displays/alertMessage";

    export default {
        components: {
            AlertMessage,
            ShopItemPrice,
            ShopItemCartBtn},
        name: "shop-item-select",
        props: ['selectFieldId', 'shopEvent', 'fieldSettings', 'consentStatus', 'requiredInformationStatus',
                'organisationIsActive'],
        data: () => ({
            conditionCheckService: new ConditionCheckService(),
            selectedLicenseId: null,
            snackbarSelected: false,
            userText: '',
        }),
        computed: {
            requiresUserText(){ return this.fieldSettings.fieldSettings?.userText},
            userTextStatus(){ return (this.fieldSettings.fieldSettings?.userText && this.userText) ||
                                     (!this.fieldSettings.fieldSettings?.userText)},
            user(){ return this.$store.getters.user },
            shoppingCart(){ return this.$store.state.shoppingCart; },
            userLicenses(){
                const userLicenses = JSON.parse(JSON.stringify(this.$store.state.realtime.userLicenses));
                if ( this.shoppingCart &&
                     this.shoppingCart.items[this.user.uid]  ) {
                    Object.keys(this.shoppingCart.items[this.user.uid]).forEach( (licenseId) => {
                        userLicenses.licenses[licenseId] = {};
                    });
                }
                return userLicenses;
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                // !!!!!!!!!!! LICENSES aus CART und WALLET zusammengeführt => noch ausführlich testen
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            },
            userLicensesServer(){
                return this.$store.state.realtime.userLicenses;
            },
            licenseList(){
                const licenses = [];
                for ( let i = 0; i < this.fieldSettings.licenses.length; i++ ) {
                    const licenseSettings = this.fieldSettings.licenses[i];
                    if (this.$store.state.shopLicenses &&
                        this.$store.state.shopLicenses[licenseSettings.id]) {
                        const license = this.$store.state.shopLicenses[licenseSettings.id];
                        if ( this.userLicenses ) {
                            const isAllowed = this.conditionCheckService.checkConditionUser(
                                license.conditions,
                                this.user,
                                this.userLicenses
                            );
                            if ( isAllowed.result && licenseSettings.id !== 'Babolat_43') {
                                license.settings = licenseSettings;
                                licenses.push(
                                    this.$store.state.shopLicenses[licenseSettings.id]
                                )
                            }
                        }
                    }
                }
                licenses.sort((a, b) => a.settings.sort > b.settings.sort ? 1 : -1);
                return licenses;
            },
            leadLicense(){
                return this.licenseList.length > 0 ? this.licenseList[0] : null;
            },
            basePrice(){
                return LicensePriceService.getCurrentBasePrice(
                    this.user, this.userLicenses, this.leadLicense, true
                )
            },
            discount(){
                return LicensePriceService.getCurrentPriceDiscount(
                    this.user, this.userLicenses, this.leadLicense, true
                )
            },
            licenseIsInCart(){
                if ( this.shoppingCart && this.shoppingCart.items[this.user.uid] ) {
                    for ( let i = 0; i < this.fieldSettings.licenses.length; i++ ) {
                        const licenseId = this.fieldSettings.licenses[i].id;
                        if ( this.shoppingCart.items[this.user.uid][licenseId] !== undefined ) {
                            return licenseId;
                        }
                    }
                }
                return false;
            },
            licenseIsInWallet(){
                if ( this.userLicensesServer && this.userLicensesServer.licenses ) {
                    for ( let i = 0; i < this.fieldSettings.licenses.length; i++ ) {
                        const licenseId = this.fieldSettings.licenses[i].id;
                        if ( this.userLicensesServer.licenses[licenseId] !== undefined ) {
                            return this.$store.state.shopLicenses?.[licenseId] ?
                                   this.$store.state.shopLicenses[licenseId].subtitle : false;
                        }
                    }
                }
                return false;
            },
            licenseInWallet(){
                if ( this.userLicensesServer && this.userLicensesServer.licenses ) {
                    for ( let i = 0; i < this.fieldSettings.licenses.length; i++ ) {
                        const licenseId = this.fieldSettings.licenses[i].id;
                        if ( this.userLicensesServer.licenses[licenseId] !== undefined ) {
                            return this.userLicensesServer.licenses[licenseId]
                        }
                    }
                }
                return null;
            },
            toBeRemovedFromCart(){
                return this.licenseIsInCart &&
                       !this.licenseList.find(license => license.settings.id === this.licenseIsInCart );
            },
        // configs and displays
            validityText(){
                return this.leadLicense.validity.type === 'TIME_FRAME' ?
                    this.leadLicense.validity.startDate === this.leadLicense.validity.endDate ?
                        TimeStamp.getLocalStringInclWeekdayFromDateString(new Date(this.leadLicense.validity.startDate))
                        :
                        TimeStamp.getTimeRangeFromTwoStringDates(
                            this.leadLicense.validity.startDate,
                            this.leadLicense.validity.endDate,
                        )
                    : null;
            },
            infoAlert(){
                return {
                    show: this.license && this.license.licenseDescription !== '',
                    css: 'mb-1 pb-3 pt-4',
                    border: 'top',
                    //shaped: true,
                    icon: false,
                }
            }
        },
        created(){
            this.setSelectedLicense();
            if ( this.toBeRemovedFromCart ) {
                this.removeFromCart(this.licenseIsInCart);
            }
        },
        methods: {
            handleSelectInput(selectedValue){
            // return if selection is not yet in the cart (not cart action required)
                if ( !this.licenseIsInCart ) return
            // remove item from cart if selection has been cleared or changed
                this.removeFromCart(this.licenseIsInCart)
            // add item to cart if selection has been changed
                if ( selectedValue !== null ) {
                    this.addToCart()
                }
            },
            setSelectedLicense(){
                this.selectedLicenseId = this.licenseIsInCart ? this.licenseIsInCart :
                                         this.licenseIsInWallet ? this.licenseIsInWallet : null;
                this.userText = !this.fieldSettings.fieldSettings?.userText ? '' :
                    this.licenseIsInCart ?
                        this.shoppingCart.items[this.user.uid][this.selectedLicenseId].description :
                        this.licenseInWallet?.description??''
            },
            processItemClick(){
                const licenseIdInCart = this.licenseIsInCart;
                if ( licenseIdInCart ) {
                    this.removeFromCart(licenseIdInCart);
                } else {
                    this.addToCart();
                }
            },
            addToCart(){
                if ( !this.selectedLicenseId || !this.userTextStatus ) {
                    this.snackbarSelected = true;
                    return;
                }
                const item = {
                    uid: this.user.uid,
                    licenseId: this.selectedLicenseId,
                    amount: 1,
                    price: this.basePrice.price - this.discount,
                    currency: this.basePrice.currency,
                    reference: '',
                    description: this.userText ? this.userText : '',
                    blockDelete: true,
                    sellingOrganisationId: this.shopEvent.data.sellingOrganisationId,
                };
                this.shoppingCart.addItem(item)
            },
            updateTextInCartItem(){
                if ( this.fieldSettings.fieldSettings?.userText && this.licenseIsInCart ) {
                    this.shoppingCart.items[this.user.uid][this.selectedLicenseId].description = this.userText
                }
            },
            removeFromCart(licenseIdInCart){
                this.shoppingCart.removeItem(
                    this.user.uid, licenseIdInCart
                )
            }
        },
        watch: {
            licenseIsInCart(){
                this.setSelectedLicense();
            },
            userText(){
                if ( this.userText?.length > 0 ) {
                    this.updateTextInCartItem();
                } else if (this.licenseIsInCart) {
                    this.removeFromCart(this.licenseIsInCart);
                }
            },
            toBeRemovedFromCart(to, from){
                if ( to && !from ) {
                    this.removeFromCart(this.licenseIsInCart);
                }
            },
            userLicensesServer(){
                if ( this.licenseIsInWallet ) { this.setSelectedLicense() }
            }
        }
    }
</script>

<style scoped>

</style>