import {Mother} from "@/utilities/models/mother";

export class StripeRefresh extends Mother {
    constructor(dataObject){
        super();
        this.uid = '';
        this.collection = '';
        this.documentId = '';
        this.stripeId = '';
        this.stripeAccountId = '';
        this.successReported = false;
        this.ts = 0;
    // database collection
        this.local.fireBaseCollection = 'stripe_document_refreshs';
    // set data
        if(dataObject){this.setFromDataObject(dataObject);}
    }
}