<template>
    <v-alert :value="showValue"
             :border="configEl.border"
             :color="configEl.color"
             :text="configEl.text"
             :type="configEl.type"
             :class="configEl.css"
             :icon="configEl.icon"
             :prominent="configEl.prominent"
             :dense="configEl.dense"
             :outlined="configEl.outlined"
             :shaped="configEl.shaped"
             :colored-border="configEl.coloredBorder"
             :dismissable="configEl.dismissable"
             :elevation="configEl.elevation"
             :transition="configEl.transition"
    >
        <slot></slot>
    </v-alert>
</template>

<script>
    // Version 1.1 2021-12-20

    import ComponentConfigService from '@/utilities/services/components/componentConfigService';

    export default {
        name: "alert-message",
        props: {
            configPackage: {default: 'default'},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            showValue: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.displays.alertMessage',
        // define here, which parameter should be customizeable
            configDefault: {
                default: {
                    show: false,
                    border: 'left',
                    color: 'primary',
                    text: true,
                    type: 'info',
                    css: '',
                    icon: undefined,
                    prominent: false,
                    dense: false,
                    outlined: false,
                    shaped: false,
                    coloredBorder: false,
                    dismissable: true,
                    elevation: 0,
                    transition: "scroll-y-transition", // slide-x-transition, scale-transition
                    timeout: 0,
                },
                progress: {
                    show: false,
                    border: 'left',
                    color: 'primary',
                    text: true,
                    type: undefined,
                    css: '',
                    icon: 'mdi-information',
                    prominent: false,
                    dense: false,
                    outlined: false,
                    shaped: false,
                    coloredBorder: false,
                    dismissable: true,
                    elevation: 0,
                    transition: "scroll-y-transition", // slide-x-transition, scale-transition
                    timeout: 0,
                },
                error: {
                    show: false,
                    border: 'left',
                    color: undefined,
                    text: true,
                    type: 'error',
                    css: '',
                    icon: undefined,
                    prominent: false,
                    dense: false,
                    outlined: false,
                    shaped: false,
                    coloredBorder: false,
                    dismissable: true,
                    elevation: 0,
                    transition: "scroll-y-transition", // slide-x-transition, scale-transition
                    timeout: 0,
                },

            }
        }),
        computed: {
        // always necessary
            configEl(){
                const configCustom = this.moduleConfig ?
                    this.configProp :
                    {[this.configPackage]: this.configProp}; // user passes props without package
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    configCustom,
                    this.moduleConfig,
                    this.path,
                )[this.configPackage];
            },
        },
        created(){
            this.showValue = this.configEl.show;
        },
        watch: {
            'configEl.show'(to){
                if ( to ) {
                    this.showValue = true;
                    if ( this.configEl.timeout > 0 ) {
                        setTimeout(()=>{
                            this.showValue = false;
                        }, this.configEl.timeout)
                    }
                } else {
                    this.showValue = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>