<template>
    <v-app>
        <v-main class="bgdGreyLight" v-if="offline">
            <offline></offline>
        </v-main>
        <v-main v-if="ieBlock">
            <p>Diese Anwendung läuft leider nicht mehr auf dem veralteten Internet Explorer.</p>
            <p>Wir empfehlen die Verwendung von Google Chrome, du kannst aber auch jeden anderen
                modernen Browser nutzen (z.B. Microsoft Edge, Firefox, Opera oder Safari für Apple).</p>
        </v-main>
        <v-main v-if="!ieBlock &&
                      !offline &&
                      !loginService.loginCompleted() &&
                      !changingUser &&
                      $route.path!=='/termsOfUse' &&
                      $route.path!=='/privacy' &&
                      $route.path!=='/imprint'"
                class="bgdGreyLight">
            <router-view v-if="isStripePaymentReply"/>
            <authentication-view v-else :login-service="loginService">
            </authentication-view>
        </v-main>
        <v-main v-if="!ieBlock &&
                      !offline &&
                      loginService.loginCompleted() ||
                      $route.path==='/termsOfUse' ||
                      $route.path==='/privacy' ||
                      $route.path==='/imprint'"
                class="bgdGreyLight">
            <router-view/>
            <progress-dialog v-if="user"
                             :waiting-for-reply="changingUser"
                             :config-prop="{text: ''}">
                {{user.firstName}} {{$t('common.isLoaded')}}!
            </progress-dialog>
        </v-main>
        <terms-confirmation/>
    </v-app>
</template>

<script>

import {FirebaseAuth} from "@/utilities/services/firebase/firebaseAuth";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import AuthenticationView from "@/views/authenticationView";
import ProgressDialog from "./utilities/components/dialogs/progressDialog";
import Offline from "./views/offline";
import TermsConfirmation from "./views/terms/termsConfirmation";
import {UserConsents} from "@/models/userConsents";

export default {
    components: {
        TermsConfirmation,
        Offline,
        ProgressDialog,
        AuthenticationView,
    },
    name: 'App',
    data: () => ({
        ieBlock: false,
    }),
    computed: {
        offline(){ return this.$store.state.local.offline; },
        loginService(){ return this.$store.state.loginService;},
        user(){ return this.$store.getters.user;},
        userPasses(){ return this.$store.state.realtime.userPasses },
        userVouchers(){ return this.$store.state.realtime.userVouchers },
        tab() { return this.$route.query.tab },
        version() { return this.$store.state.realtime.version ?
                           this.$store.state.realtime.version.version : null; },
        changingUser(){ return this.$store.state.local.changingUser },
        isStripePaymentReply(){ return this.$route.path === '/stripePaymentReply'}
        /*mainBgd() { return (this.tab === undefined || this.tab==0) && this.$route.path=== '/' ?
                   'bgdGreyMedium' : 'bgdGreyLight' }*/
    },
    created(){
        this.ieBlock = this.checkForIE();
        this.setLoginService();
        this.setLanguage();
        this.bindVersion();
        this.getAllOrganisationDocuments();
    },
    methods: {
        checkForIE(){
            const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
            const msie = ua.indexOf('MSIE '); // IE 10 or older
            const trident = ua.indexOf('Trident/'); //IE 11
            return (msie > 0 || trident > 0);
        },
        setLoginService(){
            this.$store.state.loginService = new FirebaseAuth({
                //password: '',
                //persistence: 'LOCAL', // take from store in real app: LOCAL, SESSION
                //appUser: '', // link to store but use user object service
                userPasswortStatus: 'set',
                emailVerificationRequired: true,
            })
        },
        setLanguage() {
            this.$i18n.locale = navigator.language.substring(0,2).toLowerCase() === 'de' ? 'de' : 'en';
        },
        bindVersion(){
            this.$store.dispatch('bindRealtimeDocument', {
                collection: 'version',
                storeField: 'version',
                docId: 'version',
            });
        },
        async bindUserDocs(){
            await this.$store.dispatch('bindRealtimeDocument', {
                collection: 'user_permissions',
                storeField: 'userPermissions',
                docId: this.user.uid,
            });
            await this.$store.dispatch('bindRealtimeDocument', {
                collection: 'user_passes',
                storeField: 'userPasses',
                docId: this.user.uid,
            });
            await this.$store.dispatch('bindRealtimeDocument', {
                collection: 'user_vouchers',
                storeField: 'userVouchers',
                docId: this.user.uid,
            });
            //const currentYear = new Date().getFullYear();
            await this.$store.dispatch('bindRealtimeDocument', {
                collection: 'user_licenses',
                storeField: 'userLicenses',
                docId: this.user.uid,// + '_' + currentYear,
            });
            if ( this.user.parentConfirmRequired===true ) {
                await this.$store.dispatch('bindRealtimeDocument', {
                    collection: 'parentConfirms',
                    storeField: 'parentConfirm',
                    docId: this.user.uid,
                });
                this.$store.state.local.parentConfirmAttemptedToBind = true;
            }
            if ( this.user.childs.length>0 ) {
                await this.$store.dispatch('setMasterUser');
            // change user if set in query
                let currentUser = sessionStorage.getItem("currentUser");
                currentUser = currentUser ? JSON.parse(currentUser):null;
                console.log ("currentUser", currentUser);
                if ( currentUser && currentUser.active !== this.user.uid ) {
                    this.$store.dispatch('changeUser', {uid: currentUser.active} )
                }
            }
            this.$store.state.sports = await FirebaseRead.readSingleDoc('sports', 'sports')
        // check if auth.uid === user.uid, if no change
            this.loginService.checkForUndoEmailChange();
        },
        async setNonRealtimeData(){
        // user consents
            if ( !this.$store.state.userConsents ) {
                const userConsents = await FirebaseRead.readSingleDoc(
                    'user_consents', this.user.uid.substring(0,28))
                this.$store.state.userConsents = new UserConsents(userConsents)
                this.$store.state.userConsents.id = this.user.uid.substring(0,28)
            }
        },
        unbindUserDocs(){
            this.$store.dispatch('unbindRealtimeProperty', 'userPermissions');
            this.$store.dispatch('unbindRealtimeProperty', 'userPasses');
            this.$store.dispatch('unbindRealtimeProperty', 'userVouchers');
            this.$store.dispatch('unbindRealtimeProperty', 'userLicenses');
            this.$store.dispatch('unbindRealtimeProperty', 'parentConfirm');
            //this.$store.dispatch('unbindRealtimeProperty', 'clubs');
        },
        clearStore(){
            //this.$store.state.organisations = {};
            this.$store.state.shoppingCart = null;
            this.$store.state.shopFir2022LicensesVienna = null;
            this.$store.state.shopFir2022LicensesGraz = null;
            this.$store.state.shopFir2022Vienna = null;
            this.$store.state.shopFir2022Graz = null;
            this.$store.state.userConsents = null;
            this.$store.state.myUserGroups = null;
        },
        async getAllOrganisationDocuments(){
            const organisations = await FirebaseRead.readFullList('organisations');
            organisations.forEach( (organisation) => {
                this.$store.state.organisations[organisation.id] = organisation
            });
        },
        getOrganisationDocuments(documentsToBeChecked){
            Object.keys(documentsToBeChecked).forEach( (docId) => {
                const organisationId = documentsToBeChecked[docId].organisationId;
                if ( this.$store.state.organisations[organisationId] === undefined ) {
                    this.getOrganisationFromFirebase(organisationId)
                }
            });
        },
            async getOrganisationFromFirebase(organisationId) {
                this.$store.state.organisations[organisationId] = null;
                const readDoc = await FirebaseRead.readSingleDoc('organisations', organisationId);
                if ( readDoc ) {
                    let orgsTemp = JSON.parse(JSON.stringify(this.$store.state.organisations));
                    orgsTemp[organisationId] = readDoc;
                    this.$store.state.organisations = orgsTemp;
                }
            }
    },
    watch: {
        version(to, from){
            if ( from === null && to ) {
                this.$store.dispatch('checkVersion');
            } else if ( from && from.indexOf('offline') !== -1 &&
                to && to.indexOf('offline') === -1
            ){
                this.$store.dispatch('checkVersion');
            }
        },
        user(to, from){
            if ( to && !from ) {
                this.bindUserDocs();
                this.setNonRealtimeData();
            } else if (from && !to) {
                this.unbindUserDocs();
                this.clearStore();
            }
        },
        /*'user.cloudFunctionInProgress'(to, from){
            console.log ("WATCH user.cloudFunctionInProgress !!!!!!!!!!!!!!!!!!");
            if ( !to && from ) {
                console.log ("WATCH user.cloudFunctionInProgress excuted!!!!!!!!!!!!!!!!!!");
                this.bindUserDocs();
            }
        },*/
        'user.language'(){
            if ( this.user && this.user.language ) {
                this.$i18n.locale = this.user.language;
            }
        },
        userPasses(){
            console.log ("WATCH userPasses" );
            if ( this.$store.state.realtime.userPasses ) {
                this.getOrganisationDocuments(
                    this.$store.state.realtime.userPasses.passes,
                )
            }
        },
        userVouchers(){
            console.log ("WATCH userVouchers" );
            if ( this.$store.state.realtime.userVouchers ) {
                this.getOrganisationDocuments(
                    this.$store.state.realtime.userVouchers.vouchers,
                )
            }
        }
    }

};
</script>
