<template>
    <div class="pl-4 pr-6">
        <btn-text v-if="!configs.successAlert.show"
                 @click="createPurchaseOrder()"
                  :config-prop="configs.buyBtn">
            {{$t('shop.buyFree')}}
        </btn-text>
        <alert-message :config-prop="configs.successAlert">
            {{$t('shop.buyFreeConfirmed')}}
            <div class="text-right mx-3 mt-3">
                <btn-text @click="back()"
                          :config-prop="configs.backBtn">
                    {{$t('shop.gotoWallet')}}
                </btn-text>
            </div>
        </alert-message>
    </div>
</template>

<script>
    import BtnText from "@/utilities/components/controls/btnText";
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
    import AlertMessage from "../../utilities/components/displays/alertMessage";

    export default {
        components: {
            AlertMessage,
            BtnText},
        name: "shop-cart-total-free",
        data: () => ({
            configs: {
                buyBtn: {
                    block: true,
                    color: 'secondary',
                },
                successAlert: {
                    show: false,
                    css: 'mt-5'
                }
            }
        }),
        computed: {
            shoppingCart(){ return this.$store.state.shoppingCart },
            purchaseOrder(){ return this.shoppingCart.purchaseOrder},
            uid(){ return this.$store.getters.user.uid.substring(0,28)},
            getSumObject() { return this.shoppingCart.getSum(); },
        },
        methods: {
            async createPurchaseOrder(){
                const purchaseOrderItems = this.shoppingCart.getPurchaseOrderItems();
                console.log ("purchaseOrderItems", purchaseOrderItems);
                const purchaseOrderData = this.shoppingCart.getPurchaseOrderData(purchaseOrderItems, this.uid);
                if ( !purchaseOrderData ) { return } // add error handling here
                const reply = await FirebaseWrite.add('purchaseOrders', purchaseOrderData);
                if ( reply.successful ) {
                    console.log ("reply", reply);
                    this.$store.state.shoppingCart.purchaseOrder = reply.content;
                    this.$store.state.shoppingCart.status = 'paid';
                    this.shoppingCart.setToSessionStorage();
                    this.resetShoppingCart(reply.content.id);
                    this.configs.successAlert.show = true;
                }
            },
            resetShoppingCart(purchaseOrderId){
                if ( this.shoppingCart.purchaseOrder.id === purchaseOrderId ) {
                    this.shoppingCart.reset(true);
                }
            },
            back(){
                this.$store.state.wallet.zoomed.section = '';
                this.$store.state.wallet.zoomed.documentId = '';
                this.$store.state.wallet.zoomed.document = null;
                this.$router.replace({path: '/', query: {tab: '2'}})
            }
        },
    }
</script>

<style scoped>

</style>