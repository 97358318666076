<template>
    <div class="ml-4 mr-2">
         <span v-for="(card, i) in cards" :key="i">
            <wallet-card v-if="showCard(card)"
                         :class="[{[floatingClasses[card.id]]: $vuetify.breakpoint.xsOnly },
                                  {'mb-2': $vuetify.breakpoint.xsOnly },
                                  {'mb-4': $vuetify.breakpoint.smAndUp },
                                  {'archive': archive }]"
                         :section="section" :document="card"
                         :floating-class="floatingClasses[card.id]"
                         :hide-corner-tag="hideCornerTag"
                         :style="getStyle(card)">
                <wallet-events-content v-if="cardList==='userLicenses'" :userEvent="card">
                </wallet-events-content>
                <wallet-passes-content v-if="cardList==='userPasses'" :userPass="card">
                </wallet-passes-content>
                <wallet-vouchers-content v-if="cardList==='userVouchers'" :userVoucher="card">
                </wallet-vouchers-content>
            </wallet-card>
        </span>
        <div class="clearFloat"></div>
        <div v-if="walletZoomed.section"
            :class="[{'placeholderBoxDyn': $vuetify.breakpoint.xsOnly},
                     {'placeholderBoxFix': $vuetify.breakpoint.smAndUp},
                      'bgdGreyLight']">
        </div>
    </div>
</template>

<script>
    import WalletCard from "@/components/wallet/walletCard";
    import WalletEventsContent from "@/components/wallet/walletEventsContent";
    import WalletPassesContent from "@/components/wallet/walletPassesContent";
    import WalletVouchersContent from "@/components/wallet/walletVouchersContent";
    export default {
        components: {
            WalletVouchersContent,
            WalletPassesContent,
            WalletEventsContent, WalletCard},
        name: "wallet-card-list",
        props: ['cards', 'cardList', 'section', 'stylesSource', 'archive', 'hideCornerTag'],
        computed: {
            /*
            cards(){
                return this.$store.state.realtime[this.cardList] ?
                    ObjectHandlingService
                        .getListFromObject(this.$store.state.realtime[this.cardList][this.section])
                        .sort((a, b) => a.from > b.from ? 1 : -1):
                    [];
            },
            */
            floatingClasses(){
                if ( this.$store.state.realtime[this.cardList] ) {
                    let floatingClasses = {};
                    let i = 1;
                    this.cards.forEach( (card) => {
                        floatingClasses[card.id] = i%2 !== 1 ? 'float-right' : 'float-left';
                        i++;
                    });
                    return floatingClasses
                } else {
                    return {}
                }
            },
            walletZoomed(){
                return this.$store.state.wallet.zoomed;
            },
        },
        methods: {
            showCard(card){
                return (!this.walletZoomed.section &&
                       (!this.walletZoomed.documentId ||
                        this.walletZoomed.documentId === card.id)) ||
                       (this.walletZoomed.section &&
                        this.walletZoomed.documentId === card.id)
            },
            getStyle(card){
                if ( this.stylesSource === 'organisation' ) {
                    return {backgroundColor: this.getColorFromOrganisation(card.organisationId)}
                } else if ( this.stylesSource === 'document' ) {
                    return {backgroundColor: this.getColorFromDocument(card)}
                } else if ( this.stylesSource === 'passStyle' )  {
                    return {backgroundColor: card.style?.styles?.color ? card.style.styles.color :
                                            this.getColorFromOrganisation(card.organisationId)}
                } else {
                    return {backgroundColor: 'grey'}
                }
            },
            getColorFromOrganisation(id){
                return this.$store.state.organisations[id] ?
                    this.$store.state.organisations[id].styles.color :
                    'grey';
            },
            getColorFromDocument(card){
                return card.styles.color ?
                    card.styles.color :
                    'grey';
            }
        },
    }
</script>

<style scoped>
    .clearFloat{clear: both}
    .archive{opacity: 0.5}
    .placeholderBoxDyn {width: 96%; padding-top: 31.76%; }
    .placeholderBoxFix {width: 100%; height: 48px; }
</style>