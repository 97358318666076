<template>
    <div class="mx-auto" style="max-width: 600px" v-if="shopAreaSelect">
        <alert-message :config-prop="{show: ageMessage==='olderThan18'}" config-package="error" class="mt-4 mb-0">
            {{$t('profile.' + ageMessage)}}
        </alert-message>
        <alert-message :config-prop="{show: ageMessage!=='' && ageMessage!=='olderThan18'}" class="mt-4 mb-0">
            {{$t('profile.' + ageMessage)}}
        </alert-message>
        <div v-if="ageMessage!=='olderThan18'">
        <!--  my sports -->
            <p v-if="shopAreaSelect.hasMySportsDefined"
               class="font-weight-medium text-uppercase grey--text text--darken-2 space2 font14 pt-4 ">
                {{$t('sports.my')}}
            </p>
            <shop-area-select-card v-for="(organisation, i) in shopAreaSelect.myOrganisations" :key="i" :is-others="false"
                    @selected="setQuery" :organisation="organisation" :shop-area-select="shopAreaSelect" :show-test="showTest"/>
        <!-- other sports -->
            <v-checkbox v-if="isOYMAdmin" v-model="showTest" hide-details
                        label="System Admin Only: auch Kunden im Testmodus anzeigen" />
            <btn-text v-if="shopAreaSelect.otherOrganisations.length>0 &&
                            !shopAreaSelect.othersVisible" class="mt-8 "
                      :config-prop="{color: 'primary', block: true,}" @click="shopAreaSelect.showOthers()">
                {{$t('sports.btnOthers')}} <v-icon>mdi-chevron-down</v-icon>
            </btn-text>
            <div v-else-if="shopAreaSelect.othersVisible && shopAreaSelect.otherOrganisations.length>0">
                <p class="font-weight-medium text-uppercase grey--text text--darken-2 space2 font14 pt-6 mb-2">
                    {{$t('sports.others')}}
                </p>
                <btn-text :config-prop="{color: 'primary', small: true}"
                          @click="shopAreaSelect.othersVisible=false">
                    {{$t('sports.btnOthersHide')}} <v-icon small>mdi-chevron-up</v-icon>
                </btn-text>
            </div>
            <div v-if="shopAreaSelect.othersVisible">
                <shop-area-select-card v-for="(organisation, i) in shopAreaSelect.otherOrganisations" :key="i" :is-others="true"
                                   @selected="setQuery" :organisation="organisation" :shop-area-select="shopAreaSelect"
                                       :show-test="showTest"/>
            </div>
        </div>
<!--
        <v-card style="border: solid 1px #e2e2e2"
                class="mt-3 mx-auto" width="600"
                @click="setQuery('nuXjFA2baIUUsb4jgHB7')">
            <v-row no-gutters style="background-color: #BB1918">
                <v-col class="pt-5 pb-3 pl-6 text-left" style="max-width: 400px">
                    <img src="https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/organisationLogos%2FnuXjFA2baIUUsb4jgHB7_logo.svg?alt=media&token=cd6fb4ea-da4c-4f25-b0ae-df48362d60e8" width="60%">
                </v-col>
            </v-row>
        </v-card>
        <v-card style="border: solid 1px #e2e2e2"
                class="mt-3 mx-auto" width="600"
                @click="setQuery('squashworldtour')">
            <v-row no-gutters style="background-color: #251233">
                <v-col class="pt-3 pb-2 pl-6 text-left">
                    <img  height="64"
                        src="https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/organisationLogos%2Fsquashworldtour_logo.svg?alt=media&token=8cffbfd3-97d5-400a-8682-715567f76444">

                </v-col>
            </v-row>
        </v-card>
        <v-card style="border: solid 1px #e2e2e2"
                class="mt-3 mx-auto" width="600"
                @click="setQuery('rackathletes')">
            <v-row no-gutters style="background-color: #ffed00">
                <v-col class="pt-4 pb-3 pl-5 text-left">
                    <img  height="48"
                          src="https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/organisationLogos%2Frackathletes_logoWide.svg?alt=media&token=f162c53a-29f2-4cdb-b98f-ae8732cec72b">
                </v-col>
            </v-row>
        </v-card>
        <v-card style="border: solid 1px #e2e2e2"
                class="mt-3 mx-auto" width="600"
                @click="setQuery('pickleball')">
            <v-row no-gutters style="background-color: #212226">
                <v-col class="pt-3 pb-1 pl-3 text-left">
                    <img  height="62"
                          src="https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/organisationLogos%2Fpfa_logo_neu_w.png?alt=media&token=d8b3ab0b-1903-48ea-8b58-e57d44e0ad17">
                </v-col>
            </v-row>
        </v-card>-->
    </div>
</template>

<script>
    import RouterServices from '@/utilities/services/router/routerServices';
    import {ShopAreaSelectService} from "@/services/shop/shopAreaSelectService";
    import BtnText from "@/utilities/components/controls/btnText";
    import ShopAreaSelectCard from "@/components/shop/shopAreaSelectCard";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

    export default {
        name: "shop-area-select",
        components: {AlertMessage, ShopAreaSelectCard, BtnText},
        computed: {
            masterUser(){ return this.$store.state.realtime.userMaster},
            user(){ return this.$store.getters.user},
            ageMessage(){ return this.user ? this.getAgeMessage() : ''},
            isOYMAdmin(){ return this.$store.state.realtime.userPermissions?.isOYMAdmin}
        },
        data: () => ({
            shopAreaSelect: null,
            showTest: false,
        }),
        created(){
            this.init()
        },
        methods: {
            setQuery(parameterValue, isOthers){
                RouterServices.pushRouteMultipleQueryParameter(
                    this.$router, this.$route,
                    {shopArea: parameterValue, isOthers: isOthers}
                );
                /*RouterServices.pushRouteQueryParameter(
                    this.$router,
                    this.$route,
                    'shopArea',
                    parameterValue);*/
            },
            init(){
                const user = this.masterUser ? this.masterUser : this.user
                if ( (!user) ||
                    Object.keys(this.$store.state.organisations).length === 0 ) return
                this.shopAreaSelect = new ShopAreaSelectService(user)
            },
            getAgeMessage(){
                if ( this.user.uid.length === 28 ) return ''
                const age = TimeStamp.getAgeInYears(this.user.birthday)
                const yearDelta = TimeStamp.getTodaysDateString().substring(0,4) -
                                  this.user.birthday.substring(0,4)
                return age < 17 ? '' :
                       age >= 18 && yearDelta >= 19 ? 'olderThan18' :
                       yearDelta === age ? 'nextYear18' : 'thisYear18'
            }
        },
        watch: {
            masterUser(){
                this.init()
            }
        }
    }
</script>

<style scoped>

</style>