<template>
    <div>
        <v-row :class="['text-center align-center justify-center pa-0 mt-5',
                       styleClasses]">
        <!-- Image Preview slot-->
            <slot></slot>
        <!-- Image Uploader -->
            <image-uploader @onUpload="setLoader()" @onComplete="unsetLoader()"
                            :preview="false" :className="[{ 'fileinput--loaded': !hasImage }, { 'fileinput': hasImage }]"
                            :debug=2 :autoRotate="false" outputFormat="verbose"
                            @input="setImage" :maxWidth="maxWidth" :maxHeight="maxHeight" :quality="quality" >
            <!-- Button for image selection -->
                <label for="fileInput" slot="upload-label">
                    <figure>
                        <v-col cols="12" class="ma-0" v-if="!imageLoading">
                            <div v-ripple :class="['v-btn primary px-5']">
                                <v-icon dark class="mr-2">mdi-upload</v-icon>
                                <span class="text-button">{{$t(btnTxt)}}</span>
                            </div>
                        </v-col>
                    </figure>
                </label>
            </image-uploader>
        </v-row>
    <!-- Block Window while loading -->
        <v-dialog v-model="loader" width="300" persistent>
            <v-card class="text-center pa-5">
                <p>{{$t('utilities.components.images.loading')}}</p>
                <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    // Version 1.2 2022-02-16
    export default {
        name: "image-upload-resize",
        props: {
            maxWidth: { default: 1000} ,
            maxHeight: { default: 1000} ,
            quality: { default: 0.95} ,
            hasImage: { default: false },
            styleClasses: { default: 'withBackground small'},
            imageLoading: { default: false },
        },
        data: () => ({
            loader: false,
            errorReset: null,
        }),
        computed: {
            btnTxt () { return this.hasImage ?
                'utilities.components.images.changeImage' :
                'utilities.components.images.selectImage' }
        },
        methods: {
            setLoader(){
                this.loader = true;
                this.errorReset = setTimeout( () => {
                    this.loader = false;
                    /*this.$store.dispatch('clearSnackbar');
                    this.$store.state.globalSnack.timeout = 6000;
                    this.$store.state.globalSnack.text = 'Das Dateiformat des Fotos kann nicht verarbeitet werden!';
                    this.$store.state.globalSnack.show = true;*/
                    }, 10000)
            },
            unsetLoader(){
                clearTimeout(this.errorReset);
                this.loader = false;
            },
            setImage(output) {
                this.$emit('selected', output.dataUrl)
            },
        }
    }
</script>

<style> /* Memo: style must not be scoped because otherwise #fileInput does not work in image-uploader */
    #fileInput { display: none; }
/* Info: the following classes are available to style the preview area */
    .withBackground {  background-color: #f9f9f9; border: dashed 1px #bbb; }
    .small { width: 90%; height: 200px; margin: auto; }
    .big { width: 90%; height: 400px; margin: auto; }
    .cropping { width: 80%; height: auto; margin: auto; }
</style>