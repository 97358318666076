import {ServiceMother} from "@/utilities/services/serviceMother";
import store from "@/store/store";
import ObjectHandlingService from "@/utilities/services/objectHandling/objectHandlingService";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";


export class ShopAreaSelectService extends ServiceMother {
    constructor(user) {
        super();
        this.organisations = ObjectHandlingService.getListFromObject(store.state.organisations).filter(
            organisation => !organisation.isThirdParty && !organisation.hideInShop && (
                (organisation.ownerUid === user.uid) || (store.state.realtime.userPermissions?.isOYMAdmin) ||
                (TimeStamp.getTodaysDateString() <= organisation.contracts?.base &&
                 organisation.contracts?.base && organisation.logo && organisation.logoDark &&
                 organisation.documents?.privacy)
            )
        ).sort((a, b) => a.name > b.name ? 1 : -1);
        this.masterUserSports = user.sports
        this.mySports = this.getMySportsList()
        this.hasMySportsDefined = false
        this.myOrganisations = this.getMyOrganisations()
        this.otherOrganisations = this.getOtherOrganisations()
        this.othersVisible = false
    }

    getMyOrganisations(){
        if ( !this.masterUserSports || this.masterUserSports?.none || this.masterUserSports?.all ||
            ( this.masterUserSports && Object.keys(this.masterUserSports).length === 2 &&
              this.masterUserSports.all === false && this.masterUserSports.none === false ) ) {
            this.hasMySportsDefined = false
            return this.organisations
        } else {
            this.hasMySportsDefined = true
            return this.organisations.filter(organisation =>
                (organisation.sports?.some(sport => this.mySports.includes(sport)))
            );
        }
    }

    getOtherOrganisations(){
        return this.masterUserSports && !this.masterUserSports?.none && !this.masterUserSports?.all &&
               this.hasMySportsDefined ?
            this.organisations.filter(organisation => organisation.sports?.length === 0 ||
            (organisation.sports?.some(sport => !this.mySports.includes(sport)))) : []
    }

    getMySportsList(){
        const mySports = []
        if ( !this.masterUserSports ) return mySports
        Object.keys(this.masterUserSports).forEach( (sportId) => {
            if ( this.masterUserSports[sportId] ) mySports.push(sportId)
        });
        return mySports
    }

    showOthers(){
        this.othersVisible = true
    }

    getCornerTag(organisation, isOthers){
        if ( !organisation.sports ) return
        if ( !this.masterUserSports ) {
            return organisation.sports.length > 1 ? '' :
                store.state.sports?.[organisation.sports[0]]?.cornerTag;
        }
        if ( organisation.sports.length === 1 ) {
            return store.state.sports?.[organisation.sports[0]]?.cornerTag;
        } else if (!isOthers) {
            let i = 0, cornerTagSport = ''
            organisation.sports.forEach( (sport) => {
                if ( this.masterUserSports[sport] ) {
                    cornerTagSport = sport
                    i++
                }
            });
            return i > 1 ? '' : store.state.sports?.[cornerTagSport]?.cornerTag;
        } else {
            let i = 0, cornerTagSport = ''
            organisation.sports.forEach( (sport) => {
                if ( !this.masterUserSports[sport] ) {
                    cornerTagSport = sport
                    i++
                }
            });
            return i > 1 ? '' : store.state.sports?.[cornerTagSport]?.cornerTag;
        }
    }
}