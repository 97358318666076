<template>
    <div>
        <div v-if="!verifiedIfNewRequestIsPermitted" class="py-3 text-center">
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
        </div>
        <!--
        <div v-else-if="paymentCompleted">
            necessary for back naviagtion from shop after redirection from stripe payment success
            <alert-message :config-prop="paidMessage"
                           :module-config="config">
                {{$t(content.paymentCompletedMessage.default.i18n)}}
            </alert-message>
        </div>-->
        <div v-else>
            <alert-message :config-prop="configs.failedMessage" config-package="error">
                {{$t(content.creationFailedMessage.default.i18n)}}
            </alert-message>
            <!--!isPaid noch notwendig???-->
            <btn-text v-if="content.show && !isPaid && !successReported"
                      :config-prop="contentBtn"
                      :module-config="config"
                      :disabled="disabledByModule"
                      @click="createCheckoutSession()">
                {{$t(content.btn.default.i18n)}}
            </btn-text>
            <div v-if="message.default">
                <alert-message :config-prop="message"
                               :module-config="config">
                    {{$t(message.default.i18n)}}
                    <v-progress-linear
                            v-if="checkoutSession &&
                                  !successReported &&
                                  checkoutSession.local.inProgress &&
                                  (!checkoutSessionListener.data ||
                                   !checkoutSessionListener.data.stripe.status )"
                            indeterminate color="primary" class="mt-3">
                    </v-progress-linear>
                    <v-card-actions v-if="checkoutSessionListener.data &&
                                          !successReported &&
                                          checkoutSessionListener.data.stripe.status==='open'"
                                    class="mt-3">
                        <btn-text config-package="cancel"
                                  @click="cancelCheckout()"
                                  :config-prop="{color: 'grey', icon: ''}">
                        </btn-text>
                        <v-divider></v-divider>
                        <btn-text config-package="ok"
                                  @click="openCheckout()">
                        </btn-text>
                    </v-card-actions>
                </alert-message>
            </div>
            <!--<pre>{{message}}</pre>
            <p>CHECKOUT SESSION</p>
            <pre>{{checkoutSession}}</pre>
            <p>CHECKOUT SESSION LISTENER</p>
            <pre>{{checkoutSessionListener.data}}</pre>-->
        </div>
    <!-- Block Window while loading -->
        <v-dialog v-model="loader" width="300" persistent>
            <v-card class="text-left pa-5">
                <p>{{$t(content.cancelPaymentDialog.i18n)}}</p>
                <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {locales} from '@/modules/stripe/plugins/i18n/locales';
    import BtnText from "@/utilities/components/controls/btnText";
    import {StripeCheckout} from "@/modules/stripe/models/data/stripeCheckout";
    import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import {StripeRefresh} from "@/modules/stripe/models/data/stripeRefresh";
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
    import {StripeCheckoutExpire} from "../models/data/stripeCheckoutExpire";
    import {RealtimeListener} from "@/utilities/services/firebase/firebaseRealtime";

    export default {
        components: {
            AlertMessage,
            BtnText},
        name: "stripe-checkout",
        i18n: locales,
        props: ['config', 'stripeData', 'disabled', 'purchaseOrder'],
        data: () => ({
            checkoutSession: null,
            checkoutSessionListener: new RealtimeListener({
                fireBaseCollection: 'stripe_checkouts',
            }),
            checkoutSessionExpireListener: null,
            loader: false,
            verifiedIfNewRequestIsPermitted: false,
            successReported: false, // stripe has reported successful payment (unconfirmed from server)
            stripeCheckoutFailed: false,
            configs: {
                failedMessage: {
                    show: false,
                }
            }
            //paymentCompleted: false,
        }),
        computed: {
            content(){ return this.config.content.stripe.stripeCheckout},
            contentBtn(){
                let btn = JSON.parse(JSON.stringify(this.content.btn ));
                delete btn.default.i18n;
                return btn;
            },
            disabledByModule(){ return this.content.disabled ||
                this.checkoutSessionListener.data !== null;
            },
            isSuccess(){ return this.$route.query.success === 'true'; },
            message(){
                if ( this.checkoutSessionListener.data &&
                     this.checkoutSessionListener.data.stripe.status ) {
                    let message = {default: {}};
                    if ( this.checkoutSessionListener.data.stripe.status === 'open' &&
                        !this.successReported) {
                        message = this.content.inProgressMessage;
                        message.default.show = true;
                    } else if ( (this.checkoutSessionListener.data.stripe.status === 'complete' && // noch immer notwendig???
                                this.checkoutSessionListener.data.stripe.payment_status === 'paid') ||
                                this.successReported) {
                        message = this.content.paymentCompletedMessage;
                        message.default.show = true;
                    } else {
                        message.default.show = false;
                    }
                    return message;
                } else if (this.checkoutSession &&
                           this.checkoutSession.local.inProgress &&
                          !this.successReported) {
                    let message = this.content.processingPayment;
                    message.default.show = true;
                    return message;
                } else {
                    return {}
                }
            },
            paidMessage(){
                return {
                    default: {
                        show: true
                    },
                }
            },
            isPaid(){
                return this.checkoutSessionListener.data ?
                    this.checkoutSessionListener.data.stripe.status === 'complete' &&
                    this.checkoutSessionListener.data.stripe.payment_status === 'paid' :
                    false;
            }
        },
        created(){
            this.unsubscribeAll();
            this.checkPaymentStatus();
        },
        destroyed(){
            this.unsubscribeAll();
        },
        methods: {
            async checkPaymentStatus(){
                this.paymentCompleted = false;
                const checkoutDoc = await FirebaseRead.readSingleDoc('stripe_checkouts', this.purchaseOrder.id);
                if ( !checkoutDoc ) { // other case is handled in the watcher
                    this.verifiedIfNewRequestIsPermitted = true;
                } else {
                    this.$emit('paymentStarted');
                    this.checkoutSessionListener.setListener({docId: this.purchaseOrder.id});
                    this.verifiedIfNewRequestIsPermitted = true;
                }
            },
            unsubscribeAll(){
                this.checkoutSessionListener.unsubscribe();
                if ( this.checkoutSessionExpireListener ) {
                    this.checkoutSessionExpireListener.unsubscribe();
                }
            },
            async createCheckoutSession(){
                this.configs.failedMessage.show = false;
                this.$emit('paymentStarted');
                this.checkoutSession = new StripeCheckout({
                    purchaseOrderId: this.purchaseOrder.id,
                    uid: this.stripeData.uid,
                    stripeAccountId: this.stripeData.stripeAccountId,
                    items: this.purchaseOrder.items,
                    vouchers: this.purchaseOrder.vouchers,
                    ts: TimeStamp.getGMTTimestamp(),
                });
                this.checkoutSession.local.inProgress = true;
                let reply = await this.checkoutSession.saveNewWithDocId(this.purchaseOrder.id);
                if ( reply.successful ) {
                    this.checkoutSessionListener.setListener({docId: this.purchaseOrder.id});
                }
            },
            openCheckout(){
                window.location.replace(this.checkoutSessionListener.data.stripe.url);
                //window.open(this.checkoutSessionListener.data.stripe.url, '_blank')
            },
            async cancelCheckout(){
                this.expireCheckout = new StripeCheckoutExpire({
                    status: 'requested',
                    purchaseOrderId: this.purchaseOrder.id,
                    uid: this.stripeData.uid,
                    ts: TimeStamp.getGMTTimestamp(),
                    autoExpired: false,
                });
                let reply = await this.expireCheckout.saveNewWithDocId(this.purchaseOrder.id);
                if ( reply.successful ) {
                    this.checkoutSessionExpireListener = new RealtimeListener({
                        fireBaseCollection: 'stripe_checkouts_expire',
                    });
                    this.checkoutSessionExpireListener.setListener(
                        {docId: this.purchaseOrder.id}
                    );
                    this.loader = true;
                    console.log ("EXPIRE REQUESTED");
                }
            },
            refreshStripeCheckout(successReported){
                // send refresh request
                if ( this.checkoutSessionListener.data.stripe.status === 'open' ) {
                    this.sendStripeRefreshRequest(successReported);
                    //console.log ("successReported: ", successReported);
                }
            // reset success
                let p = JSON.parse(JSON.stringify(this.$route.path));
                let q = JSON.parse(JSON.stringify(this.$route.query));
                q.success = false;
                this.$router.push({path: p, query: q});
            // set successReported Flag in purchase order

            },
            async sendStripeRefreshRequest(successReported){
                const stripeRefresh = new StripeRefresh({
                    uid: this.stripeData.uid,
                    collection: 'stripe_checkouts',
                    documentId: this.purchaseOrder.id,
                    stripeId: this.checkoutSessionListener.data.stripe.id,
                    stripeAccountId: this.stripeData.stripeAccountId,
                    successReported: successReported,
                    ts: TimeStamp.getGMTTimestamp(),
                });
                let reply = await stripeRefresh.save();
                console.log ("reply", reply );
            }
        },
        watch: {
            'checkoutSessionListener.data'(to, from){
                if ( this.checkoutSessionListener.data && this.isSuccess ) {
                    this.refreshStripeCheckout(true);
                    this.verifiedIfNewRequestIsPermitted = true;
                    this.successReported = true;
                    this.$emit('isPaid', this.checkoutSessionListener.data.purchaseOrderId);
                } else if (
                    (this.checkoutSessionListener.data &&
                        !this.checkoutSessionListener.data.stripe) ||
                    (this.checkoutSessionListener.data &&
                        this.checkoutSessionListener.data.stripe &&
                        this.checkoutSessionListener.data.stripe.status === 'complete' &&
                        this.checkoutSessionListener.data.stripe.payment_status === 'paid' )
                ) {
                    this.verifiedIfNewRequestIsPermitted = true;
                } else if ( !to && from) {
                    // stripe checkout deleted
                    this.checkoutSession = null;
                    this.checkoutSessionListener = new RealtimeListener({
                        fireBaseCollection: 'stripe_checkouts',
                    })
                    this.configs.failedMessage.show = true;
                    this.$emit('checkoutCreationFailed');
                }
                // noch notwendig ??????
                /*if ( this.checkoutSessionListener.data.stripe.status === 'complete' &&
                    this.checkoutSessionListener.data.stripe.payment_status === 'paid' ) {
                    console.log ("!!!!!!! STRIPE WATCH checkoutSessionListener C", TimeStamp.getGMTTimestamp());
                    this.$emit('isPaid', this.checkoutSessionListener.data.purchaseOrderId);
                }*/
            },
            'checkoutSessionExpireListener.data'(){
                console.log ("checkoutSessionExpireListener 1", );
                if ( this.checkoutSessionExpireListener.data ) {
                    console.log ("checkoutSessionExpireListener 2", );
                    if ( this.checkoutSessionExpireListener.data.status !== 'requested' ) {
                        console.log ("checkoutSessionExpireListener 3", );
                        this.loader = false;
                        this.$emit('isCancelled');
                        //setTimeout(()=>this.checkoutSessionExpireListener.unsubscribe(),100)
                        this.checkoutSessionExpireListener.unsubscribe()
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>