<template>
    <div v-if="shopEvent" >
        <shop-event-vouchers class="pa-2" :shop-event="shopEvent"/>
        <user-consents-list v-if="userConsents" :missing-only="true"
                            :required-consents="shopEvent.data.requiredConsents"
                            :consent-status="consentStatus"
                            :organisation-id="shopEvent.data.sellingOrganisationId"
                            :query-parameter="shopEvent.data.id"
                            :block-shopping-cart-refresh="true"
                            @consentRefreshed="setConsentStatus"/>
        <required-information-list :show-shop-header="true" class="px-4" :organisations="organisations"
                                   :required-information-data="shopEvent.data.requiredInformation"
                                   :query-parameter="shopEvent.data.id"
                                   @refresh="setRequiredInformationStatus"/>
        <shop-event-vouchers-aviso :shop-event="shopEvent"/>
        <shop-event-required-licenses-existing
                v-if="shopEvent.isPassRequiredAndMissing()"
                :shop-event="shopEvent">
        </shop-event-required-licenses-existing>
        <div v-else>
            <alert-message :config-prop="{show: shopEvent.data.info!=='' && shopEvent.data.info!==undefined}"
                           class="mx-4 mt-4 mb-0">
                <div class="body-2">
                    {{shopEvent.data.info}}
                </div>
            </alert-message>
            <div v-for="(item, i) in shopEventItemList" :key="i" >
                <shop-item v-if="item.display==='standard'"
                            :license-id="item.id" :shop-event="shopEvent"
                            :license-settings="item" :consent-status="consentStatus"
                            :required-information-status="requiredInformationStatus"
                            :organisation-is-active="organisationIsActive">
                </shop-item>
                <shop-item-select v-else-if="item.display==='select'"
                            :select-field-id="item.id" :shop-event="shopEvent"
                            :field-settings="item" :consent-status="consentStatus"
                            :required-information-status="requiredInformationStatus"
                            :organisation-is-active="organisationIsActive">
                </shop-item-select>
            </div>
<!--
            <pre>VOUCHER CHECK: {{$store.state.shoppingCart.eventsWithValidVouchers}}</pre>
            <pre>EVENTS: {{$store.state.shoppingCart.events}}</pre>
            -->
            <!--<div><b>Zustimmungen: </b>
                <div>liste mit Links zu PDFs. Bedingungen müssen akzeptiert werden.
                    Licenses hinterlegt wie bei WM Kauf.
                    Geparkt, wird nach Vorarlberg hinzugefügt.
                    ('requiredLicenses.shopable' in event)
                </div>
            </div>-->
            <!--<div><b>Kaufsperre: </b>
                <div>Text mehrere Events: du kannst dieses Event nicht gemeinsam mit anderen Events kaufen.
                    Bitte schließe zuerst deinen Einkauf ab!</div>
                <div>Text mehrere Personen: du kannst dieses Event nicht für mehrere Personen gleichzeitig kaufen.
                    Bitte schließe deinen Einkauf zuerst ab!</div>
                <div class="mt-2">Wird vollautomatisch nur dann angezeigt wenn bereits ein anderes
                    Event oder eine andere Person im Shopping Cart ist UND
                    zur gewählten Person und diesem Event ein gültiger und einlösbarer Gutschein existiert.
                </div>
            </div>-->
        </div>
        <!--
        <pre>
            {{shopEvent}}
        </pre>
        -->
    </div>
</template>

<script>
    import ShopEventRequiredLicensesExisting from "./shopEventContent/shopEventRequiredLicensesExisting";
    import ShopItem from "@/components/shop/shopItem/special/shopItem";
    import ShopItemSelect from "@/components/shop/shopItem/special/shopItemSelect";
    import UserConsentsList from "@/components/userConsents/userConsentsList";
    import RequiredInformationList from "@/components/requiredInformation/requiredInformationList";
    import ShopEventVouchersAviso from "@/components/shop/shopEvent/shopEventContent/shopEventVouchersAviso";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import ShopEventVouchers from "@/components/shop/shopEvent/shopEventContent/shopEventVouchers";

    export default {
        components: {
            ShopEventVouchers,
            AlertMessage,
            ShopEventVouchersAviso,
            RequiredInformationList,
            UserConsentsList,
            ShopItem,
            ShopItemSelect,
            ShopEventRequiredLicensesExisting},
        name: "shop-event-content",
        props: ['shopEvent', 'shopEventItemList', 'organisationIsActive'],
        computed: {
            userLicenses(){ return this.$store.state.realtime.userLicenses; },
            userConsents(){ return this.$store.state.userConsents},
            organisations(){ return this.$store.state.organisations },
            //consentStatus(){ return this.userConsents?.local.consentStatus; }
        },
        data: () => ({
            consentStatus: false,
            requiredInformationStatus: false,
        }),
        methods: {
            setConsentStatus(consentStatus){
                this.consentStatus = consentStatus;
            },
            setRequiredInformationStatus(requiredInformationStatus){
                this.requiredInformationStatus = requiredInformationStatus;
            }
        },
        watch: {
            userLicenses(){
                this.init();
            }
        }
    }
</script>

<style scoped>

</style>