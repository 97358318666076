<template>
    <div v-if="displayedMessage">
        <div class="white mt-2 rounded body-2">
        <!-- header -->
            <v-row no-gutters class="colored rounded-tl rounded-tr white--text pt-3 pb-2 px-2">
                <v-col class="white--text grow font-weight-medium font16" >
                    {{displayedMessage.logData.msgHeader[$i18n.locale]}}
                </v-col>
                <v-col class="shrink text-no-wrap">{{getTimeSince(archive)}}</v-col>
            </v-row>
        <!-- event and receivers -->
            <div v-if="showChanges">
                <div class="px-2 caption font-weight-medium mt-2 msgColor">{{$t('messages.changed')}}</div>
                <div class="px-2 font16">{{displayedMessage.logData.doc.name}}</div>
            </div>
            <div class="px-2 caption font-weight-medium mt-2 msgColor">
                <span v-if="displayedMessage.receiver.affectedUserIds.length>1">{{$t('messages.recipients')}}</span>
                <span v-else>{{$t('messages.recipient')}}</span>
            </div>
            <div class="px-2 font16">{{displayedMessage.receiver.affectedUserIds.map(user => user.name).join(', ')}}</div>
            <v-divider class="mx-2 my-2" light/>
            <!--
            <div v-if="displayedMessage.logData.logType!=='ad'">
                <v-row no-gutters class="my-2 mb-0 px-2" align="center">
                    <v-col class="shrink pr-1"><v-icon color="secondary" small>mdi-calendar</v-icon></v-col>
                    <v-col class="grow">{{displayedMessage.logData.doc.name}}</v-col>
                </v-row>
                <v-row v-if="displayedMessage.receiver.affectedUserIds.length>0" no-gutters class="px-2">
                    <v-col class="shrink pr-1"><v-icon color="secondary" small>mdi-account-multiple</v-icon></v-col>
                    <v-col class="grow">
                        {{displayedMessage.receiver.affectedUserIds.map(user => user.name).join(', ')}}
                    </v-col>
                </v-row>
                <v-divider class="mx-2 my-2" light/>
            </div>
            <div v-else class="mt-2"></div>-->
        <!-- changes -->
            <div v-if="showChanges" class="px-2 caption font-weight-medium mt-2 msgColor">
                <span v-if="displayedMessage.logData.changes.length>1">{{$t('messages.changes')}}</span>
                <span v-else>{{$t('messages.changes')}}</span>
            </div>
            <div v-for="(change, i) in displayedMessage.logData.changes" :key="i" class="px-2">
                <v-row v-if="change.dataType!=='manual'" no-gutters>
                    <v-col cols="4" class="pr-3">{{change.label[$i18n.locale]}}:</v-col>
                    <v-col cols="8">{{getData(change.new)}}
                        <div v-if="showAll" class="grey--text">({{$t('messages.insteadOf')}} '{{getData(change.old)}}')</div>
                    </v-col>
                </v-row>
                <div v-else>
                    <div class="caption font-weight-medium mt-2 msgColor">{{$t('messages.subject')}}</div>
                    <div class="font-weight-medium font16">{{change.label[$i18n.locale]}}</div>
                    <div class="caption font-weight-medium mt-2 msgColor">{{$t('messages.message')}}</div>
                    <messages-manual-text :change="change"/>
                </div>
            </div>
            <!--
            <div v-for="(change, i) in displayedMessage.logData.changes" :key="i" class="px-2 pb-2">
                <v-row v-if="change.dataType!=='manual'" no-gutters>
                    <v-col cols="4" class="pr-3">{{change.label[$i18n.locale]}}:</v-col>
                    <v-col cols="8">{{getData(change.new)}}
                        <div v-if="showAll" class="grey--text">({{$t('messages.insteadOf')}} '{{getData(change.old)}}')</div>
                    </v-col>
                </v-row>
                <div v-else>
                    <div class="font-weight-bold ">{{change.label[$i18n.locale]}}</div>
                    <div style="white-space: break-spaces;">
                        <span v-if="displayedMessage.logData.logType!=='ad'">{{getData(change.new)}}</span>
                        <span v-else-if="$i18n.locale==='de'">{{getData(change.new)}}</span>
                        <span v-else>{{getData(change.old)}}</span>
                    </div>
                </div>
            </div>-->
        <!-- ts details -->
            <div class="px-2 grey--text" v-if="showAll">
                <v-divider class="mb-2" light/>
                <v-row no-gutters>
                    <v-col cols="4">{{$t('messages.receivedShort')}}:</v-col>
                    <v-col cols="8">{{getFormattedTs(displayedMessage.logData.changedAt)}}</v-col>
                </v-row>
                <v-row no-gutters v-if="displayedMessage.receiver.readTs>0 && displayedMessage.receiver.readTs!==null">
                    <v-col cols="4">{{$t('messages.read')}}:</v-col>
                    <v-col cols="8">{{getFormattedTs(displayedMessage.receiver.readTs)}}</v-col>
                </v-row>
            </div>
        <!-- more button -->
            <div class="text-center">
                <v-icon color="secondary lighten-2" @click="showAll=!showAll" v-if="!showAll">mdi-dots-horizontal</v-icon>
                <v-icon color="secondary lighten-2" @click="showAll=!showAll" v-if="showAll">mdi-chevron-up</v-icon>
            </div>
        </div>
        <div class="text-right caption mt-1" v-if="archive">
            {{$t('messages.received')}} {{getTimeSince()}} {{$t('messages.received2')}}
        </div>
    </div>
</template>

<script>
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
import MessagesManualText from "@/components/messages/messagesManualText";

export default {
    name: "messages-msg-short",
    components: {MessagesManualText},
    props: ['displayedMessage', 'total', 'archive'],
    data: () => ({
        showAll: false
    }),
    computed: {
        showChanges(){
            return this.displayedMessage.logData.logType!=='ad' &&
                this.displayedMessage.logData.changes[0].dataType!=='manual'
        }
    },
    methods: {
        getTimeSince(read){
            return read ? TimeStamp.getTimeSince(this.displayedMessage?.receiver?.readTs) :
                TimeStamp.getTimeSince(this.displayedMessage?.logData?.changedAt)
        },
        getFormattedTs(ts){
            return TimeStamp.getLocalStringFromGMTts(ts)
        },
        getData(value){
            return Array.isArray(value) ? value.join(', ') : value
        }
    },
}
</script>

<style scoped>
    .colored{background-color: #048ba8}
</style>