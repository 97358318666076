<template>
    <div class="containerRel mx-auto" :style="{ width: configEl.size + 'px' }">
        <v-avatar :color="configEl.avatar.color" :size="configEl.size"
                  class="ma-0">
            <v-icon v-if="!imageUrl" large
                    :class="[{'zoom': configEl.size>=48}]">
                mdi-account
            </v-icon>
            <img v-else :src="imageUrl">
        </v-avatar>
        <v-btn @click="openImageEdit()"
               fab dark small
               color="primary"
               class="btnBadge">
            <v-icon dark>{{!imageUrl ? 'mdi-plus' : 'mdi-pencil'}}</v-icon>
        </v-btn>
        <profile-image-edit :user="user" style="z-index: 5"
                            :storage-source-path="storageSourcePath"
                            :storage-folder="storageFolder"
                            :config-prop="configEl.profileImageEdit"
                            :module-config="moduleConfig">
        </profile-image-edit>
    </div>
</template>

<script>
    // Version 1.0 2022-01-18
    import RouterServices from '@/utilities/services/router/routerServices';
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import ProfileImageEdit from "@/utilities/components/images/profileImageEdit";

    export default {
        components: {ProfileImageEdit},
        name: "profile-image",
        props: {
            user: {default: null},
            storageSourcePath: {default: ''},
            storageFolder: {default: ''},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.images.profileImage',
        // define here, which parameter should be customizeable
            configDefault: {
                size: 120,
                avatar: {
                    color: '#f2f2f2',
                },
                profileImageEdit: {
                    deleteBtn: {
                        color: '#B0BEC5'
                    }
                }
            },
        }),
        computed: {
            imageUrl() { return this.user.image.urlMin ?
                        this.storageSourcePath +
                        this.user.uid + 'Min.jpeg?' +
                        this.user.image.urlMin : ''},
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            openImageEdit(){
                RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                    'profileImage', 'edit');
            }
        },
    }
</script>

<style scoped>
    .containerRel { position: relative; }
    .btnBadge { position: absolute; bottom: 0; right: 0; z-index: 1; }
</style>