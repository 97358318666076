export const validityTypes = {
    UNLIMITED:'UNLIMITED',
    TIME_FRAME:'TIME_FRAME',
    DURATION: 'DURATION',
    QUANTITY: 'QUANTITY'
}

export const validityQuantityValues = [10,20,30,40,50,60,70]
export const validityTimeDurationValues = [1,2,3,4,5,6,7,8,9,10]
export const validityTimeDescriptionValues = {
    // HOURS:'HOURS',
    DAYS:'DAYS',
    WEEKS:'WEEKS',
    MONTH:'MONTH',
    YEARS:'YEARS'
}