<template>
    <div v-if="voucherList.length > 0">
        <div class="rounded-lg pb-3 body-2 voucherBox bgdGreyLight">
            <div v-if="voucherList.length===1" class="px-2 pt-3">
                <v-row no-gutters align="center">
                    <v-col class="shrink">
                        <v-icon class="mr-2">mdi-percent</v-icon>
                    </v-col>
                    <v-col class="grow">
                        {{$t('shop.includesVoucher')}}
                    </v-col>
                </v-row>
                <img v-if="getOrganisation(voucherList[0].organisationId) && getOrganisation(voucherList[0].organisationId).short"
                     class="voucherLogoShop mt-1" height="40px" :alt="getOrganisation(voucherList[0].organisationId).short"
                     :src="getOrganisation(voucherList[0].organisationId).logoDark">
                <shop-item-vouchers-card class="mt-2" :voucher="voucherList[0]"/>
            </div>
            <div v-else class="px-2 pt-3">
                <v-row no-gutters align="center" class="mb-1">
                    <v-col class="shrink">
                        <v-icon class="mr-2" color="secondary">mdi-percent</v-icon>
                    </v-col>
                    <v-col class="grow font-weight-bold secondary--text">
                        {{$t('shopVoucher.advantagesEvent')}}
                    </v-col>
                </v-row>
                <ul>
                    <li v-for="(advantage, j) in advantageList" :key="j">
                        {{$t(advantage.i18n, advantage)}}
                    </li>
                </ul>
                <div v-if="vouchersDisplayed">
                    <shop-item-vouchers-list :voucher-list="voucherList"
                                             :organisations="organisations"/>
                </div>
                <btn-text @click="vouchersDisplayed=!vouchersDisplayed" class="mt-5"
                        :config-prop="{block: true, icon: btnSettings.icon,
                                        color: 'secondary'}" >
                    {{$t(btnSettings.text)}}
                </btn-text>
                <v-row v-if="vouchersDisplayed" no-gutters
                       class="mt-3 pl-1 caption grey--text text--darken-1 font-weight-medium">
                    <v-col class="shrink pr-1 lineHeight-1-0">*</v-col>
                    <v-col class="grow lineHeight-1-0">{{$t('adminVoucher.clickForTerms')}}</v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import ShopItemVouchersCard from "@/components/shop/shopItem/shopItemVouchersCard";
import VoucherListService from "@/services/shop/voucherListService";
import BtnText from "@/utilities/components/controls/btnText";
import {Voucher} from "@/models/voucher";
import ShopItemVouchersList from "@/components/shop/shopItem/shopItemVouchersList";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export default {
    // V2023
    name: "shop-event-vouchers",
    components: {ShopItemVouchersList, BtnText, ShopItemVouchersCard},
    props: ['shopEvent'],
    computed: {
        organisations(){ return this.$store.state.organisations??[] },
        btnSettings(){ return this.vouchersDisplayed ? {icon: 'mdi-chevron-up', text: 'shopVoucher.hideVouchers'} :
                                                       {icon: 'mdi-chevron-down', text: 'shopVoucher.showVouchers'}}
    },
    data: () => ({
        vouchersDisplayed: false,
        voucherList: [],
        advantageList: [],
    }),
    created(){
        this.getVoucherList();
    },
    methods: {
        async getVoucherList(){
            if ( !this.$store.state.realtime.userVouchers ||
                 !this.shopEvent.data.vouchersIssue ||
                 Object.keys(this.shopEvent.data.vouchersIssue).length === 0 ) return
            const listOfVoucherIdsAlreadyOwnedByUser = Object.keys(this.$store.state.realtime.userVouchers.vouchers)
            const listOfVoucherIdsToBeIssuedWithEvent = Object.keys(this.shopEvent.data.vouchersIssue)
            const listOfVoucherIdsToBeIssuedWithEventNotOwnedByUser = listOfVoucherIdsToBeIssuedWithEvent.filter(
                voucherId => !listOfVoucherIdsAlreadyOwnedByUser.includes(voucherId)
            )
            this.voucherList = await FirebaseRead.getDocListByIdArray('vouchers',
                listOfVoucherIdsToBeIssuedWithEventNotOwnedByUser
            )
            const todayString = TimeStamp.getTodaysDateString()
            this.voucherList = this.voucherList.filter(voucher => voucher.issueUntil >= todayString &&
                                                                  voucher.issueFrom <= todayString &&
                                                                  voucher.validUntil >= todayString)
                                               .map(voucher => new Voucher(voucher, voucher.organisationId,
                                                        this.organisations[voucher.organisationId].styles))
                                               .sort((a, b) => a.organisationId + a.validUntil >
                                                                        b.organisationId + b.validUntil ? 1 : -1);
            this.advantageList = VoucherListService.getAdvantageList(this.voucherList)
        },
        getOrganisation(orgId){ return this.organisations?.[orgId]},
    },
}
</script>

<style scoped>
    .voucherBox {border-left: solid 8px #bbb; }
    .voucherLogoShop { position: relative; left: 4px; top: 2px}
</style>