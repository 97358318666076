<template>
    <div class="imgPreview">
        <cropper class="cropper" :src="img" :stencil-props="stencilProps"
                 stencilComponent="circle-stencil"
                  @ready="ready" @change="change" ref="cropper"
                 backgroundClass="white" foregroundClass="white">
            <!--stencilComponent="circle-stencil"-->
        </cropper>
    </div>
</template>

<script>
    // Version 1.0 2022-01-03
    import {Cropper} from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: "imageCropper",
        props: ['img', 'curr'],
        components: { Cropper },
        data: () => ({
            newImg: null,
            loading: true,
            stencilProps: {aspectRatio: 1},// {aspectRatio: 3/4},
        }),
        methods: {
            change({coordinates, canvas}) {
                    if( !canvas ||  canvas.width === 0 && canvas.height === 0) { return; }
                // neues Canvas für Verkleinerung auf 200 x 200px bauen
                    let resizedCanvas = document.createElement("canvas");
                    let resizedContext = resizedCanvas.getContext("2d");
                    resizedCanvas.height = "200";
                    resizedCanvas.width = "200";
                // Ausgeben und Verkleinern
                    resizedContext.drawImage(canvas, 0, 0, 200, 200);
                    this.newImg = resizedCanvas.toDataURL("image/jpeg", 1.0);
                // aktuellen Ausschnitt wählen
                    if ( this.loading && this.curr ) {
                        this.loading = false;
                        this.resize (this.curr);
                    } else {
                // Zurückgeben
                        this.$emit("getCrop", this.newImg, coordinates);
                    }
            },
            ready(){
                this.$emit("ready");
            },
            resize(coords) {
                this.$refs.cropper.setCoordinates({
                    width: coords.width,
                    height: coords.height,
                    left: coords.left,
                    top: coords.top
                })
            },
        },
    }
</script>

<style scoped>
    .imgPreview { max-height: 100%; max-width: 100% } /**/
    .cropper {
        height: 280px;
    }
</style>