<template>
    <div :class="configEl.container.css"
         :style="configEl.container.style">
        <v-badge v-if="!badgeContent" color="error"
                overlap
                bottom
                :value="badgeValue>0"
                :content="badgeValue">
            <v-btn @click="action()"
                   :color="configEl.color"
                   :disabled="disabled"
                   fab
                   large
                   dark>
                <v-icon>{{configEl.icon}}</v-icon>
            </v-btn>
        </v-badge>
        <v-badge v-else color="error"
                overlap
                bottom
                :content="badgeContent">
            <v-btn @click="action()"
                   :color="configEl.color"
                   :disabled="disabled"
                   fab
                   large
                   dark>
                <v-icon>{{configEl.icon}}</v-icon>
            </v-btn>
        </v-badge>
    </div>
</template>

<script>
    // Version 1.2 2022-02-28

    import ComponentConfigService from '@/utilities/services/components/componentConfigService';

    export default {
        name: "fab-btn",
        props: {
            disabled: {default: false},
            badgeValue: {default: null},
            badgeContent: {default: null},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.fabBtn',
        // define here, which parameter should be customizeable
            configDefault: {
                container: {
                    css: 'fabBtnBottomRight',
                    style: 'right: 15px',
                },
                color: 'primary',
                icon: 'mdi-arrow-left',
            },
        }),
        computed: {
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            action(){
                this.$emit('clicked');
            },

        },
    }
</script>

<style scoped>
    .fabBtnBottomRight{ position: fixed; bottom: 15px; z-index: 3 }
</style>