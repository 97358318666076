// Version 1.1 2021-12-15

import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'

export class ServiceMother {

    constructor() {}

    setParameter(parameter, value){
        this[parameter] = value;
    }

    setFromDataObject(dataObject){
        ObjectHandlingService.setFromDataObjectByKey(this, dataObject)
    }
}