
export const conditionTypes = {
    USER: 'USER',
    DATE: 'DATE',
    LICENSE:'LICENSE'
}

export const conditionsUser = {
    AGE:'AGE',
    SEX:'SEX',
    LICENSE:'LICENSE',
    COUNTRY:'COUNTRY',
    USER_PASS:'USER_PASS'
}


export const conditionComparator = {
    GREATER: '>',
    GREATER_OR_EQUAL: '>=',
    LESS: '<',
    LESS_OR_EQUAL: '<=',
    EQUAL: '=',
    NOT:'!',
    NOT_DEFINED:'not_defined',
}
export const conditionNotDefined = {
    NOT_DEFINED: 'not_defined'
}
