
import {Mother} from "@/utilities/models/mother";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import {i18n} from "@/plugins/i18n";
import store from "@/store/store";
//import {i18n} from '@/plugins/i18n';

export class UserConsents extends Mother {
    constructor(dataObject) {
        super();
        this.consents = {};
    // database collection
        this.local.fireBaseCollection = 'user_consents';
        this.local.storageFileName = '';
        this.local.termsDocsForConsent = {};
    // set data
        if (dataObject) {
            this.setFromDataObject(dataObject)
            this.consents = dataObject.consents;
        }
    }

// GETTERS
    getConsentList(missingOnly, requiredConsentsDocIdObj, organisationId, queryParameter){
        if ( requiredConsentsDocIdObj === undefined || Object.keys(requiredConsentsDocIdObj).length === 0 &&
            Object.keys(this.local.termsDocsForConsent ).length === 0) return [];
        let consentList = [];
        this.local.consentStatus = true;
        consentList = this.pushToConsentList(requiredConsentsDocIdObj, missingOnly, consentList, queryParameter)

        if ( organisationId ) {
            consentList = this.pushToConsentList(this.local.termsDocsForConsent, missingOnly, consentList, queryParameter, organisationId)
        }
        return consentList
    }
        pushToConsentList(requiredConsentsDocIdObj, missingOnly, consentList, queryParameter, organisationId){
            Object.keys(requiredConsentsDocIdObj).forEach( (requiredConsentDocId) => {
                const consentDoc = this.local.termsDocsForConsent[requiredConsentDocId];
                const consentDocShallBeDisplayed = this.consentDocShallBeDisplayed(consentDoc, missingOnly, requiredConsentDocId)
                if ( (consentDocShallBeDisplayed && !organisationId) ||
                     (consentDocShallBeDisplayed && organisationId &&
                      consentDoc.organisationId === organisationId &&
                      consentDoc.mandatoryForAllEvents ) ) {
                    consentList.push(this.getConsentDocConfig(consentDoc, queryParameter))
                }
            });
            return consentList
        }
            consentDocShallBeDisplayed(consentDoc, missingOnly, requiredConsentDocId){
                return consentDoc && consentDoc.filePath && (!missingOnly ||
                    missingOnly && this.consents[requiredConsentDocId] < consentDoc.validFrom)
            }
        getConsentDocConfig(consentDoc, queryParameter){
            return {
                id: consentDoc.id,
                field: {
                    fieldName: consentDoc.id,
                    path: 'consents',
                    cols: {xs: 12, sm: 12},
                    type: 'confirmCheck',
                    i18n: consentDoc.languages[i18n.locale] ? consentDoc[i18n.locale].name :
                        consentDoc[Object.keys(consentDoc.languages)[0]].name,
                    rules: [],
                    link: consentDoc.filePath,
                    queryParameter: queryParameter,
                },
                targetValue: consentDoc.validFrom,
                value: this.consents[consentDoc.id],
                organisationId: consentDoc.organisationId,
            }
        }

// ACTIONS
    setConsents(consents){
        this.consents = null
        setTimeout(()=>{this.consents = consents}, 100)
    }

    async verifyConsent(consentDocIdObj, organisationId){
    // get all default terms docs for an organisation
        if ( organisationId ) {
            const docList = await FirebaseRead.readDocListWithTwoWhere('termsDocuments',
                {field: 'organisationId', operator: '==', value: organisationId},
                {field: 'mandatoryForAllEvents', operator: '==', value: true})
            this.saveInTermsDocsForConsent(docList)
        }
    // check if needed
        if ( (consentDocIdObj === undefined || Object.keys(consentDocIdObj).length === 0) &&
              Object.keys(this.local.termsDocsForConsent ).length === 0) return
    // get latest termsDocuments
        const promiseList = this.getPromiseList(consentDocIdObj, 'termsDocuments')
        const termsDocuments = await this.loadTermsDocsForConsent(
            promiseList
        )
        if ( promiseList.length === 0 && Object.keys(this.local.termsDocsForConsent ).length === 0 ) { return }
        this.saveInTermsDocsForConsent(termsDocuments)
    }
        saveInTermsDocsForConsent(termsDocuments){
        // save local in termsDocsForConsent
            termsDocuments.forEach( (termsDocument) => {
                if ( termsDocument ) {
                // set termsDocument in local
                    const termsDocsForConsent = JSON.parse(JSON.stringify( this.local.termsDocsForConsent ))
                    termsDocsForConsent[termsDocument.id] = termsDocument;
                    this.local.termsDocsForConsent = termsDocsForConsent;
                // update consent doc in user consents
                    if ( !this.consents[termsDocument.id] ) this.consents[termsDocument.id] = 0
                }
            });
        }
        async loadTermsDocsForConsent(promiseList){
            return new Promise( (resolve) => {
                Promise.all(promiseList).then( (values) => {
                    resolve(values);
                });
            })
        }
        getPromiseList(keyList, collection){
            let promiseList = [];
            Object.keys(keyList).forEach( (key) => {
                if ( !this.local.termsDocsForConsent[key] ) {
                    promiseList.push(FirebaseRead.readSingleDoc(collection, key))
                }
            });
            return promiseList;
        }

    async checkAndSetEventInStore(eventId){
        if ( !store.state.shopEvents ) { store.state.shopEvents = {} }
        if ( !store.state.shopEvents[eventId] ) {
            if (!store.state.shopEvents ) { store.state.shopEvents = {} }
            store.state.shopEvents[eventId] = await FirebaseRead.readSingleDoc(
                'events', eventId
            )
        }
    }


}