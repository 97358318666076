<template>
    <div>
        <div style="max-width: 600px" class="mx-auto">
            <alert-message :config-prop="{show: !organisationIsActive, css: 'body-2 mt-3 font-weight-medium'}"
                           config-package="error">
                {{$t('shop.testMode')}}
            </alert-message>
        </div>
        <v-card v-if="shopEventItemList.length > 0" style="border: solid 1px #e2e2e2"
                class="mt-3 mx-auto" width="600">
            <shop-event-header :shop-event="shopEvent" :organisation="organisation">
            </shop-event-header>
            <alert-message v-if="shopEvent.isFullyPurchasedStatus &&
                                !shopEvent.overrideIsFullyPurchasedStatus" class="body-2 ma-4"
                           :config-prop="configs.purchasedMessage">
                {{$t('shopEvent.allPurchased')}}
                <v-btn small @click="displayDetailsForFullyPurchased()"
                       text light class="text-decoration-underline">
                    {{$t('shopEvent.display')}}</v-btn>
            </alert-message>
            <shop-event-content v-else-if="!blockForShoppingToAvoidMultipleSellers && eventBlocked.text===''"
                                :shop-event="shopEvent" :shop-event-item-list="shopEventItemList"
                                :organisation-is-active="organisationIsActive">
            </shop-event-content>
            <alert-message v-else-if="blockForShoppingToAvoidMultipleSellers"
                           :config-prop="configs.blockedMessage"
                           class="body-2 ma-4">
                {{$t('shopEvent.blockedOtherSeller')}}
            </alert-message>
            <alert-message v-else
                           :config-prop="configs.blockedMessage"
                           class="body-2 ma-4">
                {{$t(eventBlocked.text, eventBlocked.variables)}}
            </alert-message>
            <shop-event-footer :organisation-id="sellingOrganisationId">
            </shop-event-footer>
            <!--
            <pre v-if="userVouchers">userVouchers: {{userVouchers.id}}</pre>
            <pre>eventsWithValidVouchers: {{eventsWithValidVouchers}}</pre>
            <pre>SHOPPING CART: {{shoppingCart}}</pre>-->
        </v-card>
    </div>
</template>

<script>
    import ShopEventHeader from "./shopEventHeader";
    import ShopEventFooter from "./shopEventFooter";
    import ShopEventContent from "./shopEventContent";
    import AlertMessage from "../../../utilities/components/displays/alertMessage";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

    export default {
        components: {
            AlertMessage,
            ShopEventContent,
            ShopEventFooter,
            ShopEventHeader},
        name: "shop-event",
        props: ['shopEvent'],
        computed: {
            organisation(){ return this.$store.state.organisations[this.shopEvent.data.organisationId]; },
            sellingOrganisationId(){ return this.shopEvent.data.sellingOrganisationId; },
            shoppingCart(){ return this.$store.state.shoppingCart; },
            userVouchers(){ return this.$store.state.realtime.userVouchers; },
            eventsWithValidVouchers(){ return this.shoppingCart.eventsWithValidVouchers[this.shopEvent.data.eventId]},
            uid(){ return this.$store.getters.user ? this.$store.getters.user.uid : ''; },
            blockForShoppingDueToVouchersOnOtherSelectedEvent(){
                return this.shoppingCart.blockForShoppingDueToVouchersOnOtherSelectedEvent(
                    this.shopEvent.data.eventId);
            },
            eventBlocked(){
                return {text: ''};
                /* deactivated 11.1.2023
                return this.shopEvent.data &&
                this.eventsWithValidVouchers &&
                this.eventsWithValidVouchers[this.uid] ?
                this.shoppingCart.blockForShoppingDueToVouchers(
                this.shopEvent.data.eventId, this.shopEvent.data) :
                this.blockForShoppingDueToVouchersOnOtherSelectedEvent.text ?
                {text: 'shopEvent.blockedByVoucherEventAlt',
                 variables: {eventNameBlocking: this.$store.state.shopEvents[this.shopEvent.data.eventId].name}} :
                {text: ''}
                 */
            },
            blockForShoppingToAvoidMultipleSellers(){
                return this.shoppingCart.blockForShoppingToAvoidMultipleSellers(
                    this.shopEvent.data.sellingOrganisationId
                );
            },
            shopEventItemList(){
                return this.shopEvent.getSortedItemList(
                    this.$store.getters.user, this.$store.state.realtime.userLicenses) ?? []
            },
            organisationIsActive(){
                return this.organisation.contracts?.base &&
                    TimeStamp.getTodaysDateString() <= this.organisation.contracts?.base &&
                    this.organisation.logo && this.organisation.logoDark &&
                    this.organisation.documents?.privacy
            },
        },
        data: () => ({
            configs: {
                blockedMessage: { show: true,},
                purchasedMessage: { show: true,},
            }
        }),
        methods: {
            displayDetailsForFullyPurchased(){
                this.shopEvent.overrideIsFullyPurchasedStatus = true;
            }
        },
        //created(){
            //this.shopEvent.addToEventsWithValidVouchersInShoppingCart();
            /*if ( this.userVouchers !== null &&
                 this.userVouchers !== undefined &&
                 this.userVouchers.id === this.uid
            ) {
                console.log ("!!!!!!!!!!!!!! CREATED", );

            }*/
        //},
        watch: {
            userVouchers(){
                this.shopEvent.addToEventsWithValidVouchersInShoppingCart();
            },
        }
    }
</script>

<style scoped>

</style>