<template>
    <v-badge :value="showBadge"
             :color="getBadgeColor"
             :icon="getBadgeIcon"
             :content="getBadgeContent"
             overlap bottom>
        <profile-avatar :user="childUser" class="d-inline-block"
                        :storage-source-path="storageSourcePath"
                        :config-prop="{size: size, color: emptyColor,
                                        border: showBorder,
                                        borderColor: '#4CAF50'}">
        </profile-avatar>
    </v-badge>
</template>

<script>
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import {User} from "@/utilities/models/user/user";

    export default {
        components: {ProfileAvatar},
        name: "profile-user-avatar",
        props: {
            childUser: {default: null},
            size: {default: null},
            disableBorder: {default: null},
            emptyColor: {default: 'white'}
        },
        computed: {
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            user(){ return this.$store.getters.user},
            userMaster(){ return this.$store.state.realtime.userMaster },
            userIsMaster(){
                return this.childUser.uid === this.userMaster.uid
            },
            showBorder(){
                return this.childUser.uid === this.user.uid &&
                      !this.disableBorder;
            },
            userIsIncomplete(){
                const userTemp = new User(this.childUser);
                return userTemp.isIncomplete(null, this.$store.state.local.parentConfirmAttemptedToBind, true);
            },
            showBadge(){
                return this.userIsMaster ||
                    this.userIsIncomplete
            },
            getBadgeColor(){
                return this.userIsMaster ? 'primary' :
                    this.userIsIncomplete ? 'error' : '';
            },
            getBadgeIcon() {
                return this.userIsMaster ? 'mdi-crown' : '';
            },
            getBadgeContent() {
                return this.userIsIncomplete ? '!' : '';
            },
        },
    }
</script>

<style scoped>

</style>