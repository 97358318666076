<template>
    <div>
        <home-third-party-data/>
        <home-selection v-if="!userModeSelected">
        </home-selection>
        <v-app-bar v-else-if="userModeSelected"
                   class="hideForPrint"
                   app color="primary"
                   flat dark dense fixed>
            <img src="@/assets/oym_logo-icon_final_white_frei.svg"
                 height="20" width="auto" @click="routeHome()">
            <v-spacer></v-spacer>
            <home-menue-user></home-menue-user>
            <template v-slot:extension>
                <v-tabs v-model="tab" grow>
                    <v-tab v-for="(item, i) in tabs" :key="i" @click="tabClick(i)">
                        <v-badge color="error" :value="tabWarnings[i]"
                                content="!" overlap>
                            <v-icon :class="[{'mr-3': $vuetify.breakpoint.smAndUp}]">
                                {{item.icon}}
                            </v-icon>
                        </v-badge>
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{$t(item.i18n)}}
                        </span>
                    </v-tab>
                </v-tabs>
            </template>
        </v-app-bar>
        <messages-bar :user-id="userId"/>
        <v-tabs-items v-if="userModeSelected" v-model="tab" touchless
                    style="background-color: #ECEFF1;" >
            <!-- style is necessary to avoid white boxes on tab changes, class not working -->
            <v-tab-item>
                <profile></profile>
            </v-tab-item>
            <v-tab-item>
                <shop @changeTab="changeTab"></shop>
            </v-tab-item>
            <v-tab-item>
                <wallet></wallet>
            </v-tab-item>
        </v-tabs-items>
    <!-- fab-btns -->
        <fab-btn v-if="tab===0" @clicked="openProfileEdit()" class="hideForPrint"
                 :config-prop="{icon: 'mdi-pencil'}"
                 :badge-content="user.isIncomplete(this.parentConfirm, this.parentConfirmAttemptedToBind)?'!':null"> <!--mdi-cog-outline-->
        </fab-btn>
    <!-- overlay for profile -->
        <div v-if="tabOverlays[tab]" class="contentOverlay">
            <div style="position: fixed; bottom: 0; left: 0; right: 0" @click="openProfileEdit()">
                <alert-message config-package="error" class="mx-2"
                               :config-prop="alertConfig">
                    <div class="mr-3">{{$t('profile.incomplete')}}</div>
                </alert-message>
            </div>
        </div>
    </div>
</template>

<script>
    //import RouterServices from '@/utilities/services/router/routerServices';
    import Wallet from "./tabs/wallet";
    import Shop from "./tabs/shop";
    import Profile from "./tabs/profile";
    import HomeSelection from "./homeSelection";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import HomeMenueUser from "@/components/home/homeMenueUser";
    import FabBtn from "@/utilities/components/controls/fabBtn";
    import MessagesBar from "@/components/messages/messagesBar";
    import HomeThirdPartyData from "@/views/homeThirdPartyData";

    export default {
        components: {
            HomeThirdPartyData,
            MessagesBar,
            FabBtn,
            HomeMenueUser,
            AlertMessage,
            HomeSelection,
            Profile,
            Shop,
            Wallet},
        name: "home",
        computed: {
            userModeSelected() { return this.$store.state.local.userMode.selected},
            modeHome() { return !this.$store.state.local.userMode.isOrganizer},
            user() { return this.$store.getters.user },
            userId(){ return this.user?.uid.substring(0,28)},
            parentConfirm() {return this.$store.state.realtime.parentConfirm },
            parentConfirmAttemptedToBind(){
                return this.$store.state.local.parentConfirmAttemptedToBind;
            },
            tabWarnings() {
                return {
                    '0': this.user.isIncomplete(this.parentConfirm, this.parentConfirmAttemptedToBind),
                    '1': false,
                    '2': false//this.$store.getters.anyPassStatusIncomplete,
                }
            }          ,
            tabOverlays() {
                return {
                    '0': this.user.isIncomplete(this.parentConfirm, this.parentConfirmAttemptedToBind, true),
                    '1': false,
                    '2': false,
                }
            },
            isMultiUser(){
                return this.user && this.$store.state.realtime.userMaster &&
                       Object.keys(this.$store.state.realtime.userChilds).length > 0
            }
        },
        data: () => ({
            tab: 0,
            tabs: [
                {icon: 'mdi-account',
                    i18n: 'home.profile'},
                {icon: 'mdi-cart',
                    i18n: 'home.shop'},
                {icon: 'mdi-wallet',
                 i18n: 'home.wallet'},
            ],
            alertConfig: {
                show: true,
                text: false,
                css: 'pr-15 mb-2',
            },
            scroll: {
                0: 0,
                1: 0,
                2: 0,
            }
        }),
        created(){
            this.setTabFromRoute();
            this.manageScroll(0,0);
            this.openSports();
        },
        methods: {
            changeTab(tab){
                this.tab = tab;
            },
            setTabFromRoute(){
                if ( this.$route.query.tab ) { this.tab = parseInt(this.$route.query.tab) }
            },
            setRouteFromTab(){
                if ( this.tab != this.$route.query.tab ) {
                    let p = JSON.parse(JSON.stringify(this.$route.path));
                    let q = JSON.parse(JSON.stringify(this.$route.query));
                    q.tab = this.tab;
                    if ( q.shopArea ) { delete q.shopArea }
                    this.$router.replace({path: p, query: q});
                    /*RouterServices.replaceRouteQueryParameter(
                        this.$router, this.$route, 'tab', this.tab
                    );*/
                }
            },
            openProfileEdit(){
                this.$router.push({path: 'profile'});
            },
            routeHome(){
                if ( this.$route.path !== '/' ) {
                    this.$router.push({path: '/'});
                } else if ( this.$route.query.tab !== '0' ){
                    this.tab = 0;
                }
            },
            manageScroll(to, from){
                const currentScroll = window.scrollY;
                this.scroll[from] = currentScroll;
                if ( this.scroll[to] !== currentScroll ) {
                    setTimeout(()=>{
                        window.scrollTo({
                            top: this.scroll[to],
                            left: 0,
                            behaviour: 'smooth',
                        });
                    },50)
                }
            },
            tabClick(i){
                if ( this.tab == 1 && i == 1 && this.$route.query.shopArea ) {
                    let p = JSON.parse(JSON.stringify(this.$route.path));
                    let q = JSON.parse(JSON.stringify(this.$route.query));
                    delete q.shopArea;
                    this.$router.replace({path: p, query: q});
                }
                if ( i == 2 ) { this.minimizeCardInWallet(); }
            },
            minimizeCardInWallet(){
                if ( this.$route.query.card ) {
                    let p = JSON.parse(JSON.stringify(this.$route.path));
                    let q = JSON.parse(JSON.stringify(this.$route.query));
                    delete q.card;
                    this.$router.replace({path: p, query: q});
                }
            },
            openSports(){
                if ( this.user.ts > 1699948800000 && !this.user.sports) {  // Test: 1690277435000
                    setTimeout( this.openSportsRouting, 1000)
                }
            },
            openSportsRouting(){
                this.$router.push('mySports');
            }
        },
        watch: {
            tab(to, from){
                this.setRouteFromTab();
                this.$store.dispatch('checkVersion');
                this.manageScroll(to, from);
                this.minimizeCardInWallet();
            }
        }
    }
</script>

<style scoped>
    .bgdGreyLight{background-color: #ECEFF1} /*#F7F8F9*/
</style>