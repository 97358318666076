// Version 1.6 2022-01-31

import {db} from "@/utilities/services/firebase/init";
import FirebaseVersion from "@/utilities/services/firebase/firebaseVersion";
import firebase from 'firebase/app'
import 'firebase/firestore'

export default class FirebaseWrite {

    static mergeFirebaseDocWithId(collectionName, documentId, dataObject){
        return new Promise( resolve => {
            db.collection(collectionName)
                .doc(documentId)
                .set(dataObject, {merge: true})
                .then(() =>{
                    dataObject.id = documentId;
                    resolve({successful: true, content: dataObject});
                })
                .catch(error => {
                    console.error ("error", error);
                    resolve({successful: false, content: error})
                });
        })
    }

    static add (collectionName, dataObject) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseWrite.add8(collectionName, dataObject);
        } else {
            //
        }
    }

        static add8(collectionName, dataObject){
            return new Promise( resolve => {
                db.collection(collectionName)
                    .add(dataObject)
                    .then(result =>{
                        dataObject.id = result.id;
                        resolve({successful: true, content: dataObject});
                    })
                    .catch(error => {
                        console.error ("error", error);
                        resolve({successful: false, content: error})
                    });
            })
        }

    static set (collectionName, documentId, dataObject) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseWrite.set8(collectionName, documentId, dataObject);
        } else {
            //
        }
    }

        static set8(collectionName, documentId, dataObject){
            return new Promise( resolve => {
                db.collection(collectionName)
                    .doc(documentId)
                    .set(dataObject)
                    .then(() =>{
                        dataObject.id = documentId;
                        resolve({successful: true, content: dataObject});
                    })
                    .catch(error => {
                        console.error ("error", error);
                        resolve({successful: false, content: error})
                    });
            })
        }

    static update (collectionName, documentId, dataObject) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseWrite.update8(collectionName, documentId, dataObject);
        } else {
            //
        }
    }
        static update8(collectionName, documentId, dataObject){
            Object.keys(dataObject).forEach( (key) => {
                if ( dataObject[key] === 'firebase.firestore.FieldValue.delete()' ) {
                    dataObject[key] = firebase.firestore.FieldValue.delete()
                } else if (dataObject[key] === 'firebase.firestore.FieldValue.increment(1)') {
                    dataObject[key] = firebase.firestore.FieldValue.increment(1)
                }
            });
            return new Promise( resolve => {
                db.collection(collectionName)
                    .doc(documentId)
                    .update(dataObject)
                    .then(() =>{
                        resolve({successful: true});
                    })
                    .catch(error => {
                        console.error ("error", error);
                        resolve({successful: false, content: error})
                    });
            })
        }

    static delete (collectionName, documentId) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseWrite.delete8(collectionName, documentId);
        } else {
            //
        }
    }
        static delete8 (collectionName, documentId) {
            return new Promise( resolve => {
                db.collection(collectionName)
                    .doc(documentId).delete()
                .then(() => {
                    console.log("Document successfully deleted!");
                    resolve({successful: true});
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    resolve({successful: false, content: error})
                });
            })
        }

    static batch(batchList){
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseWrite.batch8(batchList);
        } else {
            //
        }
    }
        static batch8 (batchList){
            let batch = db.batch();
            batchList.forEach( (batchItem) => {
                const doc = db.collection(batchItem.collection)
                                .doc(batchItem.documentId);
                switch (batchItem.action) {
                    case 'set':
                        batch.set(doc, batchItem.data);
                        break;
                    case 'update':
                        batch.update(doc, batchItem.data);
                        break;
                    case 'delete':
                        batch.delete(doc);
                        break;
                }
            });
            return new Promise( resolve => {
                batch.commit()
                    .then(() => {
                        console.log("Batch successfully processed!");
                        resolve({successful: true});
                    })
                    .catch((error) => {
                        console.error("Error processing batch: ", error);
                        resolve({successful: false, content: error})
                    });
            })
        }
}