<template>
    <print-container printableWidth="10">
        <div class="text-center">
            <img src="@/assets/oym_logo-typo-thin_final.svg" class="mt-15" height="34"
                 alt="On your marks">
        </div>
        <div class="text-center pt-4">
            <profile-avatar :user="user"
                            :storage-source-path="storageSourcePath"
                            :config-prop="{size: 144, color: 'white'}">
            </profile-avatar>
            <div class="lineHeight-1-2 my-5">
                <div class="text-uppercase fontInter font-weight-light font28">
                    {{user.firstName}}
                </div>
                <div class="text-uppercase fontInter font-weight-black space1 font32">
                    {{user.lastName}}
                </div>
            </div>
        </div>
        <div class="text-center pa-3">
            <qr-code-display :qrValue="qrValue" :size="size"></qr-code-display>
        </div>
    </print-container>
</template>

<script>
    import PrintContainer from "@/utilities/components/print/printContainer";
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import QrCodeDisplay from "../../utilities/components/qrCode/qrCodeDisplay";

    export default {
        components: {
            QrCodeDisplay,
            ProfileAvatar,
            PrintContainer},
        name: "profile-print",
        computed: {
            user(){ return this.$store.getters.user },
            qrValue() {return 'uid:' + this.user.uid},
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
        },
        data: () => ({
            size: 140,
        }),
    }
</script>

<style scoped>

</style>