import {userSex} from '@/utilities/constants/userConstants';
import store from "@/store/store";
import CountriesService from '@/utilities/services/countries/countriesService';
import {i18n} from '@/plugins/i18n';

export const USER_FORM_CONFIG = {
    firstName: {
        cols: {xs: 12, sm: 6},
        type: 'text',
        i18n: 'utilities.services.user.firstName',
        rules: [{ruleName: 'required',
            messageText: 'utilities.services.formRules.pleaseEnter',
            active: true}],
        css: '',
    },
    lastName: {
        cols: {xs: 12, sm: 6},
        type: 'text',
        i18n: 'utilities.services.user.lastName',
        rules: [{ruleName: 'required',
            messageText: 'utilities.services.formRules.pleaseEnter',
            active: true}],
    },
    nationality: {
        cols: {xs: 12, sm: 12},
        type: 'autoComplete',
        i18n: 'utilities.services.user.nationality',
        rules: [{ruleName: 'required',
            messageText: 'utilities.services.formRules.pleaseSelect',
            active: true}],
        items: CountriesService.getCountryListSortedWithPriority(i18n.locale),
        //[{id: 'fgfdd', name: 'ABC'}, {id: 'aaa', name: 'DEF'}],
        itemText: i18n.locale,
        itemValue: 'id'
    },
    birthday: {
        cols: {xs: 12, sm: 12},
        type: 'date',
        noMargin: true, // removes default y-margin in datePicker
        i18n: 'utilities.services.user.birthday',
        rules: [{ruleName: 'required',
                    messageText: 'utilities.services.formRules.pleaseEnter',
                    active: true},
                {ruleName: 'dateInFuture',
                    messageText: '',
                    active: true,
                    dateString: ''},
                {ruleName: 'minAge',
                    messageText: '',
                    active: true,
                    dateString: '',
                    minAge: 14},
                {ruleName: 'maxAge',
                    messageText: '',
                    active: false,
                    dateString: '',
                    maxAge: 18},
        ],
    },
    sex: {
        cols: {xs: 12, sm: 12},
        type: 'radioGroup',
        i18n: 'utilities.services.user.sex',
        rules: [{ruleName: 'required',
            messageText: 'utilities.services.formRules.pleaseSelect',
            active: true}],
        options: [
            {label: 'utilities.services.user.WOMAN', value: userSex.WOMEN},
            {label: 'utilities.services.user.MEN', value: userSex.MEN},
            {label: 'utilities.services.user.DIVERSE', value: userSex.DIVERSE},
        ],
        row: true,
    },
    language: {
        cols: {xs: 12, sm: 12},
        type: 'radioGroup',
        i18n: 'utilities.services.user.languageApp',
        rules: [{ruleName: 'required',
            messageText: 'utilities.services.formRules.pleaseSelect',
            active: true}],
        options: store.state.config.languageOptions,
        row: true,
    },
}