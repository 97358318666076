<template>
    <div class="text-center">
    <!-- sign up form -->
        <div v-show="!loginService.waitingForReply && !loginService.isUserLoggedIn"
            class="pb-5">
            <v-form ref="form" lazy-validation v-if="rules" @submit.prevent>
            <!-- email and password -->
                <v-text-field v-model="loginService.email"
                              :label="translate(configEl.email.i18n)"
                              :rules="[rules.required(loginService.email,
                                                    $t(configEl.email.required.i18n),true),
                                       rules.email(loginService.email,'',true)]"
                              :outlined="configEl.textFields.outlined"
                              :dense="configEl.textFields.dense"
                              clearable>
                </v-text-field>
                <v-text-field v-model="loginService.password"
                              :label="$t(configEl.password.i18n)"
                              :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPW ? 'text' : 'password'"
                              @click:append="showPW = !showPW"
                              :rules="[rules.required(loginService.password,
                                                      $t(configEl.password.required.i18n), true),
                                   rules.checkPassWordRules(loginService.password,
                                                      '', configEl.passwordRule.activated)]"
                              :outlined="configEl.textFields.outlined"
                              :dense="configEl.textFields.dense"
                              clearable>
                </v-text-field>
                <v-text-field v-model="loginService.passwordConfirm"
                              :label="$t(configEl.passwordConfirm.i18n)"
                              :append-icon="showPWConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPWConfirm ? 'text' : 'password'"
                              @click:append="showPWConfirm = !showPWConfirm"
                              :rules="[rules.required(loginService.passwordConfirm,
                                                      $t(configEl.passwordConfirm.required.i18n), true),
                                   rules.checkPassWordRules(loginService.passwordConfirm,
                                                      '', configEl.passwordRule.activated)]"
                              :outlined="configEl.textFields.outlined"
                              :dense="configEl.textFields.dense"
                              clearable>
                </v-text-field>
            <!-- slot for user data -->
                <v-divider class="mb-5"></v-divider>
                <dynamic-form ref="dynForm" :data="user" class="mb-3"
                              :field-configs="configEl.user">
                </dynamic-form>
            <!-- slot for confirmations --->
                <v-checkbox v-model="termsOfUse" hide-details
                            :rules="[rules.isTrue('','', true)]">
                    <template v-slot:label>
                        <div>
                            {{$t('utilities.services.user.termsOfUse1')}}
                            <span @click.stop>
                                <!-- <router-link to="/termsOfUse">{{$t('utilities.services.user.termsOfUse')}}</router-link>-->
                                <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_TermsV2.pdf"
                                        target="_blank">{{$t('utilities.services.user.termsOfUse')}}</a>
                                <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_TermsV2.pdf"
                                        target="_blank">terms of use</a>
                            </span>
                            {{$t('utilities.services.user.termsOfUse2')}}
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="privacyApp" hide-details
                            :rules="[rules.isTrue('','', true)]" >
                    <template v-slot:label>
                        <div>
                            {{$t('utilities.services.user.privacyApp1')}}
                            <span @click.stop>
                                <!--<router-link to="/privacy">{{$t('utilities.services.user.privacyApp')}}</router-link>-->
                                <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_PrivacyV2.pdf"
                                        target="_blank">{{$t('utilities.components.common.privacyLong')}}</a>
                                <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_PrivacyV2.pdf"
                                        target="_blank">{{$t('utilities.components.common.privacyLong')}}</a>
                            </span>
                            {{$t('utilities.services.user.privacyApp2')}}
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="emailOptIn" hide-details class="mb-5">
                    <template v-slot:label>
                        <div>
                            {{$t('utilities.services.user.emailOptIn')}}
                        </div>
                    </template>
                </v-checkbox>
            <!-- error message -->
                <alert-message config-package="error"
                               :config-prop="signUpError"
                               :module-config="moduleConfig"
                               class="text-left">
                    {{errorMessage}}
                </alert-message>
            <!-- confirm button -->
                <btn-text v-if="loginService.userPasswortStatus!=='notSet'"
                          :config-prop="configEl.btn.config"
                          @click="processSignUp()">
                    {{translate(configEl.btn.i18n)}}
                </btn-text>
            <!-- cancel button -->
                <btn-text :config-prop="configEl.btnCancel.config"
                          @click="back()" class="mt-5">
                    {{translate(configEl.btnCancel.i18n)}}
                </btn-text>
            </v-form>
        </div>
    <!-- success message -->
        <div v-if="!loginService.waitingForReply && loginService.isUserLoggedIn">
            <alert-message
                           :config-prop="configEl.successMessage"
                           :module-config="moduleConfig" >
                {{$t(configEl.successMessage.i18n)}}
                <div class="font-weight-bold">{{loginService.email}}</div>
            </alert-message>
            <alert-message
                    :config-prop="configEl.abortMessage"
                    :module-config="moduleConfig" >
                <div v-if="!resendDone">
                    {{$t(configEl.abortMessage.part1.i18n)}}
                    <btn-text class="mb-3" :config-prop="configEl.resendBtn"
                              :module-config="moduleConfig"
                              @click="sendNewVerificationLink()">
                    </btn-text>
                </div>
                <div v-else class="mb-4">
                    {{$t(configEl.abortMessage.part3.i18n)}}
                    <div class="font-weight-bold">{{$t(configEl.supportEmail)}}</div>
                </div>
                <div>{{$t(configEl.abortMessage.part2.i18n)}}</div>
                <btn-text class="mt-2"
                        :config-prop="configEl.btnRestart"
                        @click="loginService.logout()">
                </btn-text>
            </alert-message>
        </div>
    <!-- progress circle -->
        <v-progress-circular indeterminate color="primary"
                             v-show="loginService.waitingForReply">
        </v-progress-circular>
    </div>
</template>

<script>
    // Version 1.3 2022-02-17, 2022-03-01
    import {FormRules} from "@/utilities/services/form/formRules";
    import {SIGN_UP_CONFIG} from '@/utilities/components/authentication/signUpConfig';
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import BtnText from "@/utilities/components/controls/btnText";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import {User} from "@/utilities/models/user/user";
    import DynamicForm from "@/utilities/components/form/dynamicForm";
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp';

    export default {
        components: {
            DynamicForm,
            AlertMessage,
            BtnText,
        },
        name: "sign-up",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            user: new User(),
            rules: new FormRules(),
            showPW: false,
            showPWConfirm: false,
            termsOfUse: false,
            privacyApp: false,
            emailOptIn: false,
            resendDone: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.login',
        // config
            configDefault: SIGN_UP_CONFIG,
        }),
        computed: {
            signUpError(){
                return {show: this.loginService.errorI18n !== ''}
            },
            errorMessage(){
                return this.loginService.getErrorMessage(this.$i18n.t(this.loginService.errorI18n));
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        created(){
            const userTemp = sessionStorage.getItem('signUpUser');
            if ( userTemp ) {
                this.user.setFromDataObject(JSON.parse(userTemp));
                const confirmations = JSON.parse(sessionStorage.getItem('signUpConfirmations'));
                this.termsOfUse = confirmations.termsOfUse;
                this.privacyApp = confirmations.privacyApp;
                sessionStorage.removeItem('signUpUser');
                sessionStorage.removeItem('signUpConfirmations');
            }
        },
        beforeDestroy(){
            if ( this.$route.path === '/privacy' || this.$route.path === '/termsOfUse' ) {
                sessionStorage.setItem('signUpUser',
                    JSON.stringify(this.user.computeDatabaseObject()));
                sessionStorage.setItem('signUpConfirmations',
                    JSON.stringify({termsOfUse: this.termsOfUse, privacyApp: this.privacyApp }))
            }
        },
        methods: {
            back(){
                window.history.back();
            },
            async processSignUp(){
                this.loginService.email = this.loginService.email.trim();
            // form correctly filled
                this.$refs.dynForm.$refs.form.validate();
                if( !this.$refs.form.validate() || !this.$refs.dynForm.$refs.form.validate() ) { return }
            // confirm password == first password
                if( this.wrongPasswordConfirmation() ) { return }
            // sign up
                const signUp = await this.loginService.signUp();
            // save the user data as unconfirmed user
                this.user.ts = TimeStamp.getGMTTimestamp();
                this.user.confirmations.privacyApp = this.user.ts;
                this.user.confirmations.termsOfUse = this.user.ts;
                this.user.confirmations.emailOptIn = this.emailOptIn;
                this.user.confirmations.emailOptInHistory = [{optIn: this.emailOptIn, ts: TimeStamp.getGMTTimestamp()}];
                if ( signUp.successful ) {
                    console.log ("signUp: ", signUp);
                    if ( this.loginService.emailVerificationRequired ) {
                    // create in users_unconfirmed
                        this.user.uid = signUp.content;
                        this.user.email = this.loginService.email;
                        this.user.local.fireBaseCollection = 'users_unconfirmed';
                        const userUnconfirmed = await this.user.saveInUsersUnconfirmed(signUp.content);
                        console.log ("userUnconfirmed", userUnconfirmed);
                    // request and send verification mail
                        this.loginService.requestVerificationMail();
                    // output info, that verificationMail has been send
                        //tbd
                    } else {
                    // create directly in collection 'users'
                        await this.user.saveNewWithDocId(signUp.content );
                    }
                }
            },
            wrongPasswordConfirmation(){
                if ( this.loginService.password !==
                     this.loginService.passwordConfirm ) {
                    this.loginService.errorI18n = 'utilities.components.auth.wrongPasswordConfirmation';
                    return true;
                } else {
                    return false;
                }
            },
            sendNewVerificationLink(){
                this.resendDone = true;
                this.loginService.requestVerificationMail();
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
        watch: {
            'loginService.password'(){this.loginService.errorI18n = ''},
            'loginService.passwordConfirm'(){this.loginService.errorI18n = ''},
        }
    }
</script>

<style scoped>

</style>