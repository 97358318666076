import ObjectHandlingService from "@/utilities/services/objectHandling/objectHandlingService";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";



export const MULTI_LICENSE_CONDITION_TYPES = {
    GROUP:'GROUP',
    LICENSES:'LICENSES'
}

export const MULTI_LICENSE_OPERATORS = {
    AT_LEAST_ONE:'at least 1', // ||
    ALL_OF_THEM:'all', // &&
    NONE_OF_THEM:'none' // !(||)
}


export class MultiLicenseConditionGroup extends ObjectHandlingService{
    constructor(dataObject) {
        console.log('group created')
        super()
        this.id = TimeStamp.getGMTTimestamp()
        this.groups = {}
        this.operator = MULTI_LICENSE_OPERATORS.ALL_OF_THEM
        this.type = MULTI_LICENSE_CONDITION_TYPES.GROUP
        if(dataObject) {
            ObjectHandlingService.setFromDataObjectByKey(this, dataObject)
        }
    }

    addToGroups(multiLicenseConditionOrGroup){
        if(!this.groups[multiLicenseConditionOrGroup.id]) this.groups[multiLicenseConditionOrGroup.id] = multiLicenseConditionOrGroup
        return multiLicenseConditionOrGroup.id
    }
    removeFromGroups(multiLicenseConditionOrGroup){
        if(this.groups[multiLicenseConditionOrGroup]) delete this.groups[multiLicenseConditionOrGroup]
    }
}

export class MultiLicenseConditions extends ObjectHandlingService{
    constructor(dataObject) {
        super()
        this.id = TimeStamp.getGMTTimestamp()
        this.licenseIds = []
        this.operator = MULTI_LICENSE_OPERATORS.ALL_OF_THEM
        this.type = MULTI_LICENSE_CONDITION_TYPES.LICENSES
        if(dataObject) ObjectHandlingService.setFromDataObjectByKey(this, dataObject)
    }

    addLicense(licenseId){
        if(!this.licenseIds.includes(licenseId)) this.licenseIds.push(licenseId)
    }
    removeLicense(licenseId){
        if(this.licenseIds.includes(licenseId)) this.licenseIds.splice(this.licenseIds.indexOf(licenseId),1)
    }
}

//-------------------------------------------------------------------

export const testGroups = {
    1:'{"groups":{"1646824195705":{"licenseIds":["1"],"operator":"all","type":"LICENSES"},"1646824215375":{"licenseIds":["2"],"operator":"none","type":"LICENSES"},"1646824223990":{"groups":{"1646824267060":{"licenseIds":["3","4"],"operator":"at least 1","type":"LICENSES"},"1646824280466":{"licenseIds":["5","6"],"operator":"at least 1","type":"LICENSES"}},"operator":"all","type":"GROUP"}},"operator":"all","type":"GROUP"}',
    2:'{"id":1646824346358,"objKey":1646824346358,"groups":{"1646824195705":{"id":"1646824195705","objKey":"1646824195705","licenseIds":["1"],"operator":"all","type":"LICENSES"},"1646824215375":{"id":"1646824215375","objKey":"1646824215375","licenseIds":["2"],"operator":"none","type":"LICENSES"},"1646824223990":{"id":"1646824223990","objKey":"1646824223990","groups":{"1646824267060":{"id":1646824346358,"objKey":1646824346358,"licenseIds":["3","4"],"operator":"at least 1","type":"LICENSES"},"1646824280466":{"id":1646824346358,"objKey":1646824346358,"licenseIds":["5","6"],"operator":"at least 1","type":"LICENSES"}},"operator":"all","type":"GROUP"}},"operator":"all","type":"GROUP"}',
    3:'{"groups":{"1646744375581":{"licenseIds":["1","2","3"],"operator":"all","type":"LICENSES"},"1646744394148":{"licenseIds":["4","5"],"operator":"at least 1","type":"LICENSES"},"1646744407658":{"groups":{"1646744413308":{"licenseIds":["fasdjodsjfkiujwerbfkbefwkdfnvs","fdsjkdsfgljldfgkdf"],"operator":"all","type":"LICENSES"}},"operator":"none","type":"GROUP"}},"operator":"at least 1","type":"GROUP"}',
}



// (id1 && id2 && id3) && ((id7 || id39 || id345') || !(id7986 || id439 || id3d45))
// export const multiTest = {
//     start:{
//         // a:'x',
//         a:{
//             licenseIds:[],
//             operator:'AND'
//         },
//         b:'y',
//         type:MULTI_LICENSE_CONDITION_TYPES.GROUP,
//         operator: MultiLicenseLogicalOperators.AND // (id1 && id2 && id3) && ((id7 || id39 || id345') || !(id7986 || id439 || id3d45))
//     },
//     x:{
//         a:['id1', 'id2', 'id3'], // (id1 && id2 && id3)
//         type:MULTI_LICENSE_CONDITION_TYPES.LICENSES,
//         operator:MultiLicenseGroupCondition.ALL_OF_THEM
//     },
//     y:{
//         a:'y1',
//         b:'y2',
//         type:MultiLicenseLogicalOperators.OR // (id7 || id39 || id345') || !(id7986 || id439 || id3d45)
//     },
//     y1:{
//         a:['id7', 'id39', 'id345'],
//         type:MultiLicenseGroupCondition.AT_LEAST_ONE // (id7 || id39 || id345')
//     },
//     y2:{
//         a:['id7986', 'id439', 'id3d45'], // !(id7986 || id439 || id3d45)
//         type:MultiLicenseGroupCondition.NONE_OF_THEM
//     }
// }

// (id21 ^ id22 ^ id23 ^ id24) && ((id1 || id2 || id3) && (!id4 && !id5 && !id6)) || ((id16 && id17 && id18 && id19) && (!id12 && !id13 && !id14 && !id15))
export const testStart = {
    start:{
        a:{
            a:['/a bunch of xors -> (id21 ^ id22 ^ id23 ^ id24)'],
            ONLY_ONE:true
        },
        b:{
            a:{
                a:{
                    ids:['/*liste von ids*/ -> (id16 && id17 && id18 && id19)'],
                    ALL_OF_THEM:true
                },
                b:{
                    ids:['/*liste von anderen ids*/ -> (!id12 && !id13 && !id14 && !id15)'],
                    NONE_OF_THEM:true
                },
                c:{
                    ids:['/*liste von anderen ids*/ -> (!id12 && !id13 && !id14 && !id15)'],
                    NONE_OF_THEM:true
                },
                AND:true // (id16 && id17 && id18 && id19) && (!id12 && !id13 && !id14 && !id15)
            },
            b:{
                a:{
                    ids:['/*liste von nochmal ids -> (id1 || id2 || id3)*/'],
                    AT_LEAST_ONE_OF_THEM:true
                },
                b:{
                    ids:['/*liste von ganz anderen ids*/ -> (!id4 && !id5 && !id6)'],
                    NONE_OF_THEM:true
                },
                AND:true /*  (id1 || id2 || id3) && (!id4 && !id5 && !id6)*/
            },
            OR:true // ((id1 || id2 || id3) && (!id4 && !id5 && !id6)) || ((id16 && id17 && id18 && id19) && (!id12 && !id13 && !id14 && !id15))
        },
        AND:true // (id21 ^ id22 ^ id23 ^ id24) && ((id1 || id2 || id3) && (!id4 && !id5 && !id6)) || ((id16 && id17 && id18 && id19) && (!id12 && !id13 && !id14 && !id15))
    }
}