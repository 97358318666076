<template>
    <div :class="[{'cardDyn': $vuetify.breakpoint.xsOnly },
                  {'cardFix': $vuetify.breakpoint.smAndUp},
                  {'mt-4': walletZoomed.section},
                  'd-inline-block elevation-2 fontInter']"
         v-ripple hidden
         :style="cardStyle"
         @click="zoom()">
        <slot></slot>
        <span v-if="!hideCornerTag">
            <img src="@/assets/CornerTag.svg" height="30" class="cornerTag">
            <img v-if="sportCornerTag" :src="sportCornerTag" height="14" class="rfaBaelle">
            <v-icon v-else class="plusIcon" small color="black">mdi-plus</v-icon>
        </span>
    </div>
</template>

<script>
    import RouterServices from '@/utilities/services/router/routerServices';
    export default {
        name: "wallet-card",
        props: ['section', 'document', 'floatingClass', 'hideCornerTag'],
        data: () => ({
            ratio: 1,
            timeout: null,
        }),
        computed: {
            organisation(){ return this.$store.state.organisations?.[this.document?.organisationId]},
            sportCornerTag(){ return this.document.sports && this.document.sports.length > 0 ?
                                     this.document.sports.length > 1 ? '' :
                                     this.$store.state.sports?.[this.document.sports[0]].cornerTag
                                        :
                                     this.organisation?.sports.length === 1 ?
                                     this.$store.state.sports?.[this.organisation?.sports[0]].cornerTag :
                                     '' },
            walletZoomed(){ return this.$store.state.wallet.zoomed;},
            transformOrigin(){
                return this.floatingClass === 'float-left' || this.$vuetify.breakpoint.smAndUp ?
                                              'left top' : 'right top'
            },
            cardStyle(){
                return {
                    transition: 'transform 0.3s ease-in-out',
                    transformOrigin: this.transformOrigin,
                    transform: 'scale(' + this.ratio + ')',
                    paddingTop: this.$vuetify.breakpoint.smAndUp ? '0' : '31.76%',
                }
            },
        },
        created(){
            if ( this.$route.query.card === this.document.id ) {
                this.zoomTheCard();
                //this.ratio = this.$vuetify.breakpoint.smAndUp ? 1.3 : 2.04166;
            }
        },
        methods: {
            zoom(){
                if ( !this.$route.query.card ) {
                    RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                        'card', this.document.id);
                } else {
                    window.history.back();
                }
            },
            zoomTheCard(){
                clearTimeout(this.timeout);
                if ( this.ratio === 1 ) {
                    this.ratio = this.$vuetify.breakpoint.smAndUp ? 1.3 : 2.04166;
                    //48%+2%+48% = 98%/48%
                    this.$store.state.wallet.zoomed = {
                        section: this.section,
                        documentId: this.document.id,
                        document: this.document,
                    };
                } else {
                    this.ratio = 1;
                    this.$store.state.wallet.zoomed.section = '';
                    this.$store.state.wallet.zoomed.document = null;
                    this.timeout = setTimeout(()=>{this.$store.state.wallet.zoomed.documentId = ''}, 300)
                }
            },
        },
        watch: {
            '$route.query.card'(to, from){
                if ( (!to && from === this.document.id) ||
                    (to === this.document.id && !from) ) {
                    this.zoomTheCard();
                }
            },
            '$route.query.tab'(){
                if ( this.$route.query.tab === '2' && this.$route.query.card ) {
                    this.ratio = this.$vuetify.breakpoint.smAndUp ? 1.3 : 2.04166;
                }
            }
        }
    }
</script>

<style scoped>
    .cornerTag {position: absolute; top: 0; right: 0}
    .plusIcon { position: absolute; top: 2px; right: 0; }
    .rfaBaelle {
        position: absolute; top: 3px; right: 1px;
        -webkit-transform: rotate(255deg);
        -moz-transform: rotate(255deg);
        -o-transform: rotate(255deg);
        -ms-transform: rotate(255deg);
        transform: rotate(255deg);
    }
</style>