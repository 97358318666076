<template>
    <div>
        <toolbar :showSave="false" :title="$t('sports.my')" :config-prop="{icon: 'mdi-run-fast'}"/><!--:show-back="!nothingSelected"-->
        <div class="pa-4">
            <alert-message :config-prop="{show: true}"
                           class="body-2 mx-auto" style="max-width: 400px">
                {{$t('sports.infoText')}}
            </alert-message>
            <alert-message :config-prop="{show: nothingSelected && sports!==null}" config-package="error"
                           class="body-2 mx-auto" style="max-width: 400px">
                {{$t('sports.nothingSelected')}}
            </alert-message>
            <v-list v-if="sports" max-width="400" class="mx-auto py-0">
                <span v-for="(sport, i) in sports.sportsList" :key="i">
                    <v-divider v-if="i>0"/>
                    <v-list-item class="py-2">
                        <v-list-item-icon class="pl-3 pr-0">
                            <img :src="sport.cornerTag" width="24"/>
                        </v-list-item-icon>
                        <v-list-item-title>{{sport.label[$i18n.locale]}}</v-list-item-title>
                        <v-list-item-action class="pr-3">
                            <v-checkbox color="secondary" v-model="sport.selected"/>
                        </v-list-item-action>
                    </v-list-item>
                </span>
            </v-list>
            <divider-with-text v-if="sports" class="my-2">{{$t('common.or')}}</divider-with-text>
            <v-list v-if="sports" max-width="400" class="mx-auto py-0">
                <v-list-item class="py-2 mt-1" >
                    <v-list-item-icon class="pl-3 pr-0"/>
                    <v-list-item-title>{{$t('sports.all')}}</v-list-item-title>
                    <v-list-item-action class="pr-3">
                        <v-checkbox color="secondary" v-model="sports.all"/>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <divider-with-text v-if="sports" class="my-2">{{$t('common.or')}}</divider-with-text>
            <v-list v-if="sports" max-width="400" class="mx-auto py-0">
                <v-list-item class="py-2 mt-1" >
                    <v-list-item-icon class="pl-3 pr-0"/>
                    <v-list-item-title>{{$t('sports.none')}}</v-list-item-title>
                    <v-list-item-action class="pr-3">
                        <v-checkbox color="secondary" v-model="sports.none"/>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <v-snackbar v-model="snackSuccess" color="success" timeout="2000">
                {{$t('sports.changeSucess')}}
            </v-snackbar>
            <v-snackbar v-model="snackError" color="error" timeout="3000">
                {{$t('sports.changeError')}}
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import Toolbar from "@/utilities/components/controls/toolbar";
import AlertMessage from "@/utilities/components/displays/alertMessage";
import {SportsService} from "@/services/sports/sportsService";
import DividerWithText from "@/utilities/components/displays/dividerWithText";

export default {
    name: "mySports",
    components: {DividerWithText, AlertMessage, Toolbar},
    computed: {
        user(){ return this.$store.getters.user },
        masterUser(){ return this.$store.state.realtime.userMaster},
        sportsUser() { return this.masterUser ? this.masterUser : this.user?.uid.length === 28 ? this.user : null },
        nothingSelected(){ return !this.sportsUser?.sports || ( Object.keys(this.sportsUser?.sports).length <= 2 &&
                                  !this.sportsUser.sports?.all && !this.sportsUser.sports.none)},
        sportsRaw(){ return this.$store.state.sports},
        sportsAll(){ return this.sports?.all }
    },
    data: () => ({
        sports: null,
        snackSuccess: false,
        snackError: false,
    }),
    created(){
        this.init()
    },
    methods: {
        init(){
            if ( !this.sportsRaw || !this.sportsUser || this.sports ) return
            this.sports = new SportsService(this.sportsRaw, this.sportsUser.sports, this.sportsUser.uid)
        },
        handleMasterUserChange(){
            if ( this.sports ) {
                this.sports = null;
                setTimeout( () => { this.init() }, 10)
            } else {
                this.init()
            }
        },
        async saveChanges(action){
            const reply = action === 'item' ? await this.sports.saveChangesToMasterUser() :
                          action === 'all' ? await this.sports.saveAllChanged() :
                                             await this.sports.saveNoneChanged()
            if ( reply.successful ) {
                this.snackSuccess = true
            } else {
                this.snackError = true
            }
        },
    },
    watch: {
        sportsRaw(){ this.init() },
        sportsUser(){ this.handleMasterUserChange() },
        'sports.sportsList': {
            handler(to, from){
                if ( to && from ) {
                    this.saveChanges('item')
                }
            },
            deep: true
        },
        sportsAll(to, from){
            if ( to !== undefined && from !== undefined )this.saveChanges('all')
        },
        'sports.none'(to, from){
            if ( to !== undefined && from !== undefined )this.saveChanges('none')
        }
    }
}
</script>

<style scoped>

</style>