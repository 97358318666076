<template>
    <v-menu :close-on-content-click="false"
            v-model="menu" :nudge-right="40" transition="scale-transition" min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-model="date" :label="label"
                          readonly
                          v-on="on"
                          :class="[{'py-4': !noMargin}, '']"
                          :rules="rules"
                          :outlined="configEl.outlined"
                          :dense="configEl.dense"
                          :disabled="disabled"
                          :hide-details="configEl.hideDetails"
            >
            </v-text-field>
        </template>
        <v-date-picker v-model="localValue" @input="menu = false" color="primary"
                       :locale="$i18n.locale" :first-day-of-week="firstDayOfWeek"></v-date-picker>
    </v-menu>
</template>

<script>
    //  Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "date-picker",
        props: {
            value: {default: ''},
            label: {default: ''},
            disabled: {default: false},
            rules: {default: null},
            noMargin: {default: false},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            menu: false,
            localValue: '',
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.datePicker',
        // define here, which parameter should be customizeable
            configDefault: {
                outlined: false,
                dense: false,
                hideDetails: false,
            },
        }),
        computed: {
            firstDayOfWeek () { return this.$i18n.locale === "en" ? 0 : 1},
            date () {
                if ( this.localValue === null || this.localValue === '' ) { return '' }
                let options = { timeZone: 'UTC', weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
                let x = new Date ( this.localValue );
                return x.toLocaleDateString(this.$i18n.locale, options);
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        created() {
            this.localValue = this.value;
        },
        methods: {
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
        watch: {
            value(){
                if ( this.value !== this.localValue ) { this.localValue = this.value }
            },
            localValue(){
                console.log ("AAAAAAA", JSON.parse(JSON.stringify(this.localValue)));
                this.$emit('changed', this.localValue);
            }
        }
    }
</script>
