<template>
    <div v-if="requiredInformation" :class="[{'mb-4': !showShopHeader}]">
        <div v-if='this.showShopHeader && requiredInformation.showHeader' class="error--text body-2 py-3 font-weight-bold">
            {{$t('requiredInformation.missingHeader')}}
        </div>
        <div v-if="!refresh">
            <div v-for="(item, i) in requiredInformation.items" :key="i">
                <div v-if="!showShopHeader && organisations &&
                            (i===0 || item.organisationId !== requiredInformation.items[i-1].organisationId)"
                     class="py-2">
                    <divider-with-text class="mb-1 mt-3">
                        {{$t('requiredInformation.information')}} /
                        {{organisations[item.organisationId].short}}
                    </divider-with-text>
                </div>
                <v-divider v-else-if="!showShopHeader && i>0" class="mb-5 mt-2"></v-divider>
                <v-row no-gutters align="center" justify="center" style="border-radius: 6px; letter-spacing: 0">
                    <v-col class="grow">
                        <single-line-edit v-show="!item.hide"
                                          :data-field="item.dataField"
                                          :data-document="user"
                                          collection="users"
                                          :empty-allowed="getEmptyAllowed(item)"
                                          @updated="updated(item)"
                                          @blockedClick="blockedClick"
                                          :config-prop="{labelUppercase: !showShopHeader}">
                            <a v-if="item.link" :href="item.link" target="_blank" style="color: white" @click.stop
                                class="body-2">
                                {{$t('requiredInformation.linkLabel')}}</a>
                            <template v-slot:editWindowTop>
                                <div v-if="item.link" class="mb-3">
                                    <div>{{item.dataField.i18n}}</div>
                                    <a :href="item.link" target="_blank">{{$t('requiredInformation.linkLabel')}}</a>
                                </div>
                            </template>
                        </single-line-edit>
                    </v-col>
                </v-row>
                <div v-if="requiredInformationStatus[item.id] && organisations" class="caption">
                    <div v-if="requiredInformationStatus[item.id].accepted" class="success--text text--darken-1">
                        {{$t('requiredInformation.accepted',
                        {date: getDateFromTs(requiredInformationStatus[item.id].accepted),
                            org: organisations[item.organisationId].short})}}
                        <v-icon v-if="item.dataField.disabled" small class="mt-n1" color="error">mdi-lock</v-icon></div>
                    <div v-if="requiredInformationStatus[item.id].rejected" class="error--text">
                        <v-icon small class="mt-n1" color="error">mdi-alert</v-icon>
                        {{$t('requiredInformation.rejected',
                        {date: getDateFromTs(requiredInformationStatus[item.id].rejected),
                            org: organisations[item.organisationId].short})}}</div>
                    <div v-if="requiredInformationStatus[item.id].validUntil" class=""
                        :class="[{'error--text font-weight-bold': todaysString>requiredInformationStatus[item.id].validUntil},
                                {'success--text text--darken-1': todaysString<=requiredInformationStatus[item.id].validUntil}]">
                        {{$t('requiredInformation.validUntil',
                        {date: requiredInformationStatus[item.id].validUntil,
                            org: organisations[item.organisationId].short})}}</div>
                </div>
            </div>
            <v-snackbar v-model="blockedSnack" color="error">
                {{$t('requiredInformation.blockedMsg')}}
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import {RequiredInformationService} from "@/services/requiredInformation/requiredInformationService";
import SingleLineEdit from "@/utilities/components/controls/singleLineEdit";
import DividerWithText from "@/utilities/components/displays/dividerWithText";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export default {
    name: "required-information-list",
    components: { DividerWithText, SingleLineEdit },
    props: ['requiredInformationData','showShopHeader', 'userEdit', 'organisations', 'queryParameter', 'isProfile'],
    computed: {
        user(){return this.$store.getters.user},
        requiredInformationStatus(){ return this.user?.requiredInformationStatus},
    },
    data: () => ({
        requiredInformation: null,
        refresh: false,
        blockedSnack: false,
        todaysString: TimeStamp.getTodaysDateString()
    }),
    created(){
        this.setRequiredInformation();
    },
    methods: {
        async setRequiredInformation(){
            if ( this.requiredInformationData && this.user && !this.requiredInformation ) {
                this.requiredInformation = new RequiredInformationService(
                    this.requiredInformationData, this.userEdit, this.queryParameter)
                await this.requiredInformation.setItems(this.requiredInformationData);
                this.emitRequiredInformationStatus();
            } else if (this.requiredInformationData && this.user &&
                       this.requiredInformation && !this.isProfile){
                this.requiredInformation.refreshItemsFromRequiredInformation();
            }
        },
        updated(item){
            this.refresh = true
            this.resetRequiredInformationStatus(item)
            setTimeout(()=>{
                this.requiredInformation.refreshMissingItemsAfterUpdate()
                this.emitRequiredInformationStatus();
            }, 100)
        },
        resetRequiredInformationStatus(item){
            if ( !this.user.requiredInformationStatus?.[item.id] ||
                  this.user.requiredInformation?.[item.id] === null) return
            this.user.requiredInformationStatus[item.id].accepted = null
            this.user.requiredInformationStatus[item.id].rejected = null
            this.user.requiredInformationStatus[item.id].validUntil = ''
        },
        emitRequiredInformationStatus(){
            this.$emit('refresh', this.requiredInformation.getDisplayedItems().length===0);
            this.refresh = false
        },
        getDateFromTs(ts){
            return ts > 0 && ts !== undefined ? TimeStamp.getLocalStringFromGMTts(ts) : ''
        },
        blockedClick(){
            this.blockedSnack = true
        },
        getEmptyAllowed(item){
            return item.dataField.type==='pdfUpload' &&
                (this.user.requiredInformationStatus[item.id]?.accepted ||
                 this.user.requiredInformationStatus[item.id]?.validUntil)
        }
    },
    watch: {
        requiredInformationData(){
            this.setRequiredInformation();
        },
        user(){
            this.setRequiredInformation();
        },
    }
}
</script>

<style scoped>

</style>