<template>
    <div v-if="loginService.isUserLoggedIn">
    <!-- Button -->
        <btn-text v-if="configEl.type==='button'"
                  :config-prop="configEl.button"
                  :module-config="moduleConfig"
                  @click="getConfirmation(false)">
            {{$t(configEl.i18n)}}
        </btn-text>
    <!-- or icon Button -->
        <tool-tip v-else-if="configEl.type==='iconButton'"
                  :config-prop="configEl.iconButton.toolTip"
                  :module-config="moduleConfig">
            <v-btn icon @click="getConfirmation(false)">
                <v-icon>{{configEl.icon}}</v-icon>
            </v-btn>
        </tool-tip>
    <!-- or menu list item -->
        <v-list-item v-else-if="configEl.type==='listItem'"
                     @click="getConfirmation(true)">
            <v-list-item-icon :class="configEl.listItem.icon.css">
                <v-icon>{{configEl.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                {{$t(configEl.i18n)}}
            </v-list-item-title>
        </v-list-item>
    <!-- confirm dialog -->
        <confirm-dialog :dialog-data="dialogData"
                        :config-prop="configEl.confirmDialog"
                        :module-config="moduleConfig"
                        @reply="processConfirmation">
            {{$t(configEl.confirmDialog.question.i18n)}}
        </confirm-dialog>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import BtnText from "@/utilities/components/controls/btnText";
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import ToolTip from "@/utilities/components/displays/toolTip";
    import ConfirmDialog from "@/utilities/components/dialogs/confirmDialog";

    export default {
        components: {
            ConfirmDialog,
            ToolTip,
            BtnText},
        name: "logout",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            dialogData: {
                open: false,
                query: {
                    parameter: 'confirmLogout',
                    value: true,
                },
            },
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.logout',
        // config
            configDefault: {
                type: 'button', // button or iconButton or listItem
                icon: 'mdi-logout',
                i18n: 'utilities.components.auth.logout',
                button: {
                    icon: 'mdi-logout'
                },
                iconButton: {
                    toolTip: {
                        i18n: 'utilities.components.auth.logout',
                    }
                },
                listItem: {
                    icon: {
                        css: 'mr-3'
                    }
                },
                confirmDialog: {
                    question: {
                        i18n: 'utilities.components.auth.logoutConfirm'
                    },
                    title: {
                        i18n: 'utilities.components.auth.logout',
                        color: 'error--text',
                    },
                    confirmBtn: {
                        configProp: {
                            color: 'error',
                        }
                    }
                },
                routeToHome: true,
            }
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
        // open confirm dialog to confirm start account setup
            getConfirmation(list){
                this.dialogData.open = true;
                if ( list ) {
                    this.dialogData.open = false;
                    setTimeout(()=>{ this.dialogData.open = true; }, 200)
                }
            },
        // process reply from confirm dialog
            processConfirmation(reply){
                this.dialogData.open = false;
                if ( reply ) {
                    this.loginService.logout();
                    this.$router.push('/');
                }
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>