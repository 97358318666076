<template>
    <v-form ref="form" lazy-validation v-if="rules" @submit.prevent>
        <v-row :class="configEl.row.css">
            <v-col v-for="(field, i) in fieldList" :key="i"
                   :class="configEl.col.css"
                   :xl="field.cols ? field.cols.xl : 12"
                   :lg="field.cols ? field.cols.lg : 12"
                   :md="field.cols ? field.cols.md : 12"
                   :sm="field.cols ? field.cols.sm : 12"
                   :cols="field.cols ? field.cols.xs : 12">
                <v-text-field v-if="field.type === 'text'"
                              v-model="data[field.fieldName]"
                              :label="translate(field.i18n)"
                              :rules="getRules(data, field)"
                              :class="field.css"
                              :outlined="configEl.basic.outlined"
                              :dense="configEl.basic.dense"
                              :clearable="configEl.textField.clearable"
                              :type="field.type">
                </v-text-field>
                <date-picker v-if="field.type === 'date'"
                              :value="data[field.fieldName]"
                              :label="translate(field.i18n)"
                              :rules="getRules(data, field)"
                              :class="field.css"
                              :noMargin="field.noMargin"
                              :config-prop="configEl.date"
                              @changed="(value)=>{data[field.fieldName] = value}">
                </date-picker>
                <div v-if="field.type === 'radioGroup'">
                    <!--<div v-if="field.row" class="text-left text-caption grey--text text--darken-2">
                        {{translate(field.i18n)}}
                    </div>-->
                    <v-radio-group v-model="data[field.fieldName]"
                                   :row="field.row"
                                   :rules="getRules(data, field)"
                                   :class="field.css"
                                   :dense="configEl.basic.dense"
                                   class="mt-0">
                        <template v-slot:label>
                            <div v-if="!field.row">{{translate(field.i18n)}}:</div>
                        </template>
                        <v-radio v-for="(option, i) in field.options" :key="i"
                                 :label="translate(option.label)"
                                 :value="option.value"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div v-if="field.type === 'autoComplete'">
                    <v-autocomplete :items="getArrayFromObject(field.items)"
                                    :item-text="field.itemText"
                                    :item-value="field.itemValue"
                                    v-model="data[field.fieldName]"
                                    :rules="getRules(data, field)"
                                    :class="field.css"
                                    :label="translate(field.i18n)"
                                    :outlined="configEl.basic.outlined"
                                    :dense="configEl.basic.dense"
                                    clearable>
                    </v-autocomplete>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    // Version 1.2 2022-01-18
    import {FormRules} from "@/utilities/services/form/formRules";
    import {DYNAMIC_FORM_CONFIG} from '@/utilities/components/form/dynamicFormConfig';
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import DatePicker from "../controls/datePicker";

    export default {
        components: {DatePicker},
        name: "dynamic-form",
        props: {
            fieldConfigs: {default: null},
            data: {default: null},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            rules: new FormRules(),
        // define the path where the component can be found in the directory
            path: 'utilities.components.form.dynamicForm',
        // config
            configDefault: DYNAMIC_FORM_CONFIG,
        }),
        computed: {
            fieldList(){
                return ComponentConfigService.getArrayFromConfigParameter(this.fieldConfigs)
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
            getRules(data, field){
                const rules = this.getArrayFromObject(field.rules);
                return this.rules.getRulesArrayFromList( data[field.fieldName], rules)
            },
            getArrayFromObject(object){
                //if ( Array.isArray(object) ) { return object }
                let array = [];
                Object.keys(object).forEach( (key) => {
                    array.push(object[key])
                });
                return array
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>