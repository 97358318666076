<template>
    <div :class="[{'cardContentDyn': $vuetify.breakpoint.xsOnly},
                  {'cardContentFix': $vuetify.breakpoint.smAndUp}]">
        <!--<div class="watermark" :style="watermarkUrl"></div>-->
    <!-- old layout -->
        <div v-if="!userPass.style || userPass.style.cardType !== 'customized'">
        <!-- date -->
            <div :class="[{'fontPassDate': $vuetify.breakpoint.xsOnly},
                          {'font18': $vuetify.breakpoint.smAndUp},
                          'cardDateTop', textColor]">
                {{userPass.until.substring(0,4)}}
            </div>
        <!-- logo -->
            <img v-if="organisation && organisation.short"
                 class="cardLogo" height="30%" :alt="organisation.short"
                 :src="organisation.logo">
        <!-- text box -->
            <div :class="[{'titleDyn fontPassText': $vuetify.breakpoint.xsOnly},
                          {'titleFix font20': $vuetify.breakpoint.smAndUp},
                          'lineHeight-1-0 mt-1 text-left pl-3', textColor]">
                <div :class="[{'fontPassText': $vuetify.breakpoint.xsOnly},
                              {'font18': $vuetify.breakpoint.smAndUp},
                              {'wordBreak': !userPass.style ||
                                            !userPass.style.noWordBreak || // new as of verion 2023
                                            userPass.style.noWordBreak==='false'}, // in old passes
                               'space1 text-uppercase']">
                    {{passTitle}}
                </div>
                <div class="font-weight-black space05 text-uppercase">
                    <span v-if="!userPass.style || !userPass.style.cardType">PASS</span>
                    <span v-else>{{userPass.style.cardType}}</span>
                </div>
            </div>
            <!-- name footer -->
            <div :class="[{'fontPassUserName': $vuetify.breakpoint.xsOnly},
                      {'font12': $vuetify.breakpoint.smAndUp},
                      'nameFooter font10 lineHeight-1-2 text-uppercase text-truncate',
                      textColor]">
            <span class="ml-3" >
                {{user.firstName}} {{user.lastName}}
            </span>
            </div>
        </div>
    <!-- new layout -->
        <wallet-passes-content-customized v-else :user="user" :user-pass="userPass" :organisation="organisation"/>
        <profile-avatar :user="user" class="avatar" :config-prop="configAvatar"
                        :storage-source-path="storageSourcePath">
        </profile-avatar>
    <!-- overlay for inactive passes -->
        <wallet-missing-consents-overlay type="pass"
             :card-selected="passSelected" :event-id="eventId"/>
    </div>
</template>

<script>
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp'
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import WalletMissingConsentsOverlay from "@/components/wallet/walletMissingConsentsOverlay";
    import WalletPassesContentCustomized from "@/components/wallet/walletPassesContentCustomized";
    export default {
        components: {WalletPassesContentCustomized, WalletMissingConsentsOverlay, ProfileAvatar},
        name: "wallet-passes-content",
        props: ['userPass'],
        computed: {
            eventId(){return this.userPass?.eventId },
            passTitle(){
                return this.userPass?.style?.cardType === 'Pass' ?
                            this.userPass?.name.replace('Pass','') : this.userPass?.name;
            },
            /*
            passInactive(){
                let orgId = this.userPass.organisationId;
                if ( !this.user.organisations || !this.user.organisations[orgId] ) {
                    return false;
                }
                let organisation = this.user.organisations[orgId];
                if ( organisation && orgId &&
                    this.$store.state.organisations[orgId] &&
                    organisation.memberCountries['130']) {
                    const orgDates = this.$store.state.organisations[orgId].countries['130'];
                    if ( !organisation.memberClubs['130'] ) { return true  }
                    if ( !organisation.confirmations['130'].statutes ||
                        organisation.confirmations['130'].statutes < orgDates.statutes ) {
                        return true;
                    }
                    if ( !organisation.confirmations['130'].covid ||
                        organisation.confirmations['130'].covid < orgDates.covid ) {
                        return true;
                    }
                    if ( !organisation.confirmations['130'].privacy ||
                        organisation.confirmations['130'].privacy < orgDates.privacy ) {
                        return true;
                    }
                    if ( !organisation.confirmations['130'].termsOfUse ||
                        organisation.confirmations['130'].termsOfUse < orgDates.termsOfUse ) {
                        return true;
                    }
                    if ( !organisation.confirmations['130'].rulesOfCompetition ||
                        organisation.confirmations['130'].rulesOfCompetition < orgDates.rulesOfCompetition ) {
                        return true;
                    }
                }
                return false;
            },*/
            passSelected(){
                return this.$route.query.card;
            },
            organisation() {
                return this.$store.state.organisations[this.userPass.organisationId] ?
                    this.$store.state.organisations[this.userPass.organisationId] :
                    {styles: {color: 'grey', dark: true}}
            },
            textColor(){
                return this.organisation.styles.dark ? 'lightGrey--text':'primary--text';
            },
            dateString(){
                return TimeStamp.getTimeRangeFromTwoStringDates(this.userPass.from, this.userPass.until)
            },
            walletZoomed(){
                return this.$store.state.wallet.zoomed;
            },
            /*
            watermarkUrl(){
                return this.organisation && this.organisation.sport ? {
                    'background-image': 'url(' +
                    require('@/assets/' + this.organisation.sport + 'Watermark.png') + ')'
                } : '';
            },*/
        // Avatar
            user(){ return this.$store.getters.user },
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            configAvatar(){
                return this.$vuetify.breakpoint.xsOnly ?
                    {size: 44} : {size: 60}
            },
        },
    }
</script>

<style scoped>
    .titleDyn {position: absolute; width: 100%; top: 42%}
    .titleFix {position: absolute; width: 100%; top: 44%}
    .avatar {position: absolute; right: 5%; top: 44%; z-index: 15}
    .inactiveOverlay {
        position: absolute; top: 0; bottom: 0; left: 0; right: 0;
        background-color: rgba(0,0,0,0.5);
        border-radius: 6px;
        z-index: 15;
    }
    .inactiveOverlayText {
        position: absolute; top: 10px; right: 0; left: 0;
    }
    .nameFooter {
        position: absolute; bottom: 5%; left: 0; width: 98%;
    }
</style>