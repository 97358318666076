// Version 1.5 2022-02-17

export let SIGN_UP_CONFIG = {
    supportEmail: 'support@onyourmarks.com',
    textFields: {
        outlined: true,
        dense: true,
    },
    email: {
        i18n: 'utilities.components.common.email',
        required: {
            i18n: 'utilities.services.formRules.pleaseEnter',
        }
    },
    password: {
        i18n: 'utilities.components.auth.password',
        required: {
            i18n: 'utilities.components.auth.passwordRequired',
        }
    },
    passwordConfirm: {
        i18n: 'utilities.components.auth.passwordConfirm',
        required: {
            i18n: 'utilities.components.auth.passwordRequired',
        }
    },
    passwordRule: {
        activated: true, // also configure in PW Reset
    },
    user: { // needs to follow dynamicForm config rules!!!
        /*firstName: {
            cols: {xs: 12, sm: 6},
            type: 'textField',
            i18n: 'utilities.services.user.firstName',
            rules: [{ruleName: 'required', messageText: '', active: true}],
            css: ''
        },
        sex: {
            cols: {xs: 12, sm: 12},
            type: 'radioGroup',
            i18n: 'utilities.services.user.sex',
            rules: [{ruleName: 'required', messageText: '', active: true}],
            options: [
                {label: 'utilities.services.user.WOMAN', value: userSex.WOMEN},
                {label: 'utilities.services.user.MEN', value: userSex.MEN},
                {label: 'utilities.services.user.DIVERSE', value: userSex.DIVERSE},
            ],
            row: true,
        },*/
    },
    btn: {
        i18n: 'utilities.components.auth.createAccount',
        config: {
            color: 'primary',
            block: true
        },
    },
    btnCancel: {
        i18n: 'utilities.components.common.cancel',
        config: {
            color: '',
            block: true
        },
    },
    btnRestart: {
        i18n: 'utilities.components.auth.restart',
    },
    initPassword: {
        show: true,
        i18n: 'utilities.components.auth.initPassword',
        css: 'text-left',
    },
    successMessage: {
        show: true,
        i18n: 'utilities.components.auth.signInSuccessful',
        css: 'text-left',
    },
    resendBtn: {
        i18n: 'utilities.components.auth.sendNewVerification',
        css: 'mt-2',
    },
    abortMessage: {
        show: true,
        part1: {
            i18n: 'utilities.components.auth.wrongEmail1',
        },
        part2: {
            i18n: 'utilities.components.auth.wrongEmail2',
        },
        part3: {
            i18n: 'utilities.components.auth.wrongEmail3',
        },
        css: 'text-left',
    }
}