<template>
    <div v-if="organisation">
        <v-row class="cardBanner" no-gutters :style="bannerStyle">
            <v-col v-if="organisation.logo" class="ml-5 pt-3 pb-1 text-left">
                <img :src="logoSrc" class="bannerImg"/>
            </v-col>
            <v-col v-else :style="cardStyle" :class="[textColor,
                    'py-2 pl-3 font-italic font24 font-weight-black fontInter text-uppercase']">
                {{organisation.short}}
            </v-col>
            <img src="@/assets/CornerTag.svg" height="30" class="cornerTag">
            <img v-if="sportCornerTag" :src="sportCornerTag" height="14" class="rfaBaelle">
            <v-icon v-else class="plusIcon" small color="black">mdi-plus</v-icon>
        </v-row>
        <div class="bgdGreyDark fontInter font-weight-black
                    font18 white--text
                    lineHeight-1-2 px-4 pt-2 pb-2 space03">
            <span class="text-uppercase">{{shopEvent.data.name}}</span>
            <div v-if="headerDate" :class="[{'py-1': $vuetify.breakpoint.xsOnly}, 'font14 font-weight-regular']">
                {{headerDate}}<span v-if="shopEvent.data.location">, {{shopEvent.data.location}}</span>
            </div>
            <div class="font14 font-weight-regular">
                <div v-if="shopEvent.data.shop && shopEvent.data.shop.until">
                    {{$t('shopEvent.until')}} {{bookUntilDate}}
                </div>
                <div v-if="shopEvent.data.contactEmail || shopEvent.data.contactName">
                    {{$t('shopEvent.contact')}}: {{shopEvent.data.contactName}}<span
                    v-if="shopEvent.data.contactEmail && shopEvent.data.contactName">,
                </span> <a v-if="shopEvent.data.contactEmail" :href="'mailto:'+shopEvent.data.contactEmail"
                           class="white--text">{{shopEvent.data.contactEmail}}
                </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

    export default {
        name: "shop-event-header",
        props: ['shopEvent', 'organisation'],
        computed: {
            bannerStyle(){
                return this.shopEvent?.data.styles?.color ?
                    { backgroundColor: this.shopEvent.data.styles.color} :
                    this.organisation?.styles.color ? {
                    backgroundColor: this.organisation.styles.color
                } : { backgroundColor: '#000000' };
            },
            logoSrc(){
                return this.shopEvent.data.logo ? this.shopEvent.data.logo : this.organisation.logo
            },
            headerDate(){
                return this.shopEvent.data.displayInAdminCollection==='EVENTS' ?
                    TimeStamp.getTimeRangeFromTwoStringDates(this.shopEvent.data.from, this.shopEvent.data.until) : ''
            },
            bookUntilDate(){
               return this.shopEvent?.data.shop?.until ?
                   TimeStamp.getTimeRangeFromTwoStringDates(this.shopEvent.data.shop.until, this.shopEvent.data.shop.until) : ''
            },
            sportCornerTag(){ return this.shopEvent.data.sports && this.shopEvent.data.sports.length > 0 ?
                this.shopEvent.data.sports.length > 1 ? '' :
                    this.$store.state.sports?.[this.shopEvent.data.sports[0]].cornerTag
                :
                this.organisation?.sports.length === 1 ? this.$store.state.sports?.[this.organisation?.sports[0]].cornerTag :
                    '' },
            cardStyle(){
                return { backgroundColor: this.organisation?.styles?.color ?
                        this.organisation.styles.color : 'black',
                        borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}
            },
            textColor(){
                return !this.organisation.styles.color ? 'lightGrey--text' :
                    this.organisation.styles.dark ? 'lightGrey--text':'primary--text';
            },
        },
    }
</script>

<style scoped>
    .cardBanner{
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;
    }
    .bannerImg{ max-height: 44px }
    .cornerTag {position: absolute; top: 0; right: 0}
    .plusIcon { position: absolute; top: 2px; right: 0; }
    .rfaBaelle {
        position: absolute; top: 3px; right: 1px;
        -webkit-transform: rotate(255deg);
        -moz-transform: rotate(255deg);
        -o-transform: rotate(255deg);
        -ms-transform: rotate(255deg);
        transform: rotate(255deg);
    }
</style>