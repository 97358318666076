<template>
    <div class="bgdGreyLight pb-15 mb-10">
        <!--<v-btn @click="setSharding()">SET SHARDING</v-btn>-->
        <!--<test-voucher-redeem-verification/>-->
        <div class="text-center">
            <img src="@/assets/oym_logo-typo-thin_final.svg" class="mt-6" height="34"
                 alt="On your marks">
        </div>
        <div class="text-center pt-4">
            <profile-avatar :user="user"
                            :storage-source-path="storageSourcePath"
                            :config-prop="{size: 144, color: 'white'}">
            </profile-avatar>
            <div class="lineHeight-1-2 my-5">
                <div class="text-uppercase fontInter font-weight-light font28">
                    {{user.firstName}}
                </div>
                <div class="text-uppercase fontInter font-weight-black space1 font32">
                    {{user.lastName}}
                </div>
            </div>
        </div>
        <div class="text-center pa-3">
            <qr-code-display :qrValue="qrValue" :size="size">
            </qr-code-display>
        </div>
        <profile-user-bar class="hideForPrint"></profile-user-bar>
    <!-- firebase upload
        <firebase-upload v-if="user.uid==='WGIKH1pjccbUQmIRcVwkfZEr6Fu1'"></firebase-upload>-->
    </div>
</template>

<script>
    import ProfileAvatar from "../../utilities/components/images/profileAvatar";
    import ProfileUserBar from "../../components/profile/profileUserBar";
    import QrCodeDisplay from "../../utilities/components/qrCode/qrCodeDisplay";
    //import TestVoucherRedeemVerification from "@/testCloudFunction/voucherRedeemVerification/testVoucherRedeemVerification";
    export default {
        components: {
            //TestVoucherRedeemVerification,
            QrCodeDisplay,
            ProfileUserBar,
            ProfileAvatar,
            },
        name: "profile",
        computed: {
            user(){ return this.$store.getters.user },
            qrValue() {return 'uid:' + this.user.uid},
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
        },
        data: () => ({
            size: 220, //140
        }),
        /*created(){
            this.newUserRedirectToProfileEdit()
        },*/
        methods: {
            /*newUserRedirectToProfileEdit(){
                if ( this.$store.state.local.newUserRedirect &&
                     this.user && !this.user.image.url ) {
                    this.$router.push({path: 'profile', query: {isNewUser: true}});
                    this.$store.state.local.newUserRedirect = false;
                }
            },*/
            generatePDF(){
                window.print()
            },
            /*async setSharding(){
                const licenseList = await FirebaseRead.readDocList(
                    'licenses',
                    {field: 'search.organisation_nuXjFA2baIUUsb4jgHB7_event_2022',
                        operator: '==',
                        value: true}
                );
                console.log ("AAA", licenseList.length);
                for ( let i = 0; i < licenseList.length; i++ ) {
                    const licenseData = licenseList[i];
                    if ( licenseData.license.documents ) {
                        // build shard document
                        const licenseShard = {
                            assignedTo: licenseData.assignedTo,
                            search: licenseData.search,
                            license: {
                                documents: licenseData.license.documents,
                                title: licenseData.license.title,
                                subtitle: licenseData.license.subtitle,
                                type: licenseData.license.type,
                                id: licenseData.license.id,
                            },
                            usage: {
                                usersTotal: 0,
                                currentShard: 1,
                                multiSharding: false,
                            }
                        };
                        const reply1 = await FirebaseWrite.set('licenses_shards', licenseData.license.id, licenseShard);
                        console.log ("reply1", reply1);
                        // build first users document
                        const licenseUsers = {
                            assignedTo: licenseData.assignedTo,
                            search: licenseData.search,
                            license: {
                                documents: licenseData.license.documents,
                                title: licenseData.license.title,
                                subtitle: licenseData.license.subtitle,
                                type: licenseData.license.type,
                                id: licenseData.license.id,
                            },
                            users: {},
                        };
                        const reply2 = await FirebaseWrite.set(
                            'licenses_users',
                            licenseData.license.id + '_' + licenseShard.usage.currentShard,
                            licenseUsers);
                        console.log ("reply2", reply2);
                    }

                }
            }*/
        },
        watch: {
            user(to, from){
                if ( to && !from ) { this.newUserRedirectToProfileEdit() }
            }
        }
    }
</script>

<style scoped>

</style>