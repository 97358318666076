<template>
    <div>
        <profile-image v-if="user"
                       :user="user"
                       :storage-source-path="storageSourcePath"
                       :storage-folder="storageFolder"
                       :config-prop="configEl.profileImage"
                       :module-config="moduleConfig"
                       class="my-3">
        </profile-image>
        <slot></slot>
        <div class="mb-5"></div>
        <single-line-edit :data-field="email"
                          :data-document="user"
                          collection="users"
                          :changeUserEmail="true"
                          :config-prop="configEl.singleLineEdit">
        </single-line-edit>
        <v-divider class="mt-1 mb-5 mr-3"></v-divider>
        <user-profile-p-w-change>
        </user-profile-p-w-change>
        <v-divider class="mt-1 mb-5 mr-3">
        </v-divider>
        <div v-for="(field, i) in fieldList" :key="i">
            <single-line-edit :data-field="getFieldConfig(field)"
                              :data-document="user"
                              collection="users"
                              :config-prop="configEl.singleLineEdit"
                              :usageBlocked="field.blocked"
                              @blockedClick="blockedClick">
            </single-line-edit>
            <v-divider v-if="i<fieldList.length-1"
                       class="mt-1 mb-5 mr-3">
            </v-divider>
        </div>
    </div>
</template>

<script>
    // Version 1.1 2022-02-21, 2022-03-01, 2022-03-21
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import SingleLineEdit from "@/utilities/components/controls/singleLineEdit";
    import ProfileImage from "@/utilities/components/images/profileImage";
    import UserProfilePWChange from "@/utilities/components/userProfile/userProfilePWChange";

    export default {
        components: {
            UserProfilePWChange,
            ProfileImage,
            SingleLineEdit},
        name: "user-profile",
        props: {
            user: null,
            storageSourcePath: {default: ''},
            storageFolder: {default: ''},
            fieldList: [],
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // email field
            email: {
                fieldName: 'email',
                cols: {xs: 12, sm: 6},
                type: 'text',
                i18n: 'utilities.components.common.email',
                rules: [{ruleName: 'required',
                    messageText: 'utilities.services.formRules.pleaseEnter',
                    active: true},
                    {ruleName: 'email',
                    messageText: '',
                    active: true}],
                css: ''
            },
        // define the path where the component can be found in the directory
            path: 'utilities.components.userProfile.userProfile',
        // config
            configDefault: {
                profileImage: {
                    avatar: {
                        color: 'white'
                    }
                },
                singleLineEdit: {},
            },
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
            blockedClick(){
                this.$emit('blockedClick');
            },
            getFieldConfig(fieldRaw){
            //required to switch off the min age check for childs
                const field = JSON.parse(JSON.stringify(fieldRaw));
                if ( field.fieldName === 'birthday' && this.user.uid.length > 28 ) {
                    for ( let i = 0; i < field.rules.length; i++ ) {
                        const rule = field.rules[i];
                        if ( rule.ruleName === 'minAge' ) {
                            field.rules[i].active = false;
                        } else if ( rule.ruleName === 'maxAge' ) {
                            field.rules[i].active = true;
                        }
                    }
                }
                return field;
            }
        },
    }
</script>

<style scoped>

</style>