<template>
    <div>
        <toolbar :title="$t('messages.my')" :show-save="false"
                 :config-prop="{icon: 'mdi-message-bulleted'}"/>
        <alert-message :config-prop="{show: parkedMessages.length===0}" class="mx-3 mt-3 mb-0">
            {{$t('messages.noUnread')}}
        </alert-message>
        <div v-if="parkedMessages.length>0"
             class="font-weight-medium text-uppercase grey--text text--darken-2 space2 font14 pt-4 px-3">
            <span v-if="parkedMessages.length===1">{{$t('messages.unreadHeaderSingle')}}</span>
            <span v-else-if="parkedMessages.length>=1">{{$t('messages.unreadHeaderMultiple', {count: parkedMessages.length})}}</span>
        </div>
        <div v-for="(message, i) in parkedMessages" :key="i" class="mx-3">
            <messages-msg-short :displayed-message="message"/>
            <v-card-actions class="pb-0 pr-0 pt-1 x-3">
                <v-spacer/>
                <v-btn text @click="setRead(message.id)">
                    <v-icon class="mr-1">mdi-check</v-icon>{{$t('messages.setRead')}}
                </v-btn>
            </v-card-actions>
        </div>
        <messages-previous :user-id="userId"/>
    </div>
</template>

<script>
import Toolbar from "@/utilities/components/controls/toolbar";
import MessagesMsgShort from "@/components/messages/messagesMsgShort";
import store from "@/store/store";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
import AlertMessage from "@/utilities/components/displays/alertMessage";
import MessagesPrevious from "@/components/messages/messagesPrevious";
export default {
    name: "messages",
    components: {MessagesPrevious, AlertMessage, MessagesMsgShort, Toolbar},
    computed: {
        parkedMessagesRaw(){
            return this.$store.state.messages.parked
        },
        parkedMessages(){
            if ( !this.parkedMessagesRaw ) return null
            const msgs = []
            this.parkedMessagesRaw.forEach( (msg) => {
                const msgTemp = JSON.parse(JSON.stringify(msg))
                msgTemp.id = msg.id
                msgs.push(msgTemp)
            });
            return msgs.sort((b,a) => a.logData.changedAt > b.logData.changedAt ? 1 : -1)
        },
        user(){ return this.$store.getters.user},
        userId(){ return this.user.uid.substring(0,28)}
    },
    data: () => ({
        show: true
    }),
    created(){
        this.init()
    },
    methods: {
        init(){
            if ( this.parkedMessages === null ) { // also bound in message bar
                store.dispatch('bindRealtimeQueryDouble', {
                    collection: 'messages',
                    storeField: 'messages.parked',
                    isInRealtime: false,
                    params: [
                        {   field: 'receiver.receiverUserId',
                            operator: '==',
                            value: this.userId },
                        {   field: 'receiver.readTs',
                            operator: '==',
                            value: 0 },
                    ]
                })
            }
        },
        async setRead(msgId){
            const ts = TimeStamp.getGMTTimestamp()
            await FirebaseWrite.update('messages', msgId, {
                ['receiver.readTs']: ts
            })
        }
    },
    watch: {
        parkedMessagesRaw(){
            this.init()
        }
    }
}
</script>

<style scoped>

</style>