<template>
    <div></div>
</template>

<script>
export default {
    name: "home-third-party-data",
    computed: {
        user() { return this.$store.getters.user; },
    },
    created(){
        this.init()
    },
    methods: {
        async init(){
            if ( this.user ) this.initOeskb()
            /*await this.functionTest()
            this.getUserToken()*/
            /*
            https://europe-west3-on-your-marks-dev.cloudfunctions.net/date
            https://us-central1-on-your-marks-dev.cloudfunctions.net/date
             */
        },
        initOeskb(){
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            //myHeaders.append('Authorization', `Bearer ${idToken}`);
            /*fetch('https://europe-west3-' + projectId + '.cloudfunctions.net/' + functionName, {
                method: 'GET',
                headers: myHeaders,
            })*/
            const searchKey = this.user.firstName + '_' + this.user.lastName.toUpperCase() + '_' + this.user.birthday
            const path = this.$store.state.config.storage.profileImages.indexOf('dev')!==-1 ? 'test':'app'
            fetch('https://europe-west3-oeskb-'+path+'.cloudfunctions.net/getPassData?' +
                'searchKey=' + searchKey + '&email=' + this.user.email, {
                method: 'POST',
                headers: myHeaders,
            })
                .then((response) => {
                // response is a json string
                    //console.error('response: ', response);
                    return response.json();// convert it to a pure JavaScript object
                })
                .then((data) => {
                //Process Your data
                    //console.error('data: ', data);
                    this.$store.state.otherMemberships.oeskb = data
                })
                .catch( (err) => {
                    console.error('thirdPartyError: ', err);
                    this.$store.state.otherMemberships.oeskb = null
                });
        },
        /*
        getUserToken(){
            firebase.auth().currentUser.getIdToken(true).then((idToken) => {
                // Send token to your backend via HTTPS
                // ...

                console.error ("idToken", idToken);

                //const projectId = 'oeskb-app';
                //const collection = 'saisonen';
                //const documentId = '11_1_2021_2022';

                //const projectId = 'on-your-marks-dev';
                //const collection = 'version';
                //const documentId = 'version';


                const projectId = 'on-your-marks-dev';
                const collection = 'events';
                const documentId = '20230901_nuXjFA_043340';

                const myHeaders = new Headers();

                myHeaders.append('Content-Type', 'application/json');
                myHeaders.append('Authorization', `Bearer ${idToken}`);

                fetch('https://firestore.googleapis.com/v1/projects/' + projectId + '/databases/(default)/documents/' +
                             collection + '/' + documentId, {
                    method: 'GET',
                    headers: myHeaders,
                })
                    .then((response) => {
                        // response is a json string
                        return response.json();// convert it to a pure JavaScript object
                    })
                    .then((data) => {
                        //Process Your data
                        console.error(data);
                    })
                    .catch( (err) => {
                        console.log(err);
                    });

            }).catch(function(error) {
                // Handle error
                console.error ("error",error );
            });
        }
        */
    },
    watch: {
        user(){
            this.init()
        }
    }

}
</script>

<style scoped>

</style>