// Version 2.6 2022-03-21

import {i18n} from '@/plugins/i18n';
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
export class FormRules {

    constructor () {

        //password rules: minlength: 8, min 1 upperCase, min 1 lowerCase, min 1 digit,min 1 specialCase
        // ! * # , ; ? + - _ . = ~ ^ % ( ) { } [ ] | : " / < > $ § &
        this.checkPassWordRules = (value, messageText, active) => this.passWordRules(value) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.passwordRules')
        );

        //changed !!value -> (value === 0 || !!value) - interprets 0 otherwise as false
        this.required = (value, messageText, active) => (value === 0 || !!value) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.required')
        );
        this.requiredArray = (value, messageText, active) => (value?.length>0) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.required')
        );
        this.requiredNotZero = (value, messageText, active) => !!value || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.required')
        );
        this.numberGreaterZero = (value, messageText, active) => value => value > 0   || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.required')
        );
        this.numberGreaterOrEqualZero = (value, messageText, active) => value => value >= 0 || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.required')
        );

        //maxYear is optional, standard defined in isYearBetweenTodayUntil()
        this.existingDate = (value, messageText, active, maxYear) => this.isYearBetweenTodayUntil(value, maxYear) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.existingDate')
        );

        //this.email = value => this.verifyEmail(value) || 'enter valid email address';
        this.email = (value, messageText, active) => this.verifyEmail(value) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.email')
        );
        // DH 06.12.2021
        this.betweenMinMax = (value, active, min, max, messageText) => (min <= value && value <= max) || (
            !active ? true :
                messageText ? messageText : i18n.t('utilities.services.formRules.betweenMinMax', {min: min, max: max})
        );
        // RM 08.12.2021
        this.stringMinLength = (value, messageText, active, minLength) => v => (v && v.length >= minLength) || (
            !active ? true : messageText ? messageText : i18n.t('utilities.services.formRules.stringMinLength', {min: minLength})
        );
        // RM 4.1.2022
        this.isTrue = (value, messageText, active) => v => (v === true) || (
            !active ? true : messageText ? messageText : i18n.t('utilities.services.formRules.pleaseConfirm')
        );
        // RM 13.1.2022
        this.minAge = (value, messageText, active, dateString, minAge) => () => (TimeStamp.checkIfAgeIsReached(dateString, minAge)) || (
            !active ? true : messageText ?
                messageText : i18n.t('utilities.services.formRules.minAge', {minAge: minAge})
        );
        // RM 18.3.2022
        this.maxAge = (value, messageText, active, dateString, maxAge) => () => (TimeStamp.checkIfAgeIsExceeded(dateString, maxAge)) || (
            !active ? true : messageText ?
                messageText : i18n.t('utilities.services.formRules.maxAge', {maxAge: maxAge})
        );
        // RM 14.1.2022
        this.dateInFuture = (value, messageText, active, dateString) => () => (
            TimeStamp.dateInFuture(dateString)) || (
            !active ? true : messageText ?
                messageText : i18n.t('utilities.services.formRules.dateInFuture')
        );
    }

    passWordRules(str){
        // ! * # , ; ? + - _ . = ~ ^ % ( ) { } [ ] | : " / < > $ § &
        return (str && str.length >= 8
            && (/[a-z]+/).test(str)
            && (/[A-Z]+/).test(str)
            && (/\d+/).test(str)
            && (/[!*#,;+\\?\-_.<$>=~^%(§){&}[\]|:"/]+/).test(str))
    }

    //checks if Date is existing and between today and 31.12.2099
    //other plausibility tests are performed by v-text-field type=date
    isYearBetweenTodayUntil(date, maxYear=2100){
        console.log(date)
        if(!date)return false;
        const dateArr = date.split('-');
        const todayArr = new Date().toLocaleDateString("fr-CA").split('-');
        return !(dateArr === undefined
            || dateArr.length !== 3
            || dateArr[0] < todayArr[0]
            || dateArr[0] >= maxYear
            || dateArr[1] < 1
            || dateArr[1] > 12
            || dateArr[2] < 1
            || dateArr[2] > 31);
    }

    // RM 29.11.2021
    verifyEmail(value) {
        if ( !value ) { return false}
        let parts = value.split("@");
        if ( parts.length === 1 ) { return false }
        let subparts = parts[1].split(".");
        return subparts.length > 1 && subparts[1].length>1 ;
    }

// RM 11.12.2021 - required for dynamic forms
    getRulesArrayFromList(value, rulesList){
        // ruleItem in rulesList: ruleName, messageText, active, parameters
        let rulesArray = [];
        if ( rulesList && rulesList.length > 0 ) {
            rulesList.forEach( (ruleItem) => {
                if ( this.verifyRuleItem(ruleItem) ) {
                    switch (ruleItem.ruleName) {
                        case 'required':
                            rulesArray.push(
                                this.required(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active)
                            );
                            break;
                        case 'requiredArray':
                            rulesArray.push(
                                this.requiredArray(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active)
                            );
                            break;
                        case 'numberGreaterZero':
                            rulesArray.push(
                                this.numberGreaterZero(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active)
                            );
                            break;
                        case 'minAge':
                            rulesArray.push(
                                this.minAge(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active,
                                    value,
                                    ruleItem.minAge)
                            );
                            break;
                        case 'maxAge':
                            rulesArray.push(
                                this.maxAge(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active,
                                    value,
                                    ruleItem.maxAge)
                            );
                            break;
                        case 'dateInFuture':
                            rulesArray.push(
                                this.dateInFuture(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active,
                                    value)
                            );
                            break;
                        case 'email':
                            rulesArray.push(
                                this.email(value,
                                    i18n.t(ruleItem.messageText),
                                    ruleItem.active)
                            );
                            break;
                        default:
                            console.error ("Please define Switch-Case in " +
                                "function 'getRulesArrayFromList' for rule: ", ruleItem.ruleName);
                            break;
                    }
                }
            });
        }
        return rulesArray;
    }
        verifyRuleItem(ruleItem){
            let result = true;
            if ( ruleItem.ruleName === undefined ) {
                console.error ("Invalid rule item: ruleName undefined", ruleItem);
                result = false;
            }
            if ( ruleItem.messageText === undefined ) {
                console.error ("Invalid rule item: messageText undefined", ruleItem);
                result = false;
            }
            if ( ruleItem.active === undefined ) {
                console.error ("Invalid rule item: active undefined", ruleItem);
                result = false;
            }
            return result;
        }
}