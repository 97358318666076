
export const messages = {
    en: {
        newHeaderSingle: '1 new message',
        newHeaderMultiple: '{count} new messages',
        unreadHeaderSingle: '1 unread message',
        unreadHeaderMultiple: '{count} unread messages',
        notNow: 'not now',
        setRead: 'read',
        insteadOf: 'instead of',
        concerns: 'of',
        my: 'My messages',
        noUnread: 'You have no unread messages!',
        previous: 'Recently read messages',
        showPrevious: 'view more',
        showEarlier: 'view previous',
        received: 'received',
        received2: 'ago',
        receivedShort: 'Received',
        read: 'Read',
        subject: 'Subject',
        settings: 'Settings',
        all: 'all',
        previousUnread: 'previous unread',
        change: 'Change',
        changes: 'Changes',
        recipient: 'Recipient',
        recipients: 'Recipients',
        changed: 'Changed',
        message: 'Message',
        profileHeader: 'Messages & Emails',
    },
    de: {
        newHeaderSingle: '1 neue Nachricht',
        newHeaderMultiple: '{count} neue Nachrichten',
        unreadHeaderSingle: '1 ungelesene Nachricht',
        unreadHeaderMultiple: '{count} ungelesene Nachrichten',
        notNow: 'später',
        setRead: 'gelesen',
        insteadOf: 'statt',
        concerns: 'von',
        my: 'Meine Nachrichten',
        noUnread: 'Du hast keine ungelesenen Nachrichten!',
        previous: 'Zuletzt gelesene Nachrichten',
        showPrevious: 'mehr anzeigen',
        showEarlier: 'frühere anzeigen',
        received: 'empfangen vor',
        received2: '',
        receivedShort: 'Empfangen',
        read: 'Gelesen',
        subject: 'Betrifft',
        settings: 'Einstellungen',
        all: 'alle',
        previousUnread: 'ältere',
        change: 'Änderung',
        changes: 'Änderungen',
        recipient: 'Betrifft',
        recipients: 'Betrifft',
        changed: 'Geändert wurde',
        message: 'Nachricht',
        profileHeader: 'Nachrichten & Emails',
    }
}

