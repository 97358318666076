// Version 2.1 2022-01-18

import firebase from 'firebase/app'
//import 'firebase/app-check'
import 'firebase/firestore'

// Get a Firestore instance

export const db = firebase
    .initializeApp(
        {
            apiKey: "AIzaSyB2Gg8eL3nZp7I-qAQKIsP62tWfAG5_jRc",
            authDomain: "on-your-marks-prod.firebaseapp.com",
            databaseURL: "https://on-your-marks-prod.firebaseio.com",
            projectId: "on-your-marks-prod",
            storageBucket: "on-your-marks-prod.appspot.com",
            messagingSenderId: "724035444041",
            appId: "1:724035444041:web:f4e2a75321b3346e1d0e98"
        }
    )
    .firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore;
export { TimeStamp, GeoPoint }

// appcheck for production
/*const appCheck = firebase.appCheck();
appCheck.activate(
    new firebase.appCheck.ReCaptchaEnterpriseProvider(
        // reCAPTCHA Enterprise site key
        '6LeTYx4eAAAAAB_Mz0hF_7SxIzGb6IgXDTnON8hW'
    ),
    true // Set to true to allow auto-refresh.
);*/
// OUTDATED IN VERSION 8.10 https://github.com/firebase/firebase-js-sdk/issues/5806

export const firestore = firebase.firestore;

/*
DEV
    apiKey: "AIzaSyBYT9xONMQsAQ0PR6PV93-J-gOnyC0sF5s",
    authDomain: "on-your-marks-dev.firebaseapp.com",
    databaseURL: "https://on-your-marks-dev.firebaseio.com",
    projectId: "on-your-marks-dev",
    storageBucket: "on-your-marks-dev.appspot.com",
    messagingSenderId: "257090785759",
    appId: "1:257090785759:web:e937b881754b8253abb46a"
PROD
    apiKey: "AIzaSyB2Gg8eL3nZp7I-qAQKIsP62tWfAG5_jRc",
    authDomain: "on-your-marks-prod.firebaseapp.com",
    databaseURL: "https://on-your-marks-prod.firebaseio.com",
    projectId: "on-your-marks-prod",
    storageBucket: "on-your-marks-prod.appspot.com",
    messagingSenderId: "724035444041",
    appId: "1:724035444041:web:f4e2a75321b3346e1d0e98"
 */