<template>
    <div>
        <v-row @click="confirmChangeLinkRequest()" no-gutters
               align="center" justify="center">
            <v-col class="grow">
                <div class="grey--text text--darken-1 caption">
                    <span v-if="configEl.labelUppercase">{{label.toUpperCase()}}</span>
                    <span v-else>{{label}}</span>
                </div>
                <div>
                    ********
                </div>
            </v-col>
            <v-col class="shrink pr-4 pl-3">
                <v-icon>
                    mdi-pencil</v-icon>
            </v-col>
        </v-row>
    <!-- confirm dialog -->
        <confirm-dialog :dialog-data="dialogData" :config-prop="dialogConfig"
                        @reply="sendChangeLink">
            {{$t('utilities.components.auth.changePW')}}
        </confirm-dialog>
    </div>
</template>

<script>
    //Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import ConfirmDialog from "../dialogs/confirmDialog";
    export default {
        components: {ConfirmDialog},
        name: "user-profile-p-w-change",
        props: {
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            dialogData: {
                open: false,
                query: {
                    parameter: 'changePW',
                    value: true,
                },
            },
            dialogConfig: {
                title: {
                    i18n: 'utilities.components.auth.changePWTitle',
                },
            },
        // define the path where the component can be found in the directory
            path: 'utilities.components.userProfile.userProfilePWChange',
        // config
            configDefault: {
                labelUppercase: true,
                label: {
                    i18n: 'utilities.components.auth.password',
                }
            },
        }),
        computed: {
            user(){ return this.$store.getters.user; },
            loginService(){ return this.$store.state.loginService },
            label(){ return this.translate(this.configEl.label.i18n)},
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
            confirmChangeLinkRequest(){
                this.dialogData.open = true;
            },
            sendChangeLink(reply){
                this.dialogData.open = false;
                if ( reply ){
                    this.loginService.sendPasswordMail(this.user.email);
                    this.loginService.logout();
                    this.$router.push('/');
                }
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>