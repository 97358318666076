// Version 1.4 2022-04-12 - DH

export default class ObjectHandlingService
{
    static setFromDataObjectByKey(targetObject, dataObject) {
        if ( targetObject ) {
            Object.keys(targetObject).forEach((key) => {
                if (key !== 'local') {
                    if (dataObject[key] !== undefined ) {
                        if ( dataObject[key] === null ) {
                            // empty (null)
                            targetObject[key] = dataObject[key];
                        } else if ( Array.isArray(dataObject[key])  ) {
                            // array
                            targetObject[key] = dataObject[key];
                        } else if ( typeof dataObject[key] === 'object' ) {
                            // object
                            ObjectHandlingService.setFromDataObjectByKey(targetObject[key], dataObject[key]);
                        } else {
                            // other
                            targetObject[key] = dataObject[key];
                        }
                    }
                }
            });
        }
    }

    static getListFromObject(dataObject){
        if ( dataObject === null ) { return [] }
        const list = [];
        Object.keys(dataObject).forEach( (key) => {
            if ( dataObject[key] ) {
                let subObject = dataObject[key];
                subObject.id = key;
                list.push(subObject)
            }
        });
        return list;
    }

    static getObjFromArrWithObjects(arr, key){
        let obj = {}
        arr.forEach(e => {
            obj[e[key]] = e
        })
        return obj
    }

}