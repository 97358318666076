<template>
    <div class="mb-10">
        <divider-with-text class="mb-1 mt-3">{{$t('profile.otherPersons')}}</divider-with-text>
        <p v-if="showNewUserText">{{$t('profile.addPersonInfo')}}</p>
        <div v-else-if="showUserList" class="mb-3">
            <p>{{$t('profile.changePersonInfo')}}</p>
            <v-list-item v-for="(childUser, i) in userList" :key="i"
                         :class="[{'bgdGreyMedium borderRadius': childUser.uid === user.uid},
                                   'px-4']">
                <v-list-item-icon class="px-0 text-center"
                                  @click="change(childUser.uid)">
                    <profile-user-avatar :child-user="childUser"
                                         :size="44">
                    </profile-user-avatar>
                </v-list-item-icon>
                <v-list-item-content @click="change(childUser.uid)"
                                     :class="{'font-weight-bold': userIsMaster(childUser)}">
                    <v-list-item-subtitle>
                        {{childUser.firstName}}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                        {{childUser.lastName}}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="childUser.uid!==userMaster.uid">
                    <v-btn icon @click="confirmDelete(childUser.uid)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </div>
        <btn-text @click="addUser()"
                  :config-prop="{icon: 'mdi-plus'}">
            {{$t('profile.addPerson')}}
        </btn-text>
    <!-- confirm dialog -->
        <confirm-dialog :dialog-data="dialogData"
                        config-package="delete"
                        :config-prop="dialogConfig"
                        @reply="processDelete">
            <span></span>
            {{$t(dialogData.text)}}
        </confirm-dialog>
    <!-- delete snackbars -->
        <v-snackbar v-model="deleteSnackSuccess"
                    color="success" top>
            {{$t('profile.deleteChildSuccess')}}
        </v-snackbar>
        <v-snackbar v-model="deleteSnackError"
                    color="error" top>
            {{$t('profile.deleteChildError')}}
        </v-snackbar>
    </div>
</template>

<script>
    import DividerWithText from "@/utilities/components/displays/dividerWithText";
    import BtnText from "@/utilities/components/controls/btnText";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService';
    import ProfileUserAvatar from "@/components/profile/profileUserAvatar";
    import ConfirmDialog from "@/utilities/components/dialogs/confirmDialog";
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
    import FirebaseStorage from "@/utilities/services/firebase/firebaseStorage";
    import FirebaseRead from "@/utilities/services/firebase/firebaseRead";

    export default {
        components: {
            ConfirmDialog,
            ProfileUserAvatar,
            BtnText,
            DividerWithText},
        name: "profile-multi-user",
        data: () => ({
            dialogData: {
                open: false,
                query: {
                    parameter: 'deleteChildUser',
                    value: true,
                },
                text: '',
                package: '',
            },
            dialogConfig: {
                title: {
                    i18n: ''
                },
                confirmBtn: {},
                cancelBtn: {
                    configProp: {},
                }
            },
            deleteSnackSuccess: false,
            deleteSnackError: false,
        }),
        computed: {
            user(){ return this.$store.getters.user },
            userMaster(){ return this.$store.state.realtime.userMaster },
            userChilds(){ return this.$store.state.realtime.userChilds },
            userChildList() {
                return ObjectHandlingService.getListFromObject(
                    JSON.parse(JSON.stringify(this.userChilds)));
            },
            userList(){ return this.userChilds?
                [this.userMaster].concat(this.userChildList) :
                [[this.userMaster] ];
            },
            showUserList(){
                return this.userMaster ||
                    (this.user && this.user.uid.length>28);
            },
            showNewUserText(){
                return this.user.childs.length===0 &&
                       this.user.uid.length===28
            },
        },
        methods: {
            userIsMaster(childUser){
                return childUser.uid === this.userMaster.uid
            },
            addUser(){
                this.$router.push('addUser');
            },
            change(uid){
                if ( uid === this.user.uid ) { return }
                this.$store.dispatch('changeUser', {uid: uid });
            },
            async confirmDelete(uid){
                const userLicenses = await FirebaseRead.readSingleDoc('user_licenses', uid);
                console.log ("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", userLicenses);
                if ( Object.keys(userLicenses.licenses).length > 0 ) {
                    this.dialogConfig.title.i18n = this.userChilds[uid].firstName + ' ' +
                        this.userChilds[uid].lastName + ' ' +
                        this.$i18n.t('profile.noDeleteChildHeader');
                    this.dialogData.text = 'profile.noDeleteChildContent';
                    this.dialogConfig.confirmBtn.hide = true;
                    this.dialogConfig.cancelBtn.configPackage = 'ok';
                } else {
                    this.dialogConfig.title.i18n = this.userChilds[uid].firstName + ' ' +
                        this.userChilds[uid].lastName + ' ' +
                        this.$i18n.t('profile.deleteChildHeader');
                    this.dialogData.text = 'profile.deleteChildContent';
                    this.dialogConfig.confirmBtn.hide = false;
                    this.dialogConfig.cancelBtn.configPackage = 'cancel';
                }
                this.dialogData.open = true;
                this.dialogData.query.value = uid;
            },
            async processDelete(reply){
                this.dialogData.open = false;
                const uid = this.dialogData.query.value;
                if ( reply ) {
                // change to master user if necessary !!!
                    if ( this.userMaster.uid !== this.user.uid ) {
                        this.$store.dispatch('changeUser', {
                            uid: this.userMaster.uid,
                            noOverlay: true,
                        });
                    }
                // delete images
                    if ( this.userChilds[uid].image.url ) {
                        await FirebaseStorage.deleteFile(
                            '/profileImages/' + uid + '.jpeg');
                        await FirebaseStorage.deleteFile(
                            '/profileImages/' + uid + 'Min.jpeg');
                    }
                // set list of documents to be deleted
                    let deleteDocuments = [
                        {action: 'delete', collection: 'users', documentId: uid},
                        {action: 'delete', collection: 'parentConfirms', documentId: uid},
                        {action: 'delete', collection: 'user_events', documentId: uid},
                        {action: 'delete', collection: 'user_passes', documentId: uid},
                        {action: 'delete', collection: 'user_permissions', documentId: uid},
                        {action: 'delete', collection: 'user_vouchers', documentId: uid},
                        {action: 'delete', collection: 'users', documentId: uid},
                        {action: 'update', collection: 'users', documentId: this.userMaster.uid,
                            data: {childs: this.getNewChildList(uid)}}
                    ];
                // add all attached user licenses
                    const userLicenses = await FirebaseRead.readDocList(
                        'user_licenses',
                        {   field: 'search.userId',
                            operator: '==',
                            value: uid }
                    );
                    if ( userLicenses ) {
                        userLicenses.forEach( (userLicenseDoc) => {
                            deleteDocuments.push({
                                action: 'delete', collection: 'user_licenses',
                                documentId: userLicenseDoc.id
                            })
                        });
                    }
                // delete user docs
                    const reply = await FirebaseWrite.batch(deleteDocuments);
                    if ( reply.successful ) {
                    // delete key in user childs
                        delete this.$store.state.realtime.userChilds[uid];
                    // unbind master if no childs
                        if ( this.userMaster.childs.length === 0 ) {
                            this.$store.dispatch('unbindRealtimeProperty', 'userMaster');
                        }
                    // output snack
                        this.deleteSnackSuccess = true;
                    } else {
                        this.deleteSnackError = true;
                    }
                    console.log ("reply", reply);
                }
            },
            getNewChildList(uid){
                const id = parseInt(uid.substring(29));
                for ( let i = 0; i < this.userMaster.childs.length; i++ ) {
                    if ( this.userMaster.childs[i] === id ) {
                        this.userMaster.childs.splice(i, 1);
                        break;
                    }
                }
                return this.userMaster.childs;
            }
        },
    }
</script>

<style scoped>
    .borderRadius{border-radius: 6px}
</style>