<template>
    <v-dialog v-model="dialog" persistent :max-width="configEl.width">
        <v-card :class="configEl.card.css" :dark="configEl.card.dark">
        <!-- Header -->
            <v-card-title v-if="configEl.title.i18n"
                          :class="[configEl.title.css,
                                   configEl.title.color,
                                   'pb-1']">
                {{translate(configEl.title.i18n)}}
            </v-card-title>
        <!-- Text -->
            <v-card-text :class="['pt-4 pb-3']">
                <slot></slot>
            </v-card-text>
        <!-- Schließen -->
            <v-card-actions  v-if="!configEl.hideActions" class="text-right">
                <v-spacer v-if="configEl.confirmBtn.hide"></v-spacer>
                <btn-text :config-package="configEl.cancelBtn.configPackage"
                          :config-prop="configEl.cancelBtn.configProp"
                          v-if="!configEl.cancelBtn.hide"
                          @click="reply(false)">
                </btn-text>
                <v-spacer v-if="!configEl.confirmBtn.hide"></v-spacer>
                <btn-text :config-package="configEl.confirmBtn.configPackage"
                          :config-prop="configEl.confirmBtn.configProp"
                          v-if="!configEl.confirmBtn.hide"
                          @click="reply(true)">
                </btn-text>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    // Version 2.2 2022-02-16, 2022-03-03

    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import BtnText from "@/utilities/components/controls/btnText";
    export default {
        components: {BtnText},
        name: "confirm-dialog",
        props: {
            configPackage: {default: 'default'},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        // data needed for business - always required!!!
            dialogData: {
                default() {
                    return {
                        open: false,
                        query: { parameter: '', value: null, disabled: false},
                        content: { title: ''}
                    }
                }
            },
        },
        data: () => ({
            dialog: false,
            selected: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.dialogs.confirmDialog',
        // define here, which parameter should be customizeable
            configDefault: { // = yesNo
                default: {
                    card: {
                        css: '',
                        dark: false,
                    },
                    title: {
                        css: 'title font-weight-regular',
                        color: '',
                        i18n: '',
                    },
                    confirmBtn: {
                        configPackage: 'yes',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                        },
                    },
                    cancelBtn: {
                        configPackage: 'no',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                            color: 'grey',
                        },
                    },
                    width: 340,
                    hideActions: false,
                },
                delete: {
                    card: {
                        css: '',
                        dark: false,
                    },
                    title: {
                        css: 'title font-weight-regular',
                        color: 'error--text',
                        i18n: 'utilities.components.common.delete',
                    },
                    confirmBtn: {
                        configPackage: 'delete',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                        },
                    },
                    cancelBtn: {
                        configPackage: 'cancel',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                            color: 'grey',
                        },
                    },
                    width: 340,
                    hideActions: false,
                },
                confirm: {
                    card: {
                        css: '',
                        dark: false,
                    },
                    title: {
                        css: 'title font-weight-regular',
                        color: '',
                        i18n: 'utilities.components.common.confirm',
                    },
                    confirmBtn: {
                        configPackage: 'confirm',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                        },
                    },
                    cancelBtn: {
                        configPackage: 'cancel',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                            color: 'grey',
                        },
                    },
                    width: 340,
                    hideActions: false,
                },
                save: {
                    card: {
                        css: '',
                        dark: false,
                    },
                    title: {
                        css: 'title font-weight-regular',
                        color: '',
                        i18n: 'utilities.components.common.save',
                    },
                    confirmBtn: {
                        configPackage: 'save',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                        },
                    },
                    cancelBtn: {
                        configPackage: 'cancel',
                        configProp: {
                            text: true,
                            elevation: 0,
                            rounded: false,
                            outlined: false,
                            tile: false,
                            icon: '',
                            color: 'grey',
                        },
                    },
                    width: 340,
                    hideActions: false,
                },
            },
        }),
        computed: {
        // always necessary
            configEl(){
                const configCustom = this.moduleConfig ?
                                     this.configProp :
                                     {[this.configPackage]: this.configProp}; // user passes props without package
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    configCustom,
                    this.moduleConfig,
                    this.path,
                )[this.configPackage];
            },
        // component specific (business)
            query () {return this.$route.query[this.dialogData.query.parameter] },
        },
        created () {
            if ( this.$route.query[this.dialogData.query.parameter] ) {
                window.history.back(); // close dialog on reload
            }
        },
        methods: {
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        // component specific (business)
            openDialog () {
                console.log ("METHOD: confirm dialog / openDialog",  this.dialogData);
                this.dialog = true;
                const query = this.dialogData.query;
                if ( (query.disabled === undefined ||
                      query.disabled === false) &&
                      this.$route.query[query.parameter] !==
                     query.value
                ) {
                    let p = JSON.parse(JSON.stringify(this.$route.path));
                    let q = JSON.parse(JSON.stringify(this.$route.query));
                    q[this.dialogData.query.parameter] = query.value ?
                                             query.value : true;
                    this.$router.push({path: p, query: q});
                }
            },
            reply(selection){
                this.selected = selection;
                if ( this.dialogData.query.disabled ) {
                    this.$emit("reply", this.selected);
                } else {
                    window.history.back();
                    // triggers watcher on query => calling component sets open to false
                    // => watcher on open closes the dialog
                }
            },
        },
        watch: {
            query (to, from) {
            // react to browser back
                if ( to === undefined && from ) {
                    console.log ('WATCH: confirm dialog / query => browser back');
                    this.$emit("reply", this.selected);
                }
            },
            'dialogData.open' (to) {
                console.log ('WATCH: confirm dialog',
                              JSON.stringify(this.dialogData.query),
                              ' openStatus: ', to);
                if ( to ) {
                    this.openDialog();
                } else {
                    this.dialog = false;
                }
            }
        }
    }
</script>

<style scoped>
    .v-card__text, .v-card__title {
        word-break: normal; /* maybe !important  */
    }
</style>