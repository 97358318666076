<template>
    <v-row :class="configEl.row.css"
            align="center" justify="center" class="text-center" no-gutters>
        <v-col>
            <v-divider></v-divider>
        </v-col>
        <v-col :class="[configEl.text.css, 'flex-grow-0 text-center px-5']"
               style="white-space: nowrap;">
            <slot></slot>
        </v-col>
        <v-col>
            <v-divider></v-divider>
        </v-col>
    </v-row>
</template>

<script>
    // Version 1.1 2022-01-18

    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "divider-with-text",
        props: {
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.displays.dividerWithText',
        // define here, which parameter should be customizeable
            configDefault: {
                row: {
                    css: ''
                },
                text: {
                    css: 'text-overline'
                },
            }
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
    }
</script>
