<template>
    <div :class="content.css">
        <stripe-checkout :config="config"
                         :stripeData="stripeData"
                         :purchase-order="purchaseOrder"
                         @paymentStarted="paymentStarted"
                         @isCancelled="isCancelled"
                         @checkoutCreationFailed="checkoutCreationFailed"
                         @isPaid="isPaid">
        </stripe-checkout>
    </div>
</template>

<script>

// i18n class
    import {locales} from '@/modules/stripe/plugins/i18n/locales'
// config service
    import ConfigService from '@/modules/_global/services/configService.js';
// config classes
    import {ConfigDefaultStripe} from "@/modules/stripe/config/configDefaultStripe";
    import {ConfigStripe} from "@/config/modules/stripe/configStripe";
    import StripeCheckout from "@/modules/stripe/components/stripeCheckout";

export default {
    components: {
        StripeCheckout,
    },
    name: "stripe-interface-checkout",
    i18n: locales,
    props: {
        componentCustomization: {default: null},
        stripeData: {
            default() {
                return {
                    companyId: '',
                    stripeAccountId: '',
                    uid: '',
                }
            }
        },
        purchaseOrder: {default: null}
    },
    data: () => ({
        config: null,
    }),
    computed: {
        content(){ return this.config.content.stripe}
    },
    created(){
        this.init();
    },
    methods: {
        init(){
            this.initConfig();
            this.initData();
        },
        initConfig(){
        // get default module configuration
            this.config = new ConfigDefaultStripe();
        // customize default module configuration
            const moduleCustomization = new ConfigStripe();
            this.config = ConfigService.applyCustomization(
                this.config,
                moduleCustomization,
                this.componentCustomization,
                locales
            );
        },
        initData(){
            //
        },
        paymentStarted(){
            this.$emit('paymentStarted');
        },
        isCancelled(){
            this.$emit('isCancelled');
        },
        checkoutCreationFailed(){
            this.$emit('checkoutCreationFailed');
        },
        isPaid(purchaseOrderId){
            this.$emit('isPaid', purchaseOrderId);
        }
    },
}
</script>

<style scoped>

</style>