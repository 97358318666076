<template>
    <div v-if="buyingUsers && buyingUsers.length>0" class="px-4 pb-3">
        <v-divider class="mb-3 mt-0"></v-divider>
        <alert-message :config-prop="{show: !shoppingCartVouchers.allPossibleVouchersSelectedForEvent(eventId),
                                      color: 'success', css: 'mb-2 py-3' }">
            {{$t('shopVoucher.voucherRedeem')}}
        </alert-message>
        <div v-for="(buyingUser, i) in buyingUsers" :key="i" >
            <v-row no-gutters align="center" v-if="getUser(buyingUser.userId)">
                <v-col class="shrink pr-3 py-1">
                    <profile-avatar :user="getUser(buyingUser.userId)"
                                    :storage-source-path="storageSourcePath"
                                    :config-prop="{size: 32}">
                    </profile-avatar>
                </v-col>
                <v-col class="shrink pr-3 py-0">
                    {{getUser(buyingUser.userId).firstName}}
                </v-col>
            </v-row>
            <div v-if="buyingUser.voucherUsedOnPreviousPurchase>0">
                <alert-message :config-prop="{show: true}" class="body-2">
                    {{$t('shopVoucher.redeemedPreviously')}}
                </alert-message>
            </div>
            <div v-else>
                <div v-for="(voucher, i) in buyingUser.voucherList" :key="i">
                    <v-row v-if="!voucher.redeemDocumentId"
                           no-gutters align="center" class="mt-1 mb-3">
                        <v-col class="grow">
                            <shop-item-vouchers-card :voucher="voucher.voucher"/>
                        </v-col>
                        <v-col class="shrink">
                            <shop-cart-event-vouchers-check v-model="voucher.selected"
                                                            :disabled="shoppingCart.purchaseOrder!==null"
                                                            @input="shoppingCartVouchers.voucherClicked(buyingUser, voucher, eventId)"/>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileAvatar from "@/utilities/components/images/profileAvatar";
import ShopItemVouchersCard from "@/components/shop/shopItem/shopItemVouchersCard";
import ShopCartEventVouchersCheck from "@/components/shop/shopCart/shopCartEventVouchersCheck";
import AlertMessage from "@/utilities/components/displays/alertMessage";
export default {
    name: "shop-cart-event-vouchers",
    components: {AlertMessage, ShopCartEventVouchersCheck, ShopItemVouchersCard, ProfileAvatar},
    props: ['eventId', 'shoppingCart'],
    computed: {
        shoppingCartVouchers(){ return this.shoppingCart.shoppingCartVouchers},
        eventVouchers(){ return this.shoppingCartVouchers.events[this.eventId]},
        storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
    },
    data: () => ({
        buyingUsers: [],
    }),
    created(){
        this.init();
    },
    methods: {
        init(){
            if ( this.shoppingCartVouchers.loading === false ) {
                if ( this.shoppingCartVouchers.events[this.eventId] )
                    this.buyingUsers = this.shoppingCartVouchers.events[this.eventId].buyingUsers;
            }
        },
        getUser(userId){
            return userId.length>28 ? this.$store.state.realtime.userChilds[userId] :
                this.$store.getters.user.uid.length > 28 ? this.$store.state.realtime.userMaster : this.$store.getters.user
        },
    },
    watch: {
        'shoppingCartVouchers.loading'(){
            this.init();
        }
    }
}
</script>

<style scoped>

</style>