// Version 2.2 2021-11-30

import {i18n} from '@/plugins/i18n';
import {ConfigUtilities} from "@/config/utilities/config_utilities";

export default class ComponentConfigService {

// get config for utilities component
    static getElementConfig(configDefault, configProp, moduleConfig, path) {
        const configModule = ComponentConfigService.getModuleConfigForElement(moduleConfig, path);
        return ComponentConfigService.getElementConfigFromProp(configDefault, configProp, configModule, path)
    }

        static getModuleConfigForElement(moduleConfig, path){
            if ( moduleConfig ) {
                const pathKeys = path.split('.');
                let configModule = moduleConfig;
                for ( let i = 0; i < pathKeys.length; i++ ) {
                    configModule = configModule[pathKeys[i]];
                    if ( configModule === undefined ) {
                        return {}
                    }
                }
                return configModule;
            } else {
                return null;
            }
        }

        static getElementConfigFromProp(configDefault, configProp, configModule, path){
            let config = JSON.parse(JSON.stringify(configDefault));
        // apply global utilities config
            const utilitiesConfig = new ConfigUtilities();
            const globalUtilitiesConfig = ComponentConfigService.getDataFromPath(utilitiesConfig, path);
            if ( globalUtilitiesConfig ) {
                config = ComponentConfigService.overwriteWithSpecialConfig(config, globalUtilitiesConfig);
            }
        // apply props module global (set in module/defaultConfig/utilities)
            config = ComponentConfigService.overwriteWithSpecialConfig(config, configModule);
        // apply props config
            config = ComponentConfigService.overwriteWithSpecialConfig(config, configProp);
            return config;
        }
            static getDataFromPath(object, path){
                const pathArr = path.split('.');
                let data = object;
                for ( let i = 0; i < pathArr.length; i++ ) {
                    data = data[pathArr[i]];
                    if ( data === undefined ) { return null }
                }
                return data;
            }

            static overwriteWithSpecialConfig(config, configSpecial){
                if ( configSpecial !== undefined && configSpecial !== null ) {
                    Object.keys(configSpecial).forEach( (key) => {
                        if ( typeof configSpecial[key] !== 'object' ) {
                            config[key] = configSpecial[key];
                        } else{
                            if ( config[key] === undefined ) { config[key] = {} }
                            config[key] = ComponentConfigService.overwriteWithSpecialConfig (config[key],
                                configSpecial[key])
                        }
                    });
                }
                return config;
            }

    static translateElementText(i18nKey, configProp, moduleConfig){
    // step 1: try to translate global i18n
        let text = i18n.t(i18nKey);
        if ( text !== i18nKey ) { return text }
    // step 2: key not found in global, search in module
        if ( moduleConfig ) {
            let keyPath = i18nKey.indexOf('.')!== -1 ? i18nKey.split('.') : [i18nKey];
            if ( i18nKey.indexOf('customized') === 0 ) {
                keyPath.splice(0,2); // remove 'customized' and 'instanceName'
                keyPath.push('i18n');
                let translation = moduleConfig.localesCustomization[i18n.locale];
                return ComponentConfigService.translateLoopKeys(keyPath, translation, i18nKey);
            } else {
                let translation = moduleConfig.locales[i18n.locale];
                return ComponentConfigService.translateLoopKeys(keyPath, translation, i18nKey);
            }
        } else {
            return i18nKey;
        }
    }
        static translateLoopKeys(keyPath, translation, i18nKey){
            for ( let i = 0; i < keyPath.length; i++ ) {
                translation = translation[keyPath[i]];
                if ( translation === undefined ) { return i18nKey }
            }
            return translation;
        }

// get array from config parameter
    static getArrayFromConfigParameter(configParameter){
        let list = [];
        Object.keys(configParameter).forEach( (key) => {
            let obj = configParameter[key];
            obj.fieldName = key;
            list.push(obj)
        });
        return list;
    }

}