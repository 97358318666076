<template>
    <div class="bgdGreyLight">
    <!-- title -->
        <v-expand-transition >
            <v-row v-show="showHeader()" no-gutters
                   align="center" justify="center"
                   class="px-4 mt-4">
                <v-col v-if="showTitle" class="shrink">
                    <v-icon class="mr-1">mdi-wallet-outline</v-icon>
                </v-col>
                <v-col v-if="showTitle"
                       class="grow py-0 fontInter text-no-wrap
                            font-italic font-weight-thin font24">
                    WALLET<span v-if="showArchive"> - {{$t('wallet.archive')}}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="shrink text-center">
                    <profile-user-speed-dial @usermenu="setShowTitle">
                    </profile-user-speed-dial>
                </v-col>
            </v-row>
        </v-expand-transition>
    <!-- archive switch -->
        <btn-text v-if="!walletZoomed.documentId && !empty && showArchive" @click="handleShowArchive()" class="px-4 pt-1"
                  :config-prop="{icon: showArchive?'mdi-arrow-left':'mdi-arrow-right',
                                  color: 'secondary', flat: true}">
            {{$t(showArchive?'wallet.returnFromArchive':'wallet.gotoArchive')}}
        </btn-text>
    <!-- empty wallet -->
        <alert-message class="mx-4 mt-3 mb-0"
                config-package="progress"
                :config-prop="{show: empty || (currentlyDisplayed===0 && !this.otherMemberships), transition: ''}">
            {{$t(showArchive?'wallet.emptyArchive':'wallet.empty')}}
            <!--<div v-if="creatingDocuments">
                {{$t('wallet.creatingDocuments')}}
                <v-progress-linear indeterminate
                                   color="primary"
                                   class="mt-3">
                </v-progress-linear>
            </div>
            <div v-else>{{$t('wallet.empty')}}</div>-->
        </alert-message>
    <!-- third party -->
        <wallet-other/>
    <!-- passes -->
        <v-expand-transition>
            <wallet-passes v-show="showPass('passes')" @noOfDisplayedCards="setNoOfDisplayedCards"
                           :show-archive="showArchive" :today-string="todayString">
            </wallet-passes>
        </v-expand-transition>
    <!-- events -->
        <v-expand-transition>
            <wallet-events v-show="showPass('documents')" @noOfDisplayedCards="setNoOfDisplayedCards"
                           :show-archive="showArchive" :today-string="todayString">
            </wallet-events>
        </v-expand-transition>
    <!-- vouchers -->
        <v-expand-transition>
            <wallet-vouchers v-show="showPass('vouchers')" :archive="false"
                             @noOfDisplayedCards="setNoOfDisplayedCards"
                             :show-archive="showArchive" :today-string="todayString">
            </wallet-vouchers>
        </v-expand-transition>
    <!-- vouchers redeemed -->
        <v-expand-transition>
            <wallet-vouchers v-show="showPass('vouchersArchive')" :redeemed="true"
                             @noOfDisplayedCards="setNoOfDisplayedCards"
                             :show-archive="showArchive" :today-string="todayString">
            </wallet-vouchers>
        </v-expand-transition>
    <!-- archive switch -->
        <btn-text v-if="!walletZoomed.documentId && !empty && !showArchive" @click="handleShowArchive()"
                  class="px-4 pt-1 pb-15"
                  :config-prop="{icon: showArchive?'mdi-arrow-left':'mdi-arrow-right',
                                  color: 'secondary', flat: true}">
            {{$t(showArchive?'wallet.returnFromArchive':'wallet.gotoArchive')}}
        </btn-text>
    <!-- elements for zoomed display -->
        <div v-if="walletZoomed.section">
        <!-- backbutton -->
            <fab-btn-back></fab-btn-back>
        </div>
    </div>
</template>

<script>
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'
    import WalletVouchers from "@/components/wallet/walletVouchers";
    import WalletEvents from "@/components/wallet/walletEvents";
    import WalletPasses from "@/components/wallet/walletPasses";
    import FabBtnBack from "@/utilities/components/controls/fabBtnBack";
    import AlertMessage from "../../utilities/components/displays/alertMessage";
    import ProfileUserSpeedDial from "../../components/profile/profileUserSpeedDial";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import BtnText from "@/utilities/components/controls/btnText";
    import RouterServices from "@/utilities/services/router/routerServices";
    import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
    import {StripeRefresh} from "@/modules/stripe/models/data/stripeRefresh";
    import WalletOther from "@/components/wallet/walletOther";

    export default {
        components: {
            WalletOther,
            BtnText,
            ProfileUserSpeedDial,
            AlertMessage,
            FabBtnBack, WalletPasses, WalletEvents, WalletVouchers },
        name: "wallet",
        data: () => ({
            showTitle: true,
            showArchive: false,
            todayString: '',
            currentlyDisplayed: 0,
            blockClear: false,
        }),
        computed: {
            user() { return this.$store.getters.user; },
            empty(){
                return !this.otherMemberships &&
                        !this.$store.state.realtime.userPasses &&
                       !this.$store.state.realtime.userVouchers &&
                        (!this.$store.state.realtime.userLicenses ||
                        (this.$store.state.realtime.userLicenses &&
                         Object.keys(this.$store.state.realtime.userLicenses.documents).length===0
                        ))
            },
            /*creatingDocuments(){
                return this.user && this.user.cloudFunctionInProgress;
            },*/
            walletZoomed(){ return this.$store.state.wallet.zoomed;},
            walletArchive(){ return this.$route.query.walletArchive},
            userPass(){
                return this.$store.state.realtime.userPasses ?
                    ObjectHandlingService
                        .getListFromObject(this.$store.state.realtime.userPasses.passes)
                        .sort((a, b) => a.from > b.from ? 1 : -1)[0]:
                    null;
            },
            shoppingCart(){ return this.$store.state.shoppingCart;},
            tab(){ return this.$route.query.tab },
            otherMemberships(){ return this.$store.state.otherMemberships},
        },
        created(){
            this.resetShoppingCart();
            this.refreshPendingStripeCheckout();
            this.todayString = TimeStamp.getTodaysDateString(); //
            if ( this.walletArchive ) window.history.back();
        },
        methods: {
            setShowTitle(fab){
                this.showTitle = !fab;
            },
            showPass(section) {
                return !this.walletZoomed.section ||
                        this.walletZoomed.section === section;
            },
            showHeader(){
                return !this.walletZoomed.section;
            },
            resetShoppingCart(){
                if ( this.shoppingCart && this.shoppingCart.status === 'paid' ) {
                    this.shoppingCart.reset(false);
                }
            },
            async refreshPendingStripeCheckout(){
                const pendingDoc = await FirebaseRead.readSingleDoc(
                    'stripe_checkouts_pending', this.user.uid.substring(0,28)
                );
                if ( pendingDoc ) {
                    const stripeCheckout = await FirebaseRead.readSingleDoc(
                        'stripe_checkouts', pendingDoc.pending
                    );
                    const stripeRefresh = new StripeRefresh({
                        uid: this.user.uid.substring(0,28),
                        collection: 'stripe_checkouts',
                        documentId: pendingDoc.pending,
                        stripeId: stripeCheckout.stripe.id,
                        stripeAccountId: stripeCheckout.stripeAccountId,
                        successReported: true,
                        ts: TimeStamp.getGMTTimestamp(),
                    });
                    await stripeRefresh.save();
                }
            },
            handleShowArchive(){
                this.currentlyDisplayed = 0;
                if ( this.showArchive ) {
                    window.history.back();
                } else {
                    if ( this.walletArchive ) {
                        this.showArchive=true;
                    } else {
                        RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                            'walletArchive', true);
                    }
                }
            },
            setNoOfDisplayedCards(noOfCards){
                this.currentlyDisplayed += noOfCards
            }
        },
        watch: {
            tab(to){
                if ( to==='2' ) {
                    this.currentlyDisplayed = 0;
                    this.showArchive = false;
                    if ( this.walletArchive ) {
                        this.blockClear = true
                        window.history.back();
                    }
                }
            },
            walletArchive(to){
                this.showArchive = to==='true';
                if ( !this.blockClear  ) {
                    this.currentlyDisplayed = 0;
                }
                this.blockClear = false
            }
        }
    }
</script>

<style scoped>
</style>