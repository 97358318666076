// Version 1.2 2022-02-17, 2022-02-22, 2022-02-23, 2022-03-01

import firebase from 'firebase/app'
import 'firebase/auth'
import { db } from '@/utilities/services/firebase/init'
import {ServiceMother} from "@/utilities/services/serviceMother";
import FirebaseVersion from "@/utilities/services/firebase/firebaseVersion";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import {i18n} from '@/plugins/i18n';
import FirebaseRead from "./firebaseRead";
import {User} from "../../models/user/user";
import store from "@/store/store";

export class FirebaseAuth extends ServiceMother{
    constructor(dataObject){
        super();
        this.uid = '';
        this.email = '';
        this.password = '';
        this.passwordConfirm = '';
    // other data
        this.appUser = null;
    // errors
        this.errorI18n = '';
        this.errorMsg = '';
    // status
        this.waitingForReply = true;
        this.userPasswortStatus = 'unverified'; // notSet, set
        this.isUserLoggedIn = false;
        this.isEmailVerified = false;
        this.initialLoad = true;
    // configs
        this.passwordLength = 6;
        this.persistence = 'SESSION'; // 'SESSION', 'LOCAL'
        this.emailVerificationRequired = true; // setting to trigger if email must be verified during signup
    // methods
        this.setFromDataObject(dataObject);
        this.setUserLoginState();
    }

    loginCompleted(){
        //return this.isUserLoggedIn;
        return (this.isUserLoggedIn &&
            this.emailVerificationRequired &&
            this.isEmailVerified &&
            store.state.realtime.user) ||
            (this.isUserLoggedIn &&
            !this.emailVerificationRequired &&
            store.state.realtime.user)
    }

    setUserLoginState(){
        if ( FirebaseVersion.getVersion() === 8 ) {
            this.setUserLoginState8();
        } else {
            //
        }
    }
        async setUserLoginState8(){
            firebase.auth().onAuthStateChanged(user => {
                if ( !user ) {
                    console.log ("is logged out");
                    this.isUserLoggedIn = false;
                    this.uid = '';
                    this.email = '';
                    this.waitingForReply = false;
                    this.initialLoad = false;
                // unbind user
                    store.dispatch('unbindRealtimeProperty', 'user');
                // unbind app specific (e.g. multiuser)
                    store.dispatch('unbindAppSpecific');
                } else {
                    console.log ("is logged in", user);
                    this.uid = user.uid;
                    this.email = user.email;
                    this.isUserLoggedIn = true;
                    this.waitingForReply = false;
                    this.isEmailVerified = user.emailVerified;
                    if ( this.isEmailVerified ) {
                        this.checkAndSetAppUser8(false).then(()=>{
                            this.initialLoad = false;
                        });
                    } else {
                        this.checkAdminVerification();
                    }
                // bind user
                    store.dispatch('bindRealtimeDocument', {
                        collection: 'users',
                        storeField: 'user',
                        docId: user.uid,
                    });
                }
            });
        }
            async checkAdminVerification(){
                const verifiedManually = await FirebaseRead.readSingleDoc('users_verified', this.uid);
                if ( verifiedManually ) {
                    console.log ("verifiedManually TRUE", );
                    this.isEmailVerified = true;
                    this.initialLoad = false;
                    /*this.checkAndSetAppUser8(false).then(()=>{
                        this.initialLoad = false;
                    });*/
                } else {
                    console.log ("verifiedManually FALSE", );
                    this.initialLoad = false;
                }
            }

    verifyEmail () {
        if ( FirebaseVersion.getVersion() === 8 ) {
            this.verifyEmail8();
        } else {
            //
        }
    }
        verifyEmail8(){
            const docRef = db.collection('users_pending');
            docRef.where( 'email', '==', this.email ).get()
                .then((querySnapshot) => {
                    if ( querySnapshot.docs.length > 0 ) {
                        this.userPasswortStatus = 'notSet';
                        this.sendPasswordMail();
                    } else {
                        this.userPasswortStatus = 'set';
                    }
                })
                .catch((error) => {
                    console.error("Error getting document:", error);
                    this.errorI18n = error.message;
                });
        }

    updateEmail (newEmail) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.updateEmail8(newEmail);
        } else {
            //
        }
    }
        updateEmail8(newEmail){
            return new Promise( resolve => {
                firebase.auth().currentUser.updateEmail(newEmail)
                .then(() => {
                    /*this.waitingForReply = false;
                    const user = firebase.auth().currentUser;
                    console.log ("RELOADED !!!!!!!!!!!!!!!!!!!!!", JSON.parse(JSON.stringify(user)));
                    this.isEmailVerified = user.emailVerified;*/
                    resolve({successful: true, content: newEmail});
                }).catch((error) => {
                    console.log ("Error: ", error);
                    /*this.waitingForReply = false;
                    this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;*/
                    this.errorMsg = error.message;
                    this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                    resolve({successful: false, content: error })
                });
            });
        }

    undoEmailChange(actionCode) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.undoEmailChange8(actionCode);
        } else {
            //
        }
    }
        undoEmailChange8(actionCode){
            return new Promise( resolve => {
                let restoredEmail = null;
            // Confirm the action code is valid.
                firebase.auth().checkActionCode(actionCode).then((info) => {
                // Get the restored email address.
                    restoredEmail = info['data']['email'];
                // Revert to the old email.
                    return firebase.auth().applyActionCode(actionCode);
                }).then(() => {
                    // Account email reverted to restoredEmail
                    resolve({successful: true, content: restoredEmail })
                }).catch((error) => {
                    // Invalid code.
                    console.log ("Error: ", error);
                    resolve({successful: false, content: error })
                });
            });
        }

    async checkForUndoEmailChange(){
        if ( store.state.realtime.user.email !== this.email ) {
            await FirebaseWrite.batch(
                this.createEmailChangeBatchArray(this.email)
            );
        }
    }
        createEmailChangeBatchArray(value){
            let batchArr = [];
            if ( Object.keys(store.state.realtime.userChilds).length > 0 ) {
                const userMaster = store.state.realtime.userMaster.uid;
                batchArr = [
                    {action: 'update', collection: 'users',
                        documentId: userMaster,
                        data: {email: value}}
                ];
                store.state.realtime.userMaster.childs.forEach( (childId) => {
                    batchArr.push(
                        {action: 'update', collection: 'users',
                            documentId: userMaster + '_' + childId,
                            data: {email: value}}
                    )
                });
            } else {
                batchArr = [
                    {action: 'update', collection: 'users', documentId: this.uid,
                        data: {email: value}}
                ];
            }
            return batchArr;
        }

    reloadUserData () {
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.reloadUserData8();
        } else {
            //
        }
    }
        reloadUserData8(){
            return new Promise( resolve => {
                firebase.auth().currentUser.reload().then(() => {
                    this.waitingForReply = false;
                    const user = firebase.auth().currentUser;
                    console.log ("RELOADED !!!!!!!!!!!!!!!!!!!!!", JSON.parse(JSON.stringify(user)));
                    this.isEmailVerified = user.emailVerified;
                    resolve({successful: true, content: user});
                }).catch((error) => {
                    console.log ("Error: ", error);
                    this.waitingForReply = false;
                    this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                    resolve({successful: false, content: error})
                });
            });
        }

    checkAndSetAppUser(){
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.checkAndSetAppUser8(true);
        } else {
            //
        }
    }
        async checkAndSetAppUser8(setFromUnconfirmed){
            return new Promise( resolve => {
            // check if user document is already existing
                FirebaseRead.readSingleDoc('users', this.uid)
                .then((user) => {
                    if ( !user && setFromUnconfirmed ) {
                // get user_unconfirmed to later set user in firebase
                        return this.getDataFromUserUnconfirmed8();
                    } else {
                // set appUser with to user
                        this.appUser = new User(user);
                        this.waitingForReply = false;
                        resolve({successful: true});
                    }
                });
            });
        }

        async getDataFromUserUnconfirmed8(){
            return new Promise( resolve => {
            // get unconfirmed user
                FirebaseRead.readSingleDoc('users_unconfirmed', this.uid)
                .then((userUnconfirmed) => {
                    if ( userUnconfirmed ) {
                    // set user with data from user unconfirmed
                        return this.setUserFromUnconfirmed8(userUnconfirmed);
                    } else {
                    // error in process!!!
                        this.errorI18n = 'utilities.components.common.undefinedError';
                        this.waitingForReply = false;
                        resolve({successful: false});
                    }
                });
            });
        }

        async setUserFromUnconfirmed8(userData){
            return new Promise( resolve => {
                FirebaseWrite.set ('users', this.uid, userData)
                .then((reply) => {
                    if ( reply.successful ) {
                    // delete user_unconfirmed
                        FirebaseWrite.delete ('users_unconfirmed', this.uid);
                    // user data successfully set
                        this.appUser = new User(reply.content);
                        this.waitingForReply = false;
                        resolve({successful: true});
                    } else {
                    // error in process!!!
                        this.errorI18n = 'utilities.components.common.undefinedError';
                        this.waitingForReply = false;
                        resolve({successful: false});
                    }
                });
            });
        }

    signUp () {
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.signUp8();
        } else {
            //
        }
    }
        signUp8(){
            return new Promise( resolve => {
                firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                    .then((userCredential) => {
                        this.waitingForReply = false;
                        console.log ("userCredential", userCredential);
                        resolve({successful: true, content: userCredential.user.uid});
                    })
                    .catch((error) => {
                        this.waitingForReply = false;
                        console.error(error);
                        this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                        resolve({successful: false, error: error});
                    });
            });
        }


    login () {
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.login8();
        } else {
            //
        }
    }
        login8() {
            return new Promise( resolve => {
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence[this.persistence])
                    .then(() => {
                        firebase.auth().signInWithEmailAndPassword(this.email.trim(), this.password)
                            .then(() => {
                                this.waitingForReply = false;
                                resolve({successful: true});
                            })
                            .catch((error) => {
                                this.waitingForReply = false;
                                console.error(error);
                                this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                                resolve({successful: false, error: error});
                            });
                    })
                    .catch((error) => {
                        this.waitingForReply = false;
                        console.error(error);
                        this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                        resolve({successful: false, error: error});
                    });
            });
        }

    sendPasswordMail () {
        if ( FirebaseVersion.getVersion() === 8 ) {
            this.sendPasswordMail8();
        } else {
            //
        }
    }
        sendPasswordMail8(){
            firebase.auth().sendPasswordResetEmail(this.email);
        }

    requestVerificationMail() {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.requestVerificationMail8();
        } else {
            //
        }
    }
        requestVerificationMail8(){
            return new Promise( resolve => {
                firebase.auth().languageCode = i18n.locale;
                firebase.auth().currentUser.sendEmailVerification().then(() => {
                    resolve({successful: true});
                }).catch((error) => {
                    this.errorI18n = 'utilities.services.firebase.login.error.' + error.code;
                    resolve({successful: false, error: error});
                });
            });
        }

    resetErrorMsg(){
        this.errorI18n = ''
    }

    logout() {
        this.appUser = null;
        if ( FirebaseVersion.getVersion() === 8 ) {
            this.logout8();
        } else {
            //
        }
    }
        logout8(){
            firebase.auth().signOut().then(() => {
                // Sign-out successful.
                console.log ("SUCCESSFULLY LOGGED OUT");
            }).catch((error) => {
                console.log ("logout error: ", error);
                // An error happened.
            });
        }

    oobCodeExpired(oobCode, type) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.oobCodeExpired8(oobCode, type);
        } else {
            //
        }
    }
        oobCodeExpired8(oobCode, type){
            return new Promise( resolve => {
                switch (type) {
                    case 'PWReset':
                        firebase.auth().verifyPasswordResetCode(oobCode)
                            .then( () => { resolve(false); })
                            .catch(() => { resolve(true); });
                        break;
                    case 'emailVerified':
                        firebase.auth().checkActionCode(oobCode)
                            .then( (x) => {
                                console.log ("emailVerified", x);
                                resolve(false);
                            })
                            .catch((y) => {
                                console.log ("emailVerified", y);
                                resolve(true);
                            });
                        break;
                }
            })
        }

    applyActionCode(oobCode){
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.applyActionCode8(oobCode);
        } else {
            //
        }
    }
        applyActionCode8(oobCode){
            return new Promise( resolve => {
                firebase.auth().applyActionCode(oobCode)
                    .then( (resp) => {
                        console.log ("respA", resp); //RESP GIBT UNDEFINED ZURÜCK
                        this.waitingForReply = false;
                        resolve(true);
                    })
                    .catch((error) => { //error
                        console.log ("errorA", error);
                        this.waitingForReply = false;
                        resolve(false);
                    })
            })
        }

    confirmPasswordReset(oobCode, password){
        this.waitingForReply = true;
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.confirmPasswordReset8(oobCode, password);
        } else {
            //
        }
    }
        confirmPasswordReset8(oobCode, password){
            return new Promise( resolve => {
                firebase.auth().confirmPasswordReset(oobCode, password)
                    .then(() => {
                        this.waitingForReply = false;
                        resolve({successful: true});
                    })
                    .catch((error) => {
                        this.waitingForReply = false;
                        console.error (error);
                        resolve({successful: false, error: error});
                    });
            })
        }

    clearUserPending(){
        if ( FirebaseVersion.getVersion() === 8 ) {
            return this.clearUserPending8();
        } else {
            //
        }
    }
        clearUserPending8(){
            const user = firebase.auth().currentUser;
            return FirebaseWrite.delete('users_pending', user.uid);
        }

    getErrorMessage(translated){
        return this.errorI18n === translated ? this.errorMsg : translated;
    }

}