<template>
    <div>
        <v-btn @click="clicked()"
               :elevation="configEl.elevation"
               :ripple="configEl.ripple"
               :disabled="disabled"
               :rounded="configEl.rounded"
               :text="configEl.text"
               :outlined="configEl.outlined"
               :tile="configEl.tile"
               :small="configEl.small"
               :large="configEl.large"
               :block="configEl.block"
               :color="configEl.color"
               :class="configEl.css">
            <v-icon v-if="configEl.icon"
                    :small="configEl.small"
                    :large="configEl.large"
                    class="mr-1">
                {{configEl.icon}}
            </v-icon>
            <span v-if="configEl.i18n">
                {{translate(configEl.i18n)}}
            </span>
            <slot v-else></slot>
        </v-btn>
    </div>
</template>

<script>
    // Version 2.6 2022-02-04
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "btn-text",
        props: {
            disabled: {default: false},
            configPackage: {default: 'default'},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.btnText',
        // define here, which parameter should be customizeable
            configDefault: {
                default: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'primary',
                    css: 'px-5',
                    icon: '',
                    i18n: '',
                    elevation: 2,
                    ripple: true,
                },
                save: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'primary',
                    css: 'px-5',
                    icon: 'mdi-check',
                    i18n: 'utilities.components.common.save',
                    elevation: 2,
                    ripple: true,
                },
                yes: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'primary',
                    css: 'px-5',
                    icon: 'mdi-check',
                    i18n: 'utilities.components.common.yes',
                    elevation: 2,
                    ripple: true,
                },
                confirm: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'primary',
                    css: 'px-5',
                    icon: 'mdi-check',
                    i18n: 'utilities.components.common.confirm',
                    elevation: 2,
                    ripple: true,
                },
                delete: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'error',
                    css: 'px-5',
                    icon: 'mdi-delete',
                    i18n: 'utilities.components.common.delete',
                    elevation: 2,
                    ripple: true,
                },
                cancel: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: '',
                    css: 'px-5',
                    icon: 'mdi-close',
                    i18n: 'utilities.components.common.cancel',
                    elevation: 2,
                    ripple: true,
                },
                no: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: '',
                    css: 'px-5',
                    icon: 'mdi-close',
                    i18n: 'utilities.components.common.no',
                    elevation: 2,
                    ripple: true,
                },
                ok: {
                    text: false,
                    rounded: false,
                    outlined: false,
                    tile: false,
                    small: false,
                    large: false,
                    block: false,
                    color: 'primary',
                    css: 'px-5',
                    icon: '',
                    i18n: 'utilities.components.common.ok',
                    elevation: 2,
                    ripple: true,
                },
            }
        }),
        computed: {
        // always necessary
            configEl(){
                const configCustom = this.moduleConfig ?
                    this.configProp :
                    {[this.configPackage]: this.configProp}; // user passes props without package
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    configCustom,
                    this.moduleConfig,
                    this.path,
                )[this.configPackage];
            },
        },
        methods: {
            clicked(){
                this.$emit('click');
            },
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            }
        },
    }
</script>

<style scoped>

</style>