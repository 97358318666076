import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '@/utilities/services/firebase/init'
import {User} from "@/utilities/models/user/user";
//import RouterServices from '@/utilities/services/router/routerServices';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        config: {
            storage: {
                //profileImages: "https://firebasestorage.googleapis.com/v0/b/on-your-marks-dev.appspot.com/o/profileImages%2F",
                profileImages: "https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/profileImages%2F",
            },
            stripe: {
                companyId: '2TUnOloPnwXIAuOhfu7q', //dev & prod
                // 19.12.2022 => accountID taken from selling organisation
                //accountId: 'acct_1K3hBjR9po97a3si'// '', //dev SQUASH 'acct_1DTWd0JlR61IiwzD'
                //accountId: 'acct_1KYRgwBYIr0F16IR', //prod
            },
            languageOptions: [{label: 'Deutsch', value: 'de'}, {label: 'English', value: 'en'}],
            versions: { // auch in OYM Admin ändern !!!!!!!!!!!!
                privacyApp: 1675209600000,//1646352000000,
                termsOfUse: 1675209600000,//1646352000000,
            },
        },
        wallet: {
            zoomed: {
                section: '',
                documentId: '',
                document: null,
            }
        },
        organisations: {},
        shoppingCart: null,
        shopFir2022LicensesVienna: null,
        shopFir2022LicensesGraz: null,
        shopFir2022Vienna: null,
        shopFir2022Graz: null,
        shopFir2022SalesBlock: false,
        // shop neu nach 'shopEvent' Umbau
            shopLicenses: null,
            shopEvents: null,
            shopAreasLoaded: {},
        sports: null,
        myUserGroups: null,
        realtime: {
        // version
            version: {version: null},
        // clubs
            /*
            clubs: [{id: '1', name: 'Sportunion Racketpoint Vienna'},
                {id: '2', name: 'Racket Dome Sportverein Graz'},
                {id: '3', name: 'Sportunion Rackathletes Austria'}],*/
        // user
            user: null,
            userMaster: null,
            userChilds: {},
            userPermissions: null,

            userPasses: null,
            userVouchers: null,
            userLicenses: null,
            parentConfirm: null,
        },
        messages: {
            unread: null, // realtime
            parked: null, // realtime
        },
    // user not realtime
        userConsents: null,
        local: {
            userMode: {
                selected: true,
                isOrganizer: true,
            },
            //newUserRedirect: true,
            waitingForRealtimeBinding: false,
            parentConfirmAttemptedToBind: false,
            changingUser: false,
            offline: false,
            scrollTo: null,
        },
        otherMemberships: {
            oeskb: null,
        },
    // authentication
        loginService: null,
    },
    mutations: {
        ...vuexfireMutations,
    },
    actions: {
        bindRealtimeDocument: firestoreAction(({ state, bindFirestoreRef }, parameter) => {
            return bindFirestoreRef('realtime.' + parameter.storeField, db.collection(parameter.collection)
                .doc(parameter.docId))
                .then(()=> {
                    state.local.waitingForRealtimeBinding = false;
                    console.log ("bindRealtimeDocument: successful - ", parameter.collection, parameter.docId,
                        state.realtime[parameter.storeField]);
                    return true;
                })
                .catch(() => {
                    state.local.waitingForRealtimeBinding = false;
                    console.log ("bindRealtimeDocument: error/nothing to bind -", parameter.collection, parameter.docId );
                    return false;
                });
        }),
        bindRealtimeList: firestoreAction(({ state, bindFirestoreRef }, parameter) => {
            return bindFirestoreRef('realtime.' + parameter.storeField,
                                    db.collection(parameter.collection))
                .then(()=> {
                    state.local.waitingForRealtimeBinding = false;
                    console.log ("bindRealtimeList: successful - ", parameter.collection,
                        state.realtime[parameter.storeField]);
                    return true;
                })
                .catch(() => {
                    state.local.waitingForRealtimeBinding = false;
                    console.log ("bindRealtimeList: error/nothing to bind -", parameter.collection);
                    return false;
                });
        }),
        bindRealtimeQueryDouble: firestoreAction(({ state, bindFirestoreRef }, parameter) => {
            const bindWatcher = parameter.bindWatcher ? parameter.bindWatcher : 'waitingForRealtimeBinding';
            return bindFirestoreRef(parameter.isInRealtime ? 'realtime.' + parameter.storeField : parameter.storeField ,
                db.collection(parameter.collection)
                    .where(parameter.params[0].field, parameter.params[0].operator, parameter.params[0].value)
                    .where(parameter.params[1].field, parameter.params[1].operator, parameter.params[1].value)
                )
                .then(()=> {
                    state.local[bindWatcher] = false;
                    console.log ("bindRealtimeQuery: successful - ", parameter.collection,
                        state.realtime[parameter.storeField]);
                    return true;
                })
                .catch(() => {
                    state.local[bindWatcher] = false;
                    console.log ("bindRealtimeQuery: error/nothing to bind -", parameter.collection, parameter.docId );
                    return false;
                });
        }),
        unbindRealtimeProperty: firestoreAction(({ unbindFirestoreRef }, storeField) => {
            unbindFirestoreRef('realtime.' + storeField)
        }),
        checkVersion(context) {
            let dbVersion = context.state.realtime.version.version;
            let currVersion = localStorage.getItem("Version");
            if ( dbVersion.indexOf('offline') !== -1 ) {
                context.state.local.offline = true;
            } else if ( dbVersion !== currVersion ) {
                context.state.local.offline = false;
            // set in storage
                localStorage.setItem("Version", dbVersion);
            // load new
                window.location.reload(true);
            }
        },
        unbindAppSpecific(context){
            // to be set app-specific, is called from firebaseAuth on logout
            context.dispatch('unbindRealtimeProperty', 'userMaster');
            context.state.realtime.userChilds = {};
            sessionStorage.removeItem('currentUser')
        },
        async setMasterUser(context){
            if ( !context.state.realtime.userMaster ) {
                await context.dispatch('bindRealtimeDocument', {
                    collection: 'users',
                    storeField: 'userMaster',
                    docId: context.state.realtime.user.uid,
                });

            }
            let userChilds = {};
            context.state.realtime.userMaster.childs.forEach( (child) => {
                const childField = context.state.realtime.userMaster.uid + '_' + child;
                userChilds[childField] = null;
            });
            context.state.realtime.userChilds = userChilds;
            context.state.realtime.userMaster.childs.forEach( (child) => {
                const childField = context.state.realtime.userMaster.uid + '_' + child;
                context.dispatch('bindRealtimeDocument', {
                    collection: 'users',
                    storeField: 'userChilds.'+childField,
                    docId: childField,
                });
            });
        },
        changeUser(context, params){
            if ( !params.noOverlay ) {
                context.state.local.changingUser = true;
            }
        // unbind user
            context.dispatch('unbindRealtimeProperty', 'user');
        // bind user
            context.dispatch('bindRealtimeDocument', {
                collection: 'users',
                storeField: 'user',
                docId: params.uid,
            });
        // set session storage
            let currentUser = sessionStorage.getItem("currentUser");
            currentUser = currentUser ? JSON.parse(currentUser):null;
            if ( !currentUser || params.uid !== currentUser.active  ) {
                sessionStorage.setItem("currentUser", JSON.stringify({
                    master: context.state.realtime.userMaster.uid,
                    active: params.uid,
                }));
            }
        // set overlay
            setTimeout(()=>{context.state.local.changingUser = false;}, 1000);
        }
    },
    modules: {
    },
    getters: {
        user: state => {
            //return state.loginService ? state.loginService.appUser : null;
            return state.realtime.user ? new User(state.realtime.user) : null;
        },
        /*
        clubList: state => {
            return state.realtime.clubs.sort((a, b) => a.name > b.name ? 1 : -1);
            //return [{id: null, name: 'kein Verein'}].concat(state.clubs.sort((a, b) => a.name > b.name ? 1 : -1));
        },*/
        /*
        anyPassStatusIncomplete: state => {
            let incomplete = false;
            if ( !state.realtime.user ) { return false }
            let userOrganisations = state.realtime.user.organisations;
            if ( !userOrganisations ) { return false }
            Object.keys(userOrganisations).forEach( (orgId) => {
                if ( userOrganisations[orgId].memberCountries['130'] && state.organisations[orgId] ) {
                    const orgDates = state.organisations[orgId].countries['130'];
                    if ( !userOrganisations[orgId].memberClubs['130'] ) {
                        incomplete = true
                    }
                    if ( !userOrganisations[orgId].confirmations['130'].statutes ||
                        userOrganisations[orgId].confirmations['130'].statutes < orgDates.statutes ) {
                        incomplete = true
                    }
                    if ( !userOrganisations[orgId].confirmations['130'].covid ||
                        userOrganisations[orgId].confirmations['130'].covid < orgDates.covid ) {
                        incomplete = true
                    }
                    if ( !userOrganisations[orgId].confirmations['130'].termsOfUse ||
                        userOrganisations[orgId].confirmations['130'].termsOfUse < orgDates.termsOfUse ) {
                        incomplete = true
                    }
                    if ( !userOrganisations[orgId].confirmations['130'].privacy ||
                        userOrganisations[orgId].confirmations['130'].privacy < orgDates.privacy ) {
                        incomplete = true
                    }
                    if ( !userOrganisations[orgId].confirmations['130'].rulesOfCompetition ||
                        userOrganisations[orgId].confirmations['130'].rulesOfCompetition < orgDates.rulesOfCompetition ) {
                        incomplete = true
                    }
                }
            });
            return incomplete;
        },
         */
    }
})
