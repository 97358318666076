<template>
    <div v-if="isInactive && !walletArchive" class="inactiveOverlay">
        <div v-if="!cardSelected"
             class="inactiveOverlayText error white--text font12 py-1 px-2 space03">
            <v-row no-gutters align="center" justify="center">
                <v-col class="shrink pr-2">
                    <v-icon dark >mdi-alert</v-icon>
                </v-col>
                <v-col class="grow">
                    <div class="font-weight-bold">{{$t('consents.inactive')}}!</div>
                    <div class="lineHeight-1-2">{{$t('consents.activate')}}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "wallet-missing-consents-overlay",
    props: ['cardSelected', 'eventId', 'type'],
    computed: {
        userConsents(){ return this.$store.state.userConsents},
        walletArchive(){ return this.$route.query.walletArchive},
    },
    data: () => ({
        shopEvent: null,
        isInactive: false
    }),
    created(){
        this.verifyConsents();
    },
    methods: {
        async verifyConsents(){
        // return if no eventId in card
            if ( !this.eventId ) {
                this.isInactive = false;
                return
            }
        // load events if not yet available
            await this.userConsents.checkAndSetEventInStore(this.eventId)
            this.shopEvent = this.$store.state.shopEvents[this.eventId];
        // return if no required consents defined
            if ( !this.shopEvent.requiredConsents ) return
        // verify consents, get consent list and set isInactive
            await this.userConsents.verifyConsent(this.shopEvent.requiredConsents, this.shopEvent.sellingOrganisationId);
            if ( !this.userConsents ) return
            const consentList = this.userConsents.getConsentList(true, this.shopEvent.requiredConsents);
            this.isInactive = consentList.length > 0
        }
    },
    watch: {
        cardSelected(to, from){
            if ( !to && from ) this.verifyConsents()
        },
        eventId(){
            this.verifyConsents()
        },

    }
}
</script>

<style scoped>
    .inactiveOverlay {
        position: absolute; top: 0; bottom: 0; left: 0; right: 0;
        background-color: rgba(0,0,0,0.5);
        border-radius: 6px;
        z-index: 15;
    }
    .inactiveOverlayText {
        position: absolute; top: 10px; right: 0; left: 0;
    }
</style>