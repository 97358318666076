<template>
    <div v-if="userConsents">
        <toolbar :showSave="false" :title="$t('consents.menuItem')"
                 :config-prop="{icon: 'mdi-text-box-check-outline'}"/>
        <alert-message v-if="!hasConsents" :config-prop="{show: true}" class="ma-4">
            {{$t('consents.empty')}}
        </alert-message>
        <user-consents-list v-else :required-consents="userConsents.consents"
                            :organisations="organisations"
                             class="pb-15"/>
    </div>
</template>

<script>
import Toolbar from "@/utilities/components/controls/toolbar";
import UserConsentsList from "@/components/userConsents/userConsentsList";
import AlertMessage from "@/utilities/components/displays/alertMessage";
export default {
    name: "user-consents",
    components: {AlertMessage, UserConsentsList, Toolbar},
    computed: {
        userConsents(){ return this.$store.state.userConsents },
        organisations(){ return this.$store.state.organisations },
        hasConsents(){ return this.userConsents.consents ? Object.keys(this.userConsents.consents).length>0:false},
    },
}
</script>

<style scoped>

</style>