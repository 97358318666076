<template>
    <div v-if="shoppingCart.events[eventId]">
        <div class="fontInter font-weight-bold primary--text font16
                    lineHeight-1-2 py-2 space03 ">
            <v-divider class="mb-1"></v-divider>
            <v-row no-gutters align="center">
                <v-col class="grow pl-4">
                    TOTAL
                </v-col>
                <v-col class="shrink" style="white-space: nowrap">
                    {{getSumObject.price.toFixed(2)}} {{getSumObject.currency}}
                </v-col>
                <v-col class="shrink py-0 pl-1 pr-4">
                    <v-btn icon :disabled="shoppingCart.purchaseOrder!==null"
                           @click="shoppingCart.deleteEventFromCart(eventId)">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <shop-cart-event-vouchers-total :event-id="eventId" :value="getSumObject.price"
                                            :currency="getSumObject.currency"/>
        </div>
        <shop-cart-event-vouchers :event-id="eventId" :shopping-cart="shoppingCart"/>
    </div>
</template>

<script>
    import ShopCartEventVouchers from "@/components/shop/shopCart/shopCartEventVouchers";
    import ShopCartEventVouchersTotal from "@/components/shop/shopCart/shopCartEventVouchersTotal";
    export default {
        name: "shop-cart-event-total",
        components: {ShopCartEventVouchersTotal, ShopCartEventVouchers},
        props: ['eventId'],
        computed: {
            shoppingCart(){ return this.$store.state.shoppingCart},
            getSumObject() { return this.shoppingCart.getSumByEvent(this.eventId)},
        },
    }
</script>

<style scoped>

</style>