<template>
    <a :href="link" target="_blank">
        {{linkText}}
    </a>
</template>

<script>
    // Version 1.0 2022-01-03
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "web-link",
        props: {
            link: {default: ''},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.webLink',
        // define here, which parameter should be customizeable
            configDefault: {},
        }),
        computed: {
            linkText(){
                return this.link.replace('https://', '').replace('http://', '')
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
    }
</script>

<style scoped>

</style>