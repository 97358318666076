// Version 1.2 2021-12-20

import {ConfigUtilities} from "@/config/utilities/config_utilities";

export default class FirebaseVersion {
   static getVersion(){
       const config = new ConfigUtilities();
       let version = 0;
       try { version = config.utilities.services.firebase.version;}
       catch (e) { version = 8;}
       return version;
   }
}