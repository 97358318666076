// Version 1.5 2022-04-05
import {i18n} from '@/plugins/i18n';

const newLogicTs = 1699948800000 // 1697107162000

export default class TimeStamp {
    static getGMTTimestamp(){
        // get current timestamp corrected to GMT !!!
        const localDate = new Date();
        const localUTC = localDate.getTime();
        const GMT_UTC = localUTC > newLogicTs ? localUTC : localUTC + localDate.getTimezoneOffset()* 60 * 1000;
        return GMT_UTC;
    }

    static verifyTimeStampIsInFuture(timeStampToBeVerified){
        const currentTS = TimeStamp.getGMTTimestamp();
        return timeStampToBeVerified > currentTS;
    }

    static getTimeTextFromSeconds(timeInSeconds){
        const minutes = Math.floor((timeInSeconds % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('de-DE', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        const seconds = Math.floor((timeInSeconds % (1000 * 60)) / 1000).toLocaleString('de-DE', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        return minutes + ":" + seconds;
    }

    static getTimeDiffInMillisecondsToEndTime(endTime){
        const currentTime = TimeStamp.getGMTTimestamp();
        return endTime - currentTime;
    }

    static getTimeString(GMT_UTC, short){
        const localUTC = GMT_UTC > newLogicTs ? GMT_UTC : GMT_UTC - new Date().getTimezoneOffset()* 60 * 1000;
        const date = new Date(localUTC);
    // Hours part from the timestamp
        const hours = date.getHours();
    // Minutes part from the timestamp
        const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
        const seconds = "0" + date.getSeconds();
    // Will display time in 10:30:23 format
        let formattedTime = short ? hours + 'h' + minutes.substring(minutes.length-2):
                                    hours + ':' + minutes.substring(minutes.length-2);
        formattedTime += short ? '' : ':' + seconds.substring(seconds.length-2);
        return formattedTime;
    }

    static getDateString(GMT_UTC){
        const localUTC = GMT_UTC > newLogicTs ? GMT_UTC : GMT_UTC - new Date().getTimezoneOffset()* 60 * 1000;
        const date = new Date(localUTC);
        const day = "0" + date.getDate();
        let month = date.getMonth()+1;
        month = "0" + month;
        const year = date.getFullYear();
        return year + '-' + month.substring(month.length-2) + '-' + day.substring(day.length-2);
    }

    static getDateStringFromJsDate(jsDate){
        let day = jsDate.getDate();
        day = day.toString().padStart(2, "0");
        let month = jsDate.getMonth() + 1;
        month = month.toString().padStart(2, "0");
        const year = jsDate.getFullYear();
        return year + "-" + month + "-" + day;
    }

    static getTodaysDateString(){
        const date = new Date();
        return TimeStamp.getDateStringFromJsDate(date)
    }

    static getTomorrowsDateString(){
        const gmt = TimeStamp.getGMTTimestamp() + 1000*60*60*24
        const date = new Date(gmt);
        return TimeStamp.getDateStringFromJsDate(date)
    }

    static getTimeRangeFromTwoStringDates(from, until){
        const untilSplit = until.split('-');
        const yearUntil = parseInt(untilSplit[0]);
        const monthUntil = parseInt(untilSplit[1]);
        const dayUntil = parseInt(untilSplit[2]);
        const fromSplit = from.split('-');
        const yearFrom = parseInt(fromSplit[0]);
        const monthFrom = parseInt(fromSplit[1]);
        const dayFrom = parseInt(fromSplit[2]);
        let dateString = dayUntil + "." + monthUntil + "." + yearUntil;
        if ( yearFrom !== yearUntil ) {
            return dayFrom + "." + monthFrom + "." + yearFrom + '-' + dateString;
        } else if ( monthFrom !== monthUntil ) {
            return dayFrom + "." + monthFrom + '.-' + dateString;
        } else if ( dayFrom !== dayUntil ) {
            return dayFrom + '.-' + dateString;
        } else {
            return dateString;
        }
    }

    static getDateFormatShort(dateString){
        const dateSplit = dateString.split('-');
        const year = parseInt(dateSplit[0]);
        const month = parseInt(dateSplit[1]);
        const day = parseInt(dateSplit[2]);
        return day + "." + month + "." + year;
    }

    static checkIfAgeIsReached(dateString, ageInYears){
        if ( dateString === '' || dateString === null || dateString === undefined ) { return false }
        let todayString = TimeStamp.getTodaysDateString();
        const todayParts = todayString.split('-');
        const dateParts = dateString.split('-');
        if ( todayParts[0] - dateParts[0] < ageInYears ) { return false;}
        if ( todayParts[0] - dateParts[0] > ageInYears ) { return true;}
        if ( parseInt(dateParts[1]) > parseInt(todayParts[1]) ) { return false;}
        if ( parseInt(dateParts[1]) < parseInt(todayParts[1]) ) { return true;}
        if ( parseInt(dateParts[2]) > parseInt(todayParts[2]) ) { return false;}
        if ( parseInt(dateParts[2]) < parseInt(todayParts[2]) ) { return true;}
        return true;
    }

    static checkIfAgeIsExceeded(dateString, ageInYears){
        if ( dateString === '' || dateString === null || dateString === undefined ) { return false }
        let todayString = TimeStamp.getTodaysDateString();
        const todayParts = todayString.split('-');
        const dateParts = dateString.split('-');
        if ( todayParts[0] - dateParts[0] < ageInYears ) { return true;}
        if ( todayParts[0] - dateParts[0] > ageInYears ) { return false;}
        if ( parseInt(dateParts[1]) > parseInt(todayParts[1]) ) { return true;}
        if ( parseInt(dateParts[1]) < parseInt(todayParts[1]) ) { return false;}
        if ( parseInt(dateParts[2]) > parseInt(todayParts[2]) ) { return true;}
        if ( parseInt(dateParts[2]) < parseInt(todayParts[2]) ) { return false;}
        return false;
    }

    static dateInFuture(dateString){
        if ( dateString === '' || dateString === null || dateString === undefined ) { return false }
        let todayString = TimeStamp.getTodaysDateString();
        const todayParts = todayString.split('-');
        const dateParts = dateString.split('-');
        if ( todayParts[0] - dateParts[0] < 0 ) { return false;}
        if ( todayParts[0] - dateParts[0] > 0 ) { return true;}
        if ( parseInt(dateParts[1]) > parseInt(todayParts[1]) ) { return false;}
        if ( parseInt(dateParts[1]) < parseInt(todayParts[1]) ) { return true;}
        if ( parseInt(dateParts[2]) > parseInt(todayParts[2]) ) { return false;}
        if ( parseInt(dateParts[2]) < parseInt(todayParts[2]) ) { return true;}
        return true;
    }

    static getFileTimeStamp() { // for Excel
        const localDate = new Date();
        const localUTC = localDate.getTime();
        // date
        const date = new Date(localUTC);
        let day = date.getDate();
        day = day.toString().padStart(2, "0");
        let month = date.getMonth() + 1;
        month = month.toString().padStart(2, "0");
        const year = date.getFullYear();
        // time
        // Hours part from the timestamp
        const hours = date.getHours();
        // Minutes part from the timestamp
        const minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        const seconds = "0" + date.getSeconds();
        // return
        return year + month + day + "_" + hours + minutes.substring(minutes.length-2) + seconds.substring(seconds.length-2);
    }

    static getLocalStringFromGMTts(GMTts){
        const localTs = GMTts > newLogicTs ? GMTts : GMTts - new Date().getTimezoneOffset()* 60 * 1000;
        let options = { year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric'};
        let dateFormated = new Date(localTs);
        return dateFormated.toLocaleDateString(i18n.locale, options);
    }

    static getLocalStringInclWeekdayFromDateString(dateString){
        let options = { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short'};
        let dateFormated = new Date(dateString);
        return dateFormated.toLocaleDateString(i18n.locale, options);
    }

    static getDateTodayYesterdayOthers(ts){
        const dateToBeChecked = new Date(ts);
        const dateToday = new Date();
        const dateYesterday = new Date(dateToday-3600*1000*24);
        if(dateToBeChecked.setHours(0,0,0,0) === dateToday.setHours(0,0,0,0)){
            return i18n.locale === 'de' ? 'heute':'today'
        } else if (dateToBeChecked.setHours(0,0,0,0) === dateYesterday.setHours(0,0,0,0)){
            return i18n.locale === 'de' ? 'gestern':'yesterday'
        } else {
            return i18n.d(dateToBeChecked,'longDate',i18n.locale);
        }
    }
    static getTimeSince(GMT_UTC){
        const localUTC = GMT_UTC > newLogicTs ? GMT_UTC : GMT_UTC - new Date().getTimezoneOffset()* 60 * 1000;
        const localDate = new Date();
        const delta=(localDate-localUTC)/1000;
        if(delta>365*24*60*60){
            return TimeStamp.getDateTodayYesterdayOthers(GMT_UTC)
        } else if (delta>7*24*60*60){
            const addOn = i18n.locale === 'de' ? 'Wo.':'weeks'
            return Math.round(delta/(7*24*60*60)) + ' ' + addOn
        } else if (delta>24*60*60){
            const addOn = i18n.locale === 'de' ? 'T.':'days'
            return Math.round(delta/(24*60*60)) + ' ' +  addOn
        } else if (delta>60*60){
            const addOn = i18n.locale === 'de' ? 'Std.':'hrs.'
            return Math.round(delta/(60*60)).toString() +' '+ addOn;
        } else {
            return Math.round(delta/60)+" Min.";
        }
//returnMath.round(delta/60);
    }

    static getAgeInYears(birthday){
        const dob = new Date(birthday +' 00:00:00')
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
}

