import {ServiceMother} from "@/utilities/services/serviceMother";
import store from "@/store/store";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export class ShoppingCartVouchers extends ServiceMother {
    constructor() {
        super();
        this.setEmpty();
        /*
        REGELN PRO EVENT UND USER:
        1. Ist noch kein Gutschein für dieses Event/User eingelöst
        2. Liste der Gutscheine für dieses Event im Besitz des Users ausgeben
            (ACHTUNG: ein Gutschein kann hier theoretisch bei unterschiedlichen Events aufscheinen
            => d.h. nach jeder Gutschein-Auswahl neu berechnen)
        3. Bei Klick auf Kaufen:
           Prüfen ob alle möglichen Gutscheine eingelöst wurden => falls nicht bestätigen lassen
        */

        /* TRIGGER FOR CALCULATION
            - shoppingCart.items changed: item added or removed
                - watcher in shopCart.vue
                - create in shopCart.vue
            - voucher redeem selected for user and event (voucher must be disabled on other event)
         */
        /* WHAT NEEDS TO BE SAVED
            - voucher redeem selection
         */
         /* DYNAMIC
            - user_vouchers (realtime): how to get for (not active) childs
            - is event/user combination redeemable or has a voucher be redeemed on a previous purchase
            - IMPORTANT: one trigger, one calculation that might result in outputs by event for the GUI
          */
    }

    setEmpty(){
        this.events = {};
        this.selectedVouchers = [] // {userId, voucherId, eventId}
        this.userVoucherDocs = { /*uid: doc*/}
        this.loading = true;
    }
    /*
    für jedes Event und jeden User im Shopping Cart
    - kann man am Event Gutscheine einlösen
    - hat der User am Event gekauft
    - wie viele Gutscheine hat der User für das Event
        - aus user_vouchers und pending redeems aus dem Cart
    - > 0 => wie viele davon hat er eingelöst
        - wenn Gutscheine > 1 und eingelöst: => info das er nicht mehr einlösen kann
        - wenn Gutscheine 1 und eingelöst => EXIT ohne info
    - 0 eingelöst
        - optionen für Einlösung bauen
*/

// STEP 1
    async createVoucherCheckOut(){
    // reset events that are applicable for vouchers
        this.loading = true;
        this.events = {};
        const cart = store.state.shoppingCart;
        this.verifySelectedVouchersAfterCartItemAction(cart);
        this.setSelectedVouchersIfPurchaseOrderIsCreated(cart);
    // loop through cart-events and check if vouchers can be redeemed on the event
        for (const cartEventId of Object.keys(cart.events)) {
            const cartEvent = cart.events[cartEventId];
            const noOfCartEventVouchers = !cartEvent.vouchers ? 0 : Object.keys(cartEvent.vouchers).length
            if ( noOfCartEventVouchers > 0 ) {
            // add event to voucher-events
                this.events[cartEventId] = { noOfVouchers: noOfCartEventVouchers}
            // assign buyingUsers
                const buyingUsers = await this.getUsersWithPurchasesAndVouchersOnEvent(cart, cartEvent)
                if ( buyingUsers.length > 0 ) this.events[cartEventId].buyingUsers = buyingUsers
            }
        }
        this.loading = false;
    }
        verifySelectedVouchersAfterCartItemAction(cart){
            // selected voucher needs to be removed if the event was removed for a certain user
            let i = 0
            this.selectedVouchers.forEach( (selectedVoucher) => {
            // user does not exist at all
                const userLicenses = cart.userLicenses[selectedVoucher.userId]
                if ( !userLicenses || Object.keys(userLicenses).length === 0 ) {
                    this.selectedVouchers.splice(i,1)
                } else {
                    let eventFound = false
                    Object.keys(userLicenses).forEach( (userLicenseId) => {
                        const userLicense = userLicenses[userLicenseId]
                        if ( userLicense.documentId === selectedVoucher.eventId ) eventFound = true
                    });
                    if ( !eventFound ) this.selectedVouchers.splice(i,1)
                }
            // user does not exist at this event
                i++;
            });
        }
        setSelectedVouchersIfPurchaseOrderIsCreated(cart){
        // select vouchers if purchase order has already been created in cart
            if ( cart.purchaseOrder?.vouchers?.list?.length > 0 ) {
                cart.purchaseOrder.vouchers.list.forEach( (voucher) => {
                    const voucherTemp = JSON.parse(JSON.stringify(voucher))
                    voucherTemp.selected = true;
                    voucherTemp.id = voucher.voucherId;
                    this.setSelectedVoucher(voucherTemp, voucher.userId, voucher.eventId)
                });
            }
        }
// STEP 2
    async getUsersWithPurchasesAndVouchersOnEvent(cart, cartEvent){
        const buyingUser = [];
        for (const userId of Object.keys(cart.userLicenses)) {
            const userLicenses = cart.userLicenses[userId]
            const userWithPurchasesAndVouchersOnEvent = await this.getUserWithPurchasesAndVouchersOnEvent(
                cart, cartEvent, userId, userLicenses
            )
            if ( userWithPurchasesAndVouchersOnEvent ) buyingUser.push(userWithPurchasesAndVouchersOnEvent)
        }
        return buyingUser;
    }
// STEP 3
    async getUserWithPurchasesAndVouchersOnEvent(cart, cartEvent, userId, userLicenses){
        const eventKey = 'documents.' + cartEvent.id;
    // verify if user bought on event
        if ( !userLicenses[eventKey] ) return null
    // verify if the buyer owns how many of the vouchers valid on the event and if they are redeemed (and on which event)
        const userVoucherList = await this.getUserVouchersForThisEvent(userId, cartEvent)
        if ( userVoucherList.length === 0 ) return null
    // result must be returned
        const userResult = this.getUserResultObject(userId, userVoucherList);
    // check if any voucher was redeemed on previous purchases
        userResult.voucherUsedOnPreviousPurchase = userVoucherList.filter(
            userVoucher => userVoucher.redeemDocumentId === cartEvent.id
        ).length;
    // return if user had only 1 voucher and this is already redeemed
        if ( userResult.voucherUsedOnPreviousPurchase === userResult.noOfVouchers ) return null
    // return result
        return this.checkIfVoucherIsSelected(userResult, userId, cartEvent.id);
    }
        getUserResultObject(userId, userVoucherList){
            return {
                userId: userId,
                noOfVouchers: userVoucherList.length,
                voucherUsedOnPreviousPurchase: 0,
                voucherList: userVoucherList,
                value: null,
            }
        }
// STEP 3 Sub
        async getUserVouchersForThisEvent(userId, cartEvent) {
            if (this.userVoucherDocs[userId] === undefined) {
                const vouchersDoc = await FirebaseRead.readSingleDoc('user_vouchers', userId)
                this.userVoucherDocs[userId] = vouchersDoc ? vouchersDoc.vouchers : null;
                if (!vouchersDoc) return [];
            }
            if (!this.userVoucherDocs[userId]) return []
            const userVoucherList = []
            for (const eventVoucherId of Object.keys(cartEvent.vouchers)) {
                const userVoucher = this.userVoucherDocs[userId][eventVoucherId]
                if (userVoucher && userVoucher.validUntil >= TimeStamp.getTodaysDateString() /*&&
                    !userVoucher.redeem?.redeemDocument?.ts*/ ) {
                    userVoucherList.push({
                        id: eventVoucherId,
                        redeemDocumentId: userVoucher.redeem?.redeemDocument?.documentId ?? null,
                        voucher: userVoucher,
                        selected: this.selectedVouchers.find(selectedVoucher =>
                            selectedVoucher.userId === userId &&
                            selectedVoucher.voucherId === eventVoucherId &&
                            selectedVoucher.eventId === cartEvent.id
                        ),
                    })
                }
            }
            return userVoucherList.sort((a, b) => a.voucher.validUntil > b.voucher.validUntil ? 1 : -1);
        }

        checkIfVoucherIsSelected(userResult, userId, eventId){
            const selected = this.selectedVouchers.find(
                voucher => voucher.userId===userId && voucher.eventId===eventId)
            if ( selected ) userResult.value = selected.voucherId;
            return userResult
        }

// SELECTION HANDLERS
    voucherClicked(buyingUser, voucher, eventId){
        if ( voucher.selected ) {
            this.clearOtherSelections(buyingUser, voucher.id, eventId)
            this.prohibitDoubleUsageCrossEvents(buyingUser, voucher.id, eventId)
        }
        this.setSelectedVoucher(voucher, buyingUser.userId, eventId);
    }
        clearOtherSelections(buyingUser, selectedVoucherId, eventId){
            buyingUser.voucherList.forEach( (voucher) => {
                if ( voucher.id !== selectedVoucherId ) {
                    voucher.selected = false
                    this.setSelectedVoucher(voucher, buyingUser.userId, eventId)
                }
            });
        }
        prohibitDoubleUsageCrossEvents(buyingUser, selectedVoucherId, eventId){
            Object.keys(this.events).forEach( (eventIdKey) => {
                if ( eventIdKey !== eventId) {
                    const eventItem = this.events[eventIdKey]
                    const buyingUserOnOtherEvent = eventItem.buyingUsers.find(buyingUserT => buyingUserT.userId === buyingUser.userId)
                    if ( !buyingUserOnOtherEvent ) return
                    const voucherOnThisEvent = buyingUserOnOtherEvent.voucherList.find(voucher => voucher.id === selectedVoucherId)
                    if ( !voucherOnThisEvent ) return
                    if ( voucherOnThisEvent.selected ) {
                        voucherOnThisEvent.selected = false
                        const selectedVoucherIndex = this.selectedVouchers.findIndex(
                            voucher => voucher.userId === buyingUser.userId &&
                                       voucher.eventId === eventIdKey &&
                                       voucher.voucherId === selectedVoucherId
                        )
                        this.selectedVouchers.splice(selectedVoucherIndex, 1)
                    }
                }
            });
            /*this.selectedVouchers.forEach( (voucher) => {
                if ( voucher.voucherId === selectedVoucherId &&
                     buyingUser.userId === voucher.userId &&
                     eventId !== voucher.eventId
                ) {
                    const otherSelectedVoucher = buyingUser.voucherList.find(
                        voucher => voucher.id === selectedVoucherId
                    )
                    otherSelectedVoucher.selected = false
                    this.setSelectedVoucher(voucher, buyingUser.userId, voucher.eventId)
                }
            });*/
        }

    setSelectedVoucher(voucher, userId, eventId){
        if ( !voucher.selected ) {
            const voucherIndex = this.selectedVouchers.findIndex( selectedVoucher =>
                selectedVoucher.userId === userId &&
                selectedVoucher.voucherId === voucher.id &&
                selectedVoucher.eventId === eventId
            )
            if ( voucherIndex !== -1) { this.selectedVouchers.splice(voucherIndex,1) }
        } else {
            const user = this.getUserFromStore(userId)// VORHER: userId.length === 28 ? store.getters.user : store.state.realtime.userChilds[userId]
            const event = store.state.shoppingCart.events[eventId]
            this.selectedVouchers.push({
                userId: userId,
                voucherId: voucher.id,
                eventId: eventId,
                voucher: voucher.voucher,
                userName: user.firstName,
                userLastName: user.lastName,
                event: {
                    name: event.name,
                    title: event.title,
                    subtitle: event.subtitle
                }
            })
        }
    }

// GETTERS
    allPossibleVouchersSelectedForEvent(eventId){
        if ( !this.events[eventId] || !this.events[eventId].buyingUsers ) { return true }
    // check within the shopping cart
        let anyVoucherSelected = false
        Object.keys(this.events[eventId].buyingUsers).forEach( (userId) => {
            const buyingUser = this.events[eventId].buyingUsers[userId]
            let numberOfPreviouslyRedeemedVouchers = 0
            let numberOfVouchersSelectedToday = 0
            buyingUser.voucherList.forEach( (voucher) => {
                if ( voucher.redeemDocumentId ) {
                    numberOfPreviouslyRedeemedVouchers++
                } else {
                    const indexInSelected = this.selectedVouchers.findIndex(selectedVoucher =>
                        selectedVoucher.voucherId === voucher.id && selectedVoucher.userId === buyingUser.userId)
                    if ( indexInSelected !== -1 ) numberOfVouchersSelectedToday++
                }
            });
            anyVoucherSelected = buyingUser.voucherList.length -
                                 numberOfPreviouslyRedeemedVouchers -
                                 numberOfVouchersSelectedToday === 0
        });
        return anyVoucherSelected
        /*
        if ( !this.events[eventId] || !this.events[eventId].buyingUsers ) { return true }
        let buyingUsers = 0;
        Object.keys(this.events[eventId].buyingUsers).forEach( (userId) => {
            if ( this.events[eventId].buyingUsers[userId].voucherUsedOnPreviousPurchase===0 ) buyingUsers++
        });
        const selectedVouchers = this.selectedVouchers.filter(voucher => voucher.eventId ===eventId).length
        return buyingUsers===selectedVouchers;
         */
    }

    allPossibleVouchersSelected(){
        let selected = true
        Object.keys(this.events).forEach( (eventId) => {
            if (!this.allPossibleVouchersSelectedForEvent(eventId)) selected = false;
        });
        return selected
    }

    getVouchersSumTotal(){
        let sum = 0
        Object.keys(this.events).forEach( (eventId) => {
            sum += this.getVouchersSumForEvent(eventId).sum
        });
        return {sum: sum, usersWithExpiringRemainder: []};
    }
    getVouchersSumForEvent(eventId){
        // percentage sum not yet coded !!!!!!!!!!!!!!!
        const selectedVouchers = this.selectedVouchers.filter(selectedVoucher => selectedVoucher.eventId === eventId);
        return this.getAbsoluteValueVoucherSum(selectedVouchers);
    }
        getAbsoluteValueVoucherSum(selectedVouchers){
            // percentage sum not yet coded !!!!!!!!!!!!!!!
            let subSumObj = {sum: 0, usersWithExpiringRemainder: []};
            selectedVouchers.forEach( (selectedVoucher) => {
                if ( selectedVoucher.voucher.showValue === 'absolute' ) {
                    const subSumObjTemp = this.getPurchaseTotalByUserAndEvent(selectedVoucher)
                    subSumObj.sum -= subSumObjTemp.sum
                    if ( subSumObjTemp.reminderExpiringUsers ) {
                        subSumObj.usersWithExpiringRemainder.push(subSumObjTemp.reminderExpiringUsers)
                    }
                }
            });
            return subSumObj
        }
        getPurchaseTotalByUserAndEvent(selectedVoucher){
            const cart = store.state.shoppingCart;
            const userCartItems = cart.items[selectedVoucher.userId];
            const userLicenses = cart.userLicenses[selectedVoucher.userId]
            let totalByUserAndEvent = 0;
            Object.keys(userCartItems).forEach( (licenseId) => {
                const userCartItem = userCartItems[licenseId]
                const eventIdOfCartItem = userLicenses['licenses.'+licenseId].documentId
                if ( eventIdOfCartItem === selectedVoucher.eventId ) totalByUserAndEvent += userCartItem.amount * userCartItem.price
            });
            return totalByUserAndEvent >= selectedVoucher.voucher.value ?
                {sum: selectedVoucher.voucher.value, reminderExpiringUsers: null} :
                {sum: totalByUserAndEvent, reminderExpiringUsers: this.getUserFirstNameFromStore(selectedVoucher.userId)};
        }
        getUserFirstNameFromStore(userId){
            return userId.length>28 ? store.state.realtime.userChilds[userId].firstName :
                store.getters.user.uid.length > 28 ? store.state.realtime.userMaster.firstName : store.getters.user.firstName
        }
        getUserFromStore(userId){
            return userId.length>28 ? store.state.realtime.userChilds[userId] :
                store.getters.user.uid.length > 28 ? store.state.realtime.userMaster : store.getters.user
        }

}
