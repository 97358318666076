<template>
    <div :class="[{'cardContentDyn': $vuetify.breakpoint.xsOnly},
                  {'cardContentFix': $vuetify.breakpoint.smAndUp}]">
        <!--<div class="watermark" :style="watermarkUrl"></div>-->
    <!-- logo -->
        <img v-if="organisation && organisation.short"
             class="cardLogo" height="26%" :alt="organisation.short"
             :src="organisation.logo">
    <!-- text box -->
        <div :class="[{'title100 fontVoucherText': $vuetify.breakpoint.xsOnly && userVoucher.showValue==='notAtAll'},
                      {'title100 font18': $vuetify.breakpoint.smAndUp && userVoucher.showValue==='notAtAll'},
                      {'titleDyn fontVoucherText': $vuetify.breakpoint.xsOnly && userVoucher.showValue!=='notAtAll'},
                      {'titleFix font18': $vuetify.breakpoint.smAndUp &&userVoucher.showValue!=='notAtAll'},
                      'lineHeight-1-0 mt-1 text-left pl-3',
                      textColor]" :style="userVoucher.getDynStyleTitle()">
            <div :class="[{'fontVoucherTextSmall': $vuetify.breakpoint.xsOnly},
                          {'font16': $vuetify.breakpoint.smAndUp},
                           'space03']">
                {{userVoucher.title}}
            </div>
            <div class="mt-1 font-weight-black space05 text-uppercase">
                {{$t(userVoucher.getBenefitText())}}*<!--{{$t('common.forFree')}}-->
            </div>
        </div>
    <!-- price tag
        <div :class="[{'priceTagDyn py-1 px-2': $vuetify.breakpoint.xsOnly},
                      {'priceTagFix py-2 px-3': $vuetify.breakpoint.smAndUp},
                      'primary lightGrey--text elevation-10 priceTag']">
            <div :class="[{'font8': $vuetify.breakpoint.xsOnly},
                          {'font12': $vuetify.breakpoint.smAndUp},
                           'lineHeight-1-2']">
                {{$t('common.worth')}}
            </div>
            <div :class="[{'font12': $vuetify.breakpoint.xsOnly},
                          {'font16': $vuetify.breakpoint.smAndUp},
                           'lineHeight-1-0 font-weight-medium']">
                {{userVoucher.value}} {{userVoucher.currency}}
            </div>
        </div>-->
    <!-- price tag round -->
        <div v-if="userVoucher.showValue!=='notAtAll'"
             :class="[{'priceTagRoundDyn': $vuetify.breakpoint.xsOnly},
                      {'priceTagRoundFix': $vuetify.breakpoint.smAndUp},
                      {'white black--text': !this.organisation.styles.darkCircle},
                      {'black white--text': this.organisation.styles.darkCircle},
                      'elevation-10 priceTagRound lineHeight-1-0 text-center']">
            <!--<div :class="[{'priceLabelDyn': $vuetify.breakpoint.xsOnly},
                          {'font14 mt-3': $vuetify.breakpoint.smAndUp},
                          'text-uppercase ']">
                <span v-if="userVoucher.showValue==='percent'">{{$t('shopVoucher.discount')}}</span>
                <span v-else>
                        <span v-if="!userVoucher.upTo">{{$t('common.worth')}}</span>
                        <span v-else>{{$t('common.upTo')}}</span>
                    </span>-->
                <!--<span v-if="!userVoucher.upTo">{{$t('common.worth')}}</span>
                <span v-else>{{$t('common.upTo')}}</span>-->
            <!--</div>-->
            <div :class="[{'priceValueDyn': $vuetify.breakpoint.xsOnly},
                          {'font32 mt-1 pt-5': $vuetify.breakpoint.smAndUp},
                          'font-weight-black']">
                <span v-if="userVoucher.showValue!=='percent'">
                    €</span>{{userVoucher.value}}<span v-if="userVoucher.showValue==='percent'">%</span>
            </div>
        </div>
    <!-- date -->
        <div :class="[{'fontVoucherDate': $vuetify.breakpoint.xsOnly},
                      {'font12': $vuetify.breakpoint.smAndUp},
                      'dateFooter font10 lineHeight-1-2',
                      textColor]">
            <span class="ml-3">
                {{$t('common.validUntil')}} {{dateString}}
            </span>
        </div>
    </div>
</template>

<script>
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp'
    export default {
        name: "wallet-vouchers-content",
        props: ['userVoucher'],
        computed: {
            organisation() {
                return this.$store.state.organisations[this.userVoucher.organisationId] ?
                    this.$store.state.organisations[this.userVoucher.organisationId] :
                    {styles: {color: 'grey', dark: true}}
            },
            textColor(){
                return this.organisation.styles.textColor ? '' :
                    this.organisation.styles.dark ? 'lightGrey--text' : 'primary--text';
            },
            dateString(){
                return TimeStamp.getDateFormatShort(this.userVoucher.validUntil);
            },
            walletZoomed(){
                return this.$store.state.wallet.zoomed;
            },
            /*watermarkUrl(){
                return this.organisation && this.organisation.sport ? {
                        'background-image': 'url(' +
                        require('@/assets/' + this.organisation.sport + 'Watermark.png') + ')'
                    } : '';
            }*/
        },
    }
</script>

<style scoped>
    .titleDyn {position: absolute; width: 67%; top: 37%}
    .titleFix {position: absolute; width: 66%; top: 40%}
    .title100 {position: absolute; width: 100%; top: 37%}
    .priceTagRound {
        position: absolute; border-radius: 50%;
    }
    .priceTagRoundDyn {
        width: 13vw; height: 13vw; font-size: 5vw;
        right: 2vw; bottom: 5vw;
    }
    .priceTagRoundFix {
        width: 80px; height: 80px;
        right: 12px; bottom: 20px;
    }
    .priceLabelDyn {
        font-size: 2.5vw; padding-top: 2vw;
    }
    .priceValueDyn {
        font-size: 5vw; padding-top: 4vw;
    }
    /*.priceTag { background-color: rgba(38,50,56,0.5);
                position: absolute; right: 5%; top: 7%;}
    .priceTagDyn {
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 2px;
        border-top-right-radius: 10px;
    }
    .priceTagFix {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 15px;
    }*/
    .dateFooter {
        position: absolute; bottom: 4%; left: 0
    }
</style>