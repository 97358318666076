<template>
    <div>
        <v-toolbar :dense="configEl.dense"
                   :color="configEl.color"
                   :dark="configEl.dark">
            <v-btn v-if="!configEl.backWithFab"
                    icon @click="back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-icon v-if="configEl.icon" class="mr-2">{{configEl.icon}}</v-icon>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="showSave" icon @click="save()">
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn v-else-if="showBack" icon @click="back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </v-toolbar>
        <fab-btn-back v-if="showBack" style="z-index: 5" @back="back"
                      :handleBackInComponent="handleBackInComponent">
        </fab-btn-back>
    </div>
</template>

<script>
    // Version 1.1 2022-02-01, 2022-02-03, 2022-03-12
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import FabBtnBack from "./fabBtnBack";
    export default {
        components: {FabBtnBack},
        name: "toolbar",
        props: {
            showSave: {default: true},
            showBack: {default: true},
            handleBackInComponent: {default: false},
            title: {default: ''},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.toolbar',
        // define here, which parameter should be customizeable
            configDefault: {
                dense: true,
                color: 'primary',
                dark: true,
                backWithFab: true,
            },
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            back(){
                if ( this.handleBackInComponent ) {
                    this.$emit('back');
                } else {
                    window.history.back();
                }
            },
            save(){
                this.$emit('save');
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>