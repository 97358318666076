// Version 1.1 2021-12-06

import {db} from "@/utilities/services/firebase/init";
import FirebaseVersion from "@/utilities/services/firebase/firebaseVersion";
import {ServiceMother} from "@/utilities/services/serviceMother";

export default class FirebaseRealtime {

    static setOnLocal (localObj){
        if ( FirebaseVersion.getVersion() === 8 ) {
            FirebaseRealtime.setOnLocal8(localObj);
        } else {
            //
        }
    }

        static setOnLocal8(localObj){
        console.log ("setOnLocal8", JSON.parse(JSON.stringify(localObj)));
            localObj.unsubscribe = db.collection(localObj.fireBaseCollection).doc(localObj.docId)
                .onSnapshot((doc) => {
                    let docData = doc.data();
                    docData.id = doc.id;
                    localObj.data = docData;
                }, () => {
                    localObj.data = null;
                });
        }
}

export class RealtimeListener extends ServiceMother{
    constructor(dataObject){
        super();
        this.unsubscribe = ()=>{};
        this.data = null;
        this.docId = '';
        this.fireBaseCollection = '';
        if ( dataObject ) { this.setFromDataObject(dataObject)}
    }

    setListener(dataObject){
        if ( dataObject ) { this.setFromDataObject(dataObject)}
        FirebaseRealtime.setOnLocal(this);
    }
}