import {ServiceMother} from "@/utilities/services/serviceMother";
import store from "@/store/store";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import ConditionCheckService from "@/modules/_services/conditionCheckService";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
//import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'

export class ShopEventService extends ServiceMother {
    constructor(data) {
        super();
        this.data = data;
        this.isFullyPurchasedStatus = false;
        this.isPartlyPurchasedStatus = false;
        this.overrideIsFullyPurchasedStatus = false;
    }
    async init(){
        await this.loadRequiredLicenses();
        this.addToEventsWithValidVouchersInShoppingCart();
        this.setIsPartlyPurchasedStatus();
    }

// getters
    isFullyPurchased(){
        let selectFields = {}
        for ( let i = 0; i < Object.keys(this.data.licenses).length; i++ ) {
            const licenseId = Object.keys(this.data.licenses)[i]
            const license = this.data.licenses[licenseId]
        // no select list
            if ( license.display === "standard" &&
                 !store.state.realtime.userLicenses?.licenses[licenseId] ) return false
        // with select list
            if ( license.display === "select"){
                if ( selectFields[license.selectField] === undefined ) selectFields[license.selectField] = false
                if ( store.state.realtime.userLicenses?.licenses[licenseId] ) selectFields[license.selectField] = true
            }
        }
    // analyze select field results);
        for ( let i = 0; i < Object.keys(selectFields).length; i++ ) {
            const selectFieldId = Object.keys(selectFields)[i]
            const selectField = selectFields[selectFieldId]
            if ( !selectField ) return false
        }
        return true
    }
    setIsPartlyPurchasedStatus(){
        Object.keys(this.data.licenses).forEach( (licenseId) => {
            if ( store.state.realtime.userLicenses?.licenses[licenseId] ) {
                this.isPartlyPurchasedStatus = true;
            }
        });
    }
    hasValidVoucher(){
        if ( !this.data.vouchers || Object.keys(this.data.vouchers).length === 0 ||
             !store.state.realtime.userVouchers) return false
        let hasValidVoucher = false;
        const userVouchers = store.state.realtime.userVouchers.vouchers
        Object.keys(this.data.vouchers).forEach( (voucherId) => {
            if ( userVouchers[voucherId] &&
                 userVouchers[voucherId].validUntil >= TimeStamp.getTodaysDateString() &&
                !userVouchers[voucherId].redeem?.redeemDocument) hasValidVoucher = true
        });
        return hasValidVoucher
    }
    getRequiredPassName(){
        if ( this.data.requiredPassEventId ) {
            const passEvent = store.state.shopEvents[this.data.requiredPassEventId]
            return passEvent?.name
        } else {
            let names = []
            this.data.requiredPassEventIds.forEach( (requiredPassEventId) => {
                const passEvent = store.state.shopEvents[requiredPassEventId]
                if ( passEvent?.name ) names.push( passEvent?.name )
            });
            return names.join(', ')
        }
    }
    isPassRequiredAndMissing(){
        if ( this.data.requiredPassEventId === undefined && // backwards compatibility
             this.data.requiredPassEventIds === undefined) return false
        if ( this.data.requiredPassEventId ) { // backwards compatibility
            let isMissing = true
            if ( !store.state.realtime.userPasses ) return isMissing
            Object.keys(store.state.realtime.userPasses.passes).forEach( (passKey) => {
                const pass = store.state.realtime.userPasses.passes[passKey]
                if ( pass.eventId === this.data.requiredPassEventId ) isMissing = false
            });
            return isMissing
        } else if (this.data.requiredPassEventIds !== undefined) {
            if ( this.data.requiredPassEventIds.length === 0 ) return false
            let isMissing = true
            const requiredPasses = JSON.parse(JSON.stringify(this.data.requiredPassEventIds))
            Object.keys(store.state.realtime.userPasses.passes).forEach( (passKey) => {
                const pass = store.state.realtime.userPasses.passes[passKey]
                const index = requiredPasses.indexOf(pass.eventId)
                if ( index !== -1 ) isMissing = false
            });
            return isMissing
        }
    }
    async loadRequiredLicenses(){
        let licenseIdList = [];
        let count = 0;
        for ( let i = 0; i < Object.keys(this.data.licenses).length; i++ ) {
            const licenseId = Object.keys(this.data.licenses)[i];
            if ( !store.state.shopLicenses || !store.state.shopLicenses[licenseId] ) {
                count++;
                licenseIdList.push(licenseId)
            }
            if ( count === 10 ) {
                await this.loadAndSetLicenses(licenseIdList);
                count = 0;
                licenseIdList = [];
            }
        }
        if ( count > 0 ) {
            await this.loadAndSetLicenses(licenseIdList);
        }
    }
        async loadAndSetLicenses(licenseIdList){
            const licenseList = await FirebaseRead.readDocList(
                'licenses', {
                    field: 'license.id',
                    operator: 'in',
                    value: licenseIdList,
                }
            );
            let shopLicensesTemp = JSON.parse(JSON.stringify(store.state.shopLicenses));
            licenseList.forEach( (license) => {
                if ( !shopLicensesTemp ) {
                    shopLicensesTemp = {[license.license.id]: license.license}
                } else {
                    shopLicensesTemp[license.license.id] = license.license;
                }
            });
            store.state.shopLicenses = shopLicensesTemp;
        }

    getSortedItemList(user, userLicenses){
        const conditionCheckService = new ConditionCheckService()
        let standardItems = [];
        let selectItems = [];
        Object.keys(this.data.licenses).forEach( (licenseId) => {
            if ( store.state.shopLicenses?.[licenseId] &&
                conditionCheckService.checkConditionUser(
                store.state.shopLicenses?.[licenseId]?.conditions,
                user,
                userLicenses ).result )
            {
                if ( this.data.licenses[licenseId].display === 'standard' ) {
                    standardItems.push({
                        id: licenseId,
                        sort: this.data.licenses[licenseId].sort,
                        priceType: this.data.licenses[licenseId].priceType,
                        display: this.data.licenses[licenseId].display,
                        userText: this.data.licenses[licenseId].userText??null,
                    })
                } else if ( this.data.licenses[licenseId].display === 'select' ) {
                    selectItems = this.addToSelectItem(selectItems, licenseId)
                }
            }
        });
        let itemList = standardItems.concat(selectItems);
        return itemList.sort((a, b) => a.sort > b.sort ? 1 : -1);
    }
        addToSelectItem(selectItems, licenseId){
            const currentItem = this.data.licenses[licenseId];
        // check if already existing
            const existingItem = selectItems.find(selectItem => selectItem.id === currentItem.selectField);
        // extend if existing
            if ( existingItem ) {
                existingItem.licenses.push({
                    id: licenseId,
                    sort: currentItem.sort,
                });
                existingItem.licenses.sort((a, b) => a.sort > b.sort ? 1 : -1);
                if ( currentItem.sort < existingItem.sort ) {
                    existingItem.sort = currentItem.sort;
                }
            } else {
        // create if not existing
                selectItems.push({
                    id: currentItem.selectField,
                    sort: this.data.licenses[licenseId].sort,
                    display: 'select',
                    priceType: currentItem.priceType,
                    licenses: [{
                        id: licenseId,
                        sort: currentItem.sort,
                    }],
                    fieldSettings: this.data.fieldSettings?.[currentItem.selectField]??null
                })
            }
            return selectItems
        }

    /*getStandardLicenseIdList(){
        let itemList = [];
        Object.keys(this.data.licenses).forEach( (licenseId) => {
            if ( this.data.licenses[licenseId].display === 'standard' ) {
                itemList.push({
                    id: licenseId,
                    sort: this.data.licenses[licenseId].sort,
                    priceType: this.data.licenses[licenseId].priceType,
                })
            }
        });
        return itemList.sort((a, b) => a.sort > b.sort ? 1 : -1);
    }*/

    addToEventsWithValidVouchersInShoppingCart(){
    // only necessary if event has vouchers
        if ( this.data.vouchers &&
            store.state.realtime.userVouchers &&
             Object.keys(this.data.vouchers).length > 0) {
            const userVouchers = store.state.realtime.userVouchers.vouchers;
            for ( let i = 0; i < Object.keys(this.data.vouchers).length; i++ ) {
                const voucherId = Object.keys(this.data.vouchers)[i];
                if ( userVouchers[voucherId] &&
                     !userVouchers[voucherId].redeem ) {
                    store.state.shoppingCart.addToEventsWithValidVouchers(this.data.eventId);
                }
                //
            }
        }
    }

// data loader
    async loadLicense(licenseId){
        const license = await FirebaseRead.readSingleDoc('licenses', licenseId);
        if ( !store.state.shopLicenses ) {
            store.state.shopLicenses = {[licenseId]: license.license}
        } else {
            store.state.shopLicenses[licenseId] = license.license;
        }
    }

}