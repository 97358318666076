import {ServiceMother} from "@/utilities/services/serviceMother";
import store from "@/store/store";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export class MessagesBarService extends ServiceMother {
    constructor(userId) {
        super();
        this.userId = userId
        this.unreadMessages = []
        this.displayedMessages = []
        this.setDisplayedMessages()
    }

    bindUnreadMessagesToStore(userId){
        this.userId = userId
        store.dispatch('bindRealtimeQueryDouble', {
            collection: 'messages',
            storeField: 'messages.unread',
            isInRealtime: false,
            params: [
                {   field: 'receiver.receiverUserId',
                    operator: '==',
                    value: this.userId },
                {   field: 'receiver.readTs',
                    operator: '==',
                    value: null },
            ]
        });
        if ( store.state.messages.parked === null ) { // also bound in message details
            store.dispatch('bindRealtimeQueryDouble', {
                collection: 'messages',
                storeField: 'messages.parked',
                isInRealtime: false,
                params: [
                    {   field: 'receiver.receiverUserId',
                        operator: '==',
                        value: this.userId },
                    {   field: 'receiver.readTs',
                        operator: '==',
                        value: 0 },
                ]
            })
        }
    }

// getters
    getNumberOfUnreadMessages(){
        return this.unreadMessages.length
    }

    getNumberOfDisplayedMessages(){
        return this.displayedMessages.length
    }

// data read and set
    setDisplayedMessages(resetMessages){
        this.unreadMessages = store.state.messages.unread ? store.state.messages.unread : []
        if ( this.displayedMessages.length === 0 || resetMessages ) {
            this.displayedMessages = []
            this.unreadMessages.forEach( (item) => {
                let msg = JSON.parse(JSON.stringify(item))
                msg.id = item.id
                this.displayedMessages.push(msg)
            });
        }
    }

// actions
    async setRead(index, readType){
        const msgId = this.displayedMessages[index].id
        await FirebaseWrite.update('messages', msgId, {
            ['receiver.readTs']: readType==='later' ? 0 : TimeStamp.getGMTTimestamp()
        })
    }
    async parkAllUnread(){
        for ( let i = 0; i < this.displayedMessages.length; i++ ) {
            await this.setRead(i,'later')
        }
    }
}
