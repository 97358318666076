<template>
    <div>
        <div v-for="(voucher, i) in voucherList" :key="i">
            <div v-if="i===0 || voucher.organisationId !== voucherList[i-1].organisationId">
                <v-divider class="my-3"></v-divider>
                <img v-if="getOrganisation(voucher.organisationId) && getOrganisation(voucher.organisationId).short"
                     class="voucherLogoShop" height="40px" :alt="getOrganisation(voucher.organisationId).short"
                     :src="getOrganisation(voucher.organisationId).logoDark">
            </div>
            <shop-item-vouchers-card @click="showVoucherDetails" class="mt-2" :voucher="voucher"/>
        </div>
        <shop-item-vouchers-details :voucher="voucherTemp" :organisations="organisations"/>
    </div>
</template>

<script>
import ShopItemVouchersCard from "@/components/shop/shopItem/shopItemVouchersCard";
import ShopItemVouchersDetails from "@/components/shop/shopItem/shopItemVouchersDetails";
import RouterServices from "@/utilities/services/router/routerServices";
export default {
    name: "shop-item-vouchers-list",
    components: {ShopItemVouchersDetails, ShopItemVouchersCard},
    props: ['voucherList', 'organisations'],
    computed: {
        voucherIdFromPath(){ return this.$route.query.voucherDetails}
    },
    created(){
        if ( this.voucherIdFromPath ) { window.history.back(); }
    },
    data: () => ({
        voucherTemp: null,
    }),
    methods: {
        getOrganisation(orgId){ return this.organisations?.[orgId]},
        showVoucherDetails(voucher){
            this.voucherTemp = voucher
            setTimeout( () => { RouterServices.pushRouteQueryParameter(
                this.$router, this.$route, 'voucherDetails', this.voucherTemp.id); }, 100)
        }
    },
    watch: {
        voucherIdFromPath(){
            //if ( !to ) { this.voucherTemp = null }
        }
    }
}
</script>

<style scoped>
    .voucherLogoShop { position: relative; left: 4px; top: 2px}
</style>