import {ServiceMother} from "@/utilities/services/serviceMother";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'
import store from "@/store/store";
//import {i18n} from '@/plugins/i18n';
import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
import {validityTypes} from "@/modules/createAndUpdateLicense/constants/validityConstants";
import LicensePriceService from "@/modules/_services/licensePriceService";
import {ShoppingCartVouchers} from "@/services/shop/shoppingCartVouchers";

export class ShoppingCart extends ServiceMother {
    constructor(){
        super();
        this.status = ''; // 'blocked' as soon as purchase order is created
                          // 'paid' as soon as payment is paid
        this.statusCheckout = ''; // 'created' as soon as checkout is started
        this.setEmpty();
    }
    setEmpty(){
        this.items = {};
        this.events = {};
        this.licenses = {};
        this.licenseIdList = {};
        this.eventIdList = {};
        this.isLoading = false;
        this.purchaseOrder = null;
        this.userLicenses = {};
        this.maxItems = 100;
        this.eventsWithValidVouchers = {};
        this.voucherToBeRedeemed = null;
        this.validOn = TimeStamp.getTodaysDateString();
        this.shoppingCartVouchers = new ShoppingCartVouchers();
    }

    clearUser(uid){
        delete this.items[uid];
    }

    setToSessionStorage(){
        const thisObj = JSON.parse(JSON.stringify(this));
        thisObj.uid = store.getters.user.uid.substring(0,28);
        sessionStorage.setItem('shoppingCart', JSON.stringify(thisObj));
    }

    reset(isPaid){
        if ( isPaid ) {
            this.status = 'paid';
            this.statusCheckout = '';
        } else {
            sessionStorage.removeItem('shoppingCart');
            this.setEmpty();
        }
    }

    countItems(){
        let count = 0;
        Object.keys(this.items).forEach( (userId) => {
            count += Object.keys(this.items[userId]).length;
        });
        return count;
    }

    updateItem(item){
        //uid, licenseId, status, price, amount, currency
        if ( !item.status &&
            this.items[item.uid] &&
            this.items[item.uid][item.licenseId] !== undefined ) {
            this.removeItem(item.uid, item.licenseId);
        } else if (item.status) {
            this.addItem(item);
        }
    }

    addItem(item){
        //uid, licenseId, price, amount, currency, reference, description
        if ( this.status === 'blocked' ) { return; }
        this.status = '';
        const newItems = JSON.parse(JSON.stringify(this.items));
        if ( !newItems[item.uid] ) {
            newItems[item.uid] = {[item.licenseId]: {
                amount: item.amount,
                price: item.price,
                currency: item.currency,
                reference: item.reference===undefined?'':item.reference,
                description:  item.description===undefined?'':item.description,
            }}
        } else {
            newItems[item.uid][item.licenseId] = {
                amount: item.amount,
                price: item.price,
                currency: item.currency,
                reference: item.reference===undefined?'':item.reference,
                description: item.description===undefined?'':item.description,
            };
        }
        if ( item.blockPrice ) {
            newItems[item.uid][item.licenseId].blockPrice = true
        }
        if ( item.blockDelete ) {
            newItems[item.uid][item.licenseId].blockDelete = true
        }
        if ( item.sellingOrganisationId ) {
            newItems[item.uid][item.licenseId].sellingOrganisationId = item.sellingOrganisationId
        }
        this.items = newItems;
        this.setToSessionStorage();
    }

    removeItem(uid, licenseId){
        if ( this.status === 'blocked' ) { return; }
        this.status = '';
        const newItems = JSON.parse(JSON.stringify(this.items));
        delete newItems[uid][licenseId];
        this.items = newItems;
        this.setToSessionStorage();
    }
    async deleteLicenseFromCart(licenseId, userId){
        if ( this.purchaseOrder ) { return }
        //delete this.items[userId][licenseId];
        this.removeItem(userId, licenseId);
        this.recalculateItemPrices(userId, licenseId);
        await this.createCheckOutView();
        await this.shoppingCartVouchers.createVoucherCheckOut();
        this.setToSessionStorage();
        if ( Object.keys(this.licenseIdList).length===0 ) { window.history.back(); }
    }
        recalculateItemPrices(userId, licenseId){
            const userLicenses = this.includeUserLicenses(userId, licenseId);
            Object.keys(this.items[userId]).forEach( (licenseId) => {
                const license = this.licenses[licenseId];
                this.items[userId][licenseId].price = LicensePriceService.getCurrentPrice(
                    store.getters.user,
                    userLicenses,
                    license.license
                );
            });
        }
            includeUserLicenses(userId, deleteLicenseId){
                const licensesInCart = {licenses: {}};
                Object.keys(this.userLicenses[userId]).forEach( (key) => {
                    if ( key.indexOf('licenses.') !== -1 ) {
                        const licenseId = key.substring(9);
                        if ( licenseId !== deleteLicenseId) {
                            licensesInCart.licenses[licenseId] = {};
                        }
                    }
                });
                Object.keys(store.state.realtime.userLicenses.licenses).forEach( (licenseId) => {
                    licensesInCart.licenses[licenseId] = {};
                });
                return licensesInCart;
            }
    async deleteEventFromCart(eventId){
        if ( this.purchaseOrder ) { return }
        this.events[eventId].licenses.forEach( (license) => {
            Object.keys(this.items).forEach( (userId) => {
                if ( this.items[userId][license.id]) {
                    delete this.items[userId][license.id]
                }
            });
        });
        await this.createCheckOutView();
        await this.shoppingCartVouchers.createVoucherCheckOut();
        this.setToSessionStorage();
        if ( Object.keys(this.licenseIdList).length===0 ) { window.history.back(); }
    }
    addToEventsWithValidVouchers(eventId){
        const eventsWithValidVouchers = JSON.parse(JSON.stringify(this.eventsWithValidVouchers))
        if ( eventsWithValidVouchers[eventId] === undefined ) {
            eventsWithValidVouchers[eventId] = {}
        }
        eventsWithValidVouchers[eventId][store.getters.user.uid] = true;
        this.eventsWithValidVouchers = eventsWithValidVouchers;
        this.setToSessionStorage()
    }
    blockForShoppingDueToVouchers(eventId, eventData){
        const uid = store.getters.user.uid;
        let blocked = {text: '', variables: {}};
        if ( this.eventsWithValidVouchers[eventId] &&
             this.eventsWithValidVouchers[eventId][uid] ) {
        // user has voucher for current event and other user is in shopping cart
            Object.keys(this.items).forEach( (shoppingCartUserId) => {
                if ( shoppingCartUserId !== uid &&
                     Object.keys(this.items[shoppingCartUserId]).length > 0 ) {
                    blocked.text = 'shopEvent.blockedOtherUser';
                    blocked.variables = {eventName: eventData.name}
                }
            });
            if ( blocked.text ) { return blocked }
        // user has voucher for current event and item on other event is in shopping cart for this user
            if ( this.items[uid] ) {
                Object.keys(this.items[uid]).forEach( (licenseId) => {
                    if ( !eventData.licenses[licenseId] ) {
                        blocked.text = 'shopEvent.blockedOtherEvent';
                        blocked.variables = {eventName: eventData.name}
                    }
                });
                if ( blocked.text ) { return blocked }
            }
        } else {
        // event has no valid voucher but other event with valid voucher selected
            const externalBlockCheck = this.blockForShoppingDueToVouchersOnOtherSelectedEvent(eventId);
            if ( externalBlockCheck.text !== '' ) {
                blocked.text = 'shopEvent.blockedByVoucherEventAlt';
                blocked.variables = {eventNameBlocking: externalBlockCheck.eventNameBlocking}
            }
        }
    // return blocked
        return blocked;
    }
    blockForShoppingDueToVouchersOnOtherSelectedEvent(currentEventId){
        //console.log ("zzzzzzzzzzzzzzzz", );
        let blockMessage = {text: '', eventNameBlocking: ''};
        if ( Object.keys(this.eventsWithValidVouchers).length === 0 ||
             Object.keys(this.items).length === 0) {
            return blockMessage;
        }
        //console.log ("zzzzzzzzzzzzzzzz 1", );
        Object.keys(this.eventsWithValidVouchers).forEach( (eventId) => {
            //console.log ("zzzzzzzzzzzzzzzz 2", );
            Object.keys(this.items).forEach( (userId) => {
                //console.log ("zzzzzzzzzzzzzzzz 3", Object.keys(this.items[userId]).length);
                if ( Object.keys(this.items[userId]).length > 0 ) {
                    //console.log ("zzzzzzzzzzzzzzzz 4", );
                    Object.keys(this.items[userId]).forEach( (licenseId) => {
                        //console.log ("zzzzzzzzzzzzzzzz 5", );
                        if ( store.state.shopEvents &&
                             store.state.shopEvents[eventId] &&
                             store.state.shopEvents[eventId].licenses) {
                            //console.log ("zzzzzzzzzzzzzzzz 6", licenseId, store.state.shopEvents[eventId]);
                            if ( store.state.shopEvents[eventId].licenses[licenseId] &&
                                this.eventsWithValidVouchers[eventId][userId] &&
                                !(eventId === currentEventId && userId === store.getters.user.uid) ) {
                                //console.log ("zzzzzzzzzzzzzzzz 7", );
                                blockMessage.text = 'shopEvent.blockedByVoucherEvent';
                                blockMessage.eventNameBlocking = store.state.shopEvents[eventId].name;
                            }
                        }
                    });
                }
            });
        });
        return blockMessage;
    }
    blockForShoppingToAvoidMultipleSellers(sellingOrganisationIdOfEvent){
        const usersInItems = Object.keys(this.items);
        for ( let j = 0; j < usersInItems.length; j++ ) {
            const uid = usersInItems[j];
            const licenses = Object.keys(this.items[uid]);
            if ( licenses.length > 0 ) {
                for ( let i = 0; i < licenses.length; i++ ) {
                    const license = this.items[uid][licenses[i]];
                    if ( sellingOrganisationIdOfEvent !==
                        license.sellingOrganisationId) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    offerVouchersAtCheckOut(){
        const uid = store.getters.user.uid;
        let applicableEventIds = [];
        let applicableEventId = null;
        const events = store.state.shopEvents;
        const itemIds = this.items[uid] ? Object.keys(this.items[uid]) : null;
        if ( !events || !itemIds ) { return this.voucherToBeRedeemed; }
    // get list of eventIds applicable for voucher redeems
        Object.keys(this.eventsWithValidVouchers).forEach( (eventId) => {
            if ( this.eventsWithValidVouchers[eventId][uid] !== undefined ) {
                applicableEventIds.push(eventId);
            }
        });
        if ( applicableEventIds.length === 0 ) { return this.voucherToBeRedeemed; }
    // get the event, that is currently selected in the shopping cart
        for ( let i = 0; i < applicableEventIds.length; i++ ) {
            for ( let j = 0; j < itemIds.length; j++ ) {
                if ( events[applicableEventIds[i]].licenses[itemIds[j]] !== undefined ) {
                    applicableEventId = applicableEventIds[i];
                }
            }
            if ( applicableEventId ) { break; }
        }
        if ( !applicableEventId ) { return this.voucherToBeRedeemed; }
    // return the available vouchers for this user
        return events[applicableEventId].vouchers;
    }
// GETTERS
    getNumberOfItems(){
        let numberOfItems = 0;
        Object.keys(this.items).forEach( (userKey) => {
            numberOfItems += Object.keys(this.items[userKey]).length;
        });
        return numberOfItems;
    }
    getUsersByLicenseId(licenseId){ // checked 15.2. 7h16
        const userIds = this.getUserIdsByLicenseId(licenseId);
        const users = [];
        userIds.forEach( (userId) => {
            if ( userId.length > 28 ) {
                if ( store.state.realtime.userChilds[userId] ) {
                    users.push(store.state.realtime.userChilds[userId])
                }
            } else if (store.state.realtime.userMaster){
                users.push(store.state.realtime.userMaster)
            } else {
                users.push(store.state.realtime.user)
            }
        });
        return users;
    }
    getUserIdsByLicenseId(licenseId){ // checked 15.2. 7h16
        const userIds = {};
        const userIdList = [];
        Object.keys(this.items).forEach( (userId) => {
            Object.keys(this.items[userId]).forEach( (userLicenseId) => {
                if ( userLicenseId === licenseId) {
                    userIds[userId] = true;
                }
            });
        });
        Object.keys(userIds).forEach( (userId) => {
            userIdList.push(userId);
        });
        return userIdList;
    }
    getSum(){
        let eventSum = 0, currency = '', voucherRemainderExpires = 0;
        Object.keys(this.eventIdList).forEach( (eventId) => {
            const eventSumObject = this.getSumByEvent(eventId);
            eventSum += Math.round(eventSumObject.price * 100) / 100;
            currency = eventSumObject.currency;
        });
        //console.log ("XXXXXXXXXXXXXXXXXXXXXXXXXX", this.voucherToBeRedeemed,store.state.realtime.userVouchers);
        /* OLD VOUCHER SOLUTION
        if ( this.voucherToBeRedeemed &&
             store.state.realtime.userVouchers &&
             store.state.realtime.userVouchers.vouchers[this.voucherToBeRedeemed] ) {

            eventSum = eventSum - store.state.realtime.userVouchers.vouchers[this.voucherToBeRedeemed].value;
            if ( eventSum < 0 ) {
                voucherRemainderExpires = eventSum *-1;
                eventSum = 0;
            }
        }*/
        return {price: eventSum,
                currency: currency,
                voucherTotal: this.shoppingCartVouchers.getVouchersSumTotal(),
                voucherRemainderExpired: voucherRemainderExpires};
    }
    getSumByEvent(eventId){
        let eventSum = 0, currency = '';
        this.events[eventId].licenses.forEach( (license) => {
            const users = this.getUsersByLicenseId(license.id);
            users.forEach( (user) => {
                eventSum += Math.round(this.items[user.uid][license.id].price * 100) / 100 *
                            this.items[user.uid][license.id].amount;
                currency = this.items[user.uid][license.id].currency;
            });
        });
        return {price: Math.round(eventSum * 100) / 100, currency: currency};
    }
    // purchase Order items
    getPurchaseOrderItems(getWithoutBlocking){
        // build stripe items and firebase po items at the same time
        // firebase po includes eventId and licenseId
        let purchaseOrder = {
            items: [],
            cartItems: this.items,
        };
        Object.keys(this.events).forEach( (eventId) => {
            const event = this.events[eventId];
            this.events[eventId].licenses.forEach( (license) => {
                const users = this.getUsersByLicenseId(license.id);
                users.forEach( (user) => {
                    const licenseItem = this.items[user.uid][license.id];
                //build content
                    const eventAddOn = licenseItem.description &&
                                       licenseItem.description.indexOf('https://')===-1 ?
                        ', ' + licenseItem.description : '';
                    const documentTitle = event.subtitle ? event.title + ', ' + event.subtitle + ', ' :
                                  event.title ? event.title + ', ' : '';
                    const licenseTitle = license.license.subtitle ?
                        license.license.title + ' ' + license.license.subtitle :
                        license.license.title;
                    purchaseOrder.items.push({
                        name: documentTitle +
                                licenseTitle + ', ' +
                                this.getValidityText(license.license.validity) +
                                ', ' + user.firstName +
                                eventAddOn,
                        amount: Math.round(licenseItem.price * 100),//parseInt((Math.round(licenseItem.price * 100) / 100)  * 100, 0),
                        currency: licenseItem.currency,
                        quantity: licenseItem.amount,
                    })
                });
            });
        });
        if ( !getWithoutBlocking ) { this.status = 'blocked'; }
        return purchaseOrder;
    }
        getValidityText(validity){
            if ( validity.type === validityTypes.QUANTITY ) {
                return validity.quantity + 'x' //+ i18n.t('common.item')
            } else if ( validity.type === validityTypes.TIME_FRAME ) {
                return TimeStamp.getTimeRangeFromTwoStringDates(validity.startDate, validity.endDate)
            } else {
                return '-'
            }
        }
// LOAD DOCUMENTS FOR CHECK OUT VIEW
    async createCheckOutView(){
        this.isLoading = true;
    // get and set licenses
        this.setLicenseIdList();
        const licenseList = await this.loadFirebaseDocs(
            this.getPromiseList(this.licenseIdList, 'licenses')
        );
        this.setFromReply(licenseList, 'licenses');
    // get and set events
        this.setEventIdList(licenseList);
        const eventList = await this.loadFirebaseDocs(
            this.getPromiseList(this.eventIdList, 'events')
        );
        this.setFromReply(eventList, 'events');
    // add licenses to the events
        this.setLicensesPerEvent();
    // set userLicenses
        this.createUserLicenses();
    // complete
        this.isLoading = false;
    }
        setLicenseIdList(){
            this.licenseIdList = {};
            Object.keys(this.items).forEach( (user) => {
                Object.keys(this.items[user]).forEach( (license) => {
                    this.licenseIdList[license] = true;
                });
            });
        }
        setEventIdList(licenseList){
            this.eventIdList = {};
            licenseList.forEach( (license) => {
                this.eventIdList[license.license.eventId] = true;
            });
        }
        async loadFirebaseDocs(promiseList){
            return new Promise( (resolve) => {
                Promise.all(promiseList).then( (values) => {
                    resolve(values);
                });
            })
        }
            getPromiseList(keyList, collection){
                let promiseList = [];
                Object.keys(keyList).forEach( (key) => {
                    promiseList.push(FirebaseRead.readSingleDoc(collection, key))
                });
                return promiseList;
            }
        setFromReply(replyList, targetParameter){
            let targetParameterTemp = {};
            replyList.forEach( (replyItem) => {
                targetParameterTemp[replyItem.id] = replyItem;
            });
            this[targetParameter] = targetParameterTemp
        }
        setLicensesPerEvent(){
            Object.keys(this.events).forEach( (eventId) => {
                let updatedEvent = JSON.parse(JSON.stringify(this.events[eventId]));
                updatedEvent.licenses = ObjectHandlingService.getListFromObject(this.licenses)
                    .filter(license => license.license.eventId === eventId);
                this.events[eventId] = updatedEvent;
            });
        }
    getPurchaseOrderData(purchaseOrderItems, uid, stripeAccountId){
        const sellingOrganisationId = this.getSellingOrganisation(purchaseOrderItems.cartItems)
        if ( !sellingOrganisationId ) { return null }
        return {
            assignedTo: this.getAssignedTo(uid, sellingOrganisationId),
            uid: uid,
            items: purchaseOrderItems.items,
            cartItems: purchaseOrderItems.cartItems,
            userLicenses: this.userLicenses,
            ts: TimeStamp.getGMTTimestamp(),
            successReported: false,
            seller: {
                name: store.state.organisations[sellingOrganisationId].name,
                id: sellingOrganisationId,
            },
            vouchers: {
                total: this.shoppingCartVouchers.getVouchersSumTotal().sum,
                currency: 'EUR',
                list: this.shoppingCartVouchers.selectedVouchers,
                stripeAccountId: stripeAccountId ? stripeAccountId: '',
            }
        }
    }
    getAssignedTo(uid, sellerId){
        let assignedTo = {
            events: {},
            organisations: {},
        };
        Object.keys(this.eventIdList).forEach( (eventId) => {
            assignedTo.events[eventId] = uid;
            const keyForOrganisation = eventId + '_' + uid;
            if ( !assignedTo.organisations[sellerId] ) {
                assignedTo.organisations[sellerId] = {
                    [keyForOrganisation]: true
                };
            } else {
                assignedTo.organisations[sellerId][keyForOrganisation] = true;
            }
        });
        return assignedTo;
    }
    getSellingOrganisation(cartItems){
        let sellingOrganisationId = '';
        let hasDuplicateSellers = false;
        Object.keys(cartItems).forEach( (userId) => {
            Object.keys(cartItems[userId]).forEach( (licenseId ) => {
                const organisationIdInLicense = cartItems[userId][licenseId].sellingOrganisationId
                if ( !sellingOrganisationId ) {
                    sellingOrganisationId = organisationIdInLicense;
                } else if (sellingOrganisationId !== organisationIdInLicense) {
                    hasDuplicateSellers = true;
                }
            });
        });
        return hasDuplicateSellers ? null : sellingOrganisationId;
    }
// TO BE MOVED TO CLOUD FUNCTIONS
    createUserLicenses(){
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!
        // TO BE MOVED TO CLOUD FUNCTIONS
        // !!!!!!!!!!!!!!!!!!!!!!!!!
        this.userLicenses = {};
        Object.keys(this.items).forEach( (userId) => {
            this.userLicenses[userId] = {};
            Object.keys(this.items[userId]).forEach( (licenseId) => {
            // add license
                // also update for chash sales in OYM Admin
                let key = 'licenses.' + licenseId;
                const license = this.licenses[licenseId].license;
                this.userLicenses[userId][key] = {
                    documentId: license.eventId,
                    subtitle: license.subtitle,
                    title: license.title,
                    validity: license.validity,
                    description: this.getLicenseDescription(this.items[userId][licenseId].description, license.licenseDescription),
                    type: license.type,
                };
            // add document
                // also update for chash sales in OYM Admin
                key = 'documents.' + license.eventId;
                if ( !this.userLicenses[userId][key] && license.type === 'EVENT' ) {
                    const document = this.events[license.eventId];
                    const logo = document.logo ? document.logo : store.state.organisations[document.organisationId].logo
                    const styles = document.styles?.color ? document.styles : store.state.organisations[document.organisationId].styles
                    this.userLicenses[userId][key] = {
                        from: document.from,
                        until: document.until,
                        location: document.location,
                        logo: logo,
                        styles: styles,
                        subtitle: document.subtitle,
                        title: document.title,
                        organisationId: document.organisationId,
                        info: document.info!==undefined?document.info:'',
                    };
                    if ( document.sports && document.sports.length > 0 ) this.userLicenses[userId][key].sports = document.sports
                }
            });
        });
    }

    getLicenseDescription(userDescription, licenseDescription){
        return [userDescription, licenseDescription].filter( (text) => text !== undefined && text !== '').join(' / ')
    }
}