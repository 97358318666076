import {Mother} from "@/utilities/models/mother";

export class StripeCheckoutExpire extends Mother {
    constructor(dataObject){
        super();
        this.status = '';
        this.purchaseOrderId = '';
        this.sessionId = null;
        this.uid = '';
        this.ts = 0;
        this.autoExpired = false;
    // database collection
        this.local.fireBaseCollection = 'stripe_checkouts_expire';
        this.local.inProgress = false;
    // set data
        if(dataObject){this.setFromDataObject(dataObject);}
    }

}