<template>
    <div class="text-left">
        <user-consents-list v-if="userConsents && shopEvent && !walletArchive" :missing-only="true"
                            :required-consents="shopEvent.requiredConsents"
                            :consent-status="consentStatus"
                            @consentRefreshed="setConsentStatus"
                            :header-info="headerInfo"
                            :organisation-id="shopEvent.sellingOrganisationId"
        />
    </div>
</template>

<script>
import UserConsentsList from "@/components/userConsents/userConsentsList";

export default {
    name: "wallet-missing-consents",
    components: {UserConsentsList},
    props: ['headerInfo'],
    computed: {
        userConsents(){ return this.$store.state.userConsents},
        cardId(){ return this.$route.query.card},
        walletDoc(){ return this.$store.state.wallet.zoomed.document;},
        walletArchive(){ return this.$route.query.walletArchive},
    },
    data: () => ({
        shopEvent: null,
        consentStatus: false,
    }),
    created(){
        this.checkAndSetEvent();
    },
    methods: {
        async checkAndSetEvent(){
            const eventId = this.walletDoc.eventId ? this.walletDoc.eventId : this.walletDoc.id
            await this.userConsents.checkAndSetEventInStore(eventId)
            this.shopEvent = this.$store.state.shopEvents[eventId];
        },
        setConsentStatus(consentStatus){
            this.consentStatus = consentStatus;
        },
    },
}
</script>

<style scoped>

</style>