<template>
    <div>
        <v-expand-transition>
            <div v-show="!walletZoomed.section && !noUserEvents">
                <p class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4 px-4">
                    Events
                </p>
            </div>
        </v-expand-transition>
    <!-- card list-->
        <wallet-card-list :cards="cards" card-list="userLicenses"
                          section="documents" stylesSource="document">
        </wallet-card-list>
    <!-- zoomed detail information -->
        <div v-if="walletZoomed.section==='documents'"
             :class="[{'detailContainer mx-4': $vuetify.breakpoint.smAndUp},
                         'text-center']">
            <v-row no-gutters>
                <v-col cols="12">
                    <div :class="[{'mt-4': $vuetify.breakpoint.xsOnly},
                                  {'mt-1': $vuetify.breakpoint.smAndUp},
                                    'text-left mb-4 fontInter text-center font24 lineHeight-1-2']">
                            <span class="font-weight-thin">{{user.firstName}}
                            </span> <span class="text-uppercase font-weight-black">
                            {{user.lastName}}</span>
                    </div>
                    <v-divider :class="dividerClass"></v-divider>
                </v-col>
                <v-col>
                    <profile-avatar :user="user"
                                    :storage-source-path="storageSourcePath"
                                    :config-prop="{size: 144}">
                    </profile-avatar>
                </v-col>
                <v-col>
                    <qr-code-display :qrValue="qrValue" :size="size"  class="d-inline-block"></qr-code-display>
                </v-col>
            </v-row>
            <v-divider :class="dividerClass"></v-divider>
            <wallet-missing-consents header-info="consents.walletHeaderEvent"/>
            <div :class="[{'px-4': $vuetify.breakpoint.xsOnly}, 'text-left mt-n1']">
                <line-with-label v-if="walletZoomed.document.info" label="Event-Info">
                    {{walletZoomed.document.info}}
                </line-with-label>
            </div>
            <v-list class="py-0 mb-15 text-left">
                <div class="text-overline bgdGreyLight pl-3">{{$t('wallet.yourEvents')}}</div>
                <div v-for="(license, i) in licenseList" :key="i">
                    <v-list-item class="bgdGreyLight " three-line>
                        <v-list-item-icon class="pl-1 mr-2 pb-0">
                            <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="pl-3 pb-0">
                            <v-list-item-title>
                                {{license.title}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{license.subtitle}}<span v-if="license.subtitle && license.description">, </span>
                                <span v-if="license.description &&
                                            license.description.indexOf('https://')===-1">
                                    {{license.description}}
                                </span><span class="mt-1" v-else-if="license.description&&
                                            license.description.indexOf('https://')!==-1">
                                    <v-btn small icon @click="openInfo(license.description)"
                                            class="ml-1">
                                        <v-icon >mdi-information</v-icon>
                                    </v-btn>
                                </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="license.description.indexOf('https://')===-1">
                                {{getValidityText(license.validity)}}
                            </v-list-item-subtitle>
                            <div class="mt-3 bgdGreyLight">
                                <v-divider v-if="i<licenseList.length-1" ></v-divider>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </div>
    </div>
</template>

<script>
    import {validityTypes} from "@/modules/createAndUpdateLicense/constants/validityConstants";
    import WalletCardList from "@/components/wallet/walletCardList";
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import QrCodeDisplay from "@/utilities/components/qrCode/qrCodeDisplay";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'
    import WalletMissingConsents from "@/components/wallet/walletMissingConsents";
    import LineWithLabel from "@/utilities/components/displays/lineWithLabel";

    export default {
        components: {
            LineWithLabel,
            WalletMissingConsents,
            QrCodeDisplay,
            ProfileAvatar,
            WalletCardList },
        name: "wallet-events",
        props: ['showArchive', 'todayString'],
        computed: {
            tab(){ return this.$route.query.tab},
            cards(){
                return this.$store.state.realtime.userLicenses ?
                    ObjectHandlingService
                        .getListFromObject(this.$store.state.realtime.userLicenses.documents)
                        .filter(eventDoc => this.showArchive ? eventDoc.until < this.todayString :
                                                               eventDoc.until >= this.todayString)
                        .sort((a, b) => a.from > b.from ? 1 : -1):
                    [];
            },
            noUserEvents(){
                return this.cards.length === 0;
                //return !this.userEvents || Object.keys(this.userEvents).length === 0;
            },
            dividerClass(){ return this.$vuetify.breakpoint.xsOnly ?
                'ma-4' : 'my-4'
            },
            userEvents(){ return this.$store.state.realtime.userLicenses ?
                            this.$store.state.realtime.userLicenses.documents : null; },
            walletZoomed(){ return this.$store.state.wallet.zoomed; },
            eventId(){ return this.$route.query.card},
            user(){ return this.$store.getters.user },
            qrValue() {return 'uid:' + this.user.uid + ',' + 'event:' + this.eventId},
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            userLicenses(){
                return this.$store.state.realtime.userLicenses;
            },
            licenseList(){
                let licenseList = [];
                if ( !this.userLicenses ) { return licenseList }
                const userLicenses = this.cleanUserLicensesForFIR2022(this.userLicenses.licenses);
                Object.keys(userLicenses).forEach( (licenseId) => {
                    const license = userLicenses[licenseId];
                    if ( license.documentId === this.eventId ) {
                        licenseList.push(license);
                    }
                });
                return licenseList.sort((a, b) => a.title > b.title ? 1 : -1);
            }
        },
        data: () => ({
            size: 140,
        }),
        created(){
            this.emitNoOfCards()
        },
        methods: {
            cleanUserLicensesForFIR2022(userLicenses){
                let userLicensesTemp = JSON.parse(JSON.stringify(userLicenses));
                if ( userLicensesTemp.fir2022_vienna_accomodation_private ) {
                    delete userLicensesTemp.fir2022_vienna_accomodation_other;
                }
                if ( userLicensesTemp.fir2022_graz_accomodation_private ) {
                    delete userLicensesTemp.fir2022_graz_accomodation_other;
                }
                Object.keys(userLicenses).forEach( (licenseId) => {
                    if ( licenseId.indexOf('graz_hotel') !== -1 ) {
                        delete userLicensesTemp.fir2022_graz_accomodation_other;
                        delete userLicensesTemp.fir2022_graz_accomodation_private;
                    }
                    if ( licenseId.indexOf('vienna_hotel') !== -1 ) {
                        delete userLicensesTemp.fir2022_vienna_accomodation_other;
                        delete userLicensesTemp.fir2022_vienna_accomodation_private;
                    }
                });
                return userLicensesTemp;
            },
            getValidityText(validity){
                if ( validity.type === validityTypes.QUANTITY ) {
                    return validity.quantity + 'x'// + this.$i18n.t('common.item')
                } else if ( validity.type === validityTypes.TIME_FRAME ) {
                    return TimeStamp.getTimeRangeFromTwoStringDates(validity.startDate, validity.endDate)
                } else {
                    return '-'
                }
            },
            openInfo(target){
                window.open(target, '_blank');
            },
            emitNoOfCards(){
                this.$emit('noOfDisplayedCards', this.cards.length);
            }
        },
        watch: {
            cards(){
                this.emitNoOfCards()
            },
            tab(){
                if ( this.tab === '2' ) { this.emitNoOfCards() }
            }
        }
    }
</script>

<style scoped>
    .detailContainer{  width: 346px;
    }
</style>