import {ServiceMother} from "@/utilities/services/serviceMother";
import ObjectHandlingService from "@/utilities/services/objectHandling/objectHandlingService";
import {i18n} from "@/plugins/i18n";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";

export class SportsService extends ServiceMother {
    constructor(sports, userSports, masterUserUid) {
        super();
        this.sports = this.clearIdsFromSports(sports)
        this.sportsList = []
        this.masterUserUid = masterUserUid
        this.all = userSports?.all !== undefined ? userSports?.all : false
        this.none = userSports?.none !== undefined ? userSports?.none : false
        this.setSportsList(userSports)
    }

    clearIdsFromSports(sportsRaw){
        const sports = JSON.parse(JSON.stringify(sportsRaw))
        delete sports.id
        return sports
    }

    setSportsList(userSports){
        const sportsList = ObjectHandlingService.getListFromObject(JSON.parse(JSON.stringify(this.sports)))
        this.sportsList = sportsList
            .map(sport => ({...sport, selected: !userSports ? false : userSports[sport.id] === true }))
            .sort((a, b) => a.label[i18n.locale] > b.label[i18n.locale] ? 1 : -1);
    }

    async saveChangesToMasterUser(){
        return await FirebaseWrite.update('users', this.masterUserUid, {
            sports: this.getUserSports(undefined, false, false, 'item')
        })
    }

    async saveAllChanged(){
        return await FirebaseWrite.update('users', this.masterUserUid, {
            sports: this.getUserSports(this.all, this.all, false, 'all')
        })
    }

    async saveNoneChanged() {
        const selected = this.none ? false : undefined
        return await FirebaseWrite.update('users', this.masterUserUid, {
            sports: this.getUserSports(selected, selected, this.none, 'none')
        })
    }

    getUserSports(selected, all, none, type){
        const sports = {
            all: all !== undefined ? all : this.all,
            none: none !== undefined ? none : this.none,
        }
        if ( type !== 'none' ) {
            this.sportsList.forEach( (sport) => {
                if ( selected !== undefined ) {
                    sports[sport.id] = selected
                } else if ( sport.selected ) {
                    sports[sport.id] = true
                }
            });
        }
        return sports
    }

    selectAll(isSelected){
        this.sportsList.forEach( (sport) => {
            sport.selected = isSelected
        });
    }

}