var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.offline)?_c('v-main',{staticClass:"bgdGreyLight"},[_c('offline')],1):_vm._e(),(_vm.ieBlock)?_c('v-main',[_c('p',[_vm._v("Diese Anwendung läuft leider nicht mehr auf dem veralteten Internet Explorer.")]),_c('p',[_vm._v("Wir empfehlen die Verwendung von Google Chrome, du kannst aber auch jeden anderen modernen Browser nutzen (z.B. Microsoft Edge, Firefox, Opera oder Safari für Apple).")])]):_vm._e(),(!_vm.ieBlock &&
                  !_vm.offline &&
                  !_vm.loginService.loginCompleted() &&
                  !_vm.changingUser &&
                  _vm.$route.path!=='/termsOfUse' &&
                  _vm.$route.path!=='/privacy' &&
                  _vm.$route.path!=='/imprint')?_c('v-main',{staticClass:"bgdGreyLight"},[(_vm.isStripePaymentReply)?_c('router-view'):_c('authentication-view',{attrs:{"login-service":_vm.loginService}})],1):_vm._e(),(!_vm.ieBlock &&
                  !_vm.offline &&
                  _vm.loginService.loginCompleted() ||
                  _vm.$route.path==='/termsOfUse' ||
                  _vm.$route.path==='/privacy' ||
                  _vm.$route.path==='/imprint')?_c('v-main',{staticClass:"bgdGreyLight"},[_c('router-view'),(_vm.user)?_c('progress-dialog',{attrs:{"waiting-for-reply":_vm.changingUser,"config-prop":{text: ''}}},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.$t('common.isLoaded'))+"! ")]):_vm._e()],1):_vm._e(),_c('terms-confirmation')],1)}
var staticRenderFns = []

export { render, staticRenderFns }