<template>
    <div>
        <tool-tip :config-prop="cartBtnConfigs.toolTip">
            <btn-text :config-prop="cartBtnConfigs"
                      @click="clickItem()"
                      :disabled="disabled">
            </btn-text>
        </tool-tip>
        <v-snackbar v-model="snackbarBought" color="success">
            {{$t('shop.alreadyBought')}}
        </v-snackbar>
    </div>
</template>

<script>
    import BtnText from "@/utilities/components/controls/btnText";
    import ToolTip from "@/utilities/components/displays/toolTip";

    export default {
        components: {
            ToolTip,
            BtnText},
        name: "shop-item-cart-btn",
        props: ['licenseIsInCart', 'licenseIsInWallet', 'disabled'],
        data: () => ({
            snackbarBought: false,
        }),
        computed: {
            cartBtnConfigs(){
                return this.licenseIsInWallet ?
                    {
                      icon: 'mdi-wallet',
                      color: 'success',
                      elevation: 0,
                        toolTip: {
                          i18n: 'shop.alreadyBought',
                          breakpoint: 'smAndUp',
                        },
                    }
                    : this.licenseIsInCart ?
                    {
                      icon: 'mdi-cart-check',
                      color: '#ECEFF1',
                      dark: true,
                      elevation: 2,
                        toolTip: {
                            i18n: 'shop.removeFromCart',
                            breakpoint: 'smAndUp',
                        },
                    } :
                    {
                      icon: 'mdi-cart-plus',
                      color: 'secondary',
                      dark: true,
                      elevation: 2,
                        toolTip: {
                            i18n: 'shop.addToCart',
                            breakpoint: 'smAndUp',
                        },
                    };
            },
        },
        methods: {
            clickItem(){
                if ( this.licenseIsInWallet ) {
                    this.snackbarBought = true;
                } else {
                    this.$emit('itemClicked');
                }
            },
        },
    }
</script>

<style scoped>

</style>