<template>
    <div class="px-5">
    <!-- logo -->
        <div class="text-center pt-15 pb-5">
            <img src="@/assets/oym_logo_final_black.svg"
                 :width="$vuetify.breakpoint.xsOnly?'200px':'300px'"/>
        </div>
    <!-- alert message -->
        <alert-message :config-prop="{show: isSuccessful}" style="max-width: 600px" class="mx-auto mt-8">
            <p>{{$t('stripe.success1')}}</p>
            {{$t('stripe.success2')}}
        </alert-message>
        <alert-message :config-prop="{show: !isSuccessful}" style="max-width: 600px" class="mx-auto mt-8"
                       config-package="error">
            {{$t('stripe.failure')}}
        </alert-message>
    </div>
</template>

<script>
import AlertMessage from "@/utilities/components/displays/alertMessage";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export default {
    name: "stripePaymentReply",
    components: {AlertMessage},
    computed: {
        isSuccessful(){ return this.$route.query.success === 'true'}
    },
    created() {
        this.processStripeReply()
    },
    methods: {
        processStripeReply(){
            if ( !this.isSuccessful ) return
            FirebaseWrite.update('requestAnonymousSales', this.$route.query.id,
                {tsLastUpdate: TimeStamp.getGMTTimestamp()})
        }
    },
}
</script>

<style scoped>

</style>