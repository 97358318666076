<template>
    <v-dialog v-model="dialog" persistent fullscreen>
        <toolbar :showSave="false" :title="$t('profile.change')"></toolbar>
        <div class="pb-15 px-4">
            <alert-message config-package="error" class="mt-4"
                           :config-prop="imageAlert">
                {{$t('profile.imageMissing')}}
            </alert-message>
            <user-profile :user="user"
                          :fieldList="userFieldList"
                          :storage-source-path="storageSourcePath"
                          storage-folder="profileImages"
                          :config-prop="userProfilConfig"
                          @blockedClick="openDialog">
                <v-subheader v-if="parentAuthAlert.show || parentConfirm"
                             class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 py-4 px-0">
                    {{$t('common.documents')}}
                </v-subheader>
                <alert-message config-package="error"
                               :config-prop="parentAuthAlert">
                    <p>{{$t('profile.parentAuthMissing')}}</p>
                    <p>{{$t('profile.parentAuthDownload')}}</p>
                    <a class="white--text"
                       href="https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/HaftungErziehungsberechtigte.pdf?alt=media&token=34ff2a0f-39a1-4f4e-9790-6b7f4923193a"
                       target="_blank">{{$t('common.documentDraft')}}</a>
                </alert-message>
                <profile-edit-document v-if="user.userIsUnder14()">
                </profile-edit-document>
                <p v-if="parentAuthAlert.show || parentConfirm"
                             class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4 px-0">
                    {{$t('common.personalData')}}
                </p>
                <!--<alert-message config-package="error" class="mt-7"
                               :config-prop="kidUsageTypeAlert">
                    {{$t('profile.usageType')}}
                </alert-message>-->
                <single-line-edit v-if="user.userIsUnder14() && user.uid.length===28"
                                  :data-field="parentConfirmRequired"
                                  :data-document="user"
                                  collection="users"
                                  :config-prop="{}"
                                  class="mt-6">
                </single-line-edit>
                <div v-if="$vuetify.breakpoint.smAndUp" class="text-center mt-7">
                    <btn-text @click="openPrint()"
                              :config-prop="configs.printBtn">
                        {{$t('profile.printQR')}}
                    </btn-text>
                </div>
            </user-profile>
            <div v-if="user.uid.length===28" ref="messages">
                <divider-with-text class="mb-1 mt-3 mb-n3"
                    :config-prop="messages ? {text: {css: 'accentTxt font-weight-medium font16 text-uppercase space1'}}
                    : {}">
                    {{$t('messages.profileHeader')}}</divider-with-text>
                <single-line-edit :data-field="emailOptIn"
                                :data-document="user"
                                collection="users"
                                :config-prop="{labelUppercase: false}"
                                class="mt-6">
                </single-line-edit>
                <v-divider class="mt-1 mb-n2"></v-divider>
                <single-line-edit :data-field="infoEmailOptOut"
                                  :data-document="user"
                                  collection="users"
                                  :config-prop="{labelUppercase: false}"
                                  class="mt-6">
                </single-line-edit>
            </div>
            <required-information-list :required-information-data="{}"
                                       :user-edit="true"
                                       :organisations="organisations"
                                       :is-profile="true"/>
            <profile-multi-user>
            </profile-multi-user>
            <profile-delete-request>
            </profile-delete-request>
        </div>
        <v-dialog v-model="blockedDialog">
            <v-card class="white" style="z-index: 15">
                <v-card-title class="text-h5 white lighten-2">
                    {{$t('profile.settingBlocked')}}
                </v-card-title>
                <v-card-text class="pb-0">
                    {{$t('profile.settingBlockedText')}}
                </v-card-text>
                <v-card-actions class="pt-0 pr-5">
                    <v-spacer></v-spacer>
                    <v-btn text @click="blockedDialog=false">ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import Toolbar from "@/utilities/components/controls/toolbar";
    import UserProfile from "@/utilities/components/userProfile/userProfile";
    import {USER_FORM_CONFIG} from '@/config/forms/userFormConfig';
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import SingleLineEdit from "@/utilities/components/controls/singleLineEdit";
    import ProfileEditDocument from "@/components/profile/profileEditParentConfirm";
    import ProfileMultiUser from "./profileMultiUser";
    import CountriesService from '@/utilities/services/countries/countriesService';
    import BtnText from "../../utilities/components/controls/btnText";
    import ProfileDeleteRequest from "./profileDeleteRequest";
    import RequiredInformationList from "@/components/requiredInformation/requiredInformationList";
    import DividerWithText from "@/utilities/components/displays/dividerWithText";

    export default {
        components: {
            DividerWithText,
            RequiredInformationList,
            ProfileDeleteRequest,
            BtnText,
            ProfileMultiUser,
            ProfileEditDocument, SingleLineEdit, AlertMessage, UserProfile, Toolbar },
        name: "profile-edit",
        computed: {
            user(){ return this.$store.getters.user },
            parentConfirm() {return this.$store.state.realtime.parentConfirm },
            userFieldList(){
                let configTemp = JSON.parse(JSON.stringify(USER_FORM_CONFIG))
                configTemp.nationality.items = CountriesService.getCountryListSortedWithPriority(this.$i18n.locale)
                configTemp.nationality.itemText = this.$i18n.locale;
                let list = ComponentConfigService.getArrayFromConfigParameter(configTemp);
                list.forEach( (item) => {
                    if(['firstName','lastName','birthday','sex'].indexOf(item.fieldName) !== -1){
                        item.blocked = this.$store.state.realtime.userLicenses &&
                        Object.keys(this.$store.state.realtime.userLicenses.licenses).length>0
                    }
                });
                return list;
            },
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; },
            imageAlert(){
                return {
                    show: this.user.image.url === '',
                    text: false,
                }
            },
            kidUsageTypeAlert(){
                return {
                    show: this.user.under14AndConfirmTypeUndefined(),
                    text: false,
                }
            },
            parentAuthAlert(){
                return {
                    show: this.user.under14ConfirmRequiredButNotUploaded(
                        this.parentConfirm,
                        this.$store.state.local.parentConfirmAttemptedToBind),
                    text: false,
                }
            },
            shoppingCart(){
                return this.$store.state.shoppingCart;
            },
            organisations(){ return this.$store.state.organisations },
        },
        data: () => ({
            messages: null,
            dialog: true,
            blockedDialog: false,
            userProfilConfig: {
                profileImage: {
                    profileImageEdit: {
                        fullScreenDialog: {
                            card: {
                                color: '#ECEFF1'
                            }
                        }
                    }
                },
                singleLineEdit: {
                    fullScreenDialog: {
                        card: {
                            color: '#ECEFF1'
                        }
                    }
                }
            },
            parentConfirmRequired:  {
                fieldName: 'parentConfirmRequired',
                cols: {xs: 12, sm: 12},
                type: 'radioGroup',
                i18n: 'profile.usageTypeHeader',
                rules: [{ruleName: 'required',
                    messageText: 'utilities.services.formRules.pleaseSelect',
                    active: true}],
                options: [
                    {label: 'profile.childUsage', value: true},
                    {label: 'profile.parentUsage', value: false},

                ],
                row: false,
            },
            emailOptIn:  {
                fieldName: 'emailOptIn',
                cols: {xs: 12, sm: 12},
                type: 'switch',
                i18n: 'common.emailOptInEdit',
                rules: [],
                path: 'confirmations',
                row: false,
            },
            infoEmailOptOut:  {
                fieldName: 'infoEmailOptOut',
                cols: {xs: 12, sm: 12},
                type: 'switch',
                i18n: 'common.infoEmailOptOutEdit',
                rules: [],
                path: 'confirmations',
                row: false,
                invertValue: true,
                switchLabels: {
                    off: 'common.activated', on: 'common.disabled'
                }
            },
            configs: {
                printBtn: {
                    color: 'secondary',
                    icon: 'mdi-printer'
                }
            }
        }),
        mounted() {
            this.srcollToMessages()
        },
        methods: {
            srcollToMessages(){
                this.messages = this.$route.hash.replace("#", "");
                if ( this.messages ) this.$nextTick(()=> this.$refs[this.messages].scrollIntoView());
            },
            async bindParentConfirmDoc(){
                await this.$store.dispatch('bindRealtimeDocument', {
                    collection: 'parentConfirms',
                    storeField: 'parentConfirm',
                    docId: this.user.uid,
                });
                this.$store.state.local.parentConfirmAttemptedToBind = true;
            },
            openDialog(){
                this.blockedDialog=true
            },
            clearUserFromShoppingCart(){
                if ( this.shoppingCart ) {
                    this.shoppingCart.clearUser(this.user.uid)
                } else {
                    sessionStorage.removeItem('shoppingCart')
                }
            },
            openPrint(){
                this.$router.push('profilePrint')
            }
        },
        watch: {
            'user.parentConfirmRequired'(){
                if ( !this.$store.state.local.parentConfirmAttemptedToBind &&
                      this.user.parentConfirmRequired===true ) {
                    this.bindParentConfirmDoc();
                }
            },
            'user.birthday'(){
                this.clearUserFromShoppingCart();
            },
            'user.sex'(){
                this.clearUserFromShoppingCart();
            },
            'user.firstName'(){
                this.clearUserFromShoppingCart();
            },
            'user.lastName'(){
                this.clearUserFromShoppingCart();
            },
            /*'user.image.url'(to, from){
                if ( to && !from && this.$route.query.isNewUser==='true' ) {
                    this.$router.push('/');
                }
            }*/
        }
    }
</script>

<style scoped>

</style>