var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{'cardContentDyn': _vm.$vuetify.breakpoint.xsOnly},
              {'cardContentFix': _vm.$vuetify.breakpoint.smAndUp}]},[(!_vm.userPass.style || _vm.userPass.style.cardType !== 'customized')?_c('div',[_c('div',{class:[{'fontPassDate': _vm.$vuetify.breakpoint.xsOnly},
                      {'font18': _vm.$vuetify.breakpoint.smAndUp},
                      'cardDateTop', _vm.textColor]},[_vm._v(" "+_vm._s(_vm.userPass.until.substring(0,4))+" ")]),(_vm.organisation && _vm.organisation.short)?_c('img',{staticClass:"cardLogo",attrs:{"height":"30%","alt":_vm.organisation.short,"src":_vm.organisation.logo}}):_vm._e(),_c('div',{class:[{'titleDyn fontPassText': _vm.$vuetify.breakpoint.xsOnly},
                      {'titleFix font20': _vm.$vuetify.breakpoint.smAndUp},
                      'lineHeight-1-0 mt-1 text-left pl-3', _vm.textColor]},[_c('div',{class:[{'fontPassText': _vm.$vuetify.breakpoint.xsOnly},
                          {'font18': _vm.$vuetify.breakpoint.smAndUp},
                          {'wordBreak': !_vm.userPass.style ||
                                        !_vm.userPass.style.noWordBreak || // new as of verion 2023
                                        _vm.userPass.style.noWordBreak==='false'}, // in old passes
                           'space1 text-uppercase']},[_vm._v(" "+_vm._s(_vm.passTitle)+" ")]),_c('div',{staticClass:"font-weight-black space05 text-uppercase"},[(!_vm.userPass.style || !_vm.userPass.style.cardType)?_c('span',[_vm._v("PASS")]):_c('span',[_vm._v(_vm._s(_vm.userPass.style.cardType))])])]),_c('div',{class:[{'fontPassUserName': _vm.$vuetify.breakpoint.xsOnly},
                  {'font12': _vm.$vuetify.breakpoint.smAndUp},
                  'nameFooter font10 lineHeight-1-2 text-uppercase text-truncate',
                  _vm.textColor]},[_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])])]):_c('wallet-passes-content-customized',{attrs:{"user":_vm.user,"user-pass":_vm.userPass,"organisation":_vm.organisation}}),_c('profile-avatar',{staticClass:"avatar",attrs:{"user":_vm.user,"config-prop":_vm.configAvatar,"storage-source-path":_vm.storageSourcePath}}),_c('wallet-missing-consents-overlay',{attrs:{"type":"pass","card-selected":_vm.passSelected,"event-id":_vm.eventId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }