<template>

    <div>
        <v-card style="border: solid 1px #e2e2e2; max-width: 600px"
                :class="[{'mx-auto': $vuetify.breakpoint.smAndUp}, 'mt-3']">
            <div v-if="shoppingCart.status!=='paid'">
                <!--<shop-cart-total-voucher/> 16.1.2023 OLD => NOT USED ANYMORE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                <v-row no-gutters align="center"
                       class="fontInter font-weight-bold primary--text font16
                    lineHeight-1-2 py-2 space03">
                    <v-col class="grow pl-4 text-uppercase">
                        {{$t('shop.totalSum')}}
                    </v-col>
                    <v-col class="shrink" style="white-space: nowrap">
                        {{getSumObject.price.toFixed(2)}} {{getSumObject.currency}}
                    </v-col>
                    <v-col class="shrink py-0 pl-1 pr-4">
                        <v-btn icon :disabled="shoppingCart.purchaseOrder!==null"
                               @click="deleteCart">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <shop-cart-event-vouchers-total :value="getSumObject.price" :currency="getSumObject.currency"/>
            </div>
            <div v-else-if="getSumObject.price>0" class="fontInter font-weight-bold primary--text font16
                    lineHeight-1-2 py-4 space03 px-4 text-uppercase">
                {{$t('shop.paymentSuccessful')}}
            </div>
            <div v-if="getSumObject.price+getSumObject.voucherTotal.sum>0">
                <alert-message :config-prop="alertMaxItemsExceeded">
                    {{$t('shop.maxItems', {maxItems: shoppingCart.maxItems})}}
                </alert-message>
                <div v-if="!maxItemsExceeded && shoppingCart.status==='' &&
                            !purchaseOrder && sellingOrganisation.refundPolicyDocument">
                    <v-checkbox  class="mx-5"
                                v-model="cancellationPolicyConfirmed" hide-details
                                :label="$t('shop.cancellation')">
                    </v-checkbox>
                    <a class="ml-13"
                       :href="sellingOrganisation.refundPolicyDocument"
                       target="_blank">
                        {{$t('common.learnMore')}}
                    </a>
                </div>
                <div v-if="!purchaseOrder" class="px-3 pb-3 mt-4">
                    <btn-text @click="checkIfVouchersIgnored()"
                              :config-prop="configs.checkOutBtn"
                                :disabled="maxItemsExceeded || !cancellationPolicyConfirmed">
                        {{$t('shop.buyNow')}}
                    </btn-text>
                </div>
                <alert-message :config-prop="configs.poConfirm">
                    {{$t('shop.orderCreated')}}
                </alert-message>
                <stripe-interface-checkout v-if="purchaseOrder && stripeData"
                                           :component-customization="componentCustomization"
                                           :stripeData="stripeData"
                                           :purchase-order="purchaseOrder"
                                           @paymentStarted="paymentStarted"
                                           @isCancelled="clearPurchaseOrderFromCart"
                                           @isPaid="resetShoppingCart"
                                           @checkoutCreationFailed="handleCheckoutCreationFailed">
                </stripe-interface-checkout>
                <btn-text v-if="poCancel && !shoppingCart.statusCheckout"
                          @click="requestPurchaseOrderCancellation()"
                          :config-prop="configs.cancelBtn" class="mx-4">
                    {{$t('shop.cancelOrder')}}
                </btn-text>
                <div v-if="shoppingCart.status==='paid'"
                     class="text-right mx-3 mb-3">
                    <btn-text @click="back()"
                            :config-prop="configs.backBtn">
                        {{$t('shop.gotoWallet')}}
                    </btn-text>
                </div>
            </div>
            <shop-cart-total-free v-else>
            </shop-cart-total-free>
            <!-- po creation alert message -->
            <alert-message :config-prop="configs.poError">
                {{$t('shop.poError')}}
            </alert-message>
            <shop-event-footer :organisation-id="sellingOrganisationId"/>
            <!--<shop-fir2022footer class="mb-1"></shop-fir2022footer>-->
        </v-card>
    <!-- confirm dialog -->
        <confirm-dialog :dialog-data="dialogData"
                        :config-prop="configs.confirmDialog"
                        @reply="cancelPurchaseOrder">
            {{$t('shop.cancelPurchaseOrderDescription')}}
        </confirm-dialog>
        <confirm-dialog :dialog-data="dialogDataVouchers"
                        :config-prop="configs.confirmDialogVouchers"
                        @reply="processVoucherConfirm">
            {{$t('shopVoucher.ignoreText')}}
        </confirm-dialog>
        <v-snackbar :timeout="-1" v-model="expireSnack" color="error" multi-line>
            <div>{{$t('shop.expired')}}</div>
            <div class="font-weight-bold">{{[...expired.events, ...expired.competitions, ...expired.compClasses].join(',')}}</div>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="expireSnack = false">
                    ok
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {ConfigStripe_moduleInstance} from "@/config/modules/stripe/configStripe_moduleInstance";
import BtnText from "@/utilities/components/controls/btnText";
import StripeInterfaceCheckout from "@/modules/stripe/views/stripeCheckoutOnly";
import AlertMessage from "@/utilities/components/displays/alertMessage";
import ConfirmDialog from "@/utilities/components/dialogs/confirmDialog";
//import ShopFir2022footer from "./special/fir2022/shopFir2022footer";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import ShopCartTotalFree from "./shopCartTotalFree";
//import ShopCartTotalVoucher from "./shopCartTotalVoucher";
import ShopEventFooter from "@/components/shop/shopEvent/shopEventFooter";
import ShopCartEventVouchersTotal from "@/components/shop/shopCart/shopCartEventVouchersTotal";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export default {
    components: {
        ShopCartEventVouchersTotal,
        ShopEventFooter,
        //ShopCartTotalVoucher,
        ShopCartTotalFree,
        //ShopFir2022footer,
        ConfirmDialog,
        AlertMessage,
        StripeInterfaceCheckout,
        BtnText
    },
    name: "shop-cart-total",
    computed: {
        sellingOrganisationId(){ return this.shoppingCart.getSellingOrganisation(this.shoppingCart.getPurchaseOrderItems(true).cartItems); },
        sellingOrganisation(){ return this.$store.state.organisations[this.sellingOrganisationId]},
        shoppingCart(){ return this.$store.state.shoppingCart },
        purchaseOrder(){ return this.shoppingCart.purchaseOrder},
        uid(){ return this.$store.getters.user.uid.substring(0,28)},
        getSumObject() { return this.shoppingCart.getSum(); },
        maxItemsExceeded(){return this.shoppingCart.countItems() > this.shoppingCart.maxItems;},
        alertMaxItemsExceeded(){
            return {show: this.maxItemsExceeded, color: 'error', css: 'mx-3'}
        }
    },
    data: () => ({
        expireSnack: false,
        expired: {
            events: [],
            competitions: [],
            compClasses: [],
        },
        cancellationPolicyConfirmed: false,
        componentCustomization: null,
        stripeData: null,
        dialogData: {
            open: false,
            query: {
                parameter: 'cancelPurchaseOrder',
                value: true,
            },
        },
        dialogDataVouchers: {
            open: false,
            query: {
                parameter: 'confirmVoucherIgnore',
                value: true,
            },
        },
        poCancel: false,
        configs: {
            confirmDialog: {
                title: {
                    i18n: 'shop.cancelPurchaseOrderHeader'
                },
                confirmBtn: {
                    configProp: {
                        color: 'secondary',
                    }
                },
            },
            confirmDialogVouchers: {
                title: {
                    i18n: 'shopVoucher.ignore',
                    css: 'success--text'
                },
                confirmBtn: {
                    configProp: {
                        color: 'success',
                    }
                },
            },
            checkOutBtn:{
                block: true,
                color: 'secondary',
            },
            poConfirm: {
                show: false,
                css: 'mx-3'
            },
            backBtn: {
                block: false,
                color: 'secondary'
            },
            cancelBtn: {
                block: true,
                color: '',
                css: 'mb-3'
            },
            poError: {
                show: false,
                css: 'mx-3',
                icon: 'mdi-alert',
                color: 'error'
            }
        }
    }),
    created(){
        this.setComponentCustomization();
        this.stripeData = this.sellingOrganisation ? {
            companyId: this.$store.state.config.stripe.companyId,// das ist number one solutions
            stripeAccountId: this.sellingOrganisation.accountId,
            stripeAccountIdDelete: 'deleteAccountIdTBD',
            uid: this.uid,
        } : null;
        this.poCancel = this.shoppingCart.purchaseOrder !== null;
    },
    methods: {
        setComponentCustomization() {
            this.componentCustomization = new ConfigStripe_moduleInstance();
        },
        async createPurchaseOrder(){
            // important: any changes to purchase order must be reflected in OYM cashSaleRequest and according server functions
            const purchaseOrderItems = this.shoppingCart.getPurchaseOrderItems();
            const purchaseOrderData = this.shoppingCart.getPurchaseOrderData(
                purchaseOrderItems, this.uid, this.stripeData.stripeAccountId
            );
            if ( !purchaseOrderData ) { return } // add error handling here
            console.log ("-------------- CREATE PURCHASE ORDER", purchaseOrderData);
            const reply = await FirebaseWrite.add('purchaseOrders', purchaseOrderData);
            if ( reply.successful ) {
                this.$store.state.shoppingCart.purchaseOrder = reply.content;
                this.shoppingCart.setToSessionStorage();
                this.configs.poConfirm.show = true;
                this.poCancel = true;
            } else {
                this.configs.poError.show = true;
            }
            console.log ("reply", reply);
        },
        requestPurchaseOrderCancellation(){
            this.dialogData.open = true;
        },
        async checkIfVouchersIgnored(){
            const expired = await this.checkIfEventOrLicenseIsNotAvailableAnyMore()
            if ( expired ) return
            if ( this.shoppingCart.shoppingCartVouchers.allPossibleVouchersSelected() ) {
                this.createPurchaseOrder();
            } else {
                this.dialogDataVouchers.open = true;
            }
        },
            async checkIfEventOrLicenseIsNotAvailableAnyMore(){
                let expired = {
                    events: [],
                    competitions: [],
                    compClasses: [],
                }
                const todaysDateString = TimeStamp.getTodaysDateString()
                for ( let i = 0; i < Object.keys(this.shoppingCart.eventIdList).length; i++ ) {
                    const eventId = Object.keys(this.shoppingCart.eventIdList)[i]
                    const eventLatest = await FirebaseRead.readSingleDoc('events', eventId)
                    if ( !eventLatest.shop.display || todaysDateString > eventLatest.shop.until ||
                        (eventLatest.shop.from !== undefined && eventLatest.shop.from > todaysDateString) ||
                        eventLatest.status === 'pending') {
                        expired.events.push(eventLatest.name)
                    } else {
                        expired = await this.checkCompetition(expired, eventId)
                    }
                }
                const isExpired = expired.events.length>0 || expired.competitions.length>0 || expired.compClasses.length>0
                if ( isExpired ) {
                    this.expireSnack = true
                    this.expired = expired
                }
                return isExpired
            },
                async checkCompetition(expired, eventId){
                    const eventsCompetitions = await FirebaseRead.readSingleDoc('events_competitions', eventId)
                    if ( !eventsCompetitions ) return expired
                    Object.keys(eventsCompetitions.competitions).forEach( (compId) => {
                        const competition = eventsCompetitions.competitions[compId]
                        if ( competition.offline && this.shoppingCart.licenseIdList[competition.licenseId] ) {
                            expired.competitions.push(competition.name)
                        } else if ( Object.keys(competition.compClasses.length > 0 ) ) {
                            expired = this.checkCompClasses(expired, competition)
                        }
                    });
                    return expired
                },
                checkCompClasses(expired, competition){
                    Object.keys(competition.compClasses).forEach( (compClassId) => {
                        const compClass = competition.compClasses[compClassId]
                        if ( compClass.offline && this.shoppingCart.licenseIdList[compClass.licenseId] ) {
                            expired.compClasses.push(competition.name + ' ' + compClass.name)
                        }
                    });
                    return expired
                },

        processVoucherConfirm(confirmed){
            this.dialogDataVouchers.open = false;
            if ( !confirmed ) {
                this.createPurchaseOrder();
            }
        },
        async cancelPurchaseOrder(confirmed){
            this.dialogData.open = false;
            if ( confirmed ) {
                const reply = await FirebaseWrite.delete(
                    'purchaseOrders',
                    this.shoppingCart.purchaseOrder.id
                );
                if ( reply.successful ) {
                    this.clearPurchaseOrderFromCart();
                }
            }
        },
            clearPurchaseOrderFromCart(){
                this.$store.state.shoppingCart.purchaseOrder = null;
                this.$store.state.shoppingCart.status = '';
                this.$store.state.shoppingCart.statusCheckout = '';
                this.shoppingCart.setToSessionStorage();
                this.configs.poConfirm.show = false;
                this.poCancel = false;
            },
        resetShoppingCart(purchaseOrderId){
            if ( this.shoppingCart.purchaseOrder.id === purchaseOrderId ) {
                this.shoppingCart.reset(true);
            }
        },
        paymentStarted(){
            this.configs.poConfirm.show = false;
            this.poCancel = false;
            this.shoppingCart.setParameter('statusCheckout', 'created');
        },
        back(){
            this.$store.state.wallet.zoomed.section = '';
            this.$store.state.wallet.zoomed.documentId = '';
            this.$store.state.wallet.zoomed.document = null;
            if ( this.$route.query.success !== undefined ) {
                window.history.back();
                setTimeout(()=>{
                    this.$router.replace({path: '/', query: {tab: '2'}})
                }, 100)
            } else {
                this.$router.replace({path: '/', query: {tab: '2'}})
            }
        },
        deleteCart(){
            const eventsWithValidVouchers = this.shoppingCart.eventsWithValidVouchers;
            Object.keys(this.shoppingCart.eventIdList).forEach( (eventId) => {
                this.shoppingCart.deleteEventFromCart(eventId)
            });
            setTimeout(()=> {
                    this.shoppingCart.reset(false);
                    this.shoppingCart.eventsWithValidVouchers = eventsWithValidVouchers;
            }, 100);
            //window.history.back(); => not needed, is included in deleteEventFromCart
        },
        handleCheckoutCreationFailed(){
            this.poCancel = true;
            this.$store.state.shoppingCart.statusCheckout = '';
        }
    },
}
</script>

<style scoped>

</style>