<template>
    <div>
        <divider-with-text v-if="!useWithTermsConfirmation" class="mb-3 mt-3">
            {{$t('common.others')}}
        </divider-with-text>
        <span v-if="isChild">{{$t('profile.requestDeleteTextChild')}}</span>
        <span v-else>{{$t('profile.requestDeleteText')}}</span>
        <btn-text @click="requestDeletion()"
                class="mt-3">
            <span v-if="isChild">{{$t('profile.requestDeleteBtnChild')}}</span>
            <span v-else>{{$t('profile.requestDeleteBtn')}}</span>
        </btn-text>
        <v-snackbar v-model="showSnack" color="success">
            {{$t('profile.requestDeleteSuccess')}}
        </v-snackbar>
    </div>
</template>

<script>
    import DividerWithText from "@/utilities/components/displays/dividerWithText";
    import BtnText from "@/utilities/components/controls/btnText";
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";

    export default {
        components: {
            BtnText,
            DividerWithText},
        name: "profile-delete-request",
        props: ['useWithTermsConfirmation'],
        data: () => ({
            showSnack: false,
        }),
        computed: {
            uid(){ return this.$store.getters.user.uid; },
            isChild(){ return this.uid.length>28 }
        },
        methods: {
            async requestDeletion(){
                const reply = await FirebaseWrite.add('newMail',{
                    header: {
                        mailType: 'deleteUserRequest',
                        userId: this.uid
                    }
                });
                console.log ("reply", reply);
                if ( reply.successful ) {
                    this.showSnack = true;
                }
            }
        },
    }
</script>

<style scoped>

</style>