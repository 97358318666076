// Version 2.0 2021-11-19

export default class ConfigService {
// customize default config and locales
    static applyCustomization (defaultConfig, moduleCustomization, instanceCustomization, locales){
    // overwrites the default configs of a module
        // 1. with application customization for a certain module
                let customizedConfig = ConfigService.customizeConfig(defaultConfig, moduleCustomization, locales);
        // 2. with application customization for a certain instance (= instance/usage of module)
            if ( instanceCustomization ) {
                customizedConfig = ConfigService.customizeConfig(customizedConfig, instanceCustomization, locales);
            }
        // 3. with i18n locales if defined in customization of instance
            if ( customizedConfig.componentName &&
                customizedConfig.content !== undefined &&
                 Object.keys(customizedConfig.content).length > 0 )
            {
                customizedConfig.content = this.customizeI18nKeysInConfig(
                    customizedConfig.content, customizedConfig.componentName, locales
                );
            }
        customizedConfig.locales = locales.messages;
        customizedConfig.localesCustomization = instanceCustomization.locales;
        return customizedConfig;
    }

        static customizeConfig(config, customization, locales){
            Object.keys(customization).forEach( (key) => {
                if ( key === 'componentName' ) {
                // set componentName from component level customization
                    config.componentName = customization[key];
                } else if ( key !== 'locales' ) {
                // replace default config with customized config
                    if ( typeof customization[key] !== 'object' ) {
                        config[key] = customization[key];
                    } else {
                        if ( config[key] === undefined ) { config[key] = {} }
                        config[key] = ConfigService.customizeConfig (
                            config[key],
                            customization[key],
                            locales
                        )
                    }
                } else if (key === 'locales' && Object.keys(customization[key]).length > 0) {
                // customize locales
                    ConfigService.customizeLocales(customization.componentName, customization[key], locales);
                }
            });
            return config;
        }
            static customizeLocales(component, localesCustomization, locales){
                ConfigService.customizeLocalesByLanguage('de', component, localesCustomization, locales);
                ConfigService.customizeLocalesByLanguage('en', component, localesCustomization, locales);
            }
            static customizeLocalesByLanguage(language, component, localesCustomization, locales){
                // set customized path in locales not yet set (contains the customized keys)
                    if ( locales.messages[language].customized === undefined ) {
                        locales.messages[language].customized = {}
                    }
                // save customized locales in the locales object in en/customized/... and de/customized
                    locales.messages[language].customized[component] = localesCustomization[language];
            }

        static customizeI18nKeysInConfig(configContent, componentName, locales){
        // overwrite i18n keys in i18n-config e.g. from 'subcomponent.header' to
        // 'customized.component.subcomponent.header'
            Object.keys(configContent).forEach( (key) => {
                if ( typeof configContent[key] !== 'object' ) {
                    if ( key === 'i18n' ) {
                        configContent[key] = ConfigService.getCustomizedI18nIfNecessary(
                            configContent[key], componentName, locales
                        );
                    }
                } else {
                    configContent[key] = ConfigService.customizeI18nKeysInConfig(
                        configContent[key], componentName, locales
                    );
                }
            });
            return configContent;
        }
            static getCustomizedI18nIfNecessary(i18nKey, componentName, locales){
                let keyParts =  i18nKey.split('.');
                let customized = locales.messages.en.customized;
                if ( customized === undefined ) { return i18nKey }
                customized = customized[componentName];
                for ( let i = 0; i < keyParts.length; i++ ) {
                    customized = customized[keyParts[i]];
                    if ( customized === undefined ||
                        (typeof customized === 'object' &&
                         Object.keys(customized).length === 0 ) ) {
                        return i18nKey;
                    }
                }
                return 'customized.' + componentName + '.' + i18nKey;
            }

}