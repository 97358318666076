<template>
    <div>
    <!-- issueing organisation -->
        <v-row v-if="organisation" no-gutters>
            <v-col class="shrink pl-1 pr-4"><v-icon>mdi-domain</v-icon></v-col>
            <v-col class="grow">
                <div class="grey--text text--darken-1 caption text-uppercase mb-1">{{$t('voucherDetails.issuedBy')}}</div>
                <div>
                    <div class="lineHeight-1-4">{{organisation.name}}</div>
                    <div class="lineHeight-1-4" v-if="organisation.address.name2 !== '-'">{{organisation.address.name2}}</div>
                    <div class="lineHeight-1-4" v-if="organisation.address.street !== '-'">{{organisation.address.street}}</div>
                    <div class="lineHeight-1-4">{{organisation.address.zip}} {{organisation.address.city}} </div>
                    <div class="pt-2"></div>
                    <div class="lineHeight-1-4" v-if="organisation.email !== '-'">{{organisation.email}}</div>
                    <a v-if="organisation.web && organisation.web!=='-'" class="lineHeight-1-2"
                       :href="organisation.web" target="_blank">
                        {{organisation.web}}
                    </a>
                </div>
                <div v-if="isAdmin" class="mt-3">
                    <div class="grey--text text--darken-1 caption text-uppercase mb-0">{{$t('voucherDetails.issuePeriod')}}</div>
                    {{issuePeriod}}
                    <div class="grey--text text--darken-1 caption text-uppercase mt-2 mb-0">{{$t('voucherDetails.issueOrg')}}</div>
                    {{permittedOrganisation.name}}
                </div>
            </v-col>
            <v-col cols="12" class="py-3"><v-divider></v-divider></v-col>
        </v-row>
    <!-- description -->
        <v-row v-if="voucher.description || voucher.infoLink" no-gutters>
            <v-col class="shrink pl-1 pr-4"><v-icon>mdi-information</v-icon></v-col>
            <v-col class="grow">
                <div class="grey--text text--darken-1 caption text-uppercase mb-1">{{$t('voucherDetails.description')}}</div>
                <div v-if="voucher.description" class="lineBreak lineHeight-1-4">{{voucher.description}}</div>
                <a v-if="voucher.infoLink" class="lineHeight-1-2" :href="voucher.infoLink" target="_blank">
                    {{voucher.infoLink}}
                </a>
            </v-col>
            <v-col cols="12" class="py-3"><v-divider></v-divider></v-col>
        </v-row>
    <!-- description -->
        <v-row v-if="voucher.onlyOnline || voucher.isThirdParty" no-gutters>
            <v-col class="shrink pl-1 pr-4"><v-icon>mdi-hand-coin</v-icon></v-col>
            <v-col class="grow">
                <div class="grey--text text--darken-1 caption text-uppercase mb-1">{{$t('voucherDetails.redeemInfo')}}</div>
            <!-- third party vouchers only -->
                <div v-if="voucher.thirdPartyInformation && !hideRedeemInformation">
                    <div v-if="voucher.thirdPartyInformation.issuerCode" class="mb-2">
                        <div class="lineHeight-1-4">{{$t('voucherDetails.thirdPartyCode')}}</div>
                        <div class="font20 font-weight-bold">{{voucher.thirdPartyInformation.issuerCode}}</div>
                    </div>
                    <div v-if="voucher.thirdPartyInformation.redeemInformation"
                         class="mb-2 lineHeight-1-4 lineBreak">{{voucher.thirdPartyInformation.redeemInformation}}</div>
                    <!--<alert-message :config-prop="{show: voucher.thirdPartyInformation.requestUserDataForRedeem,
                                                  icon: 'mdi-alert', css: 'py-3'}" class="body-2 mb-1" >
                        {{$t('voucherDetails.requestUserDataForRedeem')}}
                    </alert-message>-->
                </div>
                <div v-else-if="voucher.isThirdParty">{{$t('voucherDetails.redeemInfoAviso')}}</div>
            <!-- oYM internal vouchers only -->
                <alert-message :config-prop="{show: voucher.onlyOnline && !voucher.isThirdParty, icon: 'mdi-alert', css: 'py-3'}"
                               class="body-2 mb-1" >
                    {{$t('voucherDetails.onlineOnly')}}
                </alert-message>
            </v-col>
            <v-col cols="12" class="py-3"><v-divider></v-divider></v-col>
        </v-row>
    <!-- web info -->
        <v-row v-if="voucher.isThirdParty && voucher.thirdPartyInformation.externalDescriptionLink" no-gutters>
            <v-col class="shrink pl-1 pr-4"><v-icon>mdi-web</v-icon></v-col>
            <v-col class="grow">
                <div class="grey--text text--darken-1 caption text-uppercase mb-1">{{$t('voucherDetails.web')}}</div>
                <!--<div>{{$t('voucherDetails.webDetails')}}:</div>-->
                <a class="lineHeight-1-2" :href="voucher.thirdPartyInformation.externalDescriptionLink" target="_blank">
                    {{voucher.thirdPartyInformation.externalDescriptionLink}}
                </a>
            </v-col>
            <v-col cols="12" class="py-3"><v-divider></v-divider></v-col>
        </v-row>
    <!-- issued by -->
        <div class="body-2 grey--text text--darken-1">{{$t('voucherDetails.footnote')}}</div>
    </div>
</template>

<script>
import AlertMessage from "@/utilities/components/displays/alertMessage";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
export default {
    name: "voucher-details-data-list",
    components: {AlertMessage},
    props: ['voucher', 'isAdmin', 'hideRedeemInformation'],
    computed: {
        organisations(){ return this.$store.state.organisations},
        organisation(){ return this.organisations?.[this.voucher.organisationId]},
        issuePeriod(){ return this.isAdmin ? TimeStamp.getTimeRangeFromTwoStringDates(
                                    this.voucher.issueFrom, this.voucher.issueUntil).replace('-', ' - ') : ''},
        permittedOrganisation(){ return this.isAdmin ?
            this.organisations?.[this.voucher.thirdPartyInformation.permittedOrganisationId] : null
        }
    },
}
</script>

<style scoped>
    .lineHeight-1-4 { line-height: 1.4;}
    .lineBreak {white-space: break-spaces;}
</style>