<template>
    <div>
        <div v-if="readOnly && !localValue">
            <slot></slot>
        </div>
        <v-checkbox v-else
                    v-model="localValue"
                    :dark="dark"
                    :readonly="readOnly">
            <template v-slot:label>
                <div>
                    <slot></slot>
                </div>
            </template>
        </v-checkbox>
    </div>
</template>

<script>
    //Version 1.1 2022-03-12
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

    export default {
        name: "single-line-edit-confirm-check",
        data: () => ({
            localValue: null,
        }),
        props: {
            dataValue: {default: null},
            readOnly: {default: false},
            dark: {default: false},
            targetValue: {default: 0}
        },
        created(){
            this.localValue = this.dataValue && this.dataValue > this.targetValue;
        },
        watch: {
            localValue(to, from){
                if ( from !== null && !this.readOnly ) {
                    this.$emit('changed', this.localValue ? TimeStamp.getGMTTimestamp() : 0);
                }
            },
            dataValue(){
                if ( this.readOnly ) {
                    this.localValue = this.dataValue && this.dataValue > this.targetValue;
                }
            }
        }
    }
</script>

<style scoped>

</style>