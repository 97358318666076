<template>
    <div>
        <!-- your data
            <p class="font-weight-medium text-uppercase grey--text
                                text--darken-2 space2 font14 pt-3">
                {{$t('wallet.yourData')}}
            </p>-->
        <!-- new 2023 -->
            <wallet-missing-consents header-info="consents.walletHeaderPass"/>
        <!--<div v-if="isMemberInCountry">-->

        <!-- select club   DEAKTIVIERT 10.1. 9:25 weil es gebuggt hat
            <single-line-edit class="px-3"
                    :data-field="clubIdField"
                    :data-document="user"
                    collection="users"
                    :config-prop="configClubId">
            </single-line-edit>-->
            <!--<v-divider class="mt-1 mb-5 mx-3"></v-divider>-->
        <!-- confirm confirmation fields OLD !!
            <div v-for="(confirmationField, i) in confirmationFields" :key="i">
                <v-row no-gutters align="center" justify="center"
                       class="px-3 " style="border-radius: 6px; letter-spacing: 0">
                    <v-col class="grow">
                        <single-line-edit :data-field="confirmationField.field"
                                          :data-document="user"
                                          :targetValue="confirmationField.targetValue"
                                          collection="users"
                                          :config-prop="{dark: !confirmationField.value, showRequiredAlert: true}">
                            <div class="white--text lineHeight-1-2 "
                                 v-if="!$route.query.singleLineEdit && !confirmationField.value">
                                {{$t(confirmationField.field.i18n + 'Missing')}}
                            </div>
                            <span v-if="$route.query.singleLineEdit===confirmationField.field.fieldName || confirmationField.value">
                            {{$t('wallet.statutes1')}}
                            <span @click.stop>
                                <a :href="confirmationField.field.link"
                                   target="_blank">{{$t(confirmationField.field.i18n)}}</a>
                            </span>
                            {{$t('wallet.statutes2')}}
                        </span>
                        </single-line-edit>
                    </v-col>
                </v-row>
                <v-divider class="mt-1 mb-5 mx-3"></v-divider>
            </div>-->
        <!--</div>-->
        <slot></slot>
    </div>
</template>

<script>
    //import SingleLineEdit from "@/utilities/components/controls/singleLineEdit";
    import WalletMissingConsents from "@/components/wallet/walletMissingConsents";

    export default {
        components: {WalletMissingConsents,
            //SingleLineEdit
        },
        name: "wallet-passes-user",
        /*data: () => ({
            statutes: null,
            country: '130',
            targetValue: 1647039600000,
        }),*/
        /*created(){
            if ( this.clubList.length === 0 ) {
                this.bindClubList();
            }
        },*/
        computed: {
            /*
            walletZoomed(){ return this.$store.state.wallet.zoomed;},
            organisationId() {return this.walletZoomed.document.organisationId},
            organisation() {
                return this.$store.state.organisations[this.organisationId] ?
                    this.$store.state.organisations[this.organisationId] :
                    null;
            },
            user(){ return this.$store.getters.user},
            //clubList() { return this.$store.getters.clubList },
            isMemberInCountry(){
                return this.user.organisations?.[this.organisationId] &&
                       this.user.organisations?.[this.organisationId].memberCountries &&
                       this.user.organisations?.[this.organisationId].memberCountries[this.country] === true;
            },*/
            /*
            clubIdField(){
                return {
                    fieldName: this.country,
                    path: 'organisations.' + this.organisationId + '.memberClubs',
                    cols: {xs: 12, sm: 12},
                    type: 'autoComplete',
                    i18n: 'utilities.services.user.club',
                    rules: [{ruleName: 'required',
                                messageText: 'utilities.services.formRules.pleaseSelect',
                                active: true}],
                    items: this.clubList,
                    itemText: 'name',
                    itemValue: 'id'
                };
            },*/
            /*
            confirmationFields(){
                return [
                    {field: this.getFieldSettings('statutes', 'wallet.statutes',
                            'https://www.racketlon.at/wp-content/uploads/2021/12/RFA-Statutenaenderung-Anzeige-30_06_2021.pdf'),
                     value: this.getFieldValue('statutes'), targetValue: this.getTargetValue('statutes')},
                    {field: this.getFieldSettings('covid', 'wallet.covid',
                            'https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/RFA%20Corona-Regeln%20vs9%20100122.docx.pdf?alt=media&token=7f5604eb-278f-4634-b083-94f470e18619'),
                     value: this.getFieldValue('covid'), targetValue: this.getTargetValue('covid')},
                    {field: this.getFieldSettings('privacy', 'wallet.privacy',
                            'https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/DatenschutzbestimmungenRFA.pdf?alt=media&token=67e1d54a-84a3-46f9-adfe-1a09877dde67'),
                     value: this.getFieldValue('privacy'), targetValue: this.getTargetValue('privacy')},
                    {field: this.getFieldSettings('termsOfUse', 'wallet.termsOfUse',
                            'https://firebasestorage.googleapis.com/v0/b/on-your-marks-prod.appspot.com/o/NutzungsbedingungenRFA.pdf?alt=media&token=f2503f2c-d961-4423-bc8e-8042b2e0d04e'),
                     value: this.getFieldValue('termsOfUse'), targetValue: this.getTargetValue('termsOfUse')},
                    {field: this.getFieldSettings('rulesOfCompetition', 'wallet.rulesOfCompetition',
                            'https://www.racketlon.at/wp-content/uploads/2022/03/Racketlon_Wettspielordnung_2022.pdf'),
                     value: this.getFieldValue('rulesOfCompetition'), targetValue: this.getTargetValue('rulesOfCompetition')},
                ]
            },*/
            /*
            clubId(){
                return this.user.organisations?.[this.organisationId] &&
                       this.user.organisations?.[this.organisationId].memberClubs &&
                       this.user.organisations?.[this.organisationId].memberClubs[this.country] !== '' &&
                       this.user.organisations?.[this.organisationId].memberClubs[this.country] !== undefined;
            },
            configClubId(){
                return {
                    disabled: !this.clubId,
                    dark: !this.clubId,
                }
            }*/
        },
        methods: {
            /*
            bindClubList(){
                this.$store.dispatch('bindRealtimeList', {
                    collection: 'clubs',
                    storeField: 'clubs',
                });
            },*/
            /*getFieldSettings(fieldName, i18n, link ){
                return {
                    fieldName: fieldName,
                    path: 'organisations.' + this.organisationId + '.confirmations.' + this.country,
                    cols: {xs: 12, sm: 12},
                    type: 'confirmCheck',
                    i18n: i18n,
                    rules: [],
                    link: link,
                };
            },*/
            /*getFieldValue(field){
                return this.user.organisations?.[this.organisationId] &&
                    this.user.organisations?.[this.organisationId].confirmations &&
                    this.user.organisations?.[this.organisationId].confirmations[this.country] &&
                    this.user.organisations?.[this.organisationId].confirmations[this.country][field] > 0 &&
                    this.user.organisations?.[this.organisationId].confirmations[this.country][field] !== undefined &&
                    (this.organisation && this.user.organisations?.[this.organisationId].confirmations[this.country][field] >
                     this.organisation.countries[this.country][field]);
            },
            getTargetValue(field){
                return this.organisation && this.organisation.countries[this.country][field] ?
                    this.organisation.countries[this.country][field] : 0;
            },*/
        },
    }
</script>

<style scoped>

</style>