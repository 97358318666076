import {Mother} from "@/utilities/models/mother";

export class StripeCheckout extends Mother {
    constructor(dataObject){
        super();
        this.purchaseOrderId = '';
        this.uid = '';
        this.stripeAccountId = ''; // account of the selling company
        this.stripe = {};
        this.successUrl = this.getUrlForStripe('success');
        this.cancelUrl = this.getUrlForStripe('cancel');
        this.items = [];
        this.vouchers = {};
        this.ts = 0;
    // database collection
        this.local.fireBaseCollection = 'stripe_checkouts';
        this.local.inProgress = false;
    // set data
        if(dataObject){
            this.setFromDataObject(dataObject);
            this.vouchers = dataObject.vouchers
        }
    }

    getUrlForStripe(urlType){
        let url = window.location.href;
        if ( url.indexOf('?') === -1 ) {
            url += '?' + urlType + '=true'
        } else {
            url += '&' + urlType + '=true'
        }
        return url;
    }
}