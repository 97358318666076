// Version 1.0 2022-04-12 - DH
import Vue from "vue";


Vue.filter('europeanDateFormat',(value)=> {

    if(!value) return ''
    if(value.includes('-')){
        let [year, month, day] = value.split('-')
        return day + '.' + month + '.' + year
    }
})