export class ConfigUtilities {
    constructor(){
        // for whole application and usage of this element
        // in all modules (also of different types)
        this.utilities = {
            components: {
                controls: {
                    plusMinus: {
                    },
                    btnText: {
                        no: {
                            outlined: true,
                            text: false,
                            rounded: true,
                        }
                    },
                    toolbar: {
                    }
                },
                dialogs: {
                    confirmDialog: {
                        default: {
                        },
                        delete: {
                        },
                        confirm: {
                        },
                        save: {
                        },
                    },
                    fullScreenDialog: {
                        card: {
                            color: '#ECEFF1',
                        }
                    }
                },
                displays: {
                    dividerWithText: {
                        row: {
                            css: 'bgdGreyLight'
                        }
                    }
                }
            },
            services: {
                firebase: {
                    version: 8,
                }
            }
        };
    }
}