<template>
    <div>
        <toolbar :title="$t('utilities.components.common.imprint')"
                 :show-save="false">
        </toolbar>
        <div class="px-5 termsContainer">
            <img src="@/assets/oym_logo-typo-thin_final.svg"
                 class="mt-6 mb-0" height="34"
                 alt="On your marks">
            <div class="caption pl-2 lineHeight-1-0">ist ein Service der Number One Solutions Gmbh</div>
            <v-divider class="mt-5"></v-divider>
            <p class="text-h6 py-3">{{$t('utilities.components.common.imprint')}}</p>
            <line-with-label label="imprint.company">
                Number One Solutions GmbH
            </line-with-label>
            <line-with-label label="imprint.ceo">
                Ing. Mag. Peter Robič, Ing. Rainer Mikulik
            </line-with-label>
            <line-with-label label="imprint.address">
                Zimmermanngasse 8, 1090 Wien
            </line-with-label>
            <line-with-label label="email">
                get@numberone.solutions
            </line-with-label>
            <line-with-label label="imprint.UID">
                ATU76781234
            </line-with-label>
            <line-with-label label="imprint.companyReg">
                554233 z
            </line-with-label>
            <line-with-label label="imprint.companyCourt">
                Wien
            </line-with-label>
            <line-with-label label="imprint.registeredOffice">
                Wien
            </line-with-label>
            <line-with-label label="imprint.business">
                Marketing, Softwareentwickung
            </line-with-label>
            <line-with-label label="App-Version">
                {{version}}
            </line-with-label>
        </div>
    </div>
</template>

<script>
    import Toolbar from "@/utilities/components/controls/toolbar";
    import LineWithLabel from "../../utilities/components/displays/lineWithLabel";

    export default {
        components: {
            LineWithLabel,
            Toolbar},
        name: "imprint",
        computed: {
            version(){return this.$store.state.realtime.version.version}
        },
        created(){
            setTimeout(()=>{
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behaviour: 'smooth',
                });
            },50)
        },
    }
</script>

<style scoped>
    p{margin-bottom: 5px}
    .termsContainer{max-width: 800px; margin: auto}
</style>