<template>
    <v-form v-if="rules" @submit.prevent
            ref="form" lazy-validation
            :class="configEl.form.css">
    <!-- request link -->
        <div v-if="!oobCode">
            <p class="pb-1" v-if="!status.linkMailRequested">
                {{$t(configEl.form.infoStart.i18n)}}
            </p>
            <v-text-field v-model="email"
                          :label="$t(configEl.email.i18n)"
                          :rules="[rules.required(email,
                                $t(configEl.email.required.i18n),true),
                                       rules.email(email,'',true)]"
                          :outlined="configEl.email.outlined"
                          :dense="configEl.email.dense"
                          clearable>
            </v-text-field>
            <btn-text v-if="!status.linkMailRequested"
                      :config-prop="configEl.sendLinkBtn"
                      @click="sendPasswordMail()">
            </btn-text>
        </div>
    <!-- new PW -->
        <div v-else-if="!status.linkIsExpired && !status.resetAndLoginCompleted">
            <p class="pb-1">{{$t(configEl.form.info.i18n)}}:
                <span class="primary--text">{{linkEmail}}</span>
            </p>
            <v-text-field :class="configEl.password.css"
                          v-model="password"
                          :label="$t(configEl.password.i18n)"
                          :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPW ? 'text' : 'password'"
                          @click:append="showPW = !showPW"
                          :rules="[rules.required(password, $t(configEl.password.required.i18n), true),
                                   rules.checkPassWordRules(password, '', configEl.passwordRule.activated)]"
                          outlined dense clearable>
            </v-text-field>
            <btn-text :config-prop="configEl.saveAndLoginBtn"
                      @click="resetAndLogin()">
            </btn-text>
        </div>
    <!-- error message -->
        <alert-message v-if="loginService.errorI18n"
                       config-package="error"
                       :config-prop="configEl.alertError">
            {{errorMessage}}
        </alert-message>
    </v-form>
</template>

<script>
    // Version 1.1 2022-01-18
    import {FormRules} from "@/utilities/services/form/formRules";
    import BtnText from "@/utilities/components/controls/btnText";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    export default {
        components: {
            AlertMessage,
            BtnText},
        name: "reset-pw-form",
        props: ['status', 'loginService', 'oobCode', 'configEl'],
        data: () => ({
            rules: new FormRules(),
            showPW: false,
            email: '',
            password: '',
        }),
        computed: {
            linkEmail() { return this.$route.query.email },
            errorMessage(){
                return this.loginService.getErrorMessage(this.$i18n.t(this.loginService.errorI18n));
            },
        },
        created(){
            this.setEmail();
        },
        methods: {
            setEmail(){
                if ( this.linkEmail ) {
                    this.loginService.setParameter('email', this.linkEmail);
                } else if ( this.loginService.email ) {
                    this.email = this.loginService.email;
                }
            },
            sendPasswordMail(){
                this.loginService.setParameter('email', this.email);
                this.loginService.sendPasswordMail();
                this.$emit('passwordMailSent');
            },
            async resetAndLogin() {
                if ( !this.$refs.form.validate() ) { return; }
            // change password
                this.loginService.setParameter('password', this.password);
                const processPWreset = await this.loginService
                    .confirmPasswordReset(this.oobCode, this.password);
            // login if pw change was successful
                if ( processPWreset.successful ) {
                    const login = await this.loginService.login();
                    if ( login.successful ) {
                        this.loginService.clearUserPending();
                        this.$emit('resetAndLoginCompleted');
                    }
                } else {
                // link most probably expired, offer option for new link
                    this.loginService.setParameter('errorI18n', '');
                    this.$emit('setLinkIsExpired');
                }
            },
        },
    }
</script>

<style scoped>

</style>