<template>
    <div>
        <div v-if="organisationIsActive || showTest"
            @click="setQuery(organisation.id)" :style="cardStyle" class="mt-3 mx-auto elevation-2 organisationCard">
            <v-row no-gutters>
                <v-col v-if="organisation.logo" class="pt-4 pb-2 pl-6 text-left">
                    <img :src="organisation.logo" height="64">
                </v-col>
                <v-col v-else :class="[textColor,
                        'py-2 pl-5 text-left font-italic font36 font-weight-black fontInter text-uppercase']">
                    <div>{{organisation.short}}</div>
                </v-col>
            </v-row>
            <img src="@/assets/CornerTag.svg" height="30" class="cornerTag">
            <img v-if="shopAreaSelect.getCornerTag(organisation, isOthers)"
                 :src="shopAreaSelect.getCornerTag(organisation, isOthers)" height="14" class="cornerTagIcon">
            <v-icon v-else class="plusIcon" small color="black">mdi-plus</v-icon>
            <v-row no-gutters class="error pa-3 white--text body-2 rounded-b" v-if="!organisationIsActive">
                <v-col class="shrink pr-2"><v-icon dark>mdi-alert</v-icon></v-col>
                <v-col class="grow">{{$t('shop.testMode')}}</v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export default {
    name: "shop-area-select-card",
    props: ['organisation', 'shopAreaSelect', 'isOthers', 'showTest'],
    computed: {
        textColor(){
            return !this.organisation.styles.color ? 'lightGrey--text' :
                    this.organisation.styles.dark ? 'lightGrey--text':'primary--text';
        },
        cardStyle(){
            return { backgroundColor: this.organisation?.styles?.color ?
                    this.organisation.styles.color : 'black',}
        },
        organisationIsActive(){
            return this.organisation.contracts?.base &&
                    TimeStamp.getTodaysDateString() <= this.organisation.contracts?.base &&
                    this.organisation.logo && this.organisation.logoDark &&
                    this.organisation.documents?.privacy
        },
    },
    methods: {
        setQuery(organisationId){
            this.$emit('selected', organisationId, this.isOthers);
        }
    },
}
</script>

<style scoped>
    .organisationCard {border-radius: 8px; position: relative}
    .cornerTag {position: absolute; top: 0; right: 0}
    .plusIcon { position: absolute; top: 2px; right: 0; }
    .cornerTagIcon {
        position: absolute; top: 3px; right: 1px;
        -webkit-transform: rotate(255deg);
        -moz-transform: rotate(255deg);
        -o-transform: rotate(255deg);
        -ms-transform: rotate(255deg);
        transform: rotate(255deg);
    }
</style>