
import {ServiceMother} from "@/utilities/services/serviceMother";
import store from "@/store/store";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import {i18n} from "@/plugins/i18n";
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

export class RequiredInformationService extends ServiceMother {
    constructor(requiredInformation, userEdit, queryParameter) {
        super();
        this.requiredInformation = requiredInformation;
        this.items = [];
        this.userEdit = userEdit
        this.user = store.getters.user;
        this.showHeader = false;
        this.queryParameter = queryParameter;
        //this.setItems(requiredInformation);
    }

    getDisplayedItems(){
        return this.items.filter(item => !item.hide);
    }

    refreshItemsFromRequiredInformation(){
        this.user = store.getters.user;
        this.items.forEach( (item) => {
            item.hide = this.user.requiredInformation && this.user.requiredInformation[item.id]
        });
        this.showHeader = this.getDisplayedItems().length > 0;
    }

    async setItems(requiredInformation){
        this.userEdit ? await this.setItemsFromUser() : await this.setItemsFromRequiredInformation(requiredInformation);
    }

    async setItemsFromUser(){
        let documentsToBeLoaded = []
        const requiredInformation = store.getters.user.requiredInformation
        if ( !requiredInformation ) return
        Object.keys(requiredInformation).forEach( (requiredInformationID) => {
            documentsToBeLoaded.push(requiredInformationID)
        });
        await this.setItemsCommon(documentsToBeLoaded)
    }

    async setItemsFromRequiredInformation(requiredInformation){
        let documentsToBeLoaded = []
        const todayString = TimeStamp.getTodaysDateString()
        Object.keys(requiredInformation).forEach( (requiredInformationID) => {
            const userInfo = this.user.requiredInformation?.[requiredInformationID]
            const userInfoStatus = this.user.requiredInformationStatus?.[requiredInformationID]
            if ( !userInfo || userInfoStatus?.rejected ||
                (todayString > userInfoStatus?.validUntil && userInfoStatus?.validUntil !== ''
                    && userInfoStatus?.validUntil !== undefined)){
                documentsToBeLoaded.push(requiredInformationID)
            }
        });
        await this.setItemsCommon(documentsToBeLoaded)
    }
        getDataField(item){
            const multiple = item.fieldSpec.allowMoreThanOneSelection === undefined ?
                false : item.fieldSpec.allowMoreThanOneSelection
            return {
                fieldName: item.id,
                path: 'requiredInformation',
                queryParameter: this.queryParameter, // NEW
                cols: {xs: 12, sm: 12},
                type: item.fieldSpec.type,
                i18n: item.label[i18n.locale],
                rules:  [{ruleName: multiple ? 'requiredArray' : 'required',
                    messageText: 'utilities.services.formRules.pleaseSelect',
                    active: true}],
                items: this.getTranslatedAndSortedItems(item),
                itemText: 'label',
                itemValue: 'value',
                options: this.getTranslatedOptions(item, item.fieldSpec.options),
                custom: true,
                multiple: multiple,
                hideClearable: item.fieldSpec.type !== 'autoComplete',
            }
        }
        getTranslatedOptions(item, options){
            if ( !options || options.length===0 ) { return null }
            return options.map(option => ({...option, label:
                    item.fieldSpec.customValue && item.fieldSpec.showCustomValueToUser ?
                        option.value + ' - ' +  option.label[i18n.locale] : option.label[i18n.locale]
            }))
        }
        getTranslatedAndSortedItems(item){
            if ( !item.fieldSpec.options || item.fieldSpec.options.length===0 ) { return null }
            return item.fieldSpec.options
                .map(option => ({...option, label:
                        item.fieldSpec.customValue && item.fieldSpec.showCustomValueToUser ?
                        option.value + ' - ' + option.label[i18n.locale] : option.label[i18n.locale]}))
                .sort((a, b) => a.label > b.label ? 1 : -1)//[{id: 'abc', name: 'Superclub'}],
        }
    refreshMissingItemsAfterUpdate(){
        const userNew = store.getters.user
        for ( let i = 0; i < this.items.length; i++ ) {
            const item = this.items[i]
            if ( userNew.requiredInformation?.[item.id] && !item.hide && !this.userEdit) {
                item.hide = true;
                this.showHeader = this.getDisplayedItems().length > 0;
                return
            }
        }
    }
// load requiredInformationDocuments
    async setItemsCommon(documentsToBeLoaded){
        this.items = await FirebaseRead.getDocListByIdArray('requiredInformation', documentsToBeLoaded);
        this.items = this.items.filter(item => item !== null)
            .map(item => (
                {...item,
                    currentValue: this.user.requiredInformation?.[item.id]??null,
                    dataField: this.getDataField(item),
                    hide: false,
                })).sort((a, b) => a.organisationId + a.dataField.i18n >
                            b.organisationId + b.dataField.i18n ? 1 : -1);
        this.showHeader = this.getDisplayedItems().length > 0;
    }
}