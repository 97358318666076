<template>
    <div>
        <div class="font-weight-medium text-uppercase grey--text text--darken-2 space2 font14 pt-4 px-3">
            {{$t('messages.previous')}}
        </div>
        <div v-for="(message, i) in previousMessages" :key="i" class="mx-3">
            <messages-msg-short :displayed-message="message" :archive="true"/>
        </div>
        <btn-text :config-prop="{color: 'secondary lighten-1', block: true, icon: 'mdi-message-plus'}"
                  class="mx-3 mt-2 mb-15 pb-10" @click="showPrevious" :disabled="allDisplayed">
            {{$t('messages.showPrevious')}}
        </btn-text>
    </div>
</template>

<script>
import BtnText from "@/utilities/components/controls/btnText";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import MessagesMsgShort from "@/components/messages/messagesMsgShort";
export default {
    name: "messages-previous",
    components: {MessagesMsgShort, BtnText},
    props: ['userId'],
    data: () => ({
        lastVisible: null,
        previousMessages: [],
        allDisplayed: false
    }),
    methods: {
        async showPrevious(){
            const reply = await FirebaseRead.readPagination(
                'messages', {
                    field: 'receiver.receiverUserId', operator: '==', value: this.userId
                }, 'receiver.readTs', 5, this.lastVisible
            )
            this.lastVisible = reply.lastVisible
            if ( reply.list.length > 0 ) {
                this.previousMessages = [...this.previousMessages, ...reply.list]
            } else {
                this.allDisplayed = true
            }
        }
    },
}
</script>

<style scoped>

</style>