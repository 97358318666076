<template>
    <div :class="[configEl.css, 'authentication']">
    <!-- logo -->
        <slot></slot>
    <!-- root (startpoint for login, forgot pw, sign up) -->
        <v-card v-show="showRoot" flat
                :width="configEl.root.width"
                class="mx-auto">
            <div :class="[configEl.root.css]">
                <login :login-service="loginService"
                       :config-prop="configEl.loginConfig"
                       :module-config="moduleConfig"
                       :class="configEl.root.login.css">
                    <btn-text :config-prop="configEl.root.btnSignup"
                              :module-config="moduleConfig"
                              @click="setRoute('authentication', 'signUp')">
                    </btn-text>
                </login>
                <div v-show="!loginService.waitingForReply"
                     class="mb-15">
                    <btn-text :config-prop="configEl.root.btnForgotPW"
                              :module-config="moduleConfig"
                              @click="setRoute('authentication', 'resetPW')">
                    </btn-text>
                </div>
            </div>
        </v-card>
    <!-- sign up -->
        <v-card v-show="showSignUp" flat
                :width="configEl.signUp.width"
                :class="[configEl.signUp.css, 'mx-auto']">
            <divider-with-text :class="configEl.signUp.header.css">
                {{translate(configEl.signUp.header.i18n)}}
            </divider-with-text>
            <sign-up :login-service="loginService"
                     :config-prop="configEl.signUpConfig"
                     :module-config="moduleConfig"
                     :class="configEl.signUpConfig.css">
            </sign-up>
        </v-card>
    <!-- verification -->
        <v-card v-if="showVerification" flat
                :width="configEl.verification.width"
                :class="[configEl.verification.css, 'mx-auto']">
            <divider-with-text :class="configEl.verification.header.css">
                {{translate(configEl.verification.header.i18n)}}
            </divider-with-text>
            <email-verification :login-service="loginService"
                                :config-prop="configEl.verificationConfig"
                                :module-config="moduleConfig">
            </email-verification>
        </v-card>
    <!-- reset/forgot PW -->
        <v-card v-if="showPWReset" flat
                :width="configEl.resetPW.width"
                :class="[configEl.resetPW.css, 'mx-auto']">
            <divider-with-text :class="configEl.resetPW.header.css">
                {{translate(configEl.resetPW.header.i18n)}}
            </divider-with-text>
            <reset-pw :login-service="loginService"
                      :config-prop="configEl.resetPWConfig"
                      :module-config="moduleConfig"
                      @back="back()">
            </reset-pw>
        </v-card>
    <!-- undo email change -->
        <v-card v-if="showUndoEmailChange" flat
                :width="configEl.undoEmailChange.width"
                :class="[configEl.undoEmailChange.css, 'mx-auto']">
            <divider-with-text :class="configEl.undoEmailChange.header.css">
                {{translate(configEl.undoEmailChange.header.i18n)}}
            </divider-with-text>
            <undo-email-change :login-service="loginService"
                               :config-prop="configEl.undoEmailChangeConfig"
                               :module-config="moduleConfig">
            </undo-email-change>
        </v-card>
    <!-- progress circle -->
        <div class="text-center">
            <v-progress-circular indeterminate color="primary"
                                 v-show="loginService.initialLoad">
            </v-progress-circular>
        </div>
    </div>
</template>

<script>
    // Version 1.2 2022-03-04
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import Login from "@/utilities/components/authentication/login";
    import {AUTHENTICATION_CONFIG} from '@/utilities/components/authentication/authenticationConfig';
    import DividerWithText from "@/utilities/components/displays/dividerWithText";
    import BtnText from "@/utilities/components/controls/btnText";
    import RouterServices from '@/utilities/services/router/routerServices';
    import SignUp from "./signUp";
    import EmailVerification from "./emailVerification";
    import ResetPw from "./resetPW";
    import UndoEmailChange from "./undoEmailChange";

    export default {
        components: {
            UndoEmailChange,
            ResetPw,
            EmailVerification,
            SignUp,
            BtnText,
            DividerWithText,
            Login},
        name: "authentication",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.authentication',
        // config
            configDefault: AUTHENTICATION_CONFIG,
        }),
        computed: {
            showRoot(){ return !this.showSignUp &&
                               !this.showVerification &&
                               !this.showPWReset &&
                               !this.showUndoEmailChange &&
                               !this.loginService.initialLoad;},
            showSignUp(){ return this.$route.query.authentication==='signUp' &&
                                !this.loginService.initialLoad;},
            showVerification(){ return this.$route.query.mode === 'verifyEmail' &&
                                       this.$route.query.oobCode !== '' &&
                                      !this.loginService.initialLoad;},
            showPWReset(){ return (this.$route.query.authentication === 'resetPW' ||
                                  (this.$route.query.mode === 'resetPassword' &&
                                   this.$route.query.oobCode !== '') ) &&
                                  !this.loginService.initialLoad;},
            showUndoEmailChange(){ return this.$route.query.mode === 'recoverEmail' &&
                                   this.$route.query.oobCode !== ''
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
            setRoute(parameter, value){
                RouterServices.pushRouteQueryParameter(this.$router, this.$route, parameter, value);
            },
            back(){
                window.history.back();
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>
    .authentication{width: 100%}
</style>