<template>
    <div v-if="shopEvent.hasValidVoucher() && !shopEvent.isFullyPurchasedStatus">
        <alert-message :config-prop="alertConfig">
            {{$t('shopVoucher.voucherAviso')}}
        </alert-message>
    </div>
</template>

<script>
import AlertMessage from "@/utilities/components/displays/alertMessage";
export default {
    name: "shop-event-vouchers-aviso",
    components: {AlertMessage},
    props: ['shopEvent'],
    data: () => ({
        alertConfig: {
            show: true,
            css: 'mx-4 mt-3 mb-0 body-2',
            color: 'success'
        }
    }),
}
</script>

<style scoped>

</style>