// Version 1.4 2022-02-03, 2022-03-16
import {Mother} from "@/utilities/models/mother";
//import {Address} from "@/utilities/models/address/address";
import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
import {i18n} from '@/plugins/i18n';

export class User extends Mother {
    /* MEMO:
        Es gibt nur ein User-Object mit allen Daten. Darauf hat nur der User
        selbst Zugriff.
        Welche Userdaten beim Login abgefragt werden, werden über die Config
        gesteuert (show=true).
        Auf APP-Ebene laufen dann Cloud Functions die bei User create/update
        beliebig viele Dokumente mit Auszügen der Userdaten erstellen,
        auf denen dann rechte für andere vergeben werden können.
        z.B. users_public mit Vorname, Nachname, Email => Zugriff für alle
        angemeldeten User.
     */
    constructor(dataObject){
        super();
        this.uid = '';
        this.email = '';
        this.userName = '';
        this.firstName = '';
        this.lastName = '';
        this.birthday = '';
        this.nationality = '';
        this.language = i18n.locale.toLowerCase() === 'de' ? 'de' : 'en';
        this.sex = '';
        this.parentConfirmRequired = null;
        //this.cloudFunctionInProgress = false;
    // adresses
        this.billingAddress = {}//new Address();
        this.shippingAddress = {}//new Address();
    // images
        this.image = {
            crop: '',
            url: '',
            urlMin: '',
        };
    // technical
        this.ts = 0;
    // database collection
        this.local.fireBaseCollection = 'users';
                    // HERE STARTS LOCAL APP EXTENSION
                        // confirmations
                            this.confirmations = {
                                termsOfUse: 0,
                                privacyApp: 0,
                                emailOptIn: false,
                                emailOptInHistory: null,
                                infoEmailOptOut: false,
                                infoEmailOptOutHistory: null,
                            };
                        // requiredInformation
                            this.requiredInformation = {}
                            this.requiredInformationStatus = {}
                        // organisations
                            this.organisations = {};
                        // childs
                            this.childs = [];
// AGAIN DEFAULT
    // set data
        if(dataObject){ this.setFromDataObject(dataObject); }
                    // HERE STARTS LOCAL APP EXTENSION
                            if(dataObject){
                                this.setOrganisationsFromDataObject(dataObject);
                                this.requiredInformation = dataObject.requiredInformation
                                this.requiredInformationStatus = dataObject.requiredInformationStatus??{}
                                if ( dataObject.confirmations ) this.confirmations = dataObject.confirmations
                                if ( dataObject.sports ) this.sports = dataObject.sports
                                if ( dataObject.childsDeleted ) this.childsDeleted = dataObject.childsDeleted
                            }
    }
// always necessary for user setup
    async saveInUsersUnconfirmed(uid){
        this.uid = uid;
        this.local.fireBaseCollection = 'users_unconfirmed';
        let reply = await this.saveNewWithDocId(uid);
        this.local.fireBaseCollection = 'users';
        return reply;
    }
// ---------------------------------------------------------------
// local extensions for app (dependent on app data selected above
    setOrganisationsFromDataObject(dataObject){
        this.organisations = dataObject.organisations;
    }
    isIncomplete(parentConfirm, parentConfirmAttemptedToBind, isUserForOverlay){
        return (
        // no image uploaded
            !this.image.url
            ||
        // user under 1 and parent confirm type not defined
            this.under14AndConfirmTypeUndefined()
            ||
        // user under 14, parent confirm required but not uploaded
            this.under14ConfirmRequiredButNotUploaded(parentConfirm, parentConfirmAttemptedToBind)
            ||
        // nationality not set
            !this.nationality
            ||
        // required information rejected
            (!isUserForOverlay && this.requiredInformationRejected())
        );
    }
    userIsUnder14(){
        return TimeStamp.checkIfAgeIsReached(this.birthday, 1) === false;
    }
    under14AndConfirmTypeUndefined(){
        return ( this.userIsUnder14() &&
                 this.parentConfirmRequired === null)
    }
    under14ConfirmRequiredButNotUploaded(parentConfirm, parentConfirmAttemptedToBind){
        if ( !parentConfirmAttemptedToBind ) { return false }
        return ( this.userIsUnder14() &&
                 this.parentConfirmRequired === true &&
                (!parentConfirm ||
                 !parentConfirm.fileName)
        )
    }
    requiredInformationRejected(){
        if ( !this.requiredInformationStatus ) return false
        let status = false
        Object.keys(this.requiredInformationStatus).forEach( (reqInfoId) => {
            if ( this.requiredInformationStatus[reqInfoId].rejected !== undefined  &&
                 this.requiredInformationStatus[reqInfoId].rejected > 0 ) status = true
        })
        return status
    }
    getNextChild(){
        let nextChild = 1;
        this.childs.forEach( (child) => {
            if ( parseInt(child) >= nextChild ) {
                nextChild = child + 1;
            }
        });
        if ( this.childsDeleted ) {
            this.childsDeleted.forEach( (child) => {
                if ( parseInt(child) >= nextChild ) {
                    nextChild = child + 1;
                }
            });
        }
        return nextChild;
    }
}
