<template>
    <div>
        <v-expand-transition>
            <div v-show="!walletZoomed.section && cards.length>0">
                <p class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4 px-4">
                    {{$t('common.passes')}}
                </p>
            </div>
        </v-expand-transition>
    <!-- card list -->
        <wallet-card-list :cards="cards" card-list="userPasses"
                          section="passes" stylesSource="passStyle">
        </wallet-card-list>
    <!-- zoomed detail information -->
        <div v-if="walletZoomed.section==='passes'"
             class="px-4 pb-15 mt-2">
            <!-- QR area -->
            <div class="detailContainer text-center mb-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-divider :class="dividerClass"></v-divider>
                        <qr-code-display :qrValue="qrValue" :size="size"
                                         class="d-inline-block">
                        </qr-code-display>
                    </v-col>
                </v-row>
            </div>
        <!-- data changeable by user -->
            <wallet-passes-user>
            <!-- read only data -->
                <p class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4">
                    {{$t('wallet.organisationData')}}
                </p>
                <v-list v-if="organisation" class="py-0">
                    <wallet-detail-item icon="mdi-calendar"
                                        :title="$t('common.valid')">
                        {{valid}}
                    </wallet-detail-item>
                    <wallet-detail-item icon="mdi-map-marker"
                                        :title="$t('common.issuedBy')">
                        <div>{{organisation.name}}</div>
                        <div>{{organisation.address.name2}}</div>
                        <div>{{organisation.address.street}}</div>
                        <div>{{organisation.address.zip}} {{organisation.address.city}}</div>
                    </wallet-detail-item>
                    <wallet-detail-item icon="mdi-email"
                                        title="Email">
                        {{organisation.email}}
                    </wallet-detail-item>
                    <wallet-detail-item icon="mdi-web"
                                        title="Web">
                        <web-link :link="organisation.web"></web-link>
                    </wallet-detail-item>
                </v-list>
            </wallet-passes-user>
        </div>
        <v-divider v-else-if="cards.length>0" class="mt-2"></v-divider>
    </div>
</template>

<script>
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp'
    import WalletCardList from "./walletCardList";
    import WebLink from "@/utilities/components/controls/webLink";
    import WalletDetailItem from "./walletDetailItem";
    import WalletPassesUser from "./walletPassesUser";
    import QrCodeDisplay from "@/utilities/components/qrCode/qrCodeDisplay";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'

    export default {
        components: {
            QrCodeDisplay,
            WalletPassesUser,
            WalletDetailItem,
            WebLink,
            WalletCardList},
        name: "wallet-passes",
        props: ['showArchive', 'todayString'],
        created(){
            this.emitNoOfCards()
        },
        computed: {
            tab(){ return this.$route.query.tab},
            cards(){
                return this.$store.state.realtime.userPasses ?
                    ObjectHandlingService
                        .getListFromObject(this.$store.state.realtime.userPasses.passes)
                        .filter(pass => this.showArchive ? pass.until < this.todayString :
                                                           pass.until >= this.todayString)
                        .sort((a, b) => a.from > b.from ? 1 : -1):
                    [];
            },
            dividerClass(){ return this.$vuetify.breakpoint.xsOnly ?
                'ma-4' : 'mt-4 mb-5'
            },
            passes() { return this.$store.state.realtime.userPasses },
            walletZoomed(){ return this.$store.state.wallet.zoomed;},
            organisation() {
                return this.$store.state.organisations[this.walletZoomed.document.organisationId] ?
                    this.$store.state.organisations[this.walletZoomed.document.organisationId] :
                    {styles: {color: 'grey', dark: true}}
            },
            valid(){
                return this.walletZoomed.document ? TimeStamp.getTimeRangeFromTwoStringDates(
                    this.walletZoomed.document.from,
                    this.walletZoomed.document.until) : '-'
            },
            user(){ return this.$store.getters.user },
            qrValue() {return 'uid:' + this.user.uid },
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
        },
        data: () => ({
            size: 220,
        }),
        methods: {
            emitNoOfCards(){
                this.$emit('noOfDisplayedCards', this.cards.length);
            }
        },
        watch: {
            cards(){
                this.emitNoOfCards()
            },
            tab(){
                if ( this.tab === '2' ) { this.emitNoOfCards() }
            }
        }
    }
</script>

<style scoped>
    .detailContainer{  width: 346px;
    }
</style>