<template>
    <div>
        <div v-if="userIsAllowedToShopThisLicense &&
               userIsAllowedToShopThisLicense.result"
             class="pt-3">
            <div v-if="!license" class="text-center pb-5" >
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
            </div>
            <div v-else class="mx-4">
                <v-row no-gutters>
                    <v-col class="grow pr-2">
                        <div class="fontInter font-weight-bold primary--text font18 lineHeight-1-2 py-1">
                            {{license.title}}
                        </div>
                        <v-card-subtitle class="pa-0">
                            {{license.subtitle}}
                        </v-card-subtitle>
                        <v-card-subtitle v-if="validityText"
                                         class="pa-0">
                            {{validityText}}
                        </v-card-subtitle>
                        <v-text-field v-if="requiresUserText" hide-details
                                      :readonly="licenseIsInWallet"
                                      :clearable="!licenseIsInWallet"
                                      v-model="userText" outlined dense class="mt-2 mb-1 mr-4"
                                      :label="licenseSettings.userText[$i18n.locale]"/>
                    </v-col>
                    <v-col class="shrink text-right pt-1">
                        <shop-item-price :base-price="basePrice"
                                         :discount="discount"
                                         :price-type="licenseSettings.priceType">
                        </shop-item-price>
                        <!--
                        <div class="text-no-wrap font-weight-bold lineHeight-1-2">
                            {{basePrice.price | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
                        </div>
                        <div v-if="discount"
                             class="text-no-wrap font-weight-bold lineHeight-1-2 success--text">
                            {{discount * -1 | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
                        </div>
                                            -->
                        <shop-item-cart-btn class="mt-2 mb-1"
                                            :disabled="!consentStatus || !requiredInformationStatus ||
                                                        !organisationIsActive"
                                            @itemClicked="processItemClick()"
                                            :license-is-in-cart="licenseIsInCart"
                                            :license-is-in-wallet="licenseIsInWallet">
                        </shop-item-cart-btn>
                    </v-col>
                    <v-col cols="12">
                        <alert-message :config-prop="infoAlert"
                                       class="caption mt-2">
                            <div style="white-space: break-spaces">{{license.licenseDescription}}</div>
                            <ul v-if="license.licenseDescriptionList && license.licenseDescriptionList.length>0">
                                <li v-for="(descriptionItem, i) in license.licenseDescriptionList" :key="i">
                                    {{descriptionItem}}
                                </li>
                            </ul>
                        </alert-message>
                    </v-col>
                </v-row>
            </div>
            <shop-item-vouchers :license-id="license.id" class="px-4 pt-2"/>
            <v-divider class="mt-3"></v-divider>
        </div>
        <div v-else-if="!userIsAllowedToShopThisLicense" class="text-center pt-5" >
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
            <v-divider class="mt-5"></v-divider>
        </div>
        <v-snackbar v-model="snackbarText" color="error">
            <span v-if="licenseSettings.userText">{{licenseSettings.userText[$i18n.locale]}}</span>!
        </v-snackbar>
    </div>
</template>

<script>
    import ConditionCheckService from "@/modules/_services/conditionCheckService";
    import ShopItemCartBtn from "../shopItemCartBtn";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import LicensePriceService from "@/modules/_services/licensePriceService";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import ShopItemPrice from "../shopItemPrice";
    import ShopItemVouchers from "@/components/shop/shopItem/shopItemVouchers";

    export default {
        components: {
            ShopItemVouchers,
            ShopItemPrice,
            AlertMessage,
            ShopItemCartBtn},
        name: "shop-item",
        props: ['licenseId', 'shopEvent', 'licenseSettings', 'consentStatus', 'requiredInformationStatus',
                'organisationIsActive'],
        data: () => ({
            conditionCheckService: new ConditionCheckService(),
            userText: '',
            snackbarText: false,
        }),
        computed: {
            requiresUserText(){ return this.licenseSettings.userText},
            license(){ return this.$store.state.shopLicenses &&
                              this.$store.state.shopLicenses[this.licenseId] ?
                              this.$store.state.shopLicenses[this.licenseId] : null},
            user(){ return this.$store.getters.user },
            shoppingCart(){ return this.$store.state.shoppingCart; },
            userLicenses(){
                let userLicenses = JSON.parse(JSON.stringify(this.$store.state.realtime.userLicenses));
                if ( !userLicenses ) { userLicenses = {licenses: {}} }
                if ( this.shoppingCart &&
                    this.shoppingCart.items[this.user.uid]  ) {
                    Object.keys(this.shoppingCart.items[this.user.uid]).forEach( (licenseId) => {
                        userLicenses.licenses[licenseId] = {};
                    });
                }
                return userLicenses;
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                // !!!!!!!!!!! LICENSES aus CART und WALLET zusammengeführt => noch ausführlich testen
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            },
            userLicensesServer(){
                return this.$store.state.realtime.userLicenses;
            },
            userIsAllowedToShopThisLicense(){
                return this.license && this.userLicenses ? this.conditionCheckService.checkConditionUser(
                    this.license.conditions,
                    this.user,
                    this.userLicenses) : null;
            },
            licenseIsInCart(){
                return this.shoppingCart && this.shoppingCart.items[this.user.uid] ?
                    this.shoppingCart.items[this.user.uid][this.licenseId] !== undefined : false;
            },
            licenseIsInWallet(){
                return this.userLicensesServer && this.userLicensesServer.licenses ?
                    this.userLicensesServer.licenses[this.licenseId] !== undefined : true;
            },
            toBeRemovedFromCart(){
                return this.licenseIsInCart &&
                       this.userIsAllowedToShopThisLicense &&
                      !this.userIsAllowedToShopThisLicense.result
            },
            validityText(){
                return this.license.validity.type === 'TIME_FRAME' ?
                    this.license.validity.startDate === this.license.validity.endDate ?
                    TimeStamp.getLocalStringInclWeekdayFromDateString(new Date(this.license.validity.startDate))
                        :
                    TimeStamp.getTimeRangeFromTwoStringDates(
                        this.license.validity.startDate,
                        this.license.validity.endDate,
                    )
                    : null;
            },
            basePrice(){
                return LicensePriceService.getCurrentBasePrice(
                    this.user, this.userLicenses, this.license, true
                )
            },
            discount(){
                return LicensePriceService.getCurrentPriceDiscount(
                    this.user, this.userLicenses, this.license, true
                )
            },
            infoAlert(){
                return {
                    show: this.license?.licenseDescription !== '' || this.license?.licenseDescriptionList?.length > 0,
                    css: 'mb-1 py-3',
                    icon: this.license?.licenseDescriptionList?.length > 0 ? false : 'mdi-information'
                }
            },
        },
        created(){
            if ( this.toBeRemovedFromCart ) {
                this.removeFromCart();
            }
            if ( this.licenseSettings.userText && this.shoppingCart.items[this.user.uid]?.[this.licenseId] ) {
                this.userText = this.shoppingCart.items[this.user.uid][this.licenseId].description
            } else if (this.licenseSettings.userText && this.licenseIsInWallet ){
                this.userText = this.userLicenses.licenses[this.licenseId].description;
            }
        },
        methods: {
            processItemClick(){
                if ( this.licenseSettings.userText && !this.userText ) {
                    this.snackbarText = true;
                    return;
                }
                console.log ("processItemClick", );
                if ( this.licenseIsInCart ) {
                    this.removeFromCart();
                } else {
                    this.addToCart();
                }
            },
            addToCart(){
                const item = {
                    uid: this.user.uid,
                    licenseId: this.licenseId,
                    amount: 1,
                    price: this.basePrice.price - this.discount,
                    currency: this.basePrice.currency,
                    reference: '',
                    description: this.licenseSettings.userText ? this.userText : '',
                    blockDelete: true,
                    sellingOrganisationId: this.shopEvent.data.sellingOrganisationId,
                };
                this.shoppingCart.addItem(item)
            },
            removeFromCart(){
                this.shoppingCart.removeItem(
                    this.user.uid, this.licenseId
                )
                this.userText = ''
            }
        },
        watch: {
            toBeRemovedFromCart(to, from){
                if ( to && !from ) {
                    this.removeFromCart();
                }
            },
            userText(){
                if ( this.userText?.length > 0 &&
                     this.shoppingCart.items[this.user.uid]?.[this.licenseId]?.description ) {
                    this.shoppingCart.items[this.user.uid][this.licenseId].description = this.userText
                } else if ( this.shoppingCart.items[this.user.uid]?.[this.licenseId] ) {
                    this.removeFromCart();
                }
            },
        }
    }
</script>

<style scoped>

</style>