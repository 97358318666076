// Version 2 2022-12-20

import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";


export class Mother {
    constructor() {
        this.id = null;
        this.ts = 0;
        this.tsLastUpdate = 0;
        this.local = {};
    }
// data setter
    setFromDataObject(dataObject) {
        ObjectHandlingService.setFromDataObjectByKey(this, dataObject)
    }
// service methods
    getListFromObject(dataObject){
        const list = [];
        Object.keys(dataObject).forEach( (key) => {
            let subObject = dataObject[key];
            subObject.id = key;
            list.push(subObject)
        });
        return list;
    }

// save, update, delete
    async save(saveNewWithId){
        if(!this.id) {
            this.ts = TimeStamp.getGMTTimestamp();
            return FirebaseWrite.add(this.local.fireBaseCollection, this.computeDatabaseObject());
        } else if (saveNewWithId) { // save new with predefined id
            this.ts = TimeStamp.getGMTTimestamp();
            return FirebaseWrite.set(this.local.fireBaseCollection, this.id, this.computeDatabaseObject());
        } else {
            // implemented for safety reasons
            return await this.update(this.computeDatabaseObject());
            // würde eintreten wenn id vorhanden aber kein changeobject übergeben wird
            //return await FirebaseWrite.update(this.local.fireBaseCollection, this.id, this.computeChangeObj());
        }
    }

    async saveNewWithDocId(docId){
        if(!this.id) {
            this.ts = TimeStamp.getGMTTimestamp();
            return FirebaseWrite.set(this.local.fireBaseCollection, docId, this.computeDatabaseObject());
        }
    }

    async update(updateObject){
    // output warning if fireBaseCollection is not set (hardcoded) in class constructor
        if ( !this.local.fireBaseCollection ) {
            console.error ("Please set local.fireBaseCollection on model!!!");
            return;
        }
    // perform update on db
        updateObject.tsLastUpdate = TimeStamp.getGMTTimestamp();
        this.tsLastUpdate = updateObject.tsLastUpdate;
        return await FirebaseWrite.update(
            this.local.fireBaseCollection,
            this.id,
            this.cleanObject(updateObject)
        );
    }

    async deleteObj(){
        return await FirebaseWrite.delete(this.local.fireBaseCollection, this.id)
    }

    cleanObject(objectTemp) {
        if ( !objectTemp ) { return objectTemp }
        try {
            delete objectTemp.id;
            delete objectTemp.local;
        } catch (e) {
            //console.log(e)
        }
        Object.keys(objectTemp).forEach((key) => {
            if ( Array.isArray(objectTemp[key])) {
                // do nothing
            } else if (objectTemp[key] /** check for null */ && typeof objectTemp[key] === 'object') {
                objectTemp[key] = this.cleanObject(objectTemp[key]);
            } else if (typeof objectTemp[key] === 'string') {
                objectTemp[key] = objectTemp[key].trim();
            }
        });
        return objectTemp;
    }

    computeDatabaseObject(){
        let objectTemp = JSON.parse(JSON.stringify( this ));
        return this.cleanObject(objectTemp)
    }

// save, update, delete WITH IMAGE!!!
    async saveWithImage(){
    // to be able to save with images, image-Fields must be defined in this.local imageParameters (Array)
        if ( !this.local.imageParameters ||
            this.local.imageParameters.length === 0) {
            console.error ("CONFIG ERROR: please specify the image parameters in 'this.local.imageParameters'!", );
            return;
        }
    // save new document
        if(!this.id) {
        // step 1: save the uploaded images temporarily until the document is saved / created
            this.setImagesTemp();
        // step 2: save the new document
            this.ts = TimeStamp.getGMTTimestamp();
            const reply = await FirebaseWrite.add(this.local.fireBaseCollection, this.computeDatabaseObject());
            if ( reply.successful ) {
            // step 3.1.: process image upload if successful
            // step 3.1.1: save the images to storage and get urls
                this.id = reply.content.id;
                const imageUpdateObject = await this.createImageUpdateObject();
            // step 3.1.2.: save the image urls in the document and return the reply
                return await this.update(imageUpdateObject);
            } else {
        // step 3.2: document creation failed => restore the uploaded images to the pending document
                this.resetImagesTemp();
                return reply;
            }
        } else {
    // update existing document
        // implemented for safety reasons
            await this.saveImageChanges();
            return await this.update(this.computeDatabaseObject());
        // würde eintreten wenn id vorhanden aber kein changeobject übergeben wird
            //this.tsLastUpdate = TimeStamp.getGMTTimestamp();
            //return await FirebaseWrite.update(this.local.fireBaseCollection, this.id, this.computeChangeObj());
        }
    }
    setImagesTemp(){
    // to save the images, the id of the document is required (as file name)
    // therefore the document must first be saved without urls
    // the uploaded images (as imageService-Objects) are temporarily stored in image Temp
        this.local.imageTemp = {};
        this.local.imageParameters.forEach( (imageParameter) => {
            this.local.imageTemp[imageParameter] = this[imageParameter];
        // setTemporarilyEmpty must be available in any imageService,
        // return value can be either an array, an empty object or null
            this[imageParameter] = this[imageParameter].setTemporarilyEmpty();
        });
    }
    resetImagesTemp(){
    // in case of any troubles during document creation, the uploaded images must be restored
        this.local.imageParameters.forEach( (imageParameter) => {
            this[imageParameter] = this.local.imageTemp[imageParameter];
        });
    }
    async createImageUpdateObject(){
        const updateObject = {};
    // save the image-files to the storage and build and return a firebase update object
    // for each not working with async
        for ( let i = 0; i < this.local.imageParameters.length; i++ ) {
            const imageParameter = this.local.imageParameters[i];
        // IMPORTANT: processFilesToStorage must be a function in each imageService
            this.local.imageTemp[imageParameter].setDocumentId(this.id);
            updateObject[imageParameter] = await this.local.imageTemp[imageParameter].processFilesToStorage()
        }
        return updateObject;
    }
    async saveImageChanges(){
        for ( let i = 0; i < this.local.imageParameters.length; i++ ) {
            const imageParameter = this.local.imageParameters[i];
            this[imageParameter] = await this[imageParameter].processFilesToStorage()
        }
    }

}

