<template>
    <div>
<!-- list item line (display only) -->
        <v-row no-gutters align="center" justify="center"
               :class="[{'error mb-3 pl-3 pt-2 pb-3': isMissing}]" style="border-radius: 6px">
            <v-col v-if="isMissing" class="shrink pr-3">
                <v-icon color="white">mdi-alert</v-icon>
            </v-col>
            <v-col class="grow">
                <v-row @click="startEdit()" no-gutters align="center" justify="center"
                        :class="[{'white--text': configEl.dark || isMissing}]">
                    <v-col class="grow">
                        <div :class="[{'white--text': configEl.dark || isMissing},
                                      {'grey--text text--darken-1': !isMissing},
                                      'caption']">
                            <span v-if="configEl.labelUppercase">{{getLabel(dataField.i18n)}}</span>
                            <span v-else>{{getLabel(dataField.i18n)}}</span>
                        </div>
                        <div>
                            <div v-if="dataField.type === 'switch'">
                                <span v-if="getCurrentValue()==='-'">
                                    <span v-if="dataField.switchLabels">{{$t(dataField.switchLabels.off)}}</span>
                                    <span v-else>{{$t('utilities.components.common.off')}}</span>
                                </span>
                                <span v-else>
                                    <span v-if="dataField.switchLabels">{{$t(dataField.switchLabels.on)}}</span>
                                    <span v-else>{{$t('utilities.components.common.on')}}</span>
                                </span>
                            </div>
                            <div v-else-if="dataField.type === 'pdfUpload'">
                                <div v-if="modelField && modelField.pdfName" @click.stop>
                                    <a :href="modelField.pdfUrl" target="_blank">{{modelField.pdfName}}</a>
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div v-else-if="(dataField.type !== 'confirmCheck' && !dataField.custom) ||
                                            (dataField.type !== 'confirmCheck' && dataField.custom && getCurrentValue()!=='-')">
                                {{getCurrentValue()}}
                            </div>
                            <div v-else>
                                <slot></slot>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="shrink pr-4 pl-3">
                        <v-icon :color="isMissing?'white':''" :disabled="dataField.disabled">
                            mdi-pencil</v-icon>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
<!-- full screen edit window -->
        <full-screen-dialog parameter="singleLineEdit"
                            :value="routeParameter"
                            :config-prop="configEl.fullScreenDialog">
            <toolbar :title="translate(dataField.i18n)"
                     :show-save="false"
                     @save="save">
            </toolbar>
        <!-- form -->
            <v-card-text class="pt-5 pb-15" v-show="!waitingForReply">
                <slot name="editWindowTop"></slot>
                <v-form ref="form" lazy-validation v-if="rules" @submit.prevent>
                    <v-text-field v-if="dataField.type==='text'"
                                  v-model="localValue"
                                  :ref="dataField.fieldName"
                                  :class="configEl.css"
                                  :label="getLabel(dataField.i18n)"
                                  :type="dataField.type"
                                  :rules="rules.getRulesArrayFromList(
                                            localValue,
                                            dataField.rules)"
                                  clearable>
                    </v-text-field>
                    <date-picker v-if="dataField.type === 'date'"
                                 :value="localValue"
                                 :label="getLabel(dataField.i18n)"
                                 :class="configEl.css"
                                 @changed="(value)=>{localValue = value}"
                                 :rules="rules.getRulesArrayFromList(
                                            localValue,
                                            dataField.rules)">
                    </date-picker>
                    <v-radio-group v-if="dataField.type==='radioGroup'"
                                   v-model="localValue"
                                   :row="dataField.row"
                                   :class="dataField.css"
                                   class="mt-0">
                        <v-radio v-for="(option, i) in dataField.options" :key="i"
                                 :label="translate(option.label)"
                                 :value="option.value"
                        ></v-radio>
                    </v-radio-group>
                    <v-autocomplete v-if="dataField.type==='autoComplete'"
                                    v-model="localValue"
                                    :ref="dataField.fieldName"
                                    :items="dataField.items"
                                    :item-text="dataField.itemText"
                                    :item-value="dataField.itemValue"
                                    :class="dataField.css"
                                    :label="getLabel(dataField.i18n)"
                                    :rules="rules.getRulesArrayFromList(
                                            localValue,
                                            dataField.rules)"
                                    :multiple="dataField.multiple"
                                    :clearable="dataField.hideClearable">
                    </v-autocomplete> <!--:rules="getRules(data, dataField)"-->
                    <v-switch v-if="dataField.type==='switch'"
                                  v-model="localValue"
                                  :class="configEl.css"
                                  :label="getLabel(dataField.i18n)"
                                  :rules="rules.getRulesArrayFromList(
                                            localValue,
                                            dataField.rules)">
                    </v-switch>
                    <div v-if="dataField.type==='pdfUpload'">
                        <pdf-upload v-model="selectedPdf" :unique-id="dataField.fieldName" @delete="setEmptyLocalPdf"
                            :name-of-currently-saved-pdf="localPdf?localPdf.pdfName:''"/>
                    </div>
                <!-- confirm check box -->
                    <single-line-edit-confirm-check
                            :targetValue="targetValue"
                            v-if="dataField.type==='confirmCheck'"
                            :dataValue="localValue"
                            @changed="(value)=>{this.localValue = value; }">
                        <slot></slot>
                    </single-line-edit-confirm-check>
                <!-- --------------------------------------- -->
                <!-- save button -->
                    <div class="text-right">
                        <v-btn @click="save()"
                                color="primary" fab dark>
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        <!-- error message -->
            <alert-message :config-prop="configEl.errorAlert"
                           config-package="error" class="mx-5"
                           :module-config="moduleConfig">
                {{errorMsg}}
            </alert-message>
        <!-- Block Window while saving -->
            <progress-dialog :waiting-for-reply="waitingForReply">
            </progress-dialog>
        <!-- Handle restart -->
            <single-line-edit-email :open="openEmailChangeInfo"></single-line-edit-email>
        </full-screen-dialog>
    </div>
</template>

<script>
    // Version 1.2 2022-02-21, 2022-03-12
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import RouterServices from '@/utilities/services/router/routerServices';
    import FullScreenDialog from "@/utilities/components/dialogs/fullScreenDialog";
    import Toolbar from "@/utilities/components/controls/toolbar";
    import {FormRules} from "@/utilities//services/form/formRules";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import DatePicker from "./datePicker";
    import SingleLineEditConfirmCheck from "./singleLineEditConfirmCheck";
    import ProgressDialog from "../dialogs/progressDialog";
    import SingleLineEditEmail from "./singleLineEditEmail";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import PdfUpload from "@/utilities/components/pdfUpload";
    import FirebaseStorage from "@/utilities/services/firebase/firebaseStorage";

    export default {
        components: {
            PdfUpload,
            SingleLineEditEmail,
            ProgressDialog,
            SingleLineEditConfirmCheck,
            DatePicker,
            AlertMessage,
            Toolbar, FullScreenDialog},
        name: "single-line-edit",
        props: {
            usageBlocked: {default: false},
            emptyAllowed: {default: false},
            dataDocument: {default: null},
            dataField: {default: null},
            collection: {default: ''},
            changeUserEmail: {default: false},
            targetValue: {default: 0},
            setUpdateTs: {default: false},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            localValue: '',
            waitingForReply: false,
            rules: new FormRules(),
            errorMsg: '',
            openEmailChangeInfo: false,
        // used for pdf only
            localPdf: '',
            selectedPdf: '', // new selected pdf from file upload
            currentPdf: '', // currently saved pdf, needed to be deleted before saving new one
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.singleLineEdit',
        // define here, which parameter should be customizeable
            configDefault: {
                css: '',
                errorAlert: {
                    show: false,
                },
                fullScreenDialog: {},
                disabled: false,
                dark: false,
                showRequiredAlert: true,
                labelUppercase: true,
            },
        }),
        computed: {
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
            modelField(){
                if ( this.dataField.path ) {
                    let pathArr = this.dataField.path.split('.');
                    let modelField = this.dataDocument;
                    pathArr.forEach( (pathItem) => {
                        try {
                            modelField = modelField[pathItem];
                        } catch (e) {
                            return null;
                        }
                    });
                    return modelField ? modelField[this.dataField.fieldName] : null;
                } else {
                    return this.dataDocument[this.dataField.fieldName];
                }
            },
            firebaseFieldName(){
                return this.dataField.path ?
                    this.dataField.path + '.' + this.dataField.fieldName :
                    this.dataField.fieldName;
            },
            isMissing(){
                return !this.emptyAllowed && ((this.configEl.showRequiredAlert &&
                        this.dataField.rules.find(rule => rule.ruleName === 'required') &&
                       !this.modelField) ||
                       (this.modelField < this.targetValue && this.configEl.showRequiredAlert));
            },
            routeParameter(){
                return this.dataField.queryParameter?this.dataField.fieldName + '_' + this.dataField.queryParameter :
                                                      this.dataField.fieldName
            },
            isOpen(){
                return this.$route.query.singleLineEdit
            }
        },
        methods: {
            getCurrentValue(){
                if ( !this.modelField || this.modelField==='' ||
                      this.modelField === null || this.modelField === undefined
                    ) {
                    return '-';
                }
                switch (this.dataField.type) {
                    case 'text':
                    case 'date': return this.modelField;
                    case 'autoComplete':
                        if ( this.dataField.items.length === 0  ) { return '-'; }
                        if ( this.dataField.multiple) {
                            return this.dataField.items.filter(item =>
                                this.modelField.includes(item[this.dataField.itemValue]))
                                .map(item => item[this.dataField.itemText]).join(', ');
                        } else {
                            return this.dataField.items.find(item =>
                                item[this.dataField.itemValue] === this.modelField)[this.dataField.itemText];
                        }

                    case 'radioGroup':
                        return this.translate(this.dataField.options.find(item =>
                            item.value === this.modelField).label);
                    /*case 'confirmCheck':
                        return this.modelField > 0 ?
                            this.data*/
                }
                /*
                <div @click="startEdit()"
                     v-if="dataField.type==='confirmCheck'">
                    <single-line-edit-confirm-check
                            :dataValue="modelField"
                            :readOnly="true"
                            :dark="configEl.dark || isMissing">
                        <slot></slot>
                    </single-line-edit-confirm-check>
                </div>
                 */
            },
            startEdit(){
                if ( this.usageBlocked || this.dataField.disabled ) {
                    this.$emit('blockedClick');
                    return;
                }
                RouterServices.pushRouteQueryParameter(this.$router, this.$route,
                    'singleLineEdit', this.routeParameter);
            },
            init(){
                if ( this.dataField.type==='pdfUpload' ) this.initPDF()
                this.localValue = this.dataField.invertValue ? !this.modelField : this.modelField;//this.dataDocument[this.dataField.fieldName];
                this.updateLocalDataDocument(false); // required to add keyPaths for undefined (e.g. soldOut.status in license)
                if ( this.dataField.type === 'text' ) {
                    setTimeout(()=>{ this.$nextTick(() => this.$refs[this.dataField.fieldName].focus()) }, 100);
                }
            },
            initPDF(){
                if ( this.modelField ) {
                    this.localPdf = this.getEmptyLocalPdf()
                    this.currentPdf = this.getEmptyLocalPdf()
                    setTimeout(()=>{ // timeout needed to clear input field in pdf sub component
                        this.localPdf = JSON.parse(JSON.stringify(this.modelField))
                        this.currentPdf = JSON.parse(JSON.stringify(this.modelField))
                    }, 100);
                } else {
                    this.localPdf = null
                    this.currentPdf = null
                }
            },
            async save(){
                this.resetError();
                if( !this.$refs.form.validate() ) { return }
                this.waitingForReply = true;
                let reply = null;
                if ( this.changeUserEmail ) {
                    const currentValue = this.modelField;
                    const loginService = this.$store.state.loginService;
                // change email in all users
                    reply = await FirebaseWrite.batch(
                        loginService.createEmailChangeBatchArray(this.localValue)
                    );
                // change email in auth
                    const replyAuth = await loginService.updateEmail(this.localValue);
                    if ( !replyAuth.successful) {
                        this.setError(
                            loginService.getErrorMessage(
                                this.$i18n.t(loginService.errorI18n)
                            )
                        );
                    // reset email change in users
                        reply = await FirebaseWrite.batch(
                            loginService.createEmailChangeBatchArray(currentValue)
                        );
                        return;
                    }
                } else {
                // cancel if pdf and no changes
                    if ( this.dataField.type === 'pdfUpload' && (
                        (!this.selectedPdf && this.currentPdf?.pdfUrl === this.localPdf?.pdfUrl ) ||
                        (!this.currentPdf && !this.localPdf ))
                    ) {
                        this.waitingForReply = false;
                        window.history.back()
                        return
                    }
                // upload pdf
                    if ( this.dataField.type==='pdfUpload' ) await this.uploadPdfAndGetFileData()
                // create data object
                    const dataObject = !this.setUpdateTs ?
                        {[this.firebaseFieldName]: this.dataField.invertValue ? !this.localValue : this.localValue} :
                        {[this.firebaseFieldName]: this.dataField.invertValue ? !this.localValue : this.localValue,
                            tsLastUpdated: TimeStamp.getGMTTimestamp()}
                    reply = await FirebaseWrite.update(this.collection, this.dataDocument.id, dataObject);
                }
                if ( reply.successful ) {
                    this.updateLocalDataDocument(true);
                    this.waitingForReply = false;
                    this.changeUserEmail ? this.openEmailChangeInfo = true : window.history.back();
                    this.$emit('success');
                } else {
                    this.setError(reply.content.message)
                }
            },
            setError(errorMsg){
                this.waitingForReply = false;
                this.configDefault.errorAlert.show = true;
                this.errorMsg = errorMsg;
            },
            resetError(){
                this.configDefault.errorAlert.show = false;
                this.errorMsg = '';
            },
            getArrayFromObject(object){
                //if ( Array.isArray(object) ) { return object }
                let array = [];
                Object.keys(object).forEach( (key) => {
                    array.push(object[key])
                });
                return array
            },
            updateLocalDataDocument(emitChange){
                if ( this.dataField.path ) {
                    let pathArr = this.dataField.path.split('.');
                    let modelField = this.dataDocument;
                    pathArr.forEach( (pathItem) => {
                        if ( !modelField[pathItem] ) {
                            modelField[pathItem] = {};
                            modelField = modelField[pathItem];
                        } else {
                            modelField = modelField[pathItem];
                        }
                    });
                    modelField[this.dataField.fieldName] =  this.dataField.invertValue ? !this.localValue : this.localValue;
                } else {
                    this.dataDocument[this.dataField.fieldName] =  this.dataField.invertValue ? !this.localValue : this.localValue;
                }
                if (emitChange) this.$emit('updated', this.dataDocument);
            },
            getLabel(i18nKey){
                const text = this.translate(i18nKey);
                return this.configEl.labelUppercase ? text.toUpperCase() : text;
            },
        // PDF Handling
            getEmptyLocalPdf(){
                return {
                    pdfName: '',
                    pdfUrl: '',
                    fileName: '',
                }
            },
            setEmptyLocalPdf(){
                this.localPdf = this.getEmptyLocalPdf()
            },
            setSelectedPdf(){
                if ( !this.localPdf ) this.localPdf = this.getEmptyLocalPdf()
                this.localPdf.pdfName = this.selectedPdf.target.value.replace('C:\\fakepath\\', '');
                this.localPdf.pdfUrl = '';
                this.localPdf.fileName = 'userDocuments/' + this.dataDocument.id + '_' + this.firebaseFieldName + '.pdf';
            },
            async uploadPdfAndGetFileData(){
                if ( this.currentPdf?.fileName && !this.localPdf.fileName ) {
                    await FirebaseStorage.deleteFile(this.currentPdf.fileName)
                }
                if ( this.localPdf.fileName && this.selectedPdf ) {
                    const reply = await FirebaseStorage.uploadFile(
                        this.selectedPdf.target.files[0], this.localPdf.fileName)
                    this.localPdf.pdfUrl = reply.content;
                } else {
                    this.localPdf = null
                }
                this.localValue = JSON.parse(JSON.stringify(this.localPdf))
            },
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
        watch: {
            isOpen(to){
                if ( to === this.routeParameter ) { this.init(); }
            },
            selectedPdf(){
                this.setSelectedPdf()
            }
        }
    }
</script>

<style scoped>

</style>