<template>
    <div class="px-4 pb-10">
        <v-row no-gutters
               align="center" justify="center"
               class="px-4 mt-4">
            <v-col v-if="showTitle" class="shrink">
                <v-icon class="mr-1">mdi-cart-outline</v-icon>
            </v-col>
            <v-col v-if="showTitle"
                   class="grow py-0 fontInter
                            font-italic font-weight-bold font24">
                SHOP
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink text-center">
                <profile-user-speed-dial @usermenu="setShowTitle">
                </profile-user-speed-dial>
            </v-col>
        </v-row>
        <alert-message v-if="!tempUser"
                       class="mt-5 mb-0"
                       config-package="progress"
                       :config-prop="{show: empty}">
            {{$t('shop.empty')}}
        </alert-message>
        <shop-area-select v-show="!shopArea && tempUser">
        </shop-area-select>
    <!-- new 2023 -->
        <shop-event-list v-if="shopArea"
                         @gotoShoppingCart="gotoShoppingCart"/>

        <!--
        <div v-show="shopArea==='fir_rfa' && tempUser">
            <shop-cart-btn class="mt-5"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
            <shop-event-temp v-if="shopArea==='fir_rfa'"></shop-event-temp>
            <shop-cart-btn class="mb-15 mt-5"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
        </div>
        <div v-show="shopArea==='fir' && tempUser">
            <shop-cart-btn class="mt-5"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
            <shop-fir2022></shop-fir2022>
            <shop-cart-btn class="mb-15"
                            @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
        </div>-->

        <!--
        <div>
            <shop-cart-btn class="mt-5"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
            <digital-racketlon-pass/>
            <shop-event-pass pass-event-id="ERU_Digital_Racketlon_Pass_2023"/>
            <shop-event-pass pass-event-id="5q2Ojaxkb8m_FIR_PASS"/>-->
            <!--<shop-event-temp v-if="shopArea==='rfa'"></shop-event-temp>-->
        <!--
            <shop-cart-btn class="mt-4 mb-15"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
        </div>-->

        <!--
        <div v-show="shopArea==='swt' && tempUser">
            <shop-cart-btn class="mt-5"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
            <shop-event-pass pass-event-id="Squash_World_Tour_Member_2023"/>-->
            <!--<shop-event-temp v-if="shopArea==='rfa'"></shop-event-temp>-->
            <!--<shop-cart-btn class="mt-4 mb-15"
                           @gotoShoppingCart="gotoShoppingCart">
            </shop-cart-btn>
        </div>-->
        <fab-btn :config-prop="cartFab"
                 :disabled="shoppingCart.getNumberOfItems()===0"
                 :badgeValue="shoppingCart.getNumberOfItems()"
                 @clicked="gotoShoppingCart()">
        </fab-btn>
        <fab-btn-back v-if="shopArea">
        </fab-btn-back>
    <!-- Block Window if checkout unpaid -->
        <v-dialog v-model="dialogModel" width="300" persistent>
            <v-card class="text-left pa-5">
                <p>{{$t(pendingText)}}</p>
                <btn-text :config-prop="configs.completePaymentBtn"
                          @click="completePayment()">
                    {{$t(pendingBtnText)}}
                </btn-text>
                <btn-text :config-prop="configs.exitShopBtn"
                          @click="exitShop()">
                    {{$t('shop.exitShop')}}
                </btn-text>
            </v-card>
        </v-dialog>
    <!-- Block Window while loading -->
        <v-dialog v-model="loader" width="300" persistent>
            <v-card class="text-left pa-5">
                <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="maxItemSnack"
                    color="error">
            {{$t('shop.maxItems', {maxItems: shoppingCart.maxItems})}}
        </v-snackbar>
    </div>
</template>

<script>
    //import ShopMembership from "@/components/shop/shopMembership";
    //import ShopPass from "@/components/shop/shopPass";
    //import ShopPassLinked from "@/components/shop/shopPassLinked";
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import ProfileUserSpeedDial from "@/components/profile/profileUserSpeedDial";
    import FabBtn from "@/utilities/components/controls/fabBtn";
    import {ShoppingCart} from "@/services/shop/shoppingCart";
    //import ShopCartBtn from "@/components/shop/shopCartBtn";
    //import ShopFir2022 from "@/components/shop/special/fir2022/shopFir2022";
    import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
    import BtnText from "../../utilities/components/controls/btnText";
    import ShopAreaSelect from "../../components/shop/shopAreaSelect";
    //import DigitalRacketlonPass from "../../components/shop/special/digitalRacketlonPass/digitalRacketlonPass";
    import FabBtnBack from "../../utilities/components/controls/fabBtnBack";
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
    import {StripeRefresh} from "@/modules/stripe/models/data/stripeRefresh";
    import {StripeCheckoutExpire} from "@/modules/stripe/models/data/stripeCheckoutExpire";
    import {RealtimeListener} from "@/utilities/services/firebase/firebaseRealtime";
    //import ShopEventTemp from "../../components/shop/shopEvent/shopEventTemp";
    //import ShopEventPass from "@/components/shop/shopEvent/shopEventPass";
    //import ShopEvent from "@/components/shop/shopEvent/shopEvent";
    //import {ShopEventService} from "@/services/shop/shopEvent";
    import ShopEventList from "@/components/shop/shopEvent/shopEventList";
    //import ProfileUserSpeedDial from "../../components/profile/profileUserSpeedDial";
    //import StripeInterface from "../../modules/stripe/views/stripe";

    export default {
        components: {
            ShopEventList,
            //ShopEvent,
            //ShopEventPass,
            //ProfileUserSpeedDial,
            //ShopEventTemp,
            FabBtnBack,
            //DigitalRacketlonPass,
            ShopAreaSelect,
            BtnText,
            //ShopFir2022,
            //ShopCartBtn,
            FabBtn,
            ProfileUserSpeedDial,
            AlertMessage
            /*StripeInterface,
            ShopPassLinked,
            ShopPass,

            ShopMembership*/
        },
        name: "shop",
        data: () => ({
            timeoutCounter: 0,
            empty: true,
            tab: 1,
            tabs: [
                {icon: 'mdi-account',
                    i18n: 'Membership'},
                {icon: 'mdi-cart',
                    i18n: 'Pass'},
                {icon: 'mdi-wallet',
                    i18n: 'Event'},
            ],
            showTitle: true,
            configs: {
                completePaymentBtn: {
                    block: true
                },
                exitShopBtn: {
                    block: true,
                    color: 'secondary lighten-3',
                    css: 'mt-3'
                }
            },
            pendingDialog: false,
            pendingText: '',
            pendingBtnText: '',
            checkoutPending: '',
            maxItemSnack: false,
            checkoutSessionExpireListener: null,
            loader: false,
        }),
        computed: {
            dialogModel(){return this.pendingDialog && !this.loader && this.$route.query.tab == 1},
            cartFab(){
                return {
                    icon: 'mdi-cart-arrow-right',
                        container: {
                        style: 'right: ' + this.fabRight,
                    },
                }
            },
            fabRight(){
                return this.shopArea ? '94px' : '15px'
            },
            user(){return this.$store.getters.user;},
            isMultiUser(){
                return this.user && this.$store.state.realtime.userMaster &&
                    Object.keys(this.$store.state.realtime.userChilds).length > 0
            },
            shoppingCart(){
                return this.$store.state.shoppingCart;
            },
            shopArea(){
                return this.$route.query.shopArea;
            },
            tempUser(){
                return true;
                /*return !this.user ? false :
                    this.user.uid.substring(0,28) === 'WGIKH1pjccbUQmIRcVwkfZEr6Fu1' ||
                    this.user.uid.substring(0,28) === '87coCzKgHJhLJmtcG4A8fsKR3X03'*/
            },
        },
        created(){
            this.setShoppingCart();
            this.checkIfCheckoutIsPending();
        },
        methods: {
            setShoppingCart(){
                if ( !this.shoppingCart ) {
                    this.$store.state.shoppingCart = new ShoppingCart();
                    const sessionStorageData = JSON.parse(sessionStorage.getItem('shoppingCart'));
                    if ( sessionStorageData &&
                         sessionStorageData.validOn === TimeStamp.getTodaysDateString() &&
                         sessionStorageData.uid === this.user.uid.substring(0,28) ) {
                        this.shoppingCart.items = sessionStorageData.items;
                        this.shoppingCart.purchaseOrder = sessionStorageData.purchaseOrder;
                        this.shoppingCart.eventsWithValidVouchers = sessionStorageData.eventsWithValidVouchers;
                    }
                } else if ( this.shoppingCart.status === 'paid' ) {
                    this.shoppingCart.reset(false);
                }
            },
            async checkIfCheckoutIsPending(){
                this.checkoutPending = '';
                const pendingDoc = await FirebaseRead.readSingleDoc(
                    'stripe_checkouts_pending', this.user.uid.substring(0,28)
                );
                if ( pendingDoc ) {
                // checkout created
                    this.tryRefresh(pendingDoc);
                } else if (this.shoppingCart.status === 'blocked') {
                // at least purchase order created
                    this.pendingDialog = true;
                    this.pendingText = 'shop.pendingPurchase';
                    this.pendingBtnText = 'shop.completePurchase';
                }
            },
                async tryRefresh(pendingDoc){
                    const stripeCheckout = await FirebaseRead.readSingleDoc(
                        'stripe_checkouts', pendingDoc.pending
                    );
                    if ( stripeCheckout && stripeCheckout.stripe.status === 'expired' ) {
                    // handle checkout expired done by Stripe after 24hours
                        await this.cancelCheckout(pendingDoc.pending, true);
                    } else if ( TimeStamp.getDateString(stripeCheckout?.ts) < TimeStamp.getTodaysDateString() ) {
                    // expire if not paid on the same day
                        await this.cancelCheckout(pendingDoc.pending, false);
                        sessionStorage.removeItem('shoppingCart')
                        this.$store.state.shoppingCart = null;
                        this.setShoppingCart()
                    } else if ( stripeCheckout ) {
                    // output warning
                        this.pendingText = 'shop.pendingCheckout';
                        this.pendingBtnText = 'shop.completePayment';
                        this.pendingDialog = true;
                        this.checkoutPending = pendingDoc.pending;
                    // start refreshs
                        console.log ("stripeCheckout: ", stripeCheckout);
                        const stripeRefresh = new StripeRefresh({
                            uid: this.user.uid.substring(0,28),
                            collection: 'stripe_checkouts',
                            documentId: pendingDoc.pending,
                            stripeId: stripeCheckout.stripe.id,
                            stripeAccountId: stripeCheckout.stripeAccountId,
                            successReported: true,
                            ts: TimeStamp.getGMTTimestamp(),
                        });
                        await stripeRefresh.save();
                        setTimeout(()=>{
                            this.checkIfTryRefreshWasSuccessful();
                        },300)
                    }
                },
                    async checkIfTryRefreshWasSuccessful(){
                        this.timeoutCounter++;
                        const pendingDoc = this.user ? await FirebaseRead.readSingleDoc(
                            'stripe_checkouts_pending', this.user.uid.substring(0,28)
                        ) : null;
                        if( (pendingDoc && this.timeoutCounter < 20) || !this.user){
                            setTimeout(()=>{
                                this.checkIfTryRefreshWasSuccessful();
                            },500)
                        } else if (!pendingDoc) {
                            // everything completed
                            this.pendingText = '';
                            this.pendingBtnText = '';
                            this.pendingDialog = false;
                            this.checkoutPending = '';
                        }
                    },
                    async cancelCheckout(purchaseOrderId, autoExpired){
                        const expireCheckout = new StripeCheckoutExpire({
                            status: 'requested',
                            purchaseOrderId: purchaseOrderId,
                            uid: this.user.uid,
                            ts: TimeStamp.getGMTTimestamp(),
                            autoExpired: autoExpired,
                        });
                        let reply = await expireCheckout.saveNewWithDocId(purchaseOrderId);
                        if ( reply.successful ) {
                            this.checkoutSessionExpireListener = new RealtimeListener({
                                fireBaseCollection: 'stripe_checkouts_expire',
                            });
                            this.checkoutSessionExpireListener.setListener(
                                {docId: purchaseOrderId}
                            );
                            this.loader = true;
                            console.log ("EXPIRE REQUESTED");
                        }
                    },
            async completePayment(){
                if ( this.checkoutPending ) {
                    const pendingPurchaseOrder = await FirebaseRead.readSingleDoc(
                        'purchaseOrders', this.checkoutPending
                    );
                    this.shoppingCart.reset();
                    this.shoppingCart.items = pendingPurchaseOrder.cartItems;
                    this.shoppingCart.purchaseOrder = pendingPurchaseOrder;
                }
                this.gotoShoppingCart();
            },
            exitShop(){
                this.$emit('changeTab', 0);
            },
            setShowTitle(fab){
                this.showTitle = !fab;
            },
            gotoShoppingCart(){
                console.log ("gotoShoppingCart", );
                this.shoppingCart.setToSessionStorage();
                this.$router.push('shoppingCart')
            },
        },
        watch: {
            'shoppingCart.items'(){
                this.maxItemSnack = this.shoppingCart.countItems() > this.shoppingCart.maxItems;
            },
            'checkoutSessionExpireListener.data'(){
                console.log ("checkoutSessionExpireListener 1", );
                if ( this.checkoutSessionExpireListener.data ) {
                    console.log ("checkoutSessionExpireListener 2", );
                    if ( this.checkoutSessionExpireListener.data.status !== 'requested' ) {
                        console.log ("checkoutSessionExpireListener 3", );
                        this.loader = false;
                        //this.$emit('isCancelled');
                        //setTimeout(()=>this.checkoutSessionExpireListener.unsubscribe(),100)
                        this.checkoutSessionExpireListener.unsubscribe()
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>