<template>
    <v-dialog v-model="newVerificationRequired" persistent width="500">
        <v-card>
            <v-card-text class="pt-8 pb-0">
                <div v-if="showTermsOfUse">
                    {{$t('common.termsChanged')}}
                    <!-- slot for confirmations --->
                    <v-checkbox v-model="termsOfUse" hide-details class="ml-5 mb-5">
                        <template v-slot:label>
                            <div>
                                {{$t('utilities.services.user.termsOfUse1')}}
                                <span @click.stop>
                                <!--<router-link to="/termsOfUse" target="_blank">
                                    {{$t('utilities.services.user.termsOfUse')}}
                                </router-link>-->
                                <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_TermsV2.pdf"
                                   target="_blank">{{$t('utilities.services.user.termsOfUse')}}</a>
                                <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_TermsV2.pdf"
                                   target="_blank">{{$t('utilities.services.user.termsOfUse')}}</a>
                            </span>
                                {{$t('utilities.services.user.termsOfUse2')}}
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <div v-if="showPrivacy">
                    {{$t('common.privacyChanged')}}
                    <v-checkbox v-model="privacyApp" hide-details class="mb-5 ml-5">
                        <template v-slot:label>
                            <div>
                                {{$t('utilities.services.user.privacyApp1')}}
                                <span @click.stop>
                                <!--<router-link to="/privacy" target="_blank">
                                    {{$t('utilities.services.user.privacyApp')}}
                                </router-link>-->
                                <a v-if="$i18n.locale==='de'" href="https://www.onyourmarks.cc/app/terms/de/OYM_PrivacyV2.pdf"
                                   target="_blank">Datenschutzerklärung</a>
                                <a v-else href="https://www.onyourmarks.cc/app/terms/en/OYM_PrivacyV2.pdf"
                                   target="_blank">privacy policy</a>
                            </span>
                                {{$t('utilities.services.user.privacyApp2')}}
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <div v-if="user && user.confirmations && !user.confirmations.emailOptIn">
                    {{$t('common.firstEmailOptIn')}}
                    <v-checkbox v-model="emailOptIn" hide-details class="mb-5 ml-5">
                        <template v-slot:label>
                            {{$t('utilities.services.user.emailOptIn')}}
                        </template>
                    </v-checkbox>
                </div>
            </v-card-text>
            <v-card-actions class="mt-0 pb-5 pr-5">
                <v-spacer></v-spacer>
                <btn-text :disabled="disableOk" @click="saveConfirmation()"
                        config-package="confirm">
                </btn-text>
            </v-card-actions>
            <div class="px-6 pb-5 body-2">
                <v-divider class="mt-0 mb-4"></v-divider>
                <div v-if="deleteRequested===false" class="grey--text text-caption">
                    <span @click="deleteRequested=true" class="text-decoration-underline">
                        {{$t('profile.requestDeleteOnTermsBtn')}}</span>{{$t('profile.requestDeleteOnTermsConfirm')}}
                </div>
                <profile-delete-request v-else :use-with-terms-confirmation="true"/>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import BtnText from "@/utilities/components/controls/btnText";
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
    import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
    import ProfileDeleteRequest from "@/components/profile/profileDeleteRequest";

    export default {
        components: {ProfileDeleteRequest, BtnText},
        name: "terms-confirmation",
        data: () => ({
            termsOfUse: false,
            privacyApp: false,
            emailOptIn: false, // is only inserted if false
            deleteRequested: false,
        }),
        computed: {
            loginService(){ return this.$store.state.loginService;},
            user(){return this.$store.getters.user},
            confirmationUser(){ return !this.user ? null :
                                      this.user.confirmations.privacyApp === 0 ?
                                      this.$store.state.realtime.userMaster :
                                      this.user;
            },
            versions() {return this.$store.state.config.versions;},
            newVerificationRequired(){
                if ( !this.confirmationUser ) { return false; }
                const versions = this.$store.state.config.versions;
                return versions.privacyApp > this.confirmationUser.confirmations.privacyApp ||
                    versions.termsOfUse > this.confirmationUser.confirmations.termsOfUse;
            },
            showPrivacy(){ return this.confirmationUser ?
                                    this.versions.privacyApp > this.confirmationUser.confirmations.privacyApp : false},
            showTermsOfUse(){ return this.confirmationUser ?
                                this.versions.termsOfUse > this.confirmationUser.confirmations.termsOfUse :
                                false
            },
            privacyComplete(){ return this.showPrivacy ? this.privacyApp : true;},
            termsOfUseComplete(){ return this.showTermsOfUse ? this.termsOfUse : true;},
            disableOk(){return !this.privacyComplete || !this.termsOfUseComplete;}
        },
        methods: {
            async saveConfirmation(){
                const reply = await FirebaseWrite.update(
                    'users',
                    this.confirmationUser.uid,
                    this.getChangeObject()
                )
                console.log ("reply", reply);
            },
            getChangeObject(){
                const changeObject = {};
                if ( this.termsOfUse ) {
                    changeObject['confirmations.termsOfUse'] = TimeStamp.getGMTTimestamp();
                }
                if ( this.privacyApp ) {
                    changeObject['confirmations.privacyApp'] = TimeStamp.getGMTTimestamp();
                }
                if ( this.emailOptIn && !this.user.confirmations.emailOptIn ) {
                    changeObject['confirmations.emailOptIn'] = this.emailOptIn;
                }
                return changeObject;
            }
        },
    }
</script>

<style scoped>

</style>