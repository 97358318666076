// Version 1.0 2022-01-18

//import {db} from "@/utilities/services/firebase/init";
import FirebaseVersion from "@/utilities/services/firebase/firebaseVersion";
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
//import firebase from "@/utilities/services/firebase/init";
import firebase from 'firebase/app'
import 'firebase/storage'

export default class FirebaseStorage {
    // 1. Save file and get adress

    // 2. Update collection
    static uploadImage(uploadObject){
        if (FirebaseVersion.getVersion() === 8) {
            return FirebaseStorage.uploadImage8(uploadObject);
        } else {
            //
        }
    }

        static async uploadImage8(uploadObject){

                // 1. save file and get url
                let url = await FirebaseStorage.addFile(
                    uploadObject.imageName, uploadObject.url);
                // save downloadUrl without path (only image file name + token)
                if (url) {
                    url = url.replace(uploadObject.storageSourcePath, '');
                    url = url.split('?')[1];
                    console.log("URL", url);
                    const dataObject = {[uploadObject.dataObjectParameter]: url};
                    FirebaseWrite.update(uploadObject.collection, uploadObject.docId, dataObject)
                    //let spieler = this.actionObject.local.spieler;
                    //spieler.local = { documentUpdates: {url: url}};
                    //await DATABASE_HANDLER.updateWithTransaction('spieler_edit', false, spieler);
                }


        }

    static addFile(imageName, dataUrl){
        if (FirebaseVersion.getVersion() === 8) {
            return FirebaseStorage.addFile8(imageName, dataUrl);
        } else {
            //
        }
    }

        static addFile8(imageName, dataUrl){
            return new Promise( (resolve) => {
                const storageRef = firebase.storage().ref();
                // write new image to storage (from string)
                storageRef.child(imageName).putString(dataUrl, 'data_url')
                    .then( () => {
                        // get image url
                        storageRef.child(imageName).getDownloadURL()
                            .then( (url) => {
                                resolve(url);
                            })
                            .catch((error)=> {
                                console.log("Error getting image url:", error);
                                resolve(null);
                            });
                    })
                    .catch((error)=> {
                        console.log("Error uploading image:", error);
                        resolve(null);
                    });
            })
        }

    static addImageFromFile(imageName, file){
        if (FirebaseVersion.getVersion() === 8) {
            return FirebaseStorage.addImageFromFile8(imageName, file);
        } else {
            //
        }
    }

        static addImageFromFile8(imageName, file){
            return new Promise( (resolve) => {
                const storageRef = firebase.storage().ref();
                // write new image to storage (from string)
                storageRef.child(imageName).put(file)
                    .then( () => {
                        // get image url
                        storageRef.child(imageName).getDownloadURL()
                            .then( (url) => {
                                resolve(url);
                            })
                            .catch((error)=> {
                                console.log("Error getting image url:", error);
                                resolve(null);
                            });
                    })
                    .catch((error)=> {
                        console.log("Error uploading image:", error);
                        resolve(null);
                    });
            })
        }

    static deleteFile(fileName){
        if (FirebaseVersion.getVersion() === 8) {
            return FirebaseStorage.deleteFile8(fileName);
        } else {
            //
        }
    }

        static deleteFile8(fileName){
            return new Promise( (resolve) => {
                console.log ("CloudStorageHandler: ", fileName);
            // define storage
                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(fileName);
            // Delete the file
                fileRef.delete().then(() => {
                    resolve({successful: true, content: ''});
                }).catch((error) => {
                    resolve({successful: false, content: error});
                    console.log ("error: ", error);
                });
            })
        }

    static uploadFile(file, fileName){
        if (FirebaseVersion.getVersion() === 8) {
            return FirebaseStorage.uploadFile8(file, fileName);
        } else {
            //
        }
    }
        static uploadFile8(file, fileName){
            return new Promise( (resolve) => {
                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(fileName);
                // write new image to storage (from string)
                fileRef.put(file)
                    .then( () => {
                        // get image url
                        storageRef.child(fileName).getDownloadURL()
                            .then( (url) => {
                                resolve({successful: true, content: url});
                            })
                            .catch((error)=> {
                                console.log("Error getting image url:", error);
                                resolve({successful: false, content: error});
                            });
                    })
                    .catch((error)=> {
                        console.log("Error uploading image:", error);
                        resolve({successful: false, content: error});
                    });
            })
        }
}
