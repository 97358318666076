<template>
    <div :class="[{'cardContentDyn': $vuetify.breakpoint.xsOnly},
                  {'cardContentFix': $vuetify.breakpoint.smAndUp}]">
    <!-- logo -->
        <img v-if="userEvent && userEvent.logo"
             class="cardLogo" height="24%" alt="Logo"
             :src="userEvent.logo">
    <!-- text box OLD worldchamps only -->
        <div v-if="!userEvent.organisationId"
             :class="[{'titleDyn font14': $vuetify.breakpoint.xsOnly},
                      {'titleFix font20': $vuetify.breakpoint.smAndUp},
                      'lineHeight-1-0 mt-1 text-left pl-3', textColor]">
            <div :class="[{'font12': $vuetify.breakpoint.xsOnly},
                          {'font18': $vuetify.breakpoint.smAndUp},
                           'space1 wordBreak text-uppercase font-weight-black']">
                {{userEvent.subtitle}}
            </div>
            <div :class="[{'font10': $vuetify.breakpoint.xsOnly},
                          {'font16': $vuetify.breakpoint.smAndUp},
                           'wordBreak text-uppercase']">
                {{userEvent.location}}
            </div>
            <div :class="[{'font10': $vuetify.breakpoint.xsOnly},
                          {'font16': $vuetify.breakpoint.smAndUp},
                           'text-uppercase']">
                {{dateString}}
            </div>
        </div>
        <div v-else>
            <!-- text box -->
            <div :class="[{'titleDyn fontEventText pl-2': $vuetify.breakpoint.xsOnly},
                      {'titleFix font18 pl-3': $vuetify.breakpoint.smAndUp},
                      'lineHeight-1-0 mt-1 text-left text-uppercase', textColor]">
                <div class="space1 font-weight-black">
                    <span v-if="userEvent.name">{{userEvent.name}}</span>
                    <span v-else>{{userEvent.title}}</span>
                </div>
                <div  :class="[{'fontEventLocation mt05': $vuetify.breakpoint.xsOnly},
                          {'font14 mt-2': $vuetify.breakpoint.smAndUp},
                           ' space01']">
                    {{userEvent.location}}
                </div>
            </div>
            <!-- date -->
            <div :class="[{'fontEventDate pl-2': $vuetify.breakpoint.xsOnly},
                      {'font12 pl-3': $vuetify.breakpoint.smAndUp},
                      'dateFooter font10 lineHeight-1-2', textColor]">
                {{dateString}}
            </div>
        </div>
    <!-- overlay for inactive passes -->
        <wallet-missing-consents-overlay v-if="userEvent"
                :card-selected="walletZoomed.documentId!==''"
                :event-id="userEvent.id"/>
    </div>
</template>

<script>
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp'
    import WalletMissingConsentsOverlay from "@/components/wallet/walletMissingConsentsOverlay";
    export default {
        name: "wallet-events-content",
        components: {WalletMissingConsentsOverlay},
        props: ['userEvent'],
        computed: {
            dateString(){
                return TimeStamp.getTimeRangeFromTwoStringDates(
                    this.userEvent.from, this.userEvent.until)
            },
            textColor(){
                return this.userEvent.styles.dark ? 'lightGrey--text':'primary--text';
            },
            dayAndMonth(){
                let fullDate = TimeStamp.getTimeRangeFromTwoStringDates(this.userEvent.from, this.userEvent.until)
                return fullDate.substring(0, fullDate.length - 4)
            },
            walletZoomed(){
                return this.$store.state.wallet.zoomed;
            },
            /*
            userLicenses(){
                return this.$store.state.realtime.userLicenses;
            },
            getLicenseString(){
                let stringArr = [];
                const userLicenses = this.cleanUserLicensesForFIR2022(this.userLicenses.licenses);
                Object.keys(userLicenses).forEach( (licenseId) => {
                    const license = userLicenses[licenseId];
                    if ( license.documentId === this.userEvent.id ) {
                        stringArr.push(license.title + ' ' + license.subtitle)
                    }
                });
                return stringArr.join(', ');
            }*/
        },
        /*
        methods: {
            cleanUserLicensesForFIR2022(userLicenses){
                let userLicensesTemp = JSON.parse(JSON.stringify(userLicenses));
                if ( userLicensesTemp.fir2022_vienna_accomodation_private ) {
                    delete userLicensesTemp.fir2022_vienna_accomodation_other;
                }
                if ( userLicensesTemp.fir2022_graz_accomodation_private ) {
                    delete userLicensesTemp.fir2022_graz_accomodation_other;
                }
                Object.keys(userLicenses).forEach( (licenseId) => {
                    if ( licenseId.indexOf('graz_hotel') !== -1 ) {
                        delete userLicensesTemp.fir2022_graz_accomodation_other;
                        delete userLicensesTemp.fir2022_graz_accomodation_private;
                    }
                    if ( licenseId.indexOf('vienna_hotel') !== -1 ) {
                        delete userLicensesTemp.fir2022_vienna_accomodation_other;
                        delete userLicensesTemp.fir2022_vienna_accomodation_private;
                    }
                });
                return userLicensesTemp;
            },
        },
        */
    }
</script>

<style scoped>
    .titleDyn {position: absolute; width: 100%; top: 40%}
    .titleFix {position: absolute; width: 100%; top: 40%}
    .truncate2Line {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .dateFooter {
        position: absolute; bottom: 5%; left: 0%
    }
</style>