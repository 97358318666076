//Version 1.2 2022-03-12
export default class CountriesService {

    static getCountryListSortedWithPriority(locale){
        return CountriesService.getCountryListRaw()
            .sort((a, b) => a.priority + a[locale] > b.priority + b[locale] ? 1 : -1);

    }
    static getCountryListRaw(){
        return [
            {id: "1", de: "Afghanistan", en: "Afghanistan", priority: "100", alpha2:"AF", alpha3: "AFG", ioc: "AFG"},
            {id: "3", de: "Albanien", en: "Albania", priority: "100", alpha2:"AL", alpha3: "ALB", ioc: "ALB"},
            {id: "4", de: "Algerien", en: "Algeria", priority: "100", alpha2:"DZ", alpha3: "DZA", ioc: "ALG"},
            {id: "5", de: "Andorra", en: "Andorra", priority: "100", alpha2:"AD", alpha3: "AND", ioc: "AND"},
            {id: "6", de: "Angola", en: "Angola", priority: "100", alpha2:"AO", alpha3: "AGO", ioc: "ANG"},
            {id: "7", de: "Antigua und Barbuda", en: "Antigua and Barbuda", priority: "100", alpha2:"AG", alpha3: "ATG", ioc: "ANT"},
            {id: "9", de: "Argentinien", en: "Argentina", priority: "100", alpha2:"AR", alpha3: "ARG", ioc: "ARG"},
            {id: "10", de: "Armenien", en: "Armenia", priority: "100", alpha2:"AM", alpha3: "ARM", ioc: "ARM"},
            {id: "13", de: "Australien", en: "Australia", priority: "100", alpha2:"AU", alpha3: "AUS", ioc: "AUS"},
            {id: "130", de: "Österreich", en: "Austria", priority: "001", alpha2:"AT", alpha3: "AUT", ioc: "AUT"},
            {id: "11", de: "Aserbaidschan", en: "Azerbaijan", priority: "100", alpha2:"AZ", alpha3: "AZE", ioc: "AZE"},
            {id: "14", de: "Bahamas", en: "Bahamas", priority: "100", alpha2:"BS", alpha3: "BHS", ioc: "BAH"},
            {id: "15", de: "Bahrain", en: "Bahrain", priority: "100", alpha2:"BH", alpha3: "BHR", ioc: "BRN"},
            {id: "16", de: "Bangladesch", en: "Bangladesh", priority: "100", alpha2:"BD", alpha3: "BGD", ioc: "BAN"},
            {id: "17", de: "Barbados", en: "Barbados", priority: "100", alpha2:"BB", alpha3: "BRB", ioc: "BAR"},
            {id: "196", de: "Weißrussland", en: "Belarus", priority: "100", alpha2:"BY", alpha3: "BLR", ioc: "BLR"},
            {id: "18", de: "Belgien", en: "Belgium", priority: "100", alpha2:"BE", alpha3: "BEL", ioc: "BEL"},
            {id: "19", de: "Belize", en: "Belize", priority: "100", alpha2:"BZ", alpha3: "BLZ", ioc: "BIZ"},
            {id: "20", de: "Benin", en: "Benin", priority: "100", alpha2:"BJ", alpha3: "BEN", ioc: "BEN"},
            {id: "21", de: "Bhutan", en: "Bhutan", priority: "100", alpha2:"BT", alpha3: "BTN", ioc: "BHU"},
            {id: "22", de: "Bolivien", en: "Bolivia", priority: "100", alpha2:"BO", alpha3: "BOL", ioc: "BOL"},
            {id: "23", de: "Bosnien und Herzegowina", en: "Bosnia and Herzegovina", priority: "100", alpha2:"BA", alpha3: "BIH", ioc: "BIH"},
            {id: "24", de: "Botswana", en: "Botswana", priority: "100", alpha2:"BW", alpha3: "BWA", ioc: "BOT"},
            {id: "25", de: "Brasilien", en: "Brazil", priority: "100", alpha2:"BR", alpha3: "BRA", ioc: "BRA"},
            {id: "26", de: "Brunei", en: "Brunei Darussalam", priority: "100", alpha2:"BN", alpha3: "BRN", ioc: "BRU"},
            {id: "27", de: "Bulgarien", en: "Bulgaria", priority: "100", alpha2:"BG", alpha3: "BGR", ioc: "BUL"},
            {id: "28", de: "Burkina Faso", en: "Burkina Faso", priority: "100", alpha2:"BF", alpha3: "BFA", ioc: "BUR"},
            {id: "29", de: "Burundi", en: "Burundi", priority: "100", alpha2:"BI", alpha3: "BDI", ioc: "BDI"},
            {id: "73", de: "Kambodscha", en: "Cambodia", priority: "100", alpha2:"KH", alpha3: "KHM", ioc: "CAM"},
            {id: "74", de: "Kamerun", en: "Cameroon", priority: "100", alpha2:"CM", alpha3: "CMR", ioc: "CMR"},
            {id: "75", de: "Kanada", en: "Canada", priority: "100", alpha2:"CA", alpha3: "CAN", ioc: "CAN"},
            {id: "76", de: "Kap Verde", en: "Cape Verde", priority: "100", alpha2:"CV", alpha3: "CPV", ioc: "CPV"},
            {id: "197", de: "Zentral­afrikanische Republik", en: "Central African Republic", priority: "100", alpha2:"CF", alpha3: "CAF", ioc: "CAF"},
            {id: "179", de: "Tschad", en: "Chad", priority: "100", alpha2:"TD", alpha3: "TCD", ioc: "CHA"},
            {id: "30", de: "Chile", en: "Chile", priority: "100", alpha2:"CL", alpha3: "CHL", ioc: "CHI"},
            {id: "31", de: "China", en: "China", priority: "100", alpha2:"CN", alpha3: "CHN", ioc: "CHN"},
            {id: "82", de: "Kolumbien", en: "Colombia", priority: "100", alpha2:"CO", alpha3: "COL", ioc: "COL"},
            {id: "83", de: "Komoren", en: "Comoros", priority: "100", alpha2:"KM", alpha3: "COM", ioc: "COM"},
            {id: "85", de: "Kongo, Republik", en: "Congo, Republic of (Brazzaville)", priority: "100", alpha2:"CG", alpha3: "COG", ioc: "CGO"},
            {id: "84", de: "Kongo, Demokratische Republik", en: "Congo, Democratic Rep. of the (Kinshasa)", priority: "100", alpha2:"CD", alpha3: "COD", ioc: "COD"},
            {id: "32", de: "Costa Rica", en: "Costa Rica", priority: "100", alpha2:"CR", alpha3: "CRI", ioc: "CRC"},
            {id: "40", de: "Elfenbeinküste", en: "Ivory Coast", priority: "100", alpha2:"CI", alpha3: "CIV", ioc: "CIV"},
            {id: "89", de: "Kroatien", en: "Croatia", priority: "100", alpha2:"HR", alpha3: "HRV", ioc: "CRO"},
            {id: "90", de: "Kuba", en: "Cuba", priority: "100", alpha2:"CU", alpha3: "CUB", ioc: "CUB"},
            {id: "198", de: "Zypern", en: "Cyprus", priority: "100", alpha2:"CY", alpha3: "CYP", ioc: "CYP"},
            {id: "180", de: "Tschechien", en: "Czech Republic", priority: "100", alpha2:"CZ", alpha3: "CZE", ioc: "CZE"},
            {id: "33", de: "Dänemark", en: "Denmark", priority: "100", alpha2:"DK", alpha3: "DNK", ioc: "DEN"},
            {id: "37", de: "Dschibuti", en: "Djibouti", priority: "100", alpha2:"DJ", alpha3: "DJI", ioc: "DJI"},
            {id: "35", de: "Dominica", en: "Dominica", priority: "100", alpha2:"DM", alpha3: "DMA", ioc: "DMA"},
            {id: "36", de: "Dominikanische Republik", en: "Dominican Republic", priority: "100", alpha2:"DO", alpha3: "DOM", ioc: "DOM"},
            {id: "38", de: "Ecuador", en: "Ecuador", priority: "100", alpha2:"EC", alpha3: "ECU", ioc: "ECU"},
            {id: "2", de: "Ägypten", en: "Egypt", priority: "100", alpha2:"EG", alpha3: "EGY", ioc: "EGY"},
            {id: "39", de: "El Salvador", en: "El Salvador", priority: "100", alpha2:"SV", alpha3: "SLV", ioc: "ESA"},
            {id: "8", de: "Äquatorialguinea", en: "Equatorial Guinea", priority: "100", alpha2:"GQ", alpha3: "GNQ", ioc: "GEQ"},
            {id: "41", de: "Eritrea", en: "Eritrea", priority: "100", alpha2:"ER", alpha3: "ERI", ioc: "ERI"},
            {id: "42", de: "Estland", en: "Estonia", priority: "100", alpha2:"EE", alpha3: "EST", ioc: "EST"},
            {id: "12", de: "Äthiopien", en: "Ethiopia", priority: "100", alpha2:"ET", alpha3: "ETH", ioc: "ETH"},
            {id: "43", de: "Färöer", en: "Faroe Islands", priority: "100", alpha2:"FO", alpha3: "FRO", ioc: "FRO"},
            {id: "44", de: "Fidschi", en: "Fiji", priority: "100", alpha2:"FJ", alpha3: "FJI", ioc: "FIJ"},
            {id: "45", de: "Finnland", en: "Finland", priority: "100", alpha2:"FI", alpha3: "FIN", ioc: "FIN"},
            {id: "46", de: "Frankreich", en: "France", priority: "100", alpha2:"FR", alpha3: "FRA", ioc: "FRA"},
            {id: "47", de: "Gabun", en: "Gabon", priority: "100", alpha2:"GA", alpha3: "GAB", ioc: "GAB"},
            {id: "48", de: "Gambia", en: "Gambia", priority: "100", alpha2:"GM", alpha3: "GMB", ioc: "GAM"},
            {id: "49", de: "Georgien", en: "Georgia", priority: "100", alpha2:"GE", alpha3: "GEO", ioc: "GEO"},
            {id: "34", de: "Deutschland", en: "Germany", priority: "100", alpha2:"DE", alpha3: "DEU", ioc: "GER"},
            {id: "50", de: "Ghana", en: "Ghana", priority: "100", alpha2:"GH", alpha3: "GHA", ioc: "GHA"},
            {id: "52", de: "Griechenland", en: "Greece", priority: "100", alpha2:"GR", alpha3: "GRC", ioc: "GRE"},
            {id: "53", de: "Grönland", en: "Greenland", priority: "100", alpha2:"GL", alpha3: "GRL", ioc: "GRL"},
            {id: "51", de: "Grenada", en: "Grenada", priority: "100", alpha2:"GD", alpha3: "GRD", ioc: "GRN"},
            {id: "55", de: "Guatemala", en: "Guatemala", priority: "100", alpha2:"GT", alpha3: "GTM", ioc: "GUA"},
            {id: "56", de: "Guinea", en: "Guinea", priority: "100", alpha2:"GN", alpha3: "GIN", ioc: "GUI"},
            {id: "57", de: "Guinea-Bissau", en: "Guinea-Bissau", priority: "100", alpha2:"GW", alpha3: "GNB", ioc: "GBS"},
            {id: "58", de: "Guyana", en: "Guyana", priority: "100", alpha2:"GY", alpha3: "GUY", ioc: "GUY"},
            {id: "59", de: "Haiti", en: "Haiti", priority: "100", alpha2:"HT", alpha3: "HTI", ioc: "HAI"},
            {id: "191", de: "Vatikanstadt", en: "Vatican City", priority: "100", alpha2:"VA", alpha3: "VAT", ioc: "VAT"},
            {id: "60", de: "Honduras", en: "Honduras", priority: "100", alpha2:"HN", alpha3: "HND", ioc: "HON"},
            {id: "187", de: "Ungarn", en: "Hungary", priority: "100", alpha2:"HU", alpha3: "HUN", ioc: "HUN"},
            {id: "66", de: "Island", en: "Iceland", priority: "100", alpha2:"IS", alpha3: "ISL", ioc: "ISL"},
            {id: "61", de: "Indien", en: "India", priority: "100", alpha2:"IN", alpha3: "IND", ioc: "IND"},
            {id: "62", de: "Indonesien", en: "Indonesia", priority: "100", alpha2:"ID", alpha3: "IDN", ioc: "INA"},
            {id: "64", de: "Iran", en: "Iran", priority: "100", alpha2:"IR", alpha3: "IRN", ioc: "IRI"},
            {id: "63", de: "Irak", en: "Iraq", priority: "100", alpha2:"IQ", alpha3: "IRQ", ioc: "IRQ"},
            {id: "65", de: "Irland", en: "Ireland", priority: "100", alpha2:"IE", alpha3: "IRL", ioc: "IRL"},
            {id: "67", de: "Israel", en: "Israel", priority: "100", alpha2:"IL", alpha3: "ISR", ioc: "ISR"},
            {id: "68", de: "Italien", en: "Italy", priority: "100", alpha2:"IT", alpha3: "ITA", ioc: "ITA"},
            {id: "69", de: "Jamaika", en: "Jamaica", priority: "100", alpha2:"JM", alpha3: "JAM", ioc: "JAM"},
            {id: "70", de: "Japan", en: "Japan", priority: "100", alpha2:"JP", alpha3: "JPN", ioc: "JPN"},
            {id: "72", de: "Jordanien", en: "Jordan", priority: "100", alpha2:"JO", alpha3: "JOR", ioc: "JOR"},
            {id: "77", de: "Kasachstan", en: "Kazakhstan", priority: "100", alpha2:"KZ", alpha3: "KAZ", ioc: "KAZ"},
            {id: "79", de: "Kenia", en: "Kenya", priority: "100", alpha2:"KE", alpha3: "KEN", ioc: "KEN"},
            {id: "81", de: "Kiribati", en: "Kiribati", priority: "100", alpha2:"KI", alpha3: "KIR", ioc: "KIR"},
            {id: "86", de: "Korea, Nord", en: "Korea, North", priority: "100", alpha2:"KP", alpha3: "PRK", ioc: "PRK"},
            {id: "87", de: "Korea, Süd", en: "Korea, South", priority: "100", alpha2:"KR", alpha3: "KOR", ioc: "KOR"},
            {id: "91", de: "Kuwait", en: "Kuwait", priority: "100", alpha2:"KW", alpha3: "KWT", ioc: "KUW"},
            {id: "80", de: "Kirgisistan", en: "Kyrgyzstan", priority: "100", alpha2:"KG", alpha3: "KGZ", ioc: "KGZ"},
            {id: "92", de: "Laos", en: "Lao", priority: "100", alpha2:"LA", alpha3: "LAO", ioc: "LAO"},
            {id: "94", de: "Lettland", en: "Latvia", priority: "100", alpha2:"LV", alpha3: "LVA", ioc: "LAT"},
            {id: "95", de: "Libanon", en: "Lebanon", priority: "100", alpha2:"LB", alpha3: "LBN", ioc: "LBN"},
            {id: "93", de: "Lesotho", en: "Lesotho", priority: "100", alpha2:"LS", alpha3: "LSO", ioc: "LES"},
            {id: "96", de: "Liberia", en: "Liberia", priority: "100", alpha2:"LR", alpha3: "LBR", ioc: "LBR"},
            {id: "97", de: "Libyen", en: "Libya", priority: "100", alpha2:"LY", alpha3: "LBY", ioc: "LBA"},
            {id: "98", de: "Liechtenstein", en: "Liechtenstein", priority: "100", alpha2:"LI", alpha3: "LIE", ioc: "LIE"},
            {id: "99", de: "Litauen", en: "Lithuania", priority: "100", alpha2:"LT", alpha3: "LTU", ioc: "LTU"},
            {id: "100", de: "Luxemburg", en: "Luxembourg", priority: "100", alpha2:"LU", alpha3: "LUX", ioc: "LUX"},
            {id: "111", de: "Mazedonien", en: "Macedonia", priority: "100", alpha2:"MK", alpha3: "MKD", ioc: "MKD"},
            {id: "101", de: "Madagaskar", en: "Madagascar", priority: "100", alpha2:"MG", alpha3: "MDG", ioc: "MAD"},
            {id: "102", de: "Malawi", en: "Malawi", priority: "100", alpha2:"MW", alpha3: "MWI", ioc: "MAW"},
            {id: "103", de: "Malaysia", en: "Malaysia", priority: "100", alpha2:"MY", alpha3: "MYS", ioc: "MAS"},
            {id: "104", de: "Malediven", en: "Maldives", priority: "100", alpha2:"MV", alpha3: "MDV", ioc: "MDV"},
            {id: "105", de: "Mali", en: "Mali", priority: "100", alpha2:"ML", alpha3: "MLI", ioc: "MLI"},
            {id: "106", de: "Malta", en: "Malta", priority: "100", alpha2:"MT", alpha3: "MLT", ioc: "MLT"},
            {id: "108", de: "Marshallinseln", en: "Marshall Islands", priority: "100", alpha2:"MH", alpha3: "MHL", ioc: "MHL"},
            {id: "109", de: "Mauretanien", en: "Mauritania", priority: "100", alpha2:"MR", alpha3: "MRT", ioc: "MTN"},
            {id: "110", de: "Mauritius", en: "Mauritius", priority: "100", alpha2:"MU", alpha3: "MUS", ioc: "MRI"},
            {id: "112", de: "Mexiko", en: "Mexico", priority: "100", alpha2:"MX", alpha3: "MEX", ioc: "MEX"},
            {id: "113", de: "Mikronesien", en: "Micronesia", priority: "100", alpha2:"FM", alpha3: "FSM", ioc: "FSM"},
            {id: "114", de: "Moldawien", en: "Moldova", priority: "100", alpha2:"MD", alpha3: "MDA", ioc: "MDA"},
            {id: "115", de: "Monaco", en: "Monaco", priority: "100", alpha2:"MC", alpha3: "MCO", ioc: "MON"},
            {id: "116", de: "Mongolei", en: "Mongolia", priority: "100", alpha2:"MN", alpha3: "MNG", ioc: "MGL"},
            {id: "117", de: "Montenegro", en: "Montenegro", priority: "100", alpha2:"ME", alpha3: "MNE", ioc: "MNE"},
            {id: "107", de: "Marokko", en: "Morocco", priority: "100", alpha2:"MA", alpha3: "MAR", ioc: "MAR"},
            {id: "118", de: "Mosambik", en: "Mozambique", priority: "100", alpha2:"MZ", alpha3: "MOZ", ioc: "MOZ"},
            {id: "119", de: "Myanmar", en: "Myanmar oder Burma", priority: "100", alpha2:"MM", alpha3: "MMR", ioc: "MYA"},
            {id: "120", de: "Namibia", en: "Namibia", priority: "100", alpha2:"NA", alpha3: "NAM", ioc: "NAM"},
            {id: "121", de: "Nauru", en: "Nauru", priority: "100", alpha2:"NR", alpha3: "NRU", ioc: "NRU"},
            {id: "122", de: "Nepal", en: "Nepal", priority: "100", alpha2:"NP", alpha3: "NPL", ioc: "NEP"},
            {id: "125", de: "Niederlande", en: "Netherlands", priority: "100", alpha2:"NL", alpha3: "NLD", ioc: "NED"},
            {id: "123", de: "Neuseeland", en: "New Zealand", priority: "100", alpha2:"NZ", alpha3: "NZL", ioc: "NZL"},
            {id: "124", de: "Nicaragua", en: "Nicaragua", priority: "100", alpha2:"NI", alpha3: "NIC", ioc: "NCA"},
            {id: "126", de: "Niger", en: "Niger", priority: "100", alpha2:"NE", alpha3: "NER", ioc: "NIG"},
            {id: "127", de: "Nigeria", en: "Nigeria", priority: "100", alpha2:"NG", alpha3: "NGA", ioc: "NGR"},
            {id: "128", de: "Norwegen", en: "Norway", priority: "100", alpha2:"NO", alpha3: "NOR", ioc: "NOR"},
            {id: "129", de: "Oman", en: "Oman", priority: "100", alpha2:"OM", alpha3: "OMN", ioc: "OMA"},
            {id: "132", de: "Pakistan", en: "Pakistan", priority: "100", alpha2:"PK", alpha3: "PAK", ioc: "PAK"},
            {id: "133", de: "Palau", en: "Palau", priority: "100", alpha2:"PW", alpha3: "PLW", ioc: "PLW"},
            {id: "134", de: "Panama", en: "Panama", priority: "100", alpha2:"PA", alpha3: "PAN", ioc: "PAN"},
            {id: "135", de: "Papua-Neuguinea", en: "Papua New Guinea", priority: "100", alpha2:"PG", alpha3: "PNG", ioc: "PNG"},
            {id: "136", de: "Paraguay", en: "Paraguay", priority: "100", alpha2:"PY", alpha3: "PRY", ioc: "PAR"},
            {id: "137", de: "Peru", en: "Peru", priority: "100", alpha2:"PE", alpha3: "PER", ioc: "PER"},
            {id: "138", de: "Philippinen", en: "Philippines", priority: "100", alpha2:"PH", alpha3: "PHL", ioc: "PHI"},
            {id: "139", de: "Polen", en: "Poland", priority: "100", alpha2:"PL", alpha3: "POL", ioc: "POL"},
            {id: "140", de: "Portugal", en: "Portugal", priority: "100", alpha2:"PT", alpha3: "PRT", ioc: "POR"},
            {id: "78", de: "Katar", en: "Qatar", priority: "100", alpha2:"QA", alpha3: "QAT", ioc: "QAT"},
            {id: "142", de: "Rumänien", en: "Romania", priority: "100", alpha2:"RO", alpha3: "ROU", ioc: "ROU"},
            {id: "143", de: "Russland", en: "Russia", priority: "100", alpha2:"RU", alpha3: "RUS", ioc: "RUS"},
            {id: "141", de: "Ruanda", en: "Rwanda", priority: "100", alpha2:"RW", alpha3: "RWA", ioc: "RWA"},
            {id: "163", de: "St. Kitts und Nevis", en: "Saint Kitts and Nevis", priority: "100", alpha2:"KN", alpha3: "KNA", ioc: "SKN"},
            {id: "164", de: "St. Lucia", en: "Saint Lucia", priority: "100", alpha2:"LC", alpha3: "LCA", ioc: "LCA"},
            {id: "165", de: "St. Vincent und die Grenadinen", en: "Saint Vincent and the Grenadines", priority: "100", alpha2:"VC", alpha3: "VCT", ioc: "VIN"},
            {id: "146", de: "Samoa", en: "Samoa", priority: "100", alpha2:"WS", alpha3: "WSM", ioc: "SAM"},
            {id: "147", de: "San Marino", en: "San Marino", priority: "100", alpha2:"SM", alpha3: "SMR", ioc: "SMR"},
            {id: "148", de: "São Tomé und Príncipe", en: "São Tomé and Príncipe", priority: "100", alpha2:"ST", alpha3: "STP", ioc: "STP"},
            {id: "149", de: "Saudi-Arabien", en: "Saudi Arabia", priority: "100", alpha2:"SA", alpha3: "SAU", ioc: "KSA"},
            {id: "152", de: "Senegal", en: "Senegal", priority: "100", alpha2:"SN", alpha3: "SEN", ioc: "SEN"},
            {id: "153", de: "Serbien", en: "Serbia", priority: "100", alpha2:"RS", alpha3: "SRB", ioc: "SRB"},
            {id: "154", de: "Seychellen", en: "Seychelles", priority: "100", alpha2:"SC", alpha3: "SYC", ioc: "SEY"},
            {id: "155", de: "Sierra Leone", en: "Sierra Leone", priority: "100", alpha2:"SL", alpha3: "SLE", ioc: "SLE"},
            {id: "157", de: "Singapur", en: "Singapore", priority: "100", alpha2:"SG", alpha3: "SGP", ioc: "SGP"},
            {id: "158", de: "Slowakei", en: "Slovakia", priority: "100", alpha2:"SK", alpha3: "SVK", ioc: "SVK"},
            {id: "159", de: "Slowenien", en: "Slovenia", priority: "100", alpha2:"SI", alpha3: "SVN", ioc: "SLO"},
            {id: "144", de: "Salomonen", en: "Solomon Islands", priority: "100", alpha2:"SB", alpha3: "SLB", ioc: "SOL"},
            {id: "160", de: "Somalia", en: "Somalia", priority: "100", alpha2:"SO", alpha3: "SOM", ioc: "SOM"},
            {id: "166", de: "Südafrika", en: "South Africa", priority: "100", alpha2:"ZA", alpha3: "ZAF", ioc: "RSA"},
            {id: "168", de: "Südsudan", en: "South Sudan", priority: "100", alpha2:"SS", alpha3: "SSD", ioc: "SSD"},
            {id: "161", de: "Spanien", en: "Spain", priority: "100", alpha2:"ES", alpha3: "ESP", ioc: "ESP"},
            {id: "162", de: "Sri Lanka", en: "Sri Lanka", priority: "100", alpha2:"LK", alpha3: "LKA", ioc: "SRI"},
            {id: "167", de: "Sudan", en: "Sudan", priority: "100", alpha2:"SD", alpha3: "SDN", ioc: "SUD"},
            {id: "169", de: "Suriname", en: "Suriname", priority: "100", alpha2:"SR", alpha3: "SUR", ioc: "SUR"},
            {id: "170", de: "Swasiland", en: "Swaziland", priority: "100", alpha2:"SZ", alpha3: "SWZ", ioc: "SWZ"},
            {id: "150", de: "Schweden", en: "Sweden", priority: "100", alpha2:"SE", alpha3: "SWE", ioc: "SWE"},
            {id: "151", de: "Schweiz", en: "Switzerland", priority: "100", alpha2:"CH", alpha3: "CHE", ioc: "SUI"},
            {id: "171", de: "Syrien", en: "Syria", priority: "100", alpha2:"SY", alpha3: "SYR", ioc: "SYR"},
            {id: "173", de: "Taiwan", en: "Taiwan", priority: "100", alpha2:"TW", alpha3: "TWN", ioc: "TPW"},
            {id: "172", de: "Tadschikistan", en: "Tajikistan", priority: "100", alpha2:"TJ", alpha3: "TJK", ioc: "TJK"},
            {id: "174", de: "Tansania", en: "Tanzania", priority: "100", alpha2:"TZ", alpha3: "TZA", ioc: "TAN"},
            {id: "175", de: "Thailand", en: "Thailand", priority: "100", alpha2:"TH", alpha3: "THA", ioc: "THA"},
            {id: "131", de: "Osttimor", en: "East Timor", priority: "100", alpha2:"TL", alpha3: "TLS", ioc: "TLS"},
            {id: "176", de: "Togo", en: "Togo", priority: "100", alpha2:"TG", alpha3: "TGO", ioc: "TGO"},
            {id: "177", de: "Tonga", en: "Tonga", priority: "100", alpha2:"TO", alpha3: "TON", ioc: "TGA"},
            {id: "178", de: "Trinidad und Tobago", en: "Trinidad and Tobago", priority: "100", alpha2:"TT", alpha3: "TTO", ioc: "TTO"},
            {id: "181", de: "Tunesien", en: "Tunisia", priority: "100", alpha2:"TN", alpha3: "TUN", ioc: "TUN"},
            {id: "182", de: "Türkei", en: "Turkey", priority: "100", alpha2:"TR", alpha3: "TUR", ioc: "TUR"},
            {id: "183", de: "Turkmenistan", en: "Turkmenistan", priority: "100", alpha2:"TM", alpha3: "TKM", ioc: "TKM"},
            {id: "184", de: "Tuvalu", en: "Tuvalu", priority: "100", alpha2:"TV", alpha3: "TUV", ioc: "TUV"},
            {id: "185", de: "Uganda", en: "Uganda", priority: "100", alpha2:"UG", alpha3: "UGA", ioc: "UGA"},
            {id: "186", de: "Ukraine", en: "Ukraine", priority: "100", alpha2:"UA", alpha3: "UKR", ioc: "UKR"},
            {id: "193", de: "Vereinigte Arabische Emirate", en: "United Arab Emirates", priority: "100", alpha2:"AE", alpha3: "ARE", ioc: "UAE"},
            {id: "54", de: "Großbritannien", en: "United Kingdom", priority: "100", alpha2:"GB", alpha3: "GBR", ioc: "GBR"},
            {id: "194", de: "USA", en: "USA", priority: "100", alpha2:"US", alpha3: "USA", ioc: "USA"},
            {id: "188", de: "Uruguay", en: "Uruguay", priority: "100", alpha2:"UY", alpha3: "URY", ioc: "URU"},
            {id: "189", de: "Usbekistan", en: "Uzbekistan", priority: "100", alpha2:"UZ", alpha3: "UZB", ioc: "UZB"},
            {id: "190", de: "Vanuatu", en: "Vanuatu", priority: "100", alpha2:"VU", alpha3: "VUT", ioc: "VAN"},
            {id: "192", de: "Venezuela", en: "Venezuela", priority: "100", alpha2:"VE", alpha3: "VEN", ioc: "VEN"},
            {id: "195", de: "Vietnam", en: "Vietnam", priority: "100", alpha2:"VN", alpha3: "VNM", ioc: "VIE"},
            {id: "71", de: "Jemen", en: "Yemen", priority: "100", alpha2:"YE", alpha3: "YEM", ioc: "YEM"},
            {id: "145", de: "Sambia", en: "Zambia", priority: "100", alpha2:"ZM", alpha3: "ZMB", ioc: "ZAM"},
            {id: "156", de: "Simbabwe", en: "Zimbabwe", priority: "100", alpha2:"ZW", alpha3: "ZWE", ioc: "ZIM"},
            {id: "199", de: "Hongkong", en: "Hong Kong", priority: "100", alpha2:"HK", alpha3: "HKG", ioc: "HKG"},
        ]
    }
    static getCountryParamById(id, parameter){
        if ( !id ) { return '' }
        const country = CountriesService.getCountryListRaw().find(country => country.id === id);
        return country ? country[parameter] : '';
    }
}