import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const dateTimeFormats = {
    'en': {
        shortDate: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        shortDateWeekday: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
        },
        shortDateWeekdayDayOnly: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', weekday: 'short', month: 'short', day: 'numeric'
        },
        longDate: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'de': {
        shortDate: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        shortDateWeekday: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
        },
        shortDateWeekdayDayOnly: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', weekday: 'short', day: 'numeric'
        },
        longDate: {
            // do not use for timestamp!!! => conversion to UTC!!!
            timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

import {localesUtilities} from '@/utilities/plugins/i18n/locales.js';
import {messages} from "@/plugins/i18n_messages";

export const i18n = new VueI18n({
    dateTimeFormats,
    locale: 'de',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: {
        en: {
            utilities: localesUtilities.en,
            messages: messages.en,
            test: 'this is a test',
            imprint: {
                company: 'Company',
                ceo: 'CEO',
                address: 'Company address',
                UID: 'VAT-number',
                companyReg: 'Company registration number', // company registration number
                companyCourt: 'Company register court', //company register court
                registeredOffice: 'Registered office', //registered office
                business: 'Business', // business
            },
            common: {
                ieBlock: 'Unfortunately, this application no longer runs on the ' +
                'outdated Internet Explorer. We recommend using Google Chrome, ' +
                'but you can also use any other modern browser ' +
                '(e.g. Microsoft Edge, Firefox, Opera or Safari for Apple).',
                passes: 'Passes',
                voucher: 'Voucher',
                vouchers: 'Vouchers',
                redeemNow: 'redeem now',
                worth: 'worth',
                upTo: 'up to',
                valid: 'valid',
                validUntil: 'valid until',
                issuedBy: 'Issued by',
                documents: 'Documents',
                documentDraft: 'Document draft',
                personalData: 'Personal data',
                forFree: 'free',
                isLoaded: 'is loading',
                men: 'Men',
                women: 'Women',
                single: 'Singles',
                double: 'Doubles',
                triple: 'Triple',
                mixed: 'Mixed',
                done: 'done',
                pleaseSelect: 'please select',
                date: 'date',
                with: 'with',
                item: 'item',
                learnMore: 'learn more',
                or: 'or',
                maintenance: 'is out of service for maintenance',
                privacyChanged: 'Our privacy policy has changed. ' +
                'Please confirm the current version:',
                termsChanged: 'Our terms of usage have changed. ' +
                'Please confirm the current version:',
                firstEmailOptIn: 'You can receive emails from us (optional):',
                others: 'Others',
                emailOptInEdit: 'I agree to receive in-app-notifications and emails  about new features, promotions, offers ' +
                    'from On Your Marks, other Number One Solution Services, their clients or partners (optional).',
                infoEmailOptOutEdit: 'Send e-mail order confirmations and e-mail updates for events I have booked.',
                activated: 'Activated',
                disabled: 'Disabled',
                otherMemberships: 'Other memberships',
                unlimited: 'unlimited',
            },
            home: {
                digitalId: 'digital id',
                wallet: 'wallet',
                shop: 'shop',
                profile: 'profile',
            },
            profile: {
                change: 'Edit profile',
                incomplete: 'Please click here to complete and activate your profile!',
                imageMissing: "Please upload a profile picture " +
                "(click the 'plus' icon below)!",
                parentAuthMissing: 'You are under 14 years of age. Please ' +
                'upload the confirmation from your legal guardian.',
                parentAuthDownload: "Please use the following form and then " +
                "click 'Upload document' below to save the signed document: ",
                parentAuth: 'Liability declaration of legal guardians',
                childUsage: 'I am under 14 years of age and use the app myself',
                parentUsage: 'I use the app on behalf of my child under 14 years of age',
                usageType: 'The registered person is under 14 years of age. ' +
                'Please select the type of use below: ',
                usageTypeHeader: 'Type of use/under 14 years',
                addPerson: 'Add person',
                otherPersons: 'More people',
                addPersonInfo: 'You can use this account to manage other people ' +
                    '(e.g. your underage children).',
                changePersonInfo: 'Click the person in the list to switch to that ' +
                'person\'s account. You can then edit this person like your own account.',
                personExisting: 'This person already exists and cannot be created again!',
                deleteChildHeader: 'to be deleted ',
                noDeleteChildHeader: ' cannot be deleted',
                deleteChildContent: 'Are you sure you want to permanently delete this ' +
                'person from your account?',
                noDeleteChildContent: 'This person can no longer be deleted because orders, ' +
                'passes or vouchers have already been assigned to them!',
                deleteChildSuccess: 'The person was deleted!',
                deleteChildError: 'An error occurred while deleting this person!',
                confirmChildAge: 'The person is under 14 years old. You hereby expressly ' +
                'agree that we may process the data of this minor.',
                settingBlocked: 'Setting blocked',
                settingBlockedText: 'You have already registered for events and competitions ' +
                'with the current settings and can therefore no longer change this setting. ' +
                'Please contact support if the change is absolutely necessary.',
                printQR: 'print QR-Code',
                requestDeleteOnTermsConfirm: ' if you do not agree and want to delete your account!',
                requestDeleteOnTermsBtn: 'Click here',
                requestDeleteText: 'If you want to permanently and irrevocably delete your ' +
                'account, you can request deletion here. You will receive an email ' +
                'from us as soon as the deletion has taken place.',
                requestDeleteTextChild: 'If you want to permanently and irrevocably delete this person ' +
                    '(and not your full account), ' +
                    'you can request deletion here. You will receive an email ' +
                    'from us as soon as the deletion has taken place.',
                requestDeleteBtn: 'Request account deletion',
                requestDeleteBtnChild: 'Request deletion of person',
                requestDeleteSuccess: 'The account deletion was successfully requested!',
                childInfo: 'Please note:',
                childList1: "individuals under 14 years old must be registered under a guardian's account",
                childList2: "individuals between 14 and 18 years old can be registered under a guardian's account",
                childList3: "purchases and registrations can be made through a guardian's account until the age of 18",
                childList4: "from the age of 14, individuals can create their own account and make purchases there",
                childList5: "from the age of 18, individuals must create their own account (register with their own email address) to make purchases",
                save: 'Save person',
                olderThan18: 'The person is older than 18 years, you can no longer make purchases on their behalf. ' +
                    'The person needs to set up their own account.',
                nextYear18: 'The person will turn 18 next year. You can continue to make purchases on ' +
                    'their behalf until the end of next year.',
                thisYear18: 'The person will turn 18 this year. You can continue to make purchases on their behalf ' +
                    'until the end of the year.',
            },
            shop: {
                expired: 'Our offer has changed during your purchase. Please delete the following items from your shopping cart:',
                empty: 'There are currently no offers in the shop.',
                yourShoppingCart: 'Your shopping cart',
                buyNow: 'buy now',
                totalSum: 'Total of all events',
                paymentSuccessful: 'Payment successful',
                orderCreated: 'Please check your order and start payment process!',
                selectEvent: 'Please select an event',
                cancelOrder: 'Cancel order',
                alreadyBought: 'You can already find this item in the wallet!',
                gotoShoppingCart: 'Go to shopping cart',
                gotoWallet: 'Go to wallet',
                cancelPurchaseOrderHeader: 'Cancel purchase order',
                cancelPurchaseOrderDescription: 'Do you really want to cancel this purchase?',
                pendingCheckout: 'You haven\'t paid for your last order, or we haven\'t received ' +
                'confirmation of payment from Stripe. Please be patient for a few seconds or if ' +
                'you haven\'t paid yet, please complete the payment before making a new purchase.',
                completePayment: 'complete payment',
                pendingPurchase: 'Please complete your current purchase before making any new purchases.',
                completePurchase: 'Complete purchase',
                buyFree: 'Complete free purchase',
                buyFreeConfirmed: 'Your free order was successful. It may take a few seconds for your ' +
                'purchases to appear in the wallet. ' +
                'You can also find your orders in the menu under \'My purchases\'',
                poError: 'Order creation failed.',
                maxItems: 'You have exceeded the maximum number of items in the shopping cart ({maxItems}). ' +
                'Please delete items to complete the order.',
                cancellation: 'I have read and accepted the seller\'s refund policy.',
                includesVoucher: 'Includes the following voucher:',
                orderId: 'Order number',
                exitShop: 'Exit shop',
                insteadOf: 'instead of',
                soldOut: 'sold out',
                sellerText: 'sold by',
                shopNow: 'buy now',
                removeFromCart: 'remove from the shopping cart',
                addToCart: 'add to cart',
                pleaseSelect: 'Please select an item from the drop-down list on the left!',
                cash: 'Cash',
                online: 'Online',
                testMode: 'TEST MODE - this organization is only visible to you! ' +
                    "This organization's events cannot yet be booked.",
            },
            shopEvent: {
                contact: 'Contact',
                until: 'Book until',
                licenseMissingMessage: 'In order to participate in this event, ' +
                    'you must first purchase the following pass or membership:',
                licenseMissingMessageMultiple: 'In order to participate in this event, ' +
                    'you must first purchase one of the following passes or memberships:',
                blockedOtherUser: "You already have items in your shopping cart for someone else. " +
                    "Please complete this purchase first or remove these items from your " +
                    "shopping cart to be able to purchase items for '{eventName}'.",
                blockedOtherEvent: "You already have items for another event in your shopping cart. " +
                    "Please complete this purchase first or remove these items from your shopping " +
                    "cart to be able to purchase items for '{eventName}'.",
                blockedByVoucherEvent: "'eventName' cannot be purchased together with " +
                    "'{eventNameBlocking}'. Please complete this purchase first or " +
                    "empty the shopping cart.",
                blockedByVoucherEventAlt: "You already have an event in your shopping cart that " +
                    "cannot be purchased together with '{eventNameBlocking}'. Please complete this " +
                    "purchase first or empty the shopping cart.",
                blockedOtherSeller: 'You have items from another seller in your shopping cart. ' +
                'Please complete this purchase first to purchase items for this event.',
                allPurchased: 'You have already bought everything for this event.',
                display: 'Show details anyway',
                noEvents: 'At the moment you cannot buy anything from this organisation.'
            },
            shopVoucher: {
                redeemVoucher: 'Redemm voucher',
                redeemVoucherInfo: 'For this purchase you can redeem one of the following vouchers',
                remainderExpires: 'remainder expires',
                free: 'Free',
                discount: 'Discount',
                specialPrice: 'Promotion',
                advantages: 'With the purchase you get the following advantages:',
                advantagesEvent: 'With your first purchase for this event you will receive the following benefits:',
                eventVoucherAbsolute: '{number} vouchers for events with a total value of {currency} {value},--',
                eventVoucherPercent: '{number} vouchers for events with discounts up to {value}%',
                externalVoucher: '{number} vouchers with benefits from our cooperation partners',
                eventVoucherAbsoluteSingle: '{number} voucher for an event worth {currency} {value},--',
                eventVoucherPercentSingle: '{number} voucher for an event with a discount of {value}%',
                externalVoucherSingle: '{number} voucher from our cooperation partners',
                showVouchers: 'show me the vouchers',
                hideVouchers: 'hide vouchers',
                voucherAviso: 'You have at least one voucher for this event. All information ' +
                    'can be found in the shopping cart as soon as you have selected an item.',
                voucherRedeem: 'You have vouchers, please select which of them you would like to redeem:',
                deduction: 'minus vouchers',
                toBePaid: 'payment amount',
                ignore: 'Vouchers available',
                ignoreText: 'You have vouchers for this purchase, but have not redeemed them. ' +
                    'Do you want to redeem these vouchers?',
                remainderExpiresWarning: 'The entire voucher cannot be redeemed for the following people ' +
                    'because the purchase amount is too low:',
                remainderExpiresWarning2: 'The rest of the voucher value expires for these people!',
                redeemedPreviously: 'A voucher has already been redeemed for this person and this event ' +
                    'from a previous purchase. Only one voucher can be redeemed per event and person!',
            },
            adminVoucher: {
                add: 'create new voucher',
                edit: 'Change voucher',
                copy: 'Copy voucher',
                editBtn: 'Change',
                copyBtn: 'Copy',
                title: 'Voucher title',
                description: 'Description',
                validUntil: 'Redeemable until',
                showValueLabel: 'How would you like to indicate the value of the voucher?',
                showValueInPercent: 'in % of the product or order value',
                showValueAbsolute: 'as an absolute amount (e.g. 10 EUR)',
                showValueNotAtAll: 'not at all',
                value: 'Value',
                valuePercent: 'Value (%)',
                currency: 'Currency',
                benefitKeyLabel: 'What type of voucher is it?',
                benefitKeyDiscount: 'DISCOUNT - on the order value',
                benefitKeySpecialPrice: 'SPECIAL PRICE - for a specific product',
                benefitKeyFree: 'FREE - the product is free',
                benefitOther: 'Enter your text...',
                isThirdPartyLabel: 'Where should the voucher be redeemed?',
                isThirdPartyFalse: 'only in the On Your Marks shop',
                isThirdPartyTrue: 'only in a third-party shop',
                issueWithLicenseId: 'The voucher should be issued with:',
                issueFrom: 'Issue from',
                issueUntil: 'Issue until',
                issuerCode: 'Voucher code in your system',
                externalDescriptionLink: 'Link that leads to further information',
                redeemInformation: 'Information on using the voucher (free text)',
                permittedOrganisationIds: 'Organizations authorized to use this coupon?',
                saveError: 'When saving an error has occurred!',
                data: 'Data',
                redeem: 'Redeem',
                stats: 'Stats',
                copyOf: 'COPY OF ',
                clickForTerms: 'Special conditions of our client apply to this voucher. ' +
                    'Click on the coupon to view these terms.',
                activeVouchers: 'Active Vouchers',
                inactiveVouchers: 'Expired Vouchers',
                onlyOnline: 'Voucher should only be redeemable for online payments',
                /*requestUserDataForRedeem: 'I would like to be able to check the validity of a customer voucher ' +
                    'via the On Your Marks app and receive an evaluation of the redeemed vouchers ' +
                    '(this requires customer data and the voucher will not be ' +
                    'issued if the customer does not agree)',*/
            },
            voucherDetails: {
                issuedBy: 'This voucher is issued by',
                issuePeriod: 'Issue period',
                issueOrg: 'Eligible for issue',
                description: 'Voucher information',
                redeemInfo: 'Redemption information',
                onlineOnly: 'This voucher is only valid and redeemable online with a purchase in the On Your Marks app!',
                /*requestUserDataForRedeem: 'The issuer of the voucher may only (!) access your first name, ' +
                    'last name and email address when redeeming the voucher to check its validity via On Your Marks!',*/
                thirdPartyCode: 'Vendor voucher code:',
                web: 'Further information',
                //webDetails: 'Further information on the voucher and the applicable conditions can be found here:',
                footnote: '* The conditions of the client described here apply to this voucher!',
                redeemInfoAviso: 'You can find information on how to redeem this voucher in your wallet after purchase!',
            },
            wallet: {
                empty: 'Your wallet is empty. Please go to the shop ' +
                'to fill it with passes or tickets for events. If you have just bought something ' +
                'it may take a few seconds to appear here.',
                emptyArchive: 'You don\'t have any archived passes, tickets, or vouchers yet.',
                creatingDocuments: 'Please be patient, your player pass and ' +
                'vouchers will be created!',
                yourData: 'Your data',
                organisationData: 'Issuer data',
                statutes: 'Statutes',
                statutes1: 'I have read the ',
                statutes2: ' of my ERU membership country (Austria) and I agree to them.',
                statutesMissing: 'You have not yet agreed to the latest version of the statutes ' +
                'of your ERU membership country (Austria)!',
                privacy: 'Data protection',
                privacyMissing: 'You have not yet agreed to the latest version of the data protection regulations ' +
                'of your ERU membership country (Austria)!',
                covid: 'Covid-19 rules',
                covidMissing: 'You have not yet agreed to the latest version of the covid-19 rules ' +
                'of your ERU membership country (Austria)!',
                termsOfUse: 'Terms of use',
                termsOfUseMissing: 'You have not yet agreed to the latest version of the terms of usage ' +
                'of your ERU membership country (Austria)!',
                rulesOfCompetition: 'Rules of competition',
                rulesOfCompetitionMissing: 'You have not yet agreed to the latest version of the rules of competition ' +
                'of your ERU membership country (Austria)!',
                yourEvents: 'Your events and purchases',
                redeemed: 'Redeemed',
                redeemedBy: 'Redeemed at',
                redeemedAt: 'Redeemed on',
                redeemedWith: 'Redeemed with purchase order',
                gotoPo: 'Go to purchase order',
                gotoArchive: 'Go to archive',
                returnFromArchive: 'back to the wallet',
                archive: 'Archive',
            },
            myPurchases: {
                menuItem: 'My purchases',
                noPurchases: 'You haven\'t bought anything on On Your Marks yet!',
                filteredBy: 'Filtered by order number:',
            },
            fir2022: {
                selectEventHeader: 'Select your status',
                selectHotel: 'Select accomodation',
                selectEventDescription: 'Please select at least one event',
                selectExtras: 'Select your extras',
                selectHotelType: 'Please select your accomodation type:',
                termsAndInformation: 'Terms and information',
                privateRoom1: 'private accommodation ',
                privateRoom2: ' - entitles to a discount on certain events with proof',
                officialRoom1: 'official tournament hotel',
                officialRoom2: ' - entitles to a discount on certain events',
                otherRoom1: 'other hotel',
                otherRoom2: 'I will pay the full entry fee on all events',
                selectRoomType: 'Please choose your hotel and your room type. For shared rooms you can ' +
                'book one bed and name the other person(s) (but these people must also book the room via On Your Marks)',
                selectNightDescription: 'Please select the desired nights. ' +
                'In order to get the discount on the entry fee, ' +
                'you must buy the hotel at the same time as the competition and ' +
                'choose at least the night between the competition days.',
                otherPerson: 'other person(s) (for information only)',
                infoPriceLow: 'only valid until {date} if a hotel is booked at the same time or if ' +
                'private accomondation is selected and if written confirmation from the private accommodation ' +
                'provider or proof of residence is presented to the tournament organizers.',
                infoPriceHigh: 'valid until {date} if no hotel is booked',
                infoPriceCancellation: 'No refund for event and hotel bookings.',
                infoPriceFirstTimers: "*** a special price of €25 applies when booking " +
                "the 'First Timers' competition (singles only)",
                namePartner: 'please name your partner (optional)',
                ageAlert: 'Due to your age, you may only take part in this competition as an accompanying person',
                sellerText: 'sold by',
                singleRoom: 'Single',
                doubleRoom: 'Double',
                tripleRoom: 'Triple',
                multiUse: '3-4 use',
                singleUse: 'Single use',
                doubleUse: 'Double use',
                bunkBeds: 'Only multi-bed-rooms with bunk beds',
                selectExtra: 'please select your {extra}',
                free: 'Free',
                dinner: 'Players Dinner',
                tshirt: 'Official WC T-Shirt',
                insurance: 'Cancellation-Insurance (Covid, Injury, Illness)',
                insuranceDescription: '',
                insuranceDescription1: 'WC 2022 Cancellation-Insurance for only €15',
                insuranceDescription2: 'full money back for non-participation due to below reasons',
                insuranceDescription3: 'cancellation possible until one day before tournament begin',
                insuranceDescription4: 'all reasons accepted with confirmation paper: Covid19-infection, Covid19-quarantine, Covid19-travel restrictions, injury, illness and similar.',
                insuranceDescription5: 'Due to the covid19-crises and the many uncertainties until the ' +
                'Racketlon World Champs 2022 will begin, we have a special offer for all participants of ' +
                'the FIR World Championships 2022. For the first time in Racketlon history all players ' +
                'have the possibility of an insurance for only €15 in case of non-participation due to ' +
                'covid, injury or illness even on short notice until only one day before tournament begin. ' +
                'This shall give players the maximum feeling of safety not to lose any money for reasons ' +
                'they cannot influence. The insurance is bound to one person and one event for all draws ' +
                'of that event. (Event 1: Juniors & Seniors, Vienna | Event 2: Amateurs & Elite, Graz). ',
                insuranceDescription6: 'Refunds will be transferred latest in September. ',
                license: 'FIR License',
                licenseDescription: 'Infotext to be defined!',
                checkout: 'Check out',
                othersQuestion: 'Do you want to shop for someone else?',
                doublesPartnerInfo: 'The specification of the doubles partner on On Your Marks is ' +
                'just informational and not binding. Please name your doubles partner on tournamentsoftware: ',
                doublesPartnerLink: 'open tournament software in another window',
                extrasInfo1: '* free for athletes who register for at least one event',
                extrasInfo2: '** without registering for an event',
                firMembershipHeader: 'Do you have a FIR license?',
                firBuyLicense: 'here ...',
                firMembershipIntro: "The following applies to all competitions except 'First Timers':",
                /*firMembership: 'Please note that a FIR license is mandatory to participate in ' +
                'this event. The license for one tournament is 15€, the full license is 40€ and ' +
                'allows entry into an unlimited number of tournaments this season. The ' +
                'license may be purchased ',*/
                firMembership: 'Please note that a FIR license is mandatory to participate in this event. ' +
                    'The annual FIR license allows participation in an unlimited number of ' +
                    'tournaments this season. Buy the annual FIR license for €40 right ',
                firMembershipNote: 'Note: Other FIR licenses are not available on On Your Marks.',
                hotelPriceCondition: 'All prices per person per night including taxes and breakfast ' +
                'buffet (normal price 19€ per person) and shuttle service on arrival from and to ' +
                'airport/trainstation as well as to the sports venue. No refund for hotel bookings!',
                companionHeader: 'I book as an accompanying person (family member, trainer, ' +
                'physiotherapist) for the following athlete (maximum one accompanying person per athlete):',
                companionDeselectEvent: 'Delete all events to enter',
                companionLabel: 'Please enter athlete name',
                userTypeTitle: 'I book as:',
                userTypeAthlete: 'athlete',
                userTypeCompanion: 'companion',
                rules: 'In order to be able to participate in the World Championships, ' +
                'you must confirm the following points:',
                coronaCheck: 'I have read the Covid19-prevention concept and confirm that I will comply',
                ended: 'The registration period for the World Cup has expired. If you have any request, please contact the organizer.',
                coronaConfirmed: 'Covid19-prevention concept read, understood and confirmed',
                privacyCheck: 'I have read the data protection regulations and I agree',
                privacyConfirmed: 'data protection regulations read, understood and confirmed',
                rulesOfCompetitionCheck: 'I have read the rules of competition and confirm that ' +
                'I will comply',
                rulesOfCompetitionConfirmed: 'rules of competition read, understood and confirmed',
                infoLink: 'Details on how to use On Your Marks...',
            },
            rfa: {
                voucherPass: '1 entry in a RFA tournament',
            },
            consents: {
                missingHeader: 'For this purchase you must accept and confirm the following conditions of the seller:',
                walletHeaderPass: 'To activate, please accept and confirm the following terms of the seller:',
                walletHeaderEvent: 'To participate, please accept and confirm the following terms of the seller:',
                generic1: 'I have read the ',
                generic2: ' and agree to them!',
                accept: 'Please click here to learn more and agree.',
                menuItem: 'My consents',
                inactive: 'INACTIVE',
                activate: 'click to activate',
                empty: 'You have not yet agreed to any documents or conditions of our clients.',
            },
            requiredInformation: {
                missingHeader: 'Please fill out the following information ' +
                    '(you can then view and change this information in your profile):',
                linkLabel: 'You can find more information here...',
                information: 'Information',
                accepted: 'Confirmed by {org} on {date}',
                rejected: 'Rejected by {org} on {date} - please change!',
                validUntil: 'Valid until {date} (set by {org})',
                blockedMsg: 'This information cannot be changed because it has already been confirmed by the ' +
                    'administrator and changes are not allowed for this information after the confirmation!',
            },
            stripe: {
                success1: 'Thank you, your payment was successful!',
                success2: 'Please be patient, it may take a few more seconds for the checkout staff to receive the confirmation.',
                failure: 'Unfortunately, there was an error with your payment. ' +
                    'Please contact the staff at the cash desk to initiate a new payment attempt.',
            },
            sports: {
                my: 'My sports',
                nothingSelected: 'Please select at least one sport!',
                changeSucess: 'Your changes have been saved!',
                changeError: 'An error has occurred, your changes were not saved!',
                all: 'select all sports',
                none: 'no personalization',
                infoText: 'Please choose your sports. Your selection will help us display offers related to these sports.',
                btnOthers: 'Show other offers',
                btnOthersHide: 'hide',
                others: 'Other offers',
            },
            admin: {
                infoText: 'Do you already have an account and want to use On Your Marks for your organisation? ' +
                    'Try it now for free!',
                btn: 'to the admin area',
            }
        },
        de: {
            utilities: localesUtilities.de,
            messages: messages.de,
            test: 'das ist ein Test',
            imprint: {
                company: 'Firma',
                ceo: 'Geschäftsführer',
                address: 'Firmenadresse',
                UID: 'UID-Nummer',
                companyReg: 'Firmenbuchnummer', // company registration number
                companyCourt: 'Firmenbuchgericht', //company register court
                registeredOffice: 'Firmensitz', //registered office
                business: 'Unternehmensgegenstand', // business
            },
            common: {
                ieBlock: 'Diese Anwendung läuft leider nicht mehr auf dem veralteten ' +
                'Internet Explorer. Wir empfehlen die Verwendung von Google Chrome, du ' +
                'kannst aber auch jeden anderen modernen Browser nutzen ' +
                '(z.B. Microsoft Edge, Firefox, Opera oder Safari für Apple).',
                passes: 'Pässe',
                voucher: 'Gutschein',
                vouchers: 'Gutscheine',
                redeemNow: 'jetzt einlösen',
                worth: 'Wert',
                upTo: 'bis zu',
                valid: 'Gültig',
                validUntil: 'gültig bis',
                issuedBy: 'Ausgestellt durch',
                documents: 'Dokumente',
                documentDraft: 'Dokument-Vorlage',
                personalData: 'Persönliche Daten',
                forFree: 'gratis',
                isLoaded: 'wird geladen',
                men: 'Herren',
                women: 'Damen',
                single: 'Einzel',
                double: 'Doppel',
                triple: 'Dreifach',
                mixed: 'Mixed',
                done: 'fertig',
                pleaseSelect: 'bitte wählen',
                date: 'Datum',
                with: 'mit',
                item: 'Stück',
                learnMore: 'mehr erfahren',
                or: 'oder',
                maintenance: 'ist für Wartungsarbeiten vorübergehend außer Betrieb',
                privacyChanged: 'Unsere Datenschutzbestimmungen haben sich geändert. ' +
                'Bitte um Bestätigung der aktuellen Version:',
                termsChanged: 'Unsere Nutzungsbedingungen haben sich geändert. ' +
                'Bitte um Bestätigung der aktuellen Version:',
                firstEmailOptIn: 'Du kannst ab sofort e-Mails von uns erhalten (optional):',
                others: 'Sonstiges',
                emailOptInEdit: 'Ich stimme dem Empfang von In-App-Benachrichtigungen und E-Mails über neue Features, Aktionen und Angebote ' +
                    'von On Your Marks, anderen Services von Number One Solutions und ihren Auftraggeber_innen und Partner_innen zu (optional).',
                infoEmailOptOutEdit: 'E-Mail Versand von Bestellbestätigungen sowie Updates zu von mir gebuchten ' +
                    'Veranstaltungen.',
                activated: 'Aktiviert',
                disabled: 'Deaktiviert',
                otherMemberships: 'Sonstige Mitgliedschaften',
                unlimited: 'unbegrenzt',
            },
            home: {
                digitalId: 'digitaler pass',
                wallet: 'wallet',
                shop: 'shop',
                profile: 'profil',
            },
            profile: {
                change: 'Profil ändern',
                incomplete: 'Bitte klicke hier um dein Profil zu vervollständigen und zu aktiveren!',
                imageMissing: "Bitte lade noch ein Profilbild hoch (klicke dazu auf das " +
                "'Plus'-Icon gleich unterhalb)!",
                parentAuthMissing: 'Du bist unter 14 Jahren. Bitte lade die Bestätigung ' +
                'deiner Erziehungsberechtigten hoch.',
                parentAuthDownload: "Bitte nutze dazu das folgende Formular und click " +
                "dann auf die Zeile unterhalb um das unterschriebene" +
                " Dokument zu speichern: ",
                parentAuth: 'Haftungserklärung Erziehungsberechtigter',
                childUsage: 'ich bin unter 14 Jahre und nutze die App selbst',
                parentUsage: 'ich nutze die App in Vertretung für mein Kind unter 14 Jahre',
                usageType: 'Die registrierte Person ist unter 14 Jahre alt. ' +
                'Bitte wähle nachfolgend die Art der Nutzung: ',
                usageTypeHeader: 'Nutzungsart/unter 14 jährige',
                addPerson: 'Person hinzufügen',
                otherPersons: 'Weitere Personen',
                addPersonInfo: 'Du kannst mit diesem Konto weitere Personen ' +
                '(z.B. deine minderjährigen Kinder) verwalten.',
                changePersonInfo: 'Klicke auf die Person in der Liste um zum Konto dieser ' +
                'Person zu wechseln. Du kannst diese Person dann wie dein eigenes Konto bearbeiten.',
                personExisting: 'Diese Person existiert bereits und ' +
                        'kann nicht nochmals angelegt werden!',
                deleteChildHeader: ' löschen',
                noDeleteChildHeader: ' kann nicht gelöscht werden',
                deleteChildContent: 'Möchtest du diese Person wirklich endgültig aus deinem ' +
                'Konto löschen?',
                noDeleteChildContent: 'Diese Person kann nicht mehr gelöscht werden weil ihr bereits ' +
                'Bestellungen, Pässe oder Gutscheine zugeordnet sind!',
                deleteChildSuccess: 'Die Person wurde gelöscht!',
                deleteChildError: 'Beim Löschen der Person ist ein Fehler aufgetreten!',
                confirmChildAge: 'Die Person ist unter 14 Jahre alt. Hiermit stimmst du ausdrücklich zu, ' +
                'dass wir die Daten dieser minderjährigen Person verarbeiten dürfen.',
                settingBlocked: 'Einstellung nicht änderbar',
                settingBlockedText: 'Du hast dich mit den aktuellen Einstellungen bereits ' +
                'für Events und Wettbewerbe angemeldet und kannst diese Einstellung daher nicht ' +
                'mehr ändern. Bitte wende dich an den Support falls die Änderung unbedingt ' +
                'erforderlich ist.',
                printQR: 'QR-Code drucken',
                requestDeleteOnTermsConfirm: ' falls du nicht einverstanden bist und dein Konto löschen möchtest!',
                requestDeleteOnTermsBtn: 'Klicke hier,',
                requestDeleteText: 'Wenn du dein Konto endgültig und unwiderruflich löschen möchtest ' +
                'kannst du die Löschung hier beantragen. Du erhältst von uns eine eMail sobald die ' +
                'Löschung erfolgt ist.',
                requestDeleteTextChild: 'Wenn du diese Person (und nicht dein gesamtes Konto) ' +
                    'endgültig und unwiderruflich löschen möchtest ' +
                    'kannst du die Löschung hier beantragen. Du erhältst von uns eine eMail sobald die ' +
                    'Löschung erfolgt ist.',
                requestDeleteBtn: 'Konto-Löschung beantragen',
                requestDeleteBtnChild: 'Löschung der Person beantragen',
                requestDeleteSuccess: 'Die Konto-Löschung wurde erfolgreich beantragt!',
                childInfo: 'Bitte beachte:',
                childList1: 'Personen unter 14 Jahren müssen im Konto eines Erziehungsberechtigten registriert werden',
                childList2: 'Personen zwischen 14 und 18 Jahren können im Konto eines Erziehungsberechtigten registriert werden',
                childList3: 'Käufe und Anmeldungen können bis zum 18. Lebensjahr über das Konto eines Erziehungsberechtigten getätigt werden',
                childList4: 'ab dem 14. Lebensjahr können Personen ein eigenes Konto anlegen und Käufe dort tätigen',
                childList5: 'ab dem 18. Lebensjahr müssen Personen ein eigenes Konto anlegen (neu registrieren mit eigener E-Mail-Adresse), um Käufe zu tätigen',
                save: 'Person speichern',
                olderThan18: 'Die Person ist älter als 18 Jahre, du kannst keine Einkäufe mehr in ihrem Namen tätigen. ' +
                    'Sie muss ein eigenes Konto anlegen.',
                nextYear18: 'Die Person wird nächstes Jahr 18 Jahre alt. Bis Ende nächsten Jahres kannst du weiterhin ' +
                    'Käufe für sie tätigen.',
                thisYear18: 'Die Person wird im laufenden Jahr 18 Jahre alt. Bis Ende des Jahres kannst du weiterhin ' +
                    'Käufe für sie tätigen.',
            },
            shop: {
                expired: 'Während deines Einkaufs hat sich unser Angebot verändert. Bitte lösche folgende Positionen aus deinem Einkaufswagen:',
                empty: 'Zur Zeit gibt es keine Angebote im Shop.',
                yourShoppingCart: 'Dein Warenkorb',
                buyNow: 'jetzt zahlungspflichtig kaufen',
                totalSum: 'Total Einkauf',
                paymentSuccessful: 'Zahlung erfolgreich',
                orderCreated: 'Bitte prüfe deine Bestellung und starte den Zahlungsprozess!',
                selectEvent: 'Bitte wähle einen Bewerb',
                cancelOrder: 'Bestellung stornieren',
                alreadyBought: 'Diesen Artikel findest du bereits im Wallet!',
                gotoShoppingCart: 'Zum Warenkorb',
                gotoWallet: 'zum wallet',
                cancelPurchaseOrderHeader: 'Einkauf abbrechen',
                cancelPurchaseOrderDescription: 'Möchtest du diesen Einkauf wirklich abbrechen?',
                pendingCheckout: 'Du hast deine letzte Bestellung noch nicht bezahlt oder die ' +
                'Rückmeldung der Zahlung von Stripe an uns ist noch nicht eingetroffen. ' +
                'Bitte um paar Sekunden Geduld oder falls du noch nicht gezahlt hast, ' +
                'schließe die Zahlung bitte ab bevor du einen neuen Einkauf tätigst.',
                completePayment: 'Zahlung abschließen',
                pendingPurchase: 'Bitte schließe deinen aktuellen Einkauf ab bevor du neue Einkäufe tätigst.',
                completePurchase: 'Einkauf abschließen',
                buyFree: 'Gratis bestellen',
                buyFreeConfirmed: 'Deine Gratis-Bestellung war erfolgreich. Es kann ein paar ' +
                'Sekunden dauern bis deine Einkäufe im Wallet erscheinen. ' +
                'Du findest deine Bestellungen auch im Menü unter Punkt \'Meine Einkäufe\'',
                poError: 'Bei der Erstellung der Bestellung ist leider ein Fehler aufgetreten.',
                maxItems: 'Du hast die maximale Anzahl von Positionen im Warenkorb ({maxItems}) ' +
                'überschritten. Damit du die Bestellung abschließen kannst, musst du Positionen löschen.',
                cancellation: 'Ich habe die Rücktrittsbestimmungen des Verkäufers gelesen und akzeptiert.',
                includesVoucher: 'Du erhältst beim Kauf diesen Gutschein:',
                orderId: 'Bestellnummer',
                exitShop: 'Shop verlassen',
                insteadOf: 'statt',
                soldOut: 'ausverkauft',
                sellerText: 'verkauft durch',
                shopNow: 'jetzt kaufen',
                removeFromCart: 'aus dem Warenkorb entfernen',
                addToCart: 'in den Warenkorb legen',
                pleaseSelect: 'Bitte wähle eine Position aus der Auswahlliste links!',
                cash: 'Barkauf',
                online: 'Online',
                testMode: 'TESTMODUS - diese Organisation ist nur für dich sichtbar! ' +
                    'Die Events dieser Organisation können noch nicht gebucht werden.',
            },
            shopEvent: {
                contact: 'Kontakt',
                until: 'Buchbar bis',
                licenseMissingMessage: 'Um an diesem Event teilnehmen zu können, musst du zuerst ' +
                    'den folgenden Artikel kaufen:',
                licenseMissingMessageMultiple: 'Um an diesem Event teilnehmen zu können, musst du zuerst ' +
                    'einen der folgenden Artikel kaufen:',
                blockedOtherUser: "Du hast bereits Artikel für eine andere Person im Warenkorb. " +
                    "Bitte schließe diesen Einkauf zuerst ab oder lösche diese Artikel " +
                    "aus dem Warenkorb um Artikel für '{eventName}' kaufen zu können.",
                blockedOtherEvent: "Du hast bereits Artikel für ein anderes Event im Warenkorb. " +
                    "Bitte schließe diesen Einkauf zuerst ab oder lösche diese Artikel " +
                    "aus dem Warenkorb um Artikel für '{eventName}' kaufen zu können.",
                blockedByVoucherEvent: "'{eventName}' kann nicht gemeinsam mit '{eventNameBlocking}' " +
                    "gekauft werden. Bitte schließe zuerst diesen Einkauf ab oder leere den Warenkorb.",
                blockedByVoucherEventAlt: "Du hast bereits ein Event im Warenkorb, dass nicht " +
                    "gemeinsam mit '{eventNameBlocking}' gekauft werden kann. Bitte schließe " +
                    "zuerst diesen Einkauf ab oder leere den Warenkorb.",
                blockedOtherSeller: 'Du hast bereits Artikel eines anderen Verkäufers in deinem Warenkorb. ' +
                'Bitte schließe zuerst diesen Einkauf ab um Artikel für dieses Event kaufen zu können.',
                allPurchased: 'Du hast für dieses Event bereits alles gekauft.',
                display: 'Details trotzdem anzeigen',
                noEvents: 'Momentan kannst du von diesem Anbieter nichts kaufen.'
            },
            shopVoucher: {
                redeemVoucher: 'Gutschein einlösen',
                redeemVoucherInfo: 'Für diesen Einkauf kannst du einen der folgenden Gutscheine einlösen',
                remainderExpires: 'Rest verfällt',
                free: 'Gratis',
                discount: 'Rabatt',
                specialPrice: 'Aktionspreis',
                advantages: 'Mit dem Kauf erhältst du folgende Vorteile:',
                advantagesEvent: 'Mit deinem ersten Kauf für dieses Event erhältst du folgende Vorteile:',
                eventVoucherAbsolute: '{number} Gutscheine für Events im Gesamtwert von {currency} {value},--',
                eventVoucherPercent: '{number} Gutscheine für Events mit Rabatten bis zu {value}%',
                externalVoucher: '{number} Gutscheine mit Vorteilen unserer Kooperationspartner',
                eventVoucherAbsoluteSingle: '{number} Gutschein für ein Event im Wert von {currency} {value},--',
                eventVoucherPercentSingle: '{number} Gutschein für ein Event mit einem Rabatt von {value}%',
                externalVoucherSingle: '{number} Gutschein unserer Kooperationspartner',
                showVouchers: 'zeig mir die Gutscheine',
                hideVouchers: 'Gutscheine ausblenden',
                voucherAviso: 'Du hast zumindest einen Gutschein für dieses Event. ' +
                    'Alle Infos dazu findest du im Warenkorb, sobald du einen Artikel ausgewählt hast.',
                voucherRedeem: 'Du hast Gutscheine, bitte wähle aus welche davon du einlösen möchtest:',
                deduction: 'abzüglich Gutscheine',
                toBePaid: 'Zahlungsbetrag',
                ignore: 'Gutscheine verfügbar',
                ignoreText: 'Du hast Gutscheine für diesen Einkauf, diese aber nicht eingelöst. ' +
                    'Möchtest du diese Gutscheine noch einlösen?',
                remainderExpiresWarning: 'Für folgende Person(en) kann nicht der gesamte Gutschein eingelöst werden, ' +
                    'weil die Kaufsumme zu gering ist (der Rest des Gutscheinwerts verfällt!): ',
                remainderExpiresWarning2: 'Der Rest des Gutscheinwerts verfällt für diese Person(en)!',
                redeemedPreviously: 'Für diese Person und dieses Event wurden bereits bei einem früheren Einkauf ' +
                    'ein Gutschein eingelöst. Pro Event und Person kann nur ein Gutschein eingelöst werden!',
            },
            adminVoucher: {
                add: 'Neuen Gutschein anlegen',
                edit: 'Gutschein ändern',
                copy: 'Gutschein kopieren',
                editBtn: 'ändern',
                copyBtn: 'kopieren',
                title: 'Gutschein-Bezeichnung',
                description: 'Beschreibung',
                validUntil: 'Einlösbar bis',
                showValueLabel: 'Wie möchtest du den Wert des Gutscheins angeben?',
                showValueInPercent: 'in % des Produkts- oder Bestellwerts',
                showValueAbsolute: 'als absoluter Betrag (z.B. 10 EUR)',
                showValueNotAtAll: 'gar nicht',
                value: 'Wert',
                valuePercent: 'Wert in %',
                currency: 'Währung',
                benefitKeyLabel: 'Um welchen Gutschein-Typ handelt es sich?',
                benefitKeyDiscount: 'RABATT - auf den Bestellwert',
                benefitKeySpecialPrice: 'AKTIONSPREIS - für ein bestimmtes Produkt',
                benefitKeyFree: 'GRATIS - das Produkt ist komplett Gratis',
                benefitOther: 'Gib deinen Text ein...',
                isThirdPartyLabel: 'Wo soll der Gutschein eingelöst werden können?',
                isThirdPartyFalse: 'nur im Shop von On Your Marks',
                isThirdPartyTrue: 'nur im Shop eines Drittanbieters',
                issueWithLicenseId: 'Der Gutschein soll ausgegeben werden mit:',
                issueFrom: 'Ausgabe von',
                issueUntil: 'Ausgabe bis',
                issuerCode: 'Gutschein Code in deinem System',
                externalDescriptionLink: 'Link, der zu weiteren Infos führt',
                redeemInformation: 'Infos zur Verwendung des Gutscheins (Freitext)',
                permittedOrganisationIds: 'Organisationen, die diesen Gutschein verwenden dürfen?',
                saveError: 'Beim Speichern ist ein Fehler aufgetreten!',
                data: 'Daten',
                redeem: 'Einlösen',
                stats: 'Analyse',
                copyOf: 'KOPIE VON',
                clickForTerms: 'Es gelten besondere Bedingungen des Auftraggebers oder der Auftraggeberin für diesen Gutschein. ' +
                    'Klicke auf den Gutschein um diese Bedingungen anzuzeigen.',
                activeVouchers: 'Aktive Gutscheine',
                inactiveVouchers: 'Abgelaufene Gutscheine',
                onlyOnline: 'Gutschein soll nur bei Online-Zahlungen einlösbar sein',
                /*requestUserDataForRedeem: 'ich möchte die Gültigkeit eines Kundengutscheins ' +
                    'über die On Your Marks-App prüfen können und eine ' +
                    'Auswertung der eingelösten Gutscheine erhalten' +
                    ' (dazu müssen Kundendaten abgefragt werden und ' +
                    'der Gutschein wird nicht ausgegeben, wenn der Kunden nicht zustimmt)',*/
            },
            voucherDetails: {
                issuedBy: 'Dieser Gutschein wird ausgegeben von',
                issuePeriod: 'Ausgabezeitraum',
                issueOrg: 'Zur Ausgabe berechtigt',
                description: 'Informationen zum Gutschein',
                redeemInfo: 'Informationen zum Einlösen',
                onlineOnly: 'Dieser Gutschein ist nur Online bei einem Kauf in der On Your Marks App gültig und einlösbar!',
                /*requestUserDataForRedeem: 'Der Aussteller des Gutscheins darf nur (!) beim Einlösen des Gutscheins ' +
                    'zur Überprüfung der Gültigkeit über On Your Marks auf deinen Vornamen, Nachnamen und deine eMail-Adresse zugreifen!',*/
                thirdPartyCode: 'Gutschein-Code des Anbieters:',
                web: 'Weitere Informationen',
                //webDetails: 'Weitere Informationen zum Gutschein und den geltenden Bedingungen sind hier zu finden',
                footnote: '* Für diesen Gutschein gelten die hier beschriebenen Bedingungen des Auftraggebers oder der Auftraggeberin!',
                redeemInfoAviso: 'Informationen, wie du diesen Gutschein einlösen kannst, findest du nach dem Kauf in deinem Wallet!',
            },
            wallet: {
                empty: 'Dein Wallet ist leer. Bitte gehe in den Shop um es ' +
                'mit Pässen oder Tickets für Events zu füllen. Falls du soeben etwas gekauft hast, ' +
                'kann es ein paar Sekunden dauern bist du es hier siehst.',
                emptyArchive: 'Du hast noch keine archivierten Pässe, Tickets oder Gutscheine.',
                creatingDocuments: 'Bitte um etwas Geduld, dein Spielerpass und deine ' +
                'Gutscheine werden angelegt!',
                yourData: 'Deine Daten',
                organisationData: 'Daten des Ausstellers',
                statutes: 'Statuten',
                statutes1: 'Ich habe die ',
                statutes2: ' meines ERU Mitgliedslandes (Österreich) gelesen und stimme ihnen zu.',
                statutesMissing: 'Du hast noch nicht der letzten Version der Statuten zugestimmt!',
                privacy: 'Datenschutzbestimmungen',
                privacyMissing: 'Du hast noch nicht der letzten Version der Datenschutzbestimmungen zugestimmt!',
                covid: 'Covid-19-Regeln',
                covidMissing: 'Du hast noch nicht der letzten Version der Covid-19-Regeln zugestimmt!',
                termsOfUse: 'Nutzungsbedingungen',
                termsOfUseMissing: 'Du hast noch nicht der letzten Version der Nutzungsbedingungen zugestimmt!',
                rulesOfCompetition: 'Wettspielordnung',
                rulesOfCompetitionMissing: 'Du hast noch nicht der letzten Version der Wettspielordnung zugestimmt!',
                yourEvents: 'Deine Bewerbe und Einkäufe',
                redeemed: 'Eingelöste',
                redeemedBy: 'Eingelöst bei',
                redeemedAt: 'Eingelöst am',
                redeemedWith: 'Eingelöst mit Bestellung',
                gotoPo: 'Bestellung ansehen',
                gotoArchive: 'Archiv anzeigen',
                returnFromArchive: 'zurück ins Wallet',
                archive: 'Archiv',
            },
            myPurchases: {
                menuItem: 'Meine Einkäufe',
                noPurchases: 'Du hast bist jetzt noch nichts auf On Your Marks eingekauft!',
                filteredBy: 'Gefiltert nach Bestellnummer:',
            },
            fir2022: {
                selectEventHeader: 'Wähle deinen Status',
                selectHotel: 'Wähle deine Unterkunft',
                selectEventDescription: 'Bitte wähle zumindest einen Bewerb',
                selectExtras: 'Wähle deine Extras',
                selectHotelType: 'Bitte wähle deinen Unterkunftstyp:',
                termsAndInformation: 'Bedingungen und Informationen',
                privateRoom1: 'private Unterkunft',
                privateRoom2: ' - berechtigt zum Rabatt auf bestimmte Bewerbe bei Nachweis',
                officialRoom1: 'offizielles Turnier Hotel',
                officialRoom2: ' - berechtigt zum Rabatt auf bestimmte Bewerbe',
                otherRoom1: 'anderes Hotel',
                otherRoom2: ' - kein Rabatt, volle Nenngebühr für alle Bewerbe',
                selectRoomType: 'Bitte wähle dein Hotel und deinen Zimmertyp. Für Mehrbettzimmer kannst du ein Bett ' +
                'buchen und den Namen der anderen Person(en) nennen ' +
                '(aber diese Personen müssen ebenso ein Bett über On Your Marks buchen)',
                selectNightDescription: 'Bitte wähle die gewünschten Nächte. Um den Rabatt auf die ' +
                'Nenngebühr zu bekommen, musst du das Hotel gleichzeitig mit dem Bewerb kaufen ' +
                'und zumindest die Nacht zwischen den Bewerbstagen wählen.',
                otherPerson: 'andere Person(en)',
                infoPriceLow: 'gültig nur bis {date} wenn gleichzeitig ein Hotel gebucht wird oder wenn ' +
                'in einer privaten Unterkunft genächtigt wird und der Turnierleitung eine schriftliche ' +
                'Bestätigung des privaten Unterkunftgebers oder ein Nachweis des Wohnsitzes vorgelegt wird',
                infoPriceHigh: 'gültig bis {date} wenn kein Hotel gebucht wird',
                infoPriceCancellation: 'Keine Rückerstattung für Event- und Hotelbuchung',
                infoPriceFirstTimers: "*** für die Buchung des 'First Timers' - Bewerbs " +
                "gilt immer ein Sonderpreis von €25,-- (nur als Einzelbewerb)",
                namePartner: 'bitte nenne deinen Partner/deine Partnerin (optional)',
                ageAlert: 'Du darfst aufgrund deines Alters nur als Begleitperson an diesem Bewerb teilnehmen!',
                sellerText: 'Verkauft von',
                singleRoom: 'Einzel',
                doubleRoom: 'Doppel',
                tripleRoom: 'Dreibett',
                multiUse: '3-4 Nutzung',
                singleUse: 'Einzel-Nutzung',
                doubleUse: 'Doppel-Nutzung',
                bunkBeds: 'Nur Mehrbettzimmer mit Stockbetten',
                selectExtra: 'bitte wähle dein {extra}',
                free: 'Gratis',
                dinner: 'Players Dinner',
                tshirt: 'Offizielles WM T-Shirt',
                insurance: 'Rücktritts-Versicherung (Covid, Verletzung, Krankheit)',
                insuranceDescription: '',
                insuranceDescription1: 'WM 2022 Rücktritts-Versicherung für nur 15€',
                insuranceDescription2: 'volle Rückerstattung bei Nichtteilnahme aus den unten genannten Gründen',
                insuranceDescription3: 'Storno bis einen Tag vor Turnierbeginn möglich',
                insuranceDescription4: 'Mit schriftlicher Bestätigung akzeptierte Gründe: Covid19-Infektion, ' +
                'Covid19-Quarantäne, Covid19-Reisebeschränkungen, Verletzung, Krankheit und ähnliches',
                insuranceDescription5: 'Aufgrund der Covid19-Krise und der vielen Unsicherheiten bis zum ' +
                'Beginn der Racketlon World Championships 2022 haben wir ein spezielles Angebot für ' +
                'alle TeilnehmerInnen der WM 2022. Zum ersten Mal in der Racketlon-Geschichte haben ' +
                'alle SpielerInnen die Möglichkeit, sich gegen unvorsehbare Vorkommnisse zu versichern: ' +
                'Um nur 15 € kann bei Nichtteilnahme wegen Covid, Verletzung oder Krankheit auch ' +
                'kurzfristig bis nur einen Tag vor Turnierbeginn storniert werden. Dies soll den ' +
                'SpielerInnen ein Höchstmaß an Sicherheit geben, kein Geld aus Gründen höherer Gewalt ' +
                'zu verlieren. Die Versicherung ist an eine Person und eine Veranstaltung für alle ' +
                'Bewerbe dieser Veranstaltung gebunden. (Veranstaltung 1: Junioren & Senioren, Wien | ' +
                'Veranstaltung 2: Amateure & Elite, Graz).',
                insuranceDescription6: 'Rückerstattungen werden spätestens im September überwiesen.',
                license: 'FIR Lizenz',
                licenseDescription: 'Infotext to be defined!',
                checkout: 'Einkauf abschließen',
                othersQuestion: 'Möchtest du noch für jemanden anderen einkaufen?',
                doublesPartnerInfo: 'Die Angabe des Doppelpartners/der Doppelpartnerin auf On Your ' +
                'Marks dient nur zur Information und ist nicht bindend. Bitte geben Sie den ' +
                'Doppelpartner/die Doppelpartnerin auf tournamentsoftware an: ',
                doublesPartnerLink: 'Tournament Software in einem anderen Fenster öffnen',
                extrasInfo1: '* gratis für Athleten und Athletinnen bei Anmeldung zu mindestem einem Bewerb',
                extrasInfo2: '** ohne Anmeldung zu einem Bewerb',
                firMembershipHeader: 'Hast du eine FIR Lizenz?',
                firBuyLicense: 'FIR Lizenz kaufen',
                firMembershipIntro: "Für alle Bewerbe ausgenommen 'First Timers' gilt:",
                /*firMembership: 'Bitte beachte, dass für die Teilnahme an dieser Veranstaltung ' +
                'eine FIR-Lizenz erforderlich ist. Die Lizenz für ein Turnier kostet 15€, ' +
                'die Jahres-Lizenz kostet 40€ und erlaubt die Teilnahme an einer unbegrenzten Anzahl von ' +
                'Turnieren in dieser Saison. Die Lizenz kann hier erworben werden:',*/
                firMembership: 'Bitte beachte, dass für die Teilnahme an dieser Veranstaltung eine ' +
                    'FIR-Lizenz erforderlich ist. Die FIR-Jahres-Lizenz ermöglicht die Teilnahme an einer ' +
                    'unbegrenzten Anzahl von Turnieren in dieser Saison. Jahres-Lizenz um 40€ gleich hier kaufen:',
                firMembershipNote: 'Hinweis: Andere FIR-Lizenzen sind nicht auf On Your Marks verfügbar.',
                hotelPriceCondition: 'Alle Preise pro Person und Nacht inklusive Steuern und ' +
                'Frühstücksbuffet (Normalpreis 19€ pro Person) und Shuttle-Service bei Anreise vom ' +
                'und zum Flughafen/Bahnhof sowie zur Sportstätte. Keine Rückerstattung für Hotelbuchungen.',
                companionHeader: 'Ich buche als Begleitperson (Familienmitglied, ' +
                'Trainer*in, Physiotherapeut*in) für folgenden Sportler*in (maximal eine Begleitperson pro ' +
                'Sportler*in):',
                companionDeselectEvent: 'Zur Eingabe alle Bewerbe löschen',
                companionLabel: 'Bitte Athlet*innen-Name eingeben',
                userTypeTitle: 'Ich buche als:',
                userTypeAthlete: 'AthletIn',
                userTypeCompanion: 'Begleitperson',
                rules: 'Um an den Weltmeisterschaften teilnehmen zu können, musst du nachfolgende Punkte bestätigen:',
                ended: 'Die Anmeldefrist für die Weltmeisterschaft ist leider abgelaufen. Für Fragen wende dich bitte an den Veranstalter.',
                coronaCheck: 'ich habe das Covid19-Präventionskonzept gelesen und bestätige die Kenntnisnahme ' +
                'und Einhaltung der Regeln',
                coronaConfirmed: 'Covid19-Präventionskonzept gelesen, verstanden und bestätigt',
                privacyCheck: 'ich habe die Datenschutzbestimmungen gelesen und bin damit einverstanden',
                privacyConfirmed: 'Datenschutzbestimmungen gelesen, verstanden und bestätigt',
                rulesOfCompetitionCheck: 'ich habe die Wettspielordnung gelesen und bestätige die Kenntnisnahme ' +
                'und Einhaltung der Regeln',
                rulesOfCompetitionConfirmed: 'Wettspielordnung gelesen, verstanden und bestätigt',
                infoLink: 'Details zur Verwendung von On Your Marks...',
            },
            rfa: {
                voucherPass: '1 Bewerb bei RFA-Turnier',
            },
            consents: {
                missingHeader: 'Für diesen Einkauf musst du folgende Bedingungen des Verkäufers akzeptieren und bestätigen:',
                walletHeaderPass: 'Zum Aktivieren musst du noch folgende Bedingungen des Verkäufers akzeptieren und bestätigen:',
                walletHeaderEvent: 'Zur Teilname musst du noch folgende Bedingungen des Verkäufers akzeptieren und bestätigen:',
                generic1: 'Ich habe die ',
                generic2: ' gelesen und stimme ihnen zu!',
                accept: 'Bitte hier klicken um mehr zu erfahren und zuzustimmen.',
                menuItem: 'Meine Zustimmungen',
                inactive: 'INAKTIV',
                activate: 'zum Aktivieren klicken',
                empty: 'Du hast noch keinen Dokumenten oder Bedingungen unserer Auftraggeber:innen zugestimmt.',
            },
            requiredInformation: {
                missingHeader: 'Bitte fülle noch die folgenden Informationen aus (du kannst diese Informationen dann ' +
                    'in deinem Profil einsehen und ändern):',
                linkLabel: 'Mehr Infos findest du hier...',
                information: 'Informationen',
                accepted: 'Bestätigt durch {org} am {date}',
                rejected: 'Abgelehnt durch {org} am {date} - bitte ändern!',
                validUntil: 'Gültig bis {date} (gesetzt durch {org})',
                blockedMsg: 'Diese Information kann nicht geändert werden, weil sie bereits vom Adminstrator bestätigt wurde ' +
                    'und für diese Information Änderungen nach der Bestätigung nicht erlaubt sind!',
            },
            stripe: {
                success1: 'Vielen Dank, deine Zahlung war erfolgreich! ',
                success2: 'Bitte um etwas Geduld, es kann noch ein paar Sekunden dauern, ' +
                    'bis das Personal an der Kasse die Bestätigung erhält.',
                failure: 'Bei deiner Zahlung ist leider ein Fehler aufgetreten. Bitte wende dich an das Personal an der ' +
                    'Kassa um einen neuen Zahlungsversuch auszulösen.',
            },
            sports: {
                my: 'Meine Sportarten',
                nothingSelected: 'Bitte wähle mindestens eine Option aus!',
                changeSucess: 'Deine Änderungen wurden gespeichert!',
                changeError: 'Es ist ein Fehler aufgetreten, deine Änderungen wurden nicht gespeichert!',
                all: 'alle Sportarten auswählen',
                none: 'keine Personalisierung',
                infoText: 'Bitte wähle deine Sportarten. Deine Auswahl dient dazu, dir Angebote ' +
                    'aus diesen Sportarten zu zeigen.',
                btnOthers: 'Andere Angebote anzeigen',
                btnOthersHide: 'nicht mehr anzeigen',
                others: 'Andere Angebote',
            },
            admin: {
                infoText: 'Hast du schon ein Konto und willst On Your Marks für deinen Verein, Verband ' +
                            'oder dein Unternehmen nutzen? Gleich gratis testen!',
                btn: 'zum Admin Bereich',
            }
        }
    }
})