<template>
    <div :style="circleStyle">
        <v-avatar :color="configEl.color" :size="sizeAdjusted" class="ma-0">
            <v-icon v-if="!imageUrl" large
                    :style="{zoom: configEl.size/44}">
                mdi-account
            </v-icon>
            <img v-else :src="imageUrl">
        </v-avatar>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';

    export default {
        name: "profile-avatar",
        props: {
            user: {default: null},
            storageSourcePath: {default: ''},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.images.profileAvatar',
        // config
            configDefault: {
                size: 48,
                color: '#f2f2f2',
                iconLarge: true,
                border: false,
                borderColor: '',
            }
        }),
        computed: {
            sizeAdjusted(){
                return this.configEl.border ? this.configEl.size - 8 : this.configEl.size;
            },
            imageUrl() {
                return this.user.image.urlMin ?
                    this.storageSourcePath +
                    this.user.uid + 'Min.jpeg?' +
                    this.user.image.urlMin : ''
            },
            circleStyle(){
                const size = parseInt(this.configEl.size);
                return this.configEl.border ? {
                    border: 'solid 2px',
                    borderColor: this.configEl.borderColor,
                    borderRadius: '50%',
                    width: size + 'px',
                    height: size + 'px',
                    padding: '2px',
                } : '';
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        }
    }
</script>

<style scoped>
</style>