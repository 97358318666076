
export class ConfigDefaultStripe {
    constructor() {
        // Default config in the module
        /* this.componentName not usable on this level!!!
           this.locales not usable on this level!!! */
        this.content = {
            stripe: { // = root !!!
                css: 'body-2 grey lighten-4 px-5',
                stripeCheckout: {
                    show: true,
                    disabled: false,
                    btn: {
                        default: {
                            css: 'my-3',
                            i18n: 'checkout.create'
                        }
                    },
                    inProgressMessage: {
                        default: {
                            i18n: 'checkout.inProgress',
                        }
                    },
                    creationFailedMessage: {
                        default: {
                            i18n: 'checkout.creationFailed',
                        }
                    },
                    paymentCompletedMessage: {
                        default: {
                            i18n: 'checkout.paymentCompleted',
                        }
                    },
                    processingPayment: {
                        default: {
                            i18n: 'checkout.processingPayment',
                        }
                    },
                    cancelPaymentDialog: {
                        i18n: 'checkout.cancelPayment',
                    }
                },
                stripeAccountRequest: {
                    show: true,
                    disabled: false,
                    btn: {
                        default: {
                            css: 'my-3',
                            i18n: 'accountRequest.create'
                        }
                    },
                    btnRedirect: {
                        default: {
                            css: 'my-3',
                            i18n: 'accountRequest.reopen'
                        }
                    },
                    btnStripe: {
                        default: {
                            css: 'my-3',
                            i18n: 'accountRequest.openStripe'
                        }
                    },
                    confirmDialogConfig: {
                        confirm: {
                            title: {
                                i18n: 'accountRequest.confirmHeader'
                            }
                        },
                        question: {
                            i18n: 'accountRequest.confirmQuestion'
                        }
                    },
                    infoPending: {
                        i18n: 'accountRequest.infoPending'
                    },
                    infoPendingRefresh: {
                        i18n: 'accountRequest.infoPendingRefresh'
                    },
                    infoRedirected: {
                        i18n: 'accountRequest.infoRedirected'
                    },
                    infoRedirectedRefresh: {
                        i18n: 'accountRequest.infoRedirectedRefresh'
                    },
                    infoRejected: {
                        i18n: 'accountRequest.infoRejected'
                    },
                    infoIncomplete: {
                        i18n: 'accountRequest.infoIncomplete'
                    },
                    infoActive: {
                        i18n: 'accountRequest.infoActive'
                    },
                },
                stripeAccountDelete: {
                    show: true,
                    disabled: false,
                    btn: {
                        default: {
                            css: 'mt-10',
                            i18n: 'accountRequest.delete',
                            color: 'error',
                        }
                    }
                }
            },
        };
        this.utilities = {
            components: {
                dialogs: {
                    confirmDialog: {
                        default: {
                        },
                        delete: {
                        },
                        confirm: {
                        },
                        save: {
                        },
                    }
                }
            }
        };
    }
}
