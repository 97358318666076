import ConditionCheckService from "@/modules/_services/conditionCheckService";

export default class LicensePriceService {

    static getCurrentPrice(user, userLicenses, license, returnCurrency){
        const currentBasePrice = LicensePriceService.getCurrentBasePrice(
            user, userLicenses, license, returnCurrency
        );
        const basePrice = returnCurrency ? currentBasePrice.price : currentBasePrice;
        const price = basePrice - LicensePriceService
                                  .getCurrentPriceDiscount(user, userLicenses, license);
        return returnCurrency ? {price: price, currency: currentBasePrice.currency} : price;
    }

    static getCurrentBasePrice(user, userLicenses, license, returnCurrency){
        const basePriceList = this.getCurrentBasePriceList(user, userLicenses, license);
        let price = 0;
        let currency = '';
        basePriceList.forEach( (priceItem) => {
            price += priceItem.amount;
            currency = priceItem.currency;
        });
        return returnCurrency ? {price: price, currency: currency} : price;
    }

    static getCurrentBasePriceList(user, userLicenses, license){
        const conditionCheck = new ConditionCheckService();
        let basePriceList = [];
        Object.keys(license.prices).forEach( (price) => {
            const priceItem = license.prices[price];
            if ( conditionCheck.checkConditionUser(
                priceItem.conditions, user, userLicenses).result
            ) {
                basePriceList.push(priceItem);
            }
        });
        return basePriceList;
    }

    static getCurrentPriceDiscount(user, userLicenses, license){
        const discountList = this.getCurrentPriceDiscountList(user, userLicenses, license);
        let discount = 0;
        discountList.forEach( (discountItem) => {
            discount += discountItem.amount
        });
        return discount;
    }

    static getCurrentPriceDiscountList(user, userLicenses, license){
        const conditionCheck = new ConditionCheckService();
        let discountList = [];
        Object.keys(license.prices).forEach( (price) => {
            const priceItem = license.prices[price];
            if ( conditionCheck.checkConditionUser(
                    priceItem.conditions, user, userLicenses).result
            ) {
                Object.keys(priceItem.discounts).forEach( (discount) => {
                    const discountItem = priceItem.discounts[discount];
                    if ( conditionCheck.checkConditionUser(
                            discountItem.conditions, user, userLicenses).result
                    ) {
                        discountList.push(discountItem);
                    }
                });
            }
        });
        return discountList;
    }

}