// Version 1.2 2022-01-18
export let DYNAMIC_FORM_CONFIG = {
    basic: {
        outlined: true,
        dense: true,
    },
    row: {
        css: 'mt-4 mb-1'
    },
    col: {
        css: 'py-0'
    },
    textField: {
        clearable: true,
    },
    date: {
        outlined: true,
        dense: true,
    },
}