var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{'cardContentDyn': _vm.$vuetify.breakpoint.xsOnly},
              {'cardContentFix': _vm.$vuetify.breakpoint.smAndUp}]},[(_vm.organisation && _vm.organisation.short)?_c('img',{staticClass:"cardLogo",attrs:{"height":"26%","alt":_vm.organisation.short,"src":_vm.organisation.logo}}):_vm._e(),_c('div',{class:[{'title100 fontVoucherText': _vm.$vuetify.breakpoint.xsOnly && _vm.userVoucher.showValue==='notAtAll'},
                  {'title100 font18': _vm.$vuetify.breakpoint.smAndUp && _vm.userVoucher.showValue==='notAtAll'},
                  {'titleDyn fontVoucherText': _vm.$vuetify.breakpoint.xsOnly && _vm.userVoucher.showValue!=='notAtAll'},
                  {'titleFix font18': _vm.$vuetify.breakpoint.smAndUp &&_vm.userVoucher.showValue!=='notAtAll'},
                  'lineHeight-1-0 mt-1 text-left pl-3',
                  _vm.textColor],style:(_vm.userVoucher.getDynStyleTitle())},[_c('div',{class:[{'fontVoucherTextSmall': _vm.$vuetify.breakpoint.xsOnly},
                      {'font16': _vm.$vuetify.breakpoint.smAndUp},
                       'space03']},[_vm._v(" "+_vm._s(_vm.userVoucher.title)+" ")]),_c('div',{staticClass:"mt-1 font-weight-black space05 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t(_vm.userVoucher.getBenefitText()))+"*")])]),(_vm.userVoucher.showValue!=='notAtAll')?_c('div',{class:[{'priceTagRoundDyn': _vm.$vuetify.breakpoint.xsOnly},
                  {'priceTagRoundFix': _vm.$vuetify.breakpoint.smAndUp},
                  {'white black--text': !this.organisation.styles.darkCircle},
                  {'black white--text': this.organisation.styles.darkCircle},
                  'elevation-10 priceTagRound lineHeight-1-0 text-center']},[_c('div',{class:[{'priceValueDyn': _vm.$vuetify.breakpoint.xsOnly},
                      {'font32 mt-1 pt-5': _vm.$vuetify.breakpoint.smAndUp},
                      'font-weight-black']},[(_vm.userVoucher.showValue!=='percent')?_c('span',[_vm._v(" €")]):_vm._e(),_vm._v(_vm._s(_vm.userVoucher.value)),(_vm.userVoucher.showValue==='percent')?_c('span',[_vm._v("%")]):_vm._e()])]):_vm._e(),_c('div',{class:[{'fontVoucherDate': _vm.$vuetify.breakpoint.xsOnly},
                  {'font12': _vm.$vuetify.breakpoint.smAndUp},
                  'dateFooter font10 lineHeight-1-2',
                  _vm.textColor]},[_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t('common.validUntil'))+" "+_vm._s(_vm.dateString)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }