<template>
    <div>
        <toolbar :title="$t('utilities.components.common.privacy')"
                 :show-save="false">
        </toolbar>
        <div class="px-5 termsContainer">
            <img src="@/assets/oym_logo-typo-thin_final.svg"
                 class="mt-6 mb-0" height="34"
                 alt="On your marks">
            <v-divider class="mt-3"></v-divider>
            <p class="text-h6 pt-3 mb-0">{{$t('utilities.components.common.privacy')}}</p>
            <p class="caption">Version 1, 1.1.2022</p>
            <div class="body-2 pb-15 mb-5">
                <p class="text-subtitle-1">KUNDENDATEN</p>
                <p>Für die Abwicklung unserer Dienstleistung (Vermittlung von Pässen und Tickets zur Akkreditierung) über unsere Website onyourmarks.cc ist es nötig, Daten unserer Auftraggeber_innen und Endkund_innen – hier als User bezeichnet – zu erfassen. Zum Zwecke der Registrierung auf onyourmarks.cc erhebt Number One Solutions die Namen, Geburtsdaten und E-Mail-Adressen der User sowie die vom jeweiligen Auftraggeber definierten Informationen und Dokumente. Diese Daten werden über folgende Auftragsverarbeiter verarbeitet und gespeichert: Google Firebase, Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland sowie WORLD4YOU Internet Services GmbH, Hafenstrasse 35, 4020 Linz.</p>
                <p>Kinder und Jugendliche unter 14 Jahren dürfen die Dienstleistungen auf onyourmarks.cc nur nutzen, wenn einer ihrer Erziehungsberechtigten unter seinem Namen einen eigenen Account anlegt und die Kinder/Jugendlichen in seinem/ihren Account im Profil hinzugefügt. Der/Die Erziehungsberechtigte muss beim Hinzufügen eines Kindes/Jugendlichen im passwortgeschützten Bereich von onyourmarks.cc zustimmen, dass die Nutzung der Dienstleistung mit seiner/ihrer Zustimmung erfolgt. Mit dem Widerrufen der Zustimmung kann die Autorisierung durch den/die Erziehungsberechtigte/n bis zur Erreichung des 14. Lebensjahres des Users widerrufen werden.</p>
                <p>Jugendliche zwischen 14 und 18 Jahren können die Dienstleistungen auf onyourmarks.cc durch ihre Erziehungsberechtigten in Anspruch nehmen. Eine Autorisierung des Accounts durch die Erziehungsberechtigten erfolgt jedoch nicht. Mit dem Erreichen des 18. Lebensjahrs ist eine Inanspruchnahme der Dienstleistungen von onyourmarks.cc durch Dritte nicht gestattet.</p>
                <p>Zur reibungslosen Abwicklung der Dienstleistung werden der/dem Veranstalter_in die von unseren Auftraggeber_innen geforderten Daten zugänglich gemacht. In der Regel sind das Name und Foto sowie mittels QR-Code weitere von den Auftraggeber_innen definierte Informationen, die im Detail in dessen/deren individuellen Nutzungsbedingungen und Datenschutzbestimmungen angeführt sind.</p>
                <p class="text-subtitle-1">ZAHLUNGSABWICKLUNG</p>
                <p>Daten zu Zahlungen (wie Kreditkartendaten) werden nicht von Number One Solutions und ihren Auftraggeber_innen gespeichert. Mehr dazu unter Zahlungsabwicklung.</p>
                <p class="mb-0">Number One Solutions fungiert nicht als Zahlungsanbieter sondern nutzt Drittanbieter zur Zahlungsabwicklung. Zahlungen werden im Auftrag unserer Auftraggeber_innen über Stripe Inc., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Ireland getätigt. Beim Anbieten von Zahlungen über Verkaufs- und Zahlungsplattformen ist der Händler zu keinem Zeitpunkt Auftragsverarbeiter einer Handelsplattform, noch ist diese jemals Auftragsverarbeiter des Händlers. Welche Daten von Stripe zur Zahlungsabwicklung erfasst werden, sind im Detail deren Website zu entnehmen:</p>
                <p>https://stripe.com/de/privacy#welche-personenbezogenen-daten-wir-erfassen</p>
                <p>Unsere Auftraggeber_innen verfügen über eigene Konten bei Stripe. Number One Solutions hat grundsätzlich keine Einsicht auf die Konten ihrer Auftraggeber_innen, außer dies ist von der jeweiligen Auftraggeber_in ausdrücklich gewünscht. In diesem Fall wird der/die Auftraggeber_in die User über seine/ihre individuellen Datenschutzbestimmungen darüber in Kenntnis setzen.</p>
                <p>Für Fehler bei der Zahlungsabwicklung kann Number One Solutions nicht zur Verantwortung gezogen werden. Anfragen zu Zahlungen sind direkt an den/die Auftraggeber_in bzw. den Händler zu richten.</p>
                <p class="text-subtitle-1">COOKIES</p>
                <p>In den passwortgeschützten Bereichen von onyourmarks.cc werden ausschließlich Cookies eingesetzt, welche die Funktionalität der Website gewährleisten. Es werden keine persönlichen Daten mit diesen Cookies erfasst. Bei Cookies handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf deinem Endgerät abgelegt werden. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf deinem Endgerät gespeichert, bis du diese löschst. Cookies kommen zur Anwendung, um Inhalte und Anzeigen zu personalisieren.</p>
                <p class="text-subtitle-1">AUSKUNFT/SUPPORT</p>
                <p>Alle von uns gespeicherten Daten können im passwortgeschützten Bereich von onyourmarks.cc über den User-Bereich eingesehen und gelöscht werden. Unser Support-Team ist unter support@onyourmarks.cc erreichbar und erteilt auf Anfrage Auskunft über gespeicherte Daten und nimmt Löschanträge entgegen. Zur reibungslosen Abwicklung und Gewährleistung der Funktionalität von onyourmarks.cc können Löschvorgänge erst nach Ablauf der Gültigkeit von gekauften Tickets und Pässen sowie nach Abrechnung der betreffenden Veranstaltung mit dem jeweiligen Veranstalter oder Veranstalterin vorgenommen werden.</p>
                <p>Weitere Supportanfragen können ebenso per E-Mail an support@onyourmarks.cc gerichtet werden.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Toolbar from "@/utilities/components/controls/toolbar";
    export default {
        components: {Toolbar},
        name: "privacy",
        created(){
            setTimeout(()=>{
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behaviour: 'smooth',
                });
            },50)
        },
    }
</script>

<style scoped>
    .termsContainer{max-width: 800px; margin: auto}
</style>