import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home'
import StripePaymentReply from '@/components/stripePaymentReply/stripePaymentReply'
import ProfileEdit from '@/components/profile/profileEdit'
import Imprint from '@/views/terms/imprint'
import Privacy from '@/views/terms/privacy'
import TermsOfUse from '@/views/terms/termsOfUse'
import ProfileMultiUserAdd from '@/components/profile/profileMultiUserAdd'
import ShoppingCart from '@/components/shop/shopCart'
import MyPurchases from '@/components/home/myPurchases/myPurchases'
import ProfilePrint from '@/components/profile/profilePrint'
import UserConsents from '@/components/userConsents/userConsents'
import MySports from '@/components/sports/mySports'
import Messages from '@/components/messages/messages'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/stripePaymentReply',
        name: 'StripePaymentReply',
        component: StripePaymentReply,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfileEdit,
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
    },
    {
        path: '/termsOfUse',
        name: 'TermsOfUse',
        component: TermsOfUse,
    },
    {
        path: '/addUser',
        name: 'AddUser',
        component: ProfileMultiUserAdd,
    },
    {
        path: '/shoppingCart',
        name: 'ShoppingCart',
        component: ShoppingCart,
    },
    {
        path: '/myPurchases',
        name: 'MyPurchases',
        component: MyPurchases,
    },
    {
        path: '/myConsents',
        name: 'UserConsents',
        component: UserConsents,
    },
    {
        path: '/mySports',
        name: 'MySports',
        component: MySports,
    },
    {
        path: '/myMessages',
        name: 'MyMessages',
        component: Messages,
    },
    {
        path: '/profilePrint',
        name: 'ProfilePrint',
        component: ProfilePrint,
    },
    
]

const router = new VueRouter({
    routes,
})

export default router
