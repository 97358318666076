<template>
    <div>
    <!-- date -->
        <div :class="[{'fontPassDate': $vuetify.breakpoint.xsOnly},
                      {'font18': $vuetify.breakpoint.smAndUp},
                      {'font-weight-bold': userPass.style.textDate.isBold},
                      'cardDateTop', textColor]">
            {{userPass.style.textDate.value}}
        </div>
    <!-- logo -->
        <img class="cardLogo" height="24%" alt="Logo" :src="logo">
    <!-- text box -->
        <div :class="[{'titleDyn fontPassText': $vuetify.breakpoint.xsOnly},
                      {'titleFix font20': $vuetify.breakpoint.smAndUp},
                      'lineHeight-1-0 mt-1 text-left pl-2', textColor]">
            <div :class="[{'fontPassTextCust': $vuetify.breakpoint.xsOnly},
                          {'font18 textWithFix': $vuetify.breakpoint.smAndUp},
                           'space05 text-uppercase text-no-wrap']" >
                <div :class="[{'my-6': !userPass.style.text2.value},
                          {'my-4': !userPass.style.text3.value},
                          {'my-2': !userPass.style.text4.value}]"/>
                <div v-if="userPass.style.text1.value" :class="[{'font-weight-black space1': userPass.style.text1.isBold}, 'text-truncate']">
                    {{ userPass.style.text1.value }}
                </div>
                <div v-if="userPass.style.text2.value" :class="[{'font-weight-black space1': userPass.style.text2.isBold}, 'text-truncate']" style="padding-top: 2px">
                    {{ userPass.style.text2.value }}
                </div>
                <div v-if="userPass.style.text3.value" :class="[{'font-weight-black space1': userPass.style.text3.isBold}, 'text-truncate']" style="padding-top: 2px">
                    {{ userPass.style.text3.value }}
                </div>
                <div v-if="userPass.style.text4.value" :class="[{'font-weight-black space1': userPass.style.text4.isBold}, 'text-truncate']" style="padding-top: 2px">
                    {{ userPass.style.text4.value }}
                </div>
            </div>
        </div>
    <!-- name footer -->
        <div :class="[{'fontPassUserName': $vuetify.breakpoint.xsOnly},
                      {'font12': $vuetify.breakpoint.smAndUp},
                      'nameFooter font10 lineHeight-1-2 text-uppercase text-truncate',
                      textColor]">
            <span class="ml-2">
                {{user.firstName}} {{user.lastName}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "walletPassesContentCustomized",
    props: ['user', 'userPass', 'organisation'],
    computed: {
        isDark(){
            return this.userPass.style.styles.color ? this.userPass.style.styles.dark : this.organisation.styles.dark
        },
        textColor(){
            return this.isDark ? 'lightGrey--text' : 'primary--text';
        },
        logo(){
            return this.userPass.style.logo ? this.userPass.style.logo : this.organisation.logo
        }
    },
}
</script>

<style scoped>
    .titleDyn {position: absolute; width: 100%; top: 36%}
    .titleFix {position: absolute; width: 100%; top: 36%}
    .textWithFix { width: 176px }
    .nameFooter {
        position: absolute; bottom: 5%; left: 0; width: 98%;
    }
</style>