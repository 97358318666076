import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import {i18n} from './plugins/i18n';
import ImageUploader from 'vue-image-upload-resize';
import { Cropper } from 'vue-advanced-cropper';
import './utilities/services/filter/arrays'
import './utilities/services/filter/dates'
import './utilities/services/filter/money'

Vue.config.productionTip = false;

Vue.use(ImageUploader);
Vue.use(Cropper);


new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
