<template>
    <v-dialog v-model="waitingForReply" width="300" persistent>
        <v-card class="text-center pa-5" >
            <p v-if="configEl.text==='data'">{{$t('utilities.components.common.savingData')}}</p>
            <p v-if="configEl.text==='document'">{{$t('utilities.components.common.savingDocument')}}</p>
            <div v-else class="mb-3">
                <slot ></slot>
            </div>
            <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
        </v-card>
    </v-dialog>
</template>

<script>
    // Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "progress-dialog",
        props: {
            waitingForReply: {default: 'false'},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.dialogs.confirmDialog',
        // define here, which parameter should be customizeable
            configDefault: { // = yesNo
                text: 'data' // or 'document'
            }
        }),
        computed: {
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
    }
</script>

<style scoped>

</style>