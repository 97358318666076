<template>
    <v-dialog v-model="dialog" persistent fullscreen>
        <toolbar :showSave="false" :title="$t('myPurchases.menuItem')" :handle-back-in-component="true"
                 :config-prop="{icon: 'mdi-cart'}" @back="handleBack"/>
        <div v-if="filteredBy" class="white pt-3 px-4">{{$t('myPurchases.filteredBy')}}
            <v-chip small close @click:close="removeFilter()" class="ml-1">
                {{filteredBy.substring(0,6)}}</v-chip>
        </div>
        <v-list v-if="purchaseOrders.length>0"
                class="mb-15 pb-0">
            <span v-for="(purchaseOrder, i) in purchaseOrders" :key="i">
                <v-list-item @click="showDetails(purchaseOrder.id)">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{purchaseOrder.seller.name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{dateAndTime(purchaseOrder.ts)}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        € {{getSum(purchaseOrder)}}
                        <v-chip small v-if="purchaseOrder.isCashSale">{{$t('shop.cash')}}</v-chip>
                        <v-chip small v-else>{{$t('shop.online')}}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-expand-transition>
                    <div v-if="details[purchaseOrder.id]" class="px-7 pt-2">
                        <div class="mb-2 body-2">
                            {{$t('shop.orderId')}}: {{purchaseOrder.id.substring(0,6)}}
                        </div>
                        <v-row v-for="(item, j) in purchaseOrder.items"
                                :key="j" no-gutters
                                :class="[{'mt-4': j>0}, 'text--secondary text--lighten-3']">
                            <v-col class="shrink px-3">{{item.quantity}}x</v-col>
                            <v-col class="body-2 grow lineHeight-1-2 px-3">
                                {{item.name}}
                            </v-col>
                            <v-col class="shrink text-no-wrap">
                                € {{formatNumber( (item.amount * item.quantity) / 100)}}
                            </v-col>
                        </v-row>
                        <div v-if="purchaseOrder.otherSalesItems &&
                                   purchaseOrder.otherSalesItems.length>0" class="mt-4">
                            <v-row  v-for="(item, j) in purchaseOrder.otherSalesItems"
                                    :key="j" no-gutters
                                    :class="[{'mt-4': j>0}, 'text--secondary text--lighten-3']">
                                <v-col class="shrink px-3 lineHeight-1-2">1x</v-col>
                                <v-col class="grow lineHeight-1-2 px-3">
                                    {{item.description}}
                                </v-col>
                                <v-col class="shrink text-no-wrap">
                                    € {{formatNumber( item.value)}}
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="hasVouchers(purchaseOrder)" class="mt-4">
                            <v-row v-for="(voucher, j) in purchaseOrder.vouchers.list"
                                    :key="j" no-gutters
                                    :class="[{'mt-4': j>0}, 'text--secondary text--lighten-3']">
                                <v-col class="shrink px-3">1x</v-col>
                                <v-col class="body-2 grow lineHeight-1-2 px-3">
                                    {{$t('common.voucher')}}: {{voucher.voucher.title}}, {{voucher.userName}}
                                </v-col>
                                <v-col class="shrink text-no-wrap">
                                    € {{formatNumber( voucher.voucher.value * -1 )}}
                                </v-col>
                            </v-row>
                        </div>
                        <div class="text-right">
                            <v-btn icon @click="showDetails(purchaseOrder.id)">
                                <v-icon>
                                    mdi-chevron-up
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-expand-transition>
            </span>
        </v-list>
        <div v-else class="pa-5">
            <alert-message :config-prop="configs.nothingAlert">
                {{$t('myPurchases.noPurchases')}}
            </alert-message>
        </div>

    </v-dialog>
</template>

<script>
    import Toolbar from "@/utilities/components/controls/toolbar";
    import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
    import AlertMessage from "../../../utilities/components/displays/alertMessage";
    import RouterServices from "@/utilities/services/router/routerServices";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";

    export default {
        components: {
            AlertMessage,
            Toolbar},
        name: "my-purchases",
        data: () => ({
            dialog: true,
            purchaseOrders: [],
            purchaseOrdersAll: [],
            details: {},
            configs: {
                nothingAlert: {
                    show: true,
                }
            }
        }),
        computed: {
            user(){ return this.$store.getters.user; },
            filteredBy() {return this.$route.query.purchaseOrder}
        },
        created(){
            this.getPurchaseOrders();
        },
        methods: {
            async getPurchaseOrders(){
                if ( this.purchaseOrdersAll.length === 0 ) {
                    this.purchaseOrdersAll =  await FirebaseRead.readDocList('purchaseOrders', {
                        field: 'uid',
                        operator: '==',
                        value: this.user.uid.substring(0,28),
                    })
                }
                this.purchaseOrders = this.purchaseOrdersAll
                    .filter(purchaseOrder => !this.filteredBy ? purchaseOrder.successReported :
                        purchaseOrder.successReported && purchaseOrder.id === this.filteredBy)
                    .sort((b, a) => a.ts > b.ts ? 1 : -1);
            },
            dateAndTime(ts){
                return TimeStamp.getLocalStringFromGMTts(ts)
            },
            showDetails(purchaseOrderId){
                const temp = JSON.parse(JSON.stringify(this.details));
                temp[purchaseOrderId] = !temp[purchaseOrderId]
                this.details = temp;
            },
            getSum(purchaseOrder){
                let sum = 0;
                purchaseOrder.items.forEach( (item) => {
                    sum += item.amount * item.quantity;
                });
                sum = sum > 0 ? sum / 100 : 0;
                if ( purchaseOrder.otherSalesItems ) {
                    purchaseOrder.otherSalesItems.forEach( (item) => {
                        sum += item.value;
                    });
                }
                if ( purchaseOrder.vouchers?.total<0 ) {
                    sum += purchaseOrder.vouchers.total
                }
                return this.formatNumber(sum)
            },
            formatNumber(number){
                return number.toLocaleString(this.$i18n.locale, {
                    minimumFractionDigits: 2,
                    useGrouping: false
                })
            },
            removeFilter(){
                RouterServices.clearRouteQueryParameter(
                    this.$router, this.$route, ['purchaseOrder'], true
                );
                this.getPurchaseOrders();
            },
            hasVouchers(purchaseOrder){
                return purchaseOrder.vouchers?.total < 0
            },
            handleBack(){
                if ( this.$route.query.direct === 'true' ) {
                    this.$router.replace('/')
                } else {
                    window.history.back()
                }
            }
        },
    }
</script>

<style scoped>

</style>