var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{'cardContentDyn': _vm.$vuetify.breakpoint.xsOnly},
              {'cardContentFix': _vm.$vuetify.breakpoint.smAndUp}]},[(_vm.userEvent && _vm.userEvent.logo)?_c('img',{staticClass:"cardLogo",attrs:{"height":"24%","alt":"Logo","src":_vm.userEvent.logo}}):_vm._e(),(!_vm.userEvent.organisationId)?_c('div',{class:[{'titleDyn font14': _vm.$vuetify.breakpoint.xsOnly},
                  {'titleFix font20': _vm.$vuetify.breakpoint.smAndUp},
                  'lineHeight-1-0 mt-1 text-left pl-3', _vm.textColor]},[_c('div',{class:[{'font12': _vm.$vuetify.breakpoint.xsOnly},
                      {'font18': _vm.$vuetify.breakpoint.smAndUp},
                       'space1 wordBreak text-uppercase font-weight-black']},[_vm._v(" "+_vm._s(_vm.userEvent.subtitle)+" ")]),_c('div',{class:[{'font10': _vm.$vuetify.breakpoint.xsOnly},
                      {'font16': _vm.$vuetify.breakpoint.smAndUp},
                       'wordBreak text-uppercase']},[_vm._v(" "+_vm._s(_vm.userEvent.location)+" ")]),_c('div',{class:[{'font10': _vm.$vuetify.breakpoint.xsOnly},
                      {'font16': _vm.$vuetify.breakpoint.smAndUp},
                       'text-uppercase']},[_vm._v(" "+_vm._s(_vm.dateString)+" ")])]):_c('div',[_c('div',{class:[{'titleDyn fontEventText pl-2': _vm.$vuetify.breakpoint.xsOnly},
                  {'titleFix font18 pl-3': _vm.$vuetify.breakpoint.smAndUp},
                  'lineHeight-1-0 mt-1 text-left text-uppercase', _vm.textColor]},[_c('div',{staticClass:"space1 font-weight-black"},[(_vm.userEvent.name)?_c('span',[_vm._v(_vm._s(_vm.userEvent.name))]):_c('span',[_vm._v(_vm._s(_vm.userEvent.title))])]),_c('div',{class:[{'fontEventLocation mt05': _vm.$vuetify.breakpoint.xsOnly},
                      {'font14 mt-2': _vm.$vuetify.breakpoint.smAndUp},
                       ' space01']},[_vm._v(" "+_vm._s(_vm.userEvent.location)+" ")])]),_c('div',{class:[{'fontEventDate pl-2': _vm.$vuetify.breakpoint.xsOnly},
                  {'font12 pl-3': _vm.$vuetify.breakpoint.smAndUp},
                  'dateFooter font10 lineHeight-1-2', _vm.textColor]},[_vm._v(" "+_vm._s(_vm.dateString)+" ")])]),(_vm.userEvent)?_c('wallet-missing-consents-overlay',{attrs:{"card-selected":_vm.walletZoomed.documentId!=='',"event-id":_vm.userEvent.id}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }