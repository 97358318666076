export class ConfigStripe_moduleInstance {
    constructor(){
        this.componentName = 'stripe';
        this.content = {
            stripe: { // = root !!!
                css: 'body-2 px-4 pb-2',
                stripeCheckout: {
                    btn: {
                        default: {
                            block: true,
                            css: 'mb-2',
                            i18n: 'checkout.create',
                            color: 'secondary'
                        }
                    },
                }
            },
        };
        this.utilities = {
            components: {
                dialogs: {
                    confirmDialog: {
                        default: {
                        },
                        delete: {
                        },
                        confirm: {
                        },
                        save: {
                        },
                    }
                }
            }
        };
        this.locales = {
            en: {
                checkout: { // moduleName
                    // overwrite i18n keys content with plain text
                    create: 'Start payment',
                    paymentCompleted: 'Thank you, we received your payment! ' +
                    'It may take a few seconds for your purchases to appear in the wallet.' +
                    'You can also find your orders in the menu under \'My purchases\'',
                }
            },
            de: {
                checkout: {
                    create: 'Zahlung starten',
                    paymentCompleted: 'Vielen Dank, wir haben deine Zahlung erhalten! ' +
                    'Es kann ein paar Sekunden dauern bis deine Einkäufe im Wallet erscheinen. ' +
                    'Du findest deine Bestellungen auch im Menü unter Punkt \'Meine Einkäufe\'',
                    // overwrite i18n keys content with plain text
                }
            },

            /*
            examples
             */
            // en: {
            //     conditions: {
            //         userListHeader: 'Must the player meet personal ' +
            //             'requirements to be able to participate (age, gender, ...)',
            //         dateListHeader: 'Do you want to define registration deadlines',
            //         licenseListHeader: 'Must the player be a member or have' +
            //             ' participated in another tournament',
            //     }
            // },
            // de: {
            //     conditions: {
            //         userListHeader: 'Muss der Spieler persönliche Voraussetzung erfüllen ' +
            //             'um teilnehmen zu können (Alter, Geschlecht, …)',
            //         dateListHeader: 'Möchtest du Anmeldefristen definieren',
            //         licenseListHeader: 'Muss der Spieler Mitglied sein oder bei ' +
            //             'einem anderen Turnier dabei gewesen sein',
            //     }
            // }
        };
    }
}