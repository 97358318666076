<template>
    <div v-if="organisation"
         class="caption text-right pt-3 pb-2">
        {{$t('shop.sellerText')}}
        <img v-if="organisation.logoDark" height="28" class="ml-2 mr-2" :src="organisation.logoDark"/>
        <div v-else class="d-inline-block font-weight-bold font-italic font16 text-uppercase mr-3">
            {{organisation.short}}</div>
    </div>
</template>

<script>
    export default {
        name: "shop-event-footer",
        props: ['organisationId'],
        computed: {
            organisation(){ return this.$store.state.organisations[this.organisationId]}
        },
    }
</script>

<style scoped>

</style>