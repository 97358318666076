import {Mother} from "@/utilities/models/mother";
import FirebaseStorage from "@/utilities/services/firebase/firebaseStorage";
import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
import FirebaseWrite from "@/utilities/services/firebase/firebaseWrite";
import store from '@/store/store';

export class ParentConfirm extends Mother {
    constructor(dataObject){
        super();
        this.uid = '';
        this.fileName = '';
        this.originalName = '';
        this.fileLink = '';
        this.ts = '';
        this.review = {
            by: '',
            ts: '',
            status: null,
        };
    // database collection
        this.local.fireBaseCollection = 'parentConfirms';
        this.local.storageFileName = '';
    // set data
        if(dataObject){this.setFromDataObject(dataObject);}
    }

    async editPDFDocument(newFileName, uid, file, parentConfirmStore){
        this.local.storageFileName = 'parentConfirms/parentConfirm_' + uid + '.pdf';
        if ( newFileName ) {
            return await this.savePDFDocument(
                newFileName, uid, file, parentConfirmStore
            )
        } else {
            return await this.deletePDFDocument(uid);
        }
    }

    async savePDFDocument(newFileName, uid, file, parentConfirmStore){
    // write file
        const storageSaveReply = await FirebaseStorage.uploadFile(
            file, this.local.storageFileName
        );
        console.log ("storageSaveReply", storageSaveReply);
        if ( !storageSaveReply.successful ) {
            return storageSaveReply;
        }
        this.fileLink = storageSaveReply.content;
    // create or update user document
        this.ts = TimeStamp.getGMTTimestamp();
        this.originalName = newFileName;
        if ( !parentConfirmStore ) {
            this.uid = uid;
            this.fileName = this.local.storageFileName;
        }
        const documentSaveReply = !parentConfirmStore ?
                await this.saveNewWithDocId(uid) :
                await this.updateFile();
        console.log ("documentSaveReply", documentSaveReply);
    // rollback if document save failed
        if ( !documentSaveReply.successful ) {
            await FirebaseStorage.deleteFile(this.local.storageFileName);
            await FirebaseWrite.delete('parentConfirms', uid)
        }
    // set in store if not yet realtime bound
        if ( !parentConfirmStore ) {
            store.dispatch('bindRealtimeDocument', {
                collection: 'parentConfirms',
                storeField: 'parentConfirm',
                docId: uid,
            });
        }
        return documentSaveReply;
    }

    async deletePDFDocument(uid){
        const storageDeleteReply = await FirebaseStorage.deleteFile(this.local.storageFileName);
        if ( !storageDeleteReply.successful ) { return storageDeleteReply }
        return await FirebaseWrite.delete('parentConfirms', uid);
    }

    async updateFile(){
        return await this.update({
            originalName: this.originalName,
            fileLink: this.fileLink,
            ts: this.ts,
        });
    }

}