<template>
    <div v-if="oobCode" :class="[configEl.css, 'text-center']">
        <div v-show="!loginService.waitingForReply" class="text-left">
        <!-- verification was successful -->
            <alert-message :config-prop="configEl.successMessage"
                           :module-config="moduleConfig">
                {{$t(configEl.successMessage.i18n)}}
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn-text :config-prop="configEl.startBtn"
                              :module-config="moduleConfig"
                              @click="completeSignUp()">
                    </btn-text>
                </v-card-actions>
            </alert-message>
        <!-- oobCode is expired -->
            <alert-message :config-prop="configEl.expiredMessage"
                            :module-config="moduleConfig">
                {{$t(configEl.expiredMessage.i18n)}}
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn-text :config-prop="configEl.resendBtn"
                              :module-config="moduleConfig"
                              @click="sendNewVerificationLink()">
                    </btn-text>
                </v-card-actions>
                <!-- sub alert after repeated send of link -->
                <alert-message :config-prop="configEl.subAlert"
                               :module-config="moduleConfig">
                    {{$t(configEl.subAlert.i18n)}}
                </alert-message>
            </alert-message>
        <!-- unexpected error message -->
            <alert-message config-package="error"
                           :config-prop="verificationError"
                           :module-config="moduleConfig"
                           class="text-left mt-5">
                {{errorMessage}}
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <btn-text :config-prop="configEl.resendBtn"
                              :module-config="moduleConfig"
                              @click="sendNewVerificationLink()">
                    </btn-text>
                </v-card-actions>
                <!-- sub alert after repeated send of link -->
                <alert-message :config-prop="configEl.subAlert"
                               :module-config="moduleConfig">
                    {{$t(configEl.subAlert.i18n)}}
                </alert-message>
            </alert-message>
        </div>
    <!-- progress circle -->
        <v-progress-circular indeterminate color="primary"
                             v-show="loginService.waitingForReply">
        </v-progress-circular>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import AlertMessage from "@/utilities/components/displays/alertMessage";
    import BtnText from "@/utilities/components/controls/btnText";
    import RouterServices from '@/utilities/services/router/routerServices';

    export default {
        components: {
            BtnText,
            AlertMessage},
        name: "email-verification",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            oobCodeExpired: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.emailVerification',
        // config
            configDefault: {
                expiredMessage: {
                    show: false,
                    i18n: 'utilities.components.auth.verificationExpired'
                },
                successMessage: {
                    show: false,
                    i18n: 'utilities.components.auth.emailVerified',
                },
                resendBtn: {
                    i18n: 'utilities.components.auth.sendNewVerification',
                    css: 'mt-2',
                },
                startBtn: {
                    i18n: 'utilities.components.auth.startApp',
                    css: 'mt-2',
                },
                subAlert: {
                    css: 'mt-3',
                    show: false,
                    color: 'grey',
                    text: false,
                    icon: 'mdi-email-fast',
                    dense: true,
                    timeout: 4000,
                    i18n: 'utilities.components.auth.emailSent',
                },
            },
        }),
        computed: {
            oobCode() { return this.$route.query.oobCode },
            verificationError(){
                return {show: this.loginService.errorI18n !== ''}
            },
            errorMessage(){
                return this.loginService.getErrorMessage(this.$i18n.t(this.loginService.errorI18n));
            },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        created(){
            this.verifyOobCode();
        },
        methods: {
            async verifyOobCode(){
                if ( this.oobCode ) {
                    const oobExpired = await this.loginService.oobCodeExpired(this.oobCode, 'emailVerified');
                // handling if user is still logged in and login process is completed
                    if ( oobExpired && !this.loginService.isEmailVerified && this.loginService.isUserLoggedIn ) {
                    // expired and not verified => offer new link button
                        console.log ("verifyOobCode: AAAAAAAAAAAA" );
                        this.setMessages(false, true);
                    } else if (oobExpired && this.loginService.isEmailVerified && this.loginService.isUserLoggedIn){
                    // expired but already verified => give success
                        console.log ("verifyOobCode: BBBBBBBBBBBB" );
                        this.setMessages(true, false);
                    } else if ( !oobExpired && !this.loginService.isEmailVerified && this.loginService.isUserLoggedIn ) {
                    // handle verification
                        console.log ("verifyOobCode: CCCCCCCCCCCC" );
                        await this.loginService.applyActionCode(this.oobCode);
                        await this.loginService.reloadUserData();
                        this.setMessages(true, false);
                // handling if user is not logged in
                    } else if ( !oobExpired && !this.loginService.waitingForReply ) {
                    // link valid => route to login start screen (without further comment)
                        console.log ("verifyOobCode: DDDDDDDDDDDD" );
                        await this.loginService.applyActionCode(this.oobCode);
                        this.setMessages(true, false);
                    } else if ( oobExpired && !this.loginService.waitingForReply ) {
                    // link expired => route to login start screen (without further comment)
                        console.log ("verifyOobCode: EEEEEEEEEEEE" );
                        this.completeSignUp();
                    }
                }
            },
            setMessages(success, expired){
                this.configDefault.successMessage.show = success;
                this.configDefault.expiredMessage.show = expired;
            },
            sendNewVerificationLink(){
                this.loginService.requestVerificationMail();
                this.showSubAlert();
            },
            showSubAlert(){
                this.configDefault.subAlert.show = true;
                setTimeout(()=>{
                    this.configDefault.subAlert.show = false;
                }, this.configDefault.subAlert.timeout)
            },
            async completeSignUp(){
                console.log ("completeSignUp!!!!", );
                if ( this.$route.query.oobCode ) {
                    RouterServices.clearRouteQueryParameter(
                        this.$router, this.$route, ['mode', 'oobCode', 'apiKey', 'lang'], true
                    );
                }
                /*if ( !this.loginService.isEmailVerified ) {
                // reload data to get verified state
                    await this.reloadUserData();
                } else {
                // check if user is existing or still user_unconfirmed => set if needed
                    await this.loginService.checkAndSetAppUser();
                }*/
            },
            async reloadUserData(){
                const userUpdate = await this.loginService.reloadUserData();
                if (userUpdate.successful) {
                    if (!userUpdate.content.emailVerified) {
                    // user refreshed but verification not completed
                        this.loginService.setParameter('errorI18n',
                            'utilities.components.auth.verificationFailed');
                    } /*else {
                    // user refreshed, verification successful, complete user signup
                        await this.loginService.checkAndSetAppUser();
                    }*/
                }
            },
        },
        watch: {
            'loginService.waitingForReply'(to) {
                if ( !this.loginService.isUserLoggedIn && !to ) {
                    console.log ("WATCH loginService.waitingForReply");
                    this.verifyOobCode();
                }
            },
            'loginService.isUserLoggedIn'() {
                console.log ("WATCH loginService.isUserLoggedIn");
                this.verifyOobCode();
            }
        }
    }
</script>

<style scoped>

</style>