<template>
    <div>
        <div v-for="(item, i) in options" :key="i" @click="select(item)"
              :class="[{'text-decoration-underline': item.value==current},
                        {'borderLeft': i > 0}, 'px-3 d-inline-block']">
            {{item.label}}
        </div>
    </div>
</template>

<script>
    // Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "text-switch",
        props: {
            options: {default: []},
            current: {default: null},
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.controls.textSwitch',
        // define here, which parameter should be customizeable
            configDefault: {
            },
        }),
        computed: {
            // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                )
            },
        },
        methods: {
            select(item){
                this.$emit("selected", item.value)
            }
        },
    }
</script>

<style scoped>
    .borderLeft { border-left: 1px solid grey}
</style>