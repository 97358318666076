export const locales = {
    messages: {
        en: {
            checkout: {
                create: 'Pay now',
                inProgress: "We use an external service (STRIPE) to process the payment, " +
                            "please click 'ok' to process the payment." +
                            "Payment must be made today, otherwise the order will be automatically deleted.",
                creationFailed: "Unfortunately we could not start the payment, please try again.",
                paymentCompleted: 'Thank you, we received your payment! ' +
                                  'It may take a few seconds for your purchases to appear in the app.',
                processingPayment: 'We will start the payment process, this might take a few seconds!',
                cancelPayment: 'We will cancel your order and stop the payment. ' +
                                'This can take a few seconds.'
            },
            accountRequest: {
                create: 'Create Stripe Account',
                delete: 'Delete Stripe Account',
                reopen: 'Request new Stripe Registration-Link',
                openStripe: 'goto stripe.com',
                confirmHeader: 'Create STRIPE account',
                confirmQuestion: 'Are you sure you want to create a payment account on STRIPE? ' +
                                 'It is an external service that is integrated in this app.',
                infoPending: 'Your payment account at STRIPE is created, this will take a few seconds.',
                infoPendingRefresh: 'Your registration link had expired! ' +
                                    'Please be patient, we are requesting a new link for you.',
                infoRedirected: 'Your payment account with STRIPE has been successfully created, ' +
                                'but you still have to complete your registration directly on the STRIPE website.',
                infoRedirectedRefresh: 'The registration link for your STRIPE account had expired, ' +
                                    'we have requested a new one for you.\n' +
                                    'Please click the button below to complete your registration ' +
                                    'directly on the STRIPE website.',
                infoRejected: 'Your request is corrupt and has been rejected!',
                infoIncomplete: 'Your STRIPE account has been created, but data is still missing! ' +
                                'Please log in to stripe.com to complete your account details.',
                infoActive: 'Your STRIPE account is active and ready to process payments. ' +
                            'For details, please log on to stripe.com.',
            }
        },
        de: {
            checkout: {
                create: 'jetzt Zahlen',
                inProgress: "Wir nutzen ein externes Service (STRIPE) zur Abwicklungen der Zahlung, " +
                            "bitte klicke auf 'ok' um die Zahlung durchzuführen. " +
                            "Die Zahlung muss noch am heutigen Tag erfolgen, anderenfalls wird die Bestellung automatisch gelöscht.",
                creationFailed: "Wir konnten die Zahlung leider nicht starten, bitte probiere es nochmals.",
                paymentCompleted: 'Vielen Dank, wir haben deine Zahlung erhalten! Es kann ein paar Sekunden dauern ' +
                                  'bis deine Einkäufe in der App sichtbar sind.',
                processingPayment: 'Bitte um etwas Geduld, wir starten den Zahlungsprozess!',
                cancelPayment: 'Wir stornieren deine Bestellung und brechen die Zahlung ab. ' +
                'Das kann einige Sekunden dauern.'
            },
            accountRequest: {
                create: 'Stripe Zahlungskonto anlegen',
                delete: 'Stripe Zahlungskonto löschen',
                reopen: 'Registrierung bei Stripe abschließen',
                openStripe: 'stripe.com öffnen',
                confirmHeader: 'STRIPE Konto erstellen',
                confirmQuestion: 'Möchtest du wirklich ein Zahlungskonto auf STRIPE anlegen? ' +
                                 'Es handelt sich dabei um ein externes Service, das in diese App integriert ist.',
                infoPending: 'Dein Zahlungskonto bei STRIPE wird angelegt, bitte um etwas Geduld!',
                infoPendingRefresh: 'Dein Registrierungslink war leider abgelaufen! ' +
                                     'Bitte um etwas Geduld, wir fordern einen neuen Link für dich an.',
                infoRedirected: 'Dein Zahlungskonto bei STRIPE wurde erfolgreich angelegt, ' +
                                'du musst aber noch deine Registrierung direkt auf der Seite ' +
                                'von STRIPE abschließen.',
                infoRedirectedRefresh: 'Der Registrierungslink zur deinem STRIPE Konto war leider ' +
                                'abgelaufen, wir haben für dich einen neuen angefordert.\n' +
                                'Bitte klicke nochmals den nachstehenden Button um deine ' +
                                'Registrierung direkt auf der Seite von STRIPE abschließen.',
                infoRejected: 'Dein Antrag ist korrupt und wurde daher abgelehnt!',
                infoIncomplete: 'Dein STRIPE Konto ist angelegt, es fehlen aber noch Daten! Bitte melde ' +
                                'dich auf stripe.com an, um deine Kontodaten zu vervollständigen.',
                infoActive: 'Dein STRIPE Konto ist aktiv und bereit Zahlungen abzuwickeln. ' +
                            'Für Details melde dich bitte auf stripe.com an.',
            }
        }
    }
}