<template>
    <v-tooltip bottom :color="configEl.color" :disabled="!$vuetify.breakpoint[configEl.breakpoint]">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
              <slot></slot>
          </span>
        </template>
        <span>{{translate(configEl.i18n)}}</span>
    </v-tooltip>
</template>

<script>
    // Version 1.1 2021-12-20
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    export default {
        name: "tool-tip",
        props: {
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
        // define the path where the component can be found in the directory
            path: 'utilities.components.displays.toolTip',
        // config
            configDefault: {
                breakpoint: 'mdAndUp',
                color: '',
                i18n: '',
            }
        }),
        computed: {
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
        // always necessary
            translate(i18nKey){
                return ComponentConfigService
                    .translateElementText(i18nKey, this.configProp, this.moduleConfig);
            },
        },
    }
</script>

<style scoped>

</style>