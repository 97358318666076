import { render, staticRenderFns } from "./profileUserSpeedDial.vue?vue&type=template&id=43558a3c&scoped=true&"
import script from "./profileUserSpeedDial.vue?vue&type=script&lang=js&"
export * from "./profileUserSpeedDial.vue?vue&type=script&lang=js&"
import style0 from "./profileUserSpeedDial.vue?vue&type=style&index=0&id=43558a3c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43558a3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VSpeedDial})
