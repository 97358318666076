import {ServiceMother} from "@/utilities/services/serviceMother";

export default class VoucherListService extends ServiceMother{
    static getAdvantageList(voucherList){
        const eventVoucherAbsolute = {number: 0, currency: '', value: 0, i18n: 'shopVoucher.eventVoucherAbsoluteSingle'}
        const eventVoucherPercent = {number: 0, value: 0, i18n: 'shopVoucher.eventVoucherPercentSingle'}
        const externalVoucher = {number: 0, i18n: 'shopVoucher.externalVoucherSingle'};
        voucherList.forEach( (voucher) => {
            if ( voucher.isThirdParty ) {
                externalVoucher.number++;
            } else if ( voucher.showValue !== 'percent' ) {
                eventVoucherAbsolute.number++;
                eventVoucherAbsolute.currency = voucher.currency;
                eventVoucherAbsolute.value += voucher.value;
            } else {
                eventVoucherPercent.number++;
                if ( eventVoucherPercent.value < voucher.value ) eventVoucherPercent.value = voucher.value
            }
        });
        let advantageList = [];
        if ( eventVoucherAbsolute.number > 1 ) eventVoucherAbsolute.i18n = 'shopVoucher.eventVoucherAbsolute'
        if ( eventVoucherPercent.number > 1 )eventVoucherPercent.i18n = 'shopVoucher.eventVoucherPercent'
        if ( externalVoucher.number > 1 ) externalVoucher.i18n = 'shopVoucher.externalVoucher'
        if ( eventVoucherAbsolute.number > 0 ) advantageList.push(eventVoucherAbsolute)
        if ( eventVoucherPercent.number > 0 ) advantageList.push(eventVoucherPercent)
        if ( externalVoucher.number > 0 ) advantageList.push(externalVoucher)
        return advantageList;
    }
}