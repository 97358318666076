<template>
    <div>
        <v-divider v-if="!$route.query.card && redeemed && cards.length>0"></v-divider>
        <v-expand-transition>
            <div v-show="!walletZoomed.section && cards.length>0">
                <p class="font-weight-medium text-uppercase grey--text
                        text--darken-2 space2 font14 pt-4 px-4">
                    <span v-if="redeemed">{{$t('wallet.redeemed')}}</span> {{$t('common.vouchers')}}
                </p>
            </div>
        </v-expand-transition>
    <!-- card list -->
        <wallet-card-list :cards="cards" card-list="userVouchers" :archive="redeemed"
                          :section="section" stylesSource="organisation" :hide-corner-tag="true">
        </wallet-card-list>
    <!-- terms -->
        <v-row no-gutters v-if="!walletZoomed.section && cards.length>0"
               class="mb-4 mt-1 px-4 caption grey--text text--darken-1 font-weight-medium">
            <v-col class="shrink pr-1 lineHeight-1-0">*</v-col>
            <v-col class="grow lineHeight-1-0">{{$t('adminVoucher.clickForTerms')}}</v-col>
        </v-row>
    <!-- zoomed detail information -->
        <div v-if="walletZoomed.section===section && !redeemed"
                :class="[{'detailContainer mx-4': $vuetify.breakpoint.smAndUp},
                         'text-center mb-10 pb-10']">
            <v-row no-gutters>
                <v-col cols="12">
                    <div :class="[{'mt-4': $vuetify.breakpoint.xsOnly},
                                  {'mt-1': $vuetify.breakpoint.smAndUp},
                                    'text-left mb-4 fontInter text-center font24 lineHeight-1-2']">
                            <span class="font-weight-thin">{{user.firstName}}
                            </span> <span class="text-uppercase font-weight-black">
                            {{user.lastName}}</span>
                    </div>
                    <v-divider :class="dividerClass"></v-divider>
                </v-col>
                <v-col cols="12">
                    <qr-code-display :qrValue="qrValue" :size="size"
                                     class="d-inline-block">
                    </qr-code-display>
                </v-col>
                <v-col cols="12" class="mt-5">
                    <profile-avatar :user="user"
                                    :storage-source-path="storageSourcePath"
                                    :config-prop="{size: 144}">
                    </profile-avatar>
                </v-col>
            </v-row>
            <v-divider :class="dividerClass"></v-divider>
            <voucher-details-data-list :class="[{'px-4': $vuetify.breakpoint.xsOnly}, 'text-left']"
                :voucher="userVouchers.vouchers[voucherId]"
                :is-admin="false"/>
        </div>
        <div v-else-if="walletZoomed.section===section && redeemed"
                  class="px-4 pb-15 mt-2">
            <v-list class="py-0">
                <wallet-detail-item v-if="userVouchers.vouchers[voucherId] &&
                                          userVouchers.vouchers[voucherId].redeem.redeemDocument.name" icon="mdi-qrcode"
                                    :title="$t('wallet.redeemedBy')">
                    {{userVouchers.vouchers[voucherId].redeem.redeemDocument.name}}
                </wallet-detail-item>
                <wallet-detail-item icon="mdi-calendar"
                                    :title="$t('wallet.redeemedAt')">
                    {{redeemDate}}
                </wallet-detail-item>
                <wallet-detail-item v-if="redeemPurchaseOrderId"
                                    icon="mdi-cart-outline" :title="$t('wallet.redeemedWith')">
                    {{redeemPurchaseOrderId}}
                    <span class="text-decoration-underline"
                          @click="gotoPo(userVouchers.vouchers[voucherId].redeem.purchaseOrderId)">
                        ({{$t('wallet.gotoPo')}})</span>
                </wallet-detail-item>
            </v-list>
        </div>
    </div>
</template>

<script>
    import TimeStamp from '@/utilities/services/timeStamp/timeStamp';
    import WalletCardList from "./walletCardList";
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import QrCodeDisplay from "@/utilities/components/qrCode/qrCodeDisplay";
    import ObjectHandlingService from '@/utilities/services/objectHandling/objectHandlingService'
    import WalletDetailItem from "./walletDetailItem";
    import {Voucher} from "@/models/voucher";
    import VoucherDetailsDataList from "@/components/voucher/voucherDetailsDataList";

    export default {
        components: {
            VoucherDetailsDataList,
            WalletDetailItem,
            QrCodeDisplay,
            ProfileAvatar,
            WalletCardList},
        name: "wallet-vouchers",
        props: ['redeemed', 'showArchive', 'todayString'],
        computed: {
            tab(){ return this.$route.query.tab},
            redeemDate(){
                return this.userVouchers.vouchers[this.voucherId] && this.userVouchers.vouchers[this.voucherId].redeem &&
                    this.userVouchers.vouchers[this.voucherId].redeem.ts ?
                    TimeStamp.getLocalStringFromGMTts(this.userVouchers.vouchers[this.voucherId].redeem.ts)
                    /*new Date(this.userVouchers.vouchers[this.voucherId].redeem.ts).toLocaleString(this.$i18n.locale, {
                        minimumFractionDigits: 2,
                        useGrouping: false
                    })*/
                    : '-'
            },
            redeemPurchaseOrderId(){
                const purchaseOrderId = this.userVouchers?.vouchers[this.voucherId]?.redeem?.purchaseOrderId
                return purchaseOrderId ? purchaseOrderId.substring(0,6) : ''
            },
            section(){ return !this.redeemed ? 'vouchers' : 'vouchersArchive';},
            stylesSource(){ return !this.redeemed ? 'vouchers' : 'vouchersArchive';},
            cards(){
                return this.$store.state.realtime.userVouchers ?
                    this.redeemed ?
                    ObjectHandlingService
                            .getListFromObject(this.$store.state.realtime.userVouchers.vouchers)
                            .filter(voucher => voucher.redeem && voucher.redeem.ts)
                            .map(voucher => new Voucher(voucher, voucher.organisationId,
                                this.organisations[voucher.organisationId].styles))
                            .filter(voucher => this.showArchive ? voucher.validUntil < this.todayString :
                                                                  voucher.validUntil >= this.todayString)
                            .sort((a, b) => a.organisationId + a.validUntil >
                            b.organisationId + b.validUntil ? 1 : -1) :
                            //.sort((a, b) => a.from > b.from ? 1 : -1) :
                    ObjectHandlingService
                            .getListFromObject(this.$store.state.realtime.userVouchers.vouchers)
                            .filter(voucher => voucher.redeem === undefined || !voucher.redeem || !voucher.redeem.ts)
                            .map(voucher => new Voucher(voucher, voucher.organisationId,
                                this.organisations[voucher.organisationId].styles))
                            .filter(voucher => this.showArchive ? voucher.validUntil < this.todayString :
                                                                  voucher.validUntil >= this.todayString)
                            .sort((a, b) => a.organisationId + a.validUntil >
                            b.organisationId + b.validUntil ? 1 : -1)
                            //.sort((a, b) => a.from > b.from ? 1 : -1)
                            : [];
            },
            dividerClass(){ return this.$vuetify.breakpoint.xsOnly ?
                'ma-4' : 'mt-4 mb-5'
            },
            organisations(){ return this.$store.state.organisations??[] },
            userVouchers(){ return this.$store.state.realtime.userVouchers },
            walletZoomed(){ return this.$store.state.wallet.zoomed;},
            voucherId(){ return this.$route.query.card},
            voucher(){return this.$store.state.realtime.userVouchers.vouchers[this.voucherId]},
            user(){ return this.$store.getters.user },
            qrValue() {return this.voucher ? 'uid:' + this.user.uid +
                                ',voucher:' + this.voucherId +
                                ',addOn:' + this.voucher.ts : 'uid:' + this.user.uid +
                                ',voucher:' + this.voucherId },
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
        },
        data: () => ({
            size: 220,
        }),
        created(){
            this.emitNoOfCards()
        },
        methods: {
            emitNoOfCards(){
                this.$emit('noOfDisplayedCards', this.cards.length);
            },
            gotoPo(purchaseOrderId){
                this.$router.push({path: 'myPurchases', query: {purchaseOrder: purchaseOrderId}})
            }
        },
        watch: {
            cards(){
                this.emitNoOfCards()
            },
            tab(){
                if ( this.tab === '2' ) { this.emitNoOfCards() }
            }
        }
    }
</script>

<style scoped>
    /*
    .detailContainer{  width: 346px;}
    */
</style>