// Version 1.0 2022-04-12 - DH
import Vue from "vue";

Vue.filter('formatIntToMoneyWithCurrency',(value, currency)=> {
    if(!value || !currency || typeof currency !== 'string') return 'no value or currency'

    return currency.toUpperCase() + ' ' + value + ',--'
})

Vue.filter('formatIntToMoneyWithCurrencyTwoDigits',(value, currency)=> {
    if((!value && value !== 0) || !currency || typeof currency !== 'string') return 'no value or currency'

    return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) +
        ' ' + currency.toUpperCase();
    //return currency.toUpperCase() + ' ' + (Math.round(value * 100) / 100).toFixed(2);
})