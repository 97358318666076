// Version 1.1 2022-01-18
export default class routerServices {
    static pushRouteQueryParameter(router, route, parameter, value){
        let p = JSON.parse(JSON.stringify(route.path));
        let q = JSON.parse(JSON.stringify(route.query));
        q[parameter] = value;
        router.push({path: p, query: q});
    }
    static pushRouteMultipleQueryParameter(router, route, params){
        let p = JSON.parse(JSON.stringify(route.path));
        let q = JSON.parse(JSON.stringify(route.query));
        q = {...q, ...params};
        router.push({path: p, query: q});
    }
    static replaceRouteQueryParameter(router, route, parameter, value){
        let p = JSON.parse(JSON.stringify(route.path));
        let q = JSON.parse(JSON.stringify(route.query));
        q[parameter] = value;
        router.replace({path: p, query: q});
    }
    static clearRouteQueryParameter(router, route, parameters, replace){
        let p = JSON.parse(JSON.stringify(route.path));
        let q = JSON.parse(JSON.stringify(route.query));
        parameters.forEach( (parameter) => {
            delete q[parameter];
        });
        if ( replace ) {
            router.replace({path: p, query: q});
        } else {
            router.push({path: p, query: q});
        }
    }
    static clearRouteAllParameter(router, route, replace){
        let p = JSON.parse(JSON.stringify(route.path));
        let q = JSON.parse(JSON.stringify(route.query));
        Object.keys(route.query).forEach( (key) => {
            delete q[key];
        });
        if ( replace ) {
            router.replace({path: p, query: q});
        } else {
            router.push({path: p, query: q});
        }
    }
}