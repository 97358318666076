<template>
    <div>
        <toolbar :title="$t('shop.yourShoppingCart')"
                 :show-save="false"
                 :show-back="$route.query.success===undefined"
                 :config-prop="{icon: 'mdi-cart-outline'}"
                 :handleBackInComponent="true"
                 @back="back">
        </toolbar>
    <!-- progress circle -->
        <div v-if="shoppingCart.isLoading" class="text-center pt-10">
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
        </div>
    <!-- show data -->
        <div v-else class="px-3">
            <div v-if="shoppingCart.status !== 'paid'">
                <v-card v-for="(eventItem, i) in shoppingCart.events" :key="i"
                        style="border: solid 1px #e2e2e2; max-width: 600px"
                        :class="[{'mx-auto mt-5': $vuetify.breakpoint.smAndUp}, 'mt-3']">
                    <div>
                        <v-row :class="getEventClass(eventItem)" no-gutters
                               :style="getEventStyles(eventItem)">
                            <v-col class="pt-2 px-2">
                                <img :src="getEventLogo(eventItem)" height="48px">
                            </v-col>
                        </v-row>
                        <div class="bgdGreyDark fontInter font-weight-black font18
                        white--text lineHeight-1-2 px-4 pt-2 space03 text-uppercase">
                            <div v-if="!eventItem.title">{{eventItem.name}}</div>
                            <div v-else>{{eventItem.title}}</div>
                            {{eventItem.subtitle}}
                        </div>
                        <div class="bgdGreyDark white--text fontInter font14 lineHeight-1-2 px-4 pb-2">
                            {{eventItem.location}} {{getDateRange(eventItem.from, eventItem.until)}}
                        </div>
                    </div>
                    <div class="px-4 py-3">
                        <div v-for="(license, i) in eventItem.licenses"
                             :key="i">
                            <v-row no-gutters
                                   >
                                <v-col class="grow">
                                    <div class="fontInter font-weight-bold primary--text font16">
                                        {{license.license.title}} {{license.license.subtitle}}
                                    </div>
                                    <div class="font-weight-bold caption mb-2">
                                        {{shoppingCart.getValidityText(license.license.validity)}}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters no-wrap align="center"
                                   v-for="(user, i) in shoppingCart.getUsersByLicenseId(license.id)"
                                   :key="i" class="py-0 my-0 ">
                                <v-col class="shrink pr-3 py-1">
                                    <profile-avatar v-if="user"
                                                    :user="user"
                                                    :storage-source-path="storageSourcePath"
                                                    :config-prop="{size: 32}">
                                    </profile-avatar>
                                </v-col>
                                <v-col class="grow pr-3 py-0">
                                    <span v-if="user">
                                        {{user.firstName}}
                                    </span>
                                </v-col>
                                <v-col v-if="!shoppingCart.items[user.uid][license.id].blockPrice"
                                        class="shrink py-0" style="white-space: nowrap">
                                    {{shoppingCart.items[user.uid][license.id].price.toFixed(2)}}
                                    {{shoppingCart.items[user.uid][license.id].currency}}
                                </v-col>
                                <v-col v-if="!shoppingCart.items[user.uid][license.id].blockDelete"
                                        class="shrink py-0 pl-1">
                                    <v-btn icon :disabled="shoppingCart.purchaseOrder!==null"
                                           @click="shoppingCart.deleteLicenseFromCart(license.id, user.uid)">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-divider v-if="i<eventItem.licenses.length-1"
                                        class="mb-2 mt-3">
                            </v-divider>
                        </div>
                    </div>
                    <shop-cart-event-total :event-id="eventItem.id">
                    </shop-cart-event-total>
                </v-card>
            </div>
            <shop-cart-total class="mb-15">
            </shop-cart-total>
            <!--<fab-btn-back v-if="$route.query.success===undefined"
                          style="z-index: 5">
            </fab-btn-back>-->
        </div>
<!--
        <pre>
            {{shoppingCart}}
        </pre>-->
    </div>
</template>

<script>
    import {ShoppingCart} from "@/services/shop/shoppingCart";
    import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
    import ProfileAvatar from "@/utilities/components/images/profileAvatar";
    import Toolbar from "@/utilities/components/controls/toolbar";
    import ShopCartEventTotal from "@/components/shop/shopCartEventTotal";
    import ShopCartTotal from "@/components/shop/shopCartTotal";
    //import FabBtnBack from "@/utilities/components/controls/fabBtnBack";

    export default {
        components: {
            //FabBtnBack,
            ShopCartTotal,
            ShopCartEventTotal,
            Toolbar,
            ProfileAvatar},
        name: "shop-cart",
        computed: {
            user(){ return this.$store.getters.user},
            shoppingCart(){ return this.$store.state.shoppingCart},
            storageSourcePath(){ return this.$store.state.config.storage.profileImages; }
        },
        created(){
            setTimeout(()=>{
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behaviour: 'smooth',
                });
            },50);
            this.setShoppingCart();
        },
        methods: {
            async setShoppingCart(){
                if ( !this.shoppingCart ) {
                    this.$store.state.shoppingCart = new ShoppingCart();
                    const sessionStorageData = JSON.parse(sessionStorage.getItem('shoppingCart'));
                    if ( sessionStorageData &&
                         sessionStorageData.validOn === TimeStamp.getTodaysDateString() &&
                         sessionStorageData.uid === this.user.uid.substring(0,28)) {
                        this.shoppingCart.items = sessionStorageData.items;
                        this.shoppingCart.purchaseOrder = sessionStorageData.purchaseOrder;
                        this.shoppingCart.eventsWithValidVouchers = sessionStorageData.eventsWithValidVouchers;
                    }
                }
                if ( Object.keys(this.shoppingCart.items).length === 0 ) {
                    this.$router.replace({path: '/', query: {tab: '1'}})
                } else {
                    await this.shoppingCart.createCheckOutView();
                    this.shoppingCart.shoppingCartVouchers.createVoucherCheckOut();
                }
            },
            getDateRange(from, until){
                return from && until ? '/ ' + TimeStamp.getTimeRangeFromTwoStringDates(from, until) : '';
            },
            back(){
                if ( this.$route.query.cancel ) {
                    this.$router.replace({path: '/', query: {tab: 1}})
                } else {
                    window.history.back();
                }
            },
            getEventStyles(eventItem){
                if ( eventItem?.styles?.color ) {
                    return {backgroundColor: eventItem.styles.color!== '#37474F' ? eventItem.styles.color : '#263238'}
                }
                return {backgroundColor: this.$store.state.organisations[eventItem.organisationId]?.styles.color}
            },
            getEventClass(eventItem){
                return this.$store.state.organisations[eventItem.organisationId]?.styles.color ?
                        'cardBanner text-left radiusTop' : 'primary cardBanner text-left radiusTop';
            },
            getEventLogo(eventItem){
                return eventItem.logo ? eventItem.logo :
                    this.$store.state.organisations[eventItem.organisationId]?.logoWide?
                    this.$store.state.organisations[eventItem.organisationId]?.logoWide:
                    this.$store.state.organisations[eventItem.organisationId]?.logo;
            }
        },
        /*watch: {
            'shoppingCart.isLoading'(to, from){
                console.log ("WATCH !!!!!!!!!!!!!!!!!!!", to, from);
                if ( !to && from ) {
                    console.log ("YES !!!!!!!!!!!!!!!!!!!");
                    setTimeout(()=>{
                        console.log ("OK !!!!!!!!!!!!!!!!!!!");
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behaviour: 'smooth',
                        });
                    },1000)

                }
            }
        }*/
    }
</script>

<style scoped>
    .radiusTop {border-top-left-radius: 3px; border-top-right-radius: 3px}
</style>