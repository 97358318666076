<template>
    <div v-if="vouchersSumTotal.sum<0" class="fontInter font-weight-bold primary--text font16 lineHeight-1-2 space03">
        <v-row no-gutters align="center"
            :class="[{'mt-1': eventId}, 'success--text mb-3']">
            <v-col class="grow pl-4">
                {{$t('shopVoucher.deduction')}}<span v-if="vouchersSumTotal.message">*</span>
            </v-col>
            <v-col class="shrink" style="white-space: nowrap">
                {{vouchersSumTotal.sum}} {{currency}}
            </v-col>
            <v-col class="shrink px-7"/>
        </v-row>
        <v-row no-gutters align="center" class="mb-1">
            <v-col class="grow pl-4 text-uppercase">
                {{$t('shopVoucher.toBePaid')}}
            </v-col>
            <v-col class="shrink" style="white-space: nowrap">
                {{value + vouchersSumTotal.sum}} {{currency}}
            </v-col>
            <v-col class="shrink px-7"/>
        </v-row>
        <v-divider v-if="!eventId" class="mt-3"></v-divider>
        <alert-message config-package="error" class="mx-4 body-2 mt-3 "
                       :config-prop="{show: eventId!==undefined && vouchersSumTotal.usersWithExpiringRemainder.length > 0}">
            <div class="mb-1">{{$t('shopVoucher.remainderExpiresWarning')}}</div>
            <ul v-for="(userName, i) in vouchersSumTotal.usersWithExpiringRemainder" :key="i">
                <li>{{userName}}</li>
            </ul>
            <div class="font-weight-bold mt-2">{{$t('shopVoucher.remainderExpiresWarning2')}}</div>
        </alert-message>
    </div>
</template>

<script>
import AlertMessage from "@/utilities/components/displays/alertMessage";
export default {
    name: "shop-cart-event-vouchers-total",
    components: {AlertMessage},
    props: ['eventId', 'value', 'currency'],
    computed: {
        shoppingCart(){ return this.$store.state.shoppingCart},
        vouchersSumTotal(){
            return this.eventId ?
                this.shoppingCart.shoppingCartVouchers.getVouchersSumForEvent(this.eventId) :
                this.shoppingCart.shoppingCartVouchers.getVouchersSumTotal();
        }
    },
}
</script>

<style scoped>

</style>