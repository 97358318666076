<template>
        <!--<v-alert :value="show" color="#048ba8" class="px-0 pt-2 pb-2 elevation-4" dark >
            <v-row align="center" class="mx-3">
                <v-col class="shrink pr-0">
                    <v-icon>mdi-message-text</v-icon>
                </v-col>
                <v-col class="grow fontInter font-weight-medium text-uppercase">
                    <span v-if="messagesBar.getNumberOfDisplayedMessages() <= 1">{{$t('messages.newHeaderSingle')}}</span>
                    <span v-else>{{$t('messages.newHeaderMultiple', {count: messagesBar.getNumberOfDisplayedMessages()})}}</span>
                </v-col>
            </v-row>
            <v-window v-if="messagesBar.getNumberOfDisplayedMessages() > 0" v-model="displayedMsgNo">
                <v-window-item v-for="(displayedMessage, i) in messagesBar.displayedMessages" :key="i"
                               transition="slide-x-transition" class="mx-3">
                    <messages-msg-short :displayed-message="displayedMessage"
                                        :total="messagesBar.getNumberOfDisplayedMessages()"/>
                </v-window-item>
            </v-window>
            <v-card-actions class="px-3 pb-0">
                <v-btn text @click="setRead('later')" color="grey lighten-2">{{$t('messages.notNow')}}</v-btn>
                <v-spacer></v-spacer>
                {{ displayedMsgNo+1 }} / {{ messagesBar.getNumberOfDisplayedMessages() }}
                <v-spacer></v-spacer>
                <v-btn text @click="setRead('read')"><v-icon class="mr-1">mdi-check</v-icon>{{$t('messages.setRead')}}</v-btn>
            </v-card-actions>
        </v-alert>-->
    <v-bottom-sheet v-model="show" persistent scrollable>
        <v-card class="pa-0" tile>
            <v-card-text class="pa-0">
                <div class="text-center py-4" @click="parkItems()">
                    <div class="handle mx-auto"/>
                </div>
                <v-row align="center" class="mx-2">
                    <v-col class="shrink pr-0">
                        <v-icon color="#048ba8">mdi-message-text</v-icon>
                    </v-col>
                    <v-col class="grow fontInter font-weight-medium text-uppercase black--text">
                        <span v-if="messagesBar.getNumberOfDisplayedMessages() <= 1">{{$t('messages.newHeaderSingle')}}</span>
                        <span v-else>{{$t('messages.newHeaderMultiple', {count: messagesBar.getNumberOfDisplayedMessages()})}}</span>
                    </v-col>
                </v-row>
                <div class="px-5 pt-4">
                    <v-badge :content="noOfParkedMessages" overlap
                             color="grey" class="mr-4" v-if="noOfParkedMessages>0">
                        <v-btn rounded color="#048ba8" dark small @click="displayUnread()">{{$t('messages.previousUnread')}}</v-btn>
                    </v-badge>
                    <v-btn rounded color="#048ba8" dark small class="mr-4" @click="displayAll()">{{$t('messages.all')}}</v-btn>
                    <v-btn rounded :disabled="user.uid.length>28" color="#048ba8"
                                   :dark="user.uid.length===28" small @click="displaySettings()">
                        {{$t('messages.settings')}}</v-btn>
                </div>
                <v-divider class="my-3"/>
                <v-window v-if="messagesBar.getNumberOfDisplayedMessages() > 0" v-model="displayedMsgNo">
                    <v-window-item v-for="(displayedMessage, i) in messagesBar.displayedMessages" :key="i"
                                   transition="slide-x-transition" class="mx-3">
                        <messages-msg-short-bottom :displayed-message="displayedMessage"
                                                   :total="messagesBar.getNumberOfDisplayedMessages()"/>
                    </v-window-item>
                </v-window>
            </v-card-text>
            <v-divider/>
            <v-card-actions class="px-3 pb-2">
                <v-btn text @click="setRead('later')" color="grey">{{$t('messages.notNow')}}</v-btn>
                <v-spacer></v-spacer>
                <span class="body-2">{{ displayedMsgNo+1 }} / {{ messagesBar.getNumberOfDisplayedMessages() }}</span>
                <v-spacer></v-spacer>
                <v-btn text @click="setRead('read')"  color="#048ba8"><v-icon class="mr-1">mdi-check</v-icon>{{$t('messages.setRead')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-bottom-sheet>
</template>

<script>

import {MessagesBarService} from "@/services/messages/messagesBarService";
import MessagesMsgShortBottom from "@/components/messages/messagesMsgShortBottom";

export default {
    name: "messages-bar",
    components: {MessagesMsgShortBottom},
    data: () => ({
        show: false,
        messagesBar: null,
        displayedMsgNo: 0,
    }),
    props: ['userId'],
    computed: {
        unreadMessages(){ return this.$store.state.messages.unread },
        noOfParkedMessages(){ return this.$store.state.messages.parked?.length??0},
        user(){ return this.$store.getters.user }
    },
    created(){
        this.init()
    },
    methods: {
        init(){
            this.messagesBar = new MessagesBarService(this.userId)
            if ( this.unreadMessages === null && this.userId ) this.bindUnreadMessages(this.userId)
            if ( this.unreadMessages && this.userId ) this.setDisplayedMessages(true)
        },
        bindUnreadMessages(){
            this.messagesBar.bindUnreadMessagesToStore(this.userId)
        },
        setDisplayedMessages(resetMessages){
            this.messagesBar.setDisplayedMessages(resetMessages);
            this.show = this.messagesBar.getNumberOfDisplayedMessages() > 0
        },
        async setRead(type){
            await this.messagesBar.setRead(this.displayedMsgNo, type)
            if ( this.displayedMsgNo + 1 === this.messagesBar.getNumberOfDisplayedMessages() ) {
                this.show = false
                this.messagesBar.setParameter('displayedMessages', [])
                setTimeout(()=>this.setDisplayedMessages(true), 1000)
                return
            }
            this.displayedMsgNo++
        },
        async parkItems(){
            await this.messagesBar.parkAllUnread()
            this.show = false
        },
        async displayUnread(){
            await this.parkItems()
            this.show = false
            this.$router.push('/myMessages')
        },
        async displayAll(){
            await this.parkItems()
            this.show = false
            this.$router.push('/myMessages')
        },
        async displaySettings(){
            await this.parkItems()
            this.show = false
            this.$router.push({path: '/profile', hash: '#messages'})
        },
    },
    watch: {
        unreadMessages(){
            this.setDisplayedMessages()
        },
        userId(){
            this.bindUnreadMessages()
        }
    }
}
</script>

<style scoped>
    .messageBarContainer{position: fixed; top: 0; left: 0; right: 0; z-index: 30}
    .handle {width: 70px; height: 8px; border-radius: 4px; background-color: #ccc}
</style>