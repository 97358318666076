<template>
    <div :class="[configEl.css, 'px-5']">
        <alert-message :config-prop="{show: undoUnconfirmed, icon: 'mdi-help-circle'}">
            {{$t('utilities.components.auth.undoEmailChangeConfirm')}}
            <v-card-actions class="px-0 mt-2">
                <btn-text @click="toLogin()"
                          config-package="no"
                          :config-prop="configEl.noBtn">
                </btn-text>
                <v-spacer></v-spacer>
                <btn-text @click="undoEmailChange()"
                          config-package="yes"
                          :config-prop="configEl.yesBtn">
                </btn-text>
            </v-card-actions>
        </alert-message>
        <alert-message :config-prop="{show: !undoUnconfirmed && !oobCodeExpired}">
            {{$t('utilities.components.auth.undoEmailChangeSuccess')}} {{resetEmailAddress}}
            <v-card-actions class="px-0 mt-2">
                <v-spacer></v-spacer>
                <btn-text @click="toLogin()"
                          :config-prop="configEl.loginBtn">
                </btn-text>
            </v-card-actions>
        </alert-message>
        <alert-message :config-prop="{show: oobCodeExpired}">
            {{$t('utilities.components.auth.undoEmailChangeExpired')}}
            <v-card-actions class="px-0 mt-2">
                <v-spacer></v-spacer>
                <btn-text @click="toLogin()"
                          :config-prop="configEl.backBtn">
                </btn-text>
            </v-card-actions>
        </alert-message>
    <!-- Block Window while saving -->
        <progress-dialog :waiting-for-reply="waitingForReply" :config-prop="{text: ''}">
            {{$t('utilities.components.auth.undoEmailChangeInProgress')}}
        </progress-dialog>
    </div>
</template>

<script>
    // Version 1.0 2022-01-18
    import ComponentConfigService from '@/utilities/services/components/componentConfigService';
    import AlertMessage from "../displays/alertMessage";
    import BtnText from "../controls/btnText";
    import ProgressDialog from "../dialogs/progressDialog";
    export default {
        components: { ProgressDialog, BtnText, AlertMessage},
        name: "undo-email-change",
        props: {
            loginService: null,
        // use this to overwrite defaultConfig for this instance
            configProp: {default: null},
        // pass the module config if the element is customized in the module
            moduleConfig: {default: null},
        },
        data: () => ({
            resetEmailAddress: '',
            undoUnconfirmed: true,
            oobCodeExpired: false,
            waitingForReply: false,
        // define the path where the component can be found in the directory
            path: 'utilities.components.authentication.undoEmailChange',
        // config
            configDefault: {
                backBtn: { i18n: 'utilities.components.common.back', },
                loginBtn: { i18n: 'utilities.components.auth.login', },
                yesBtn: {icon: ''},
                noBtn: {icon: '', rounded: false, outlined: false, color: 'secondary'},
            },
        }),
        computed: {
            oobCode() { return this.$route.query.oobCode },
        // always necessary
            configEl(){
                return ComponentConfigService.getElementConfig(
                    this.configDefault,
                    this.configProp,
                    this.moduleConfig,
                    this.path,
                );
            },
        },
        methods: {
            toLogin(){
                this.$router.push('/')
            },
            async undoEmailChange(){
                this.waitingForReply = true;
                const reply = await this.loginService.undoEmailChange(this.oobCode);
                this.waitingForReply = false;
                this.undoUnconfirmed = false;
                if ( reply.successful ) {
                    this.resetEmailAddress = reply.content;
                } else {
                    this.oobCodeExpired = true;
                }
            }

        },
    }
</script>

<style scoped>

</style>