<template>
    <div class="mx-auto pb-8" style="max-width: 400px">
        <div class="btnContainer white">
            <btn-text @click="triggerFileSelection()" :config-prop="{block: true, icon: 'mdi-folder-outline'}">
                <span v-if="!nameOfCurrentlySavedPdf">{{$t('utilities.components.pdfUpload.btnAdd')}}</span>
                <span v-else>{{$t('utilities.components.pdfUpload.btnChange')}}</span>
            </btn-text>
            <input class="inputAbsolute" @input="valueChanged" type="file"
                   accept="application/pdf" :name="uniqueId" ref="pdfFileInput"/>
        </div>
        <alert-message :config-prop="{show: nameOfCurrentlySavedPdf!=='', css: 'mt-5 mb-0'}">
            <v-row no-gutters align="center">
                <v-col class="grow">
                    <line-with-label class="black--text" :config-prop="{divider: {show: false}}"
                                     :label="$t('utilities.components.pdfUpload.selected')">
                        {{nameOfCurrentlySavedPdf}}
                    </line-with-label>
                </v-col>
                <v-col class="shrink">
                    <v-btn icon light @click="deletePDF()">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </alert-message>
    </div>
</template>

<script>
import BtnText from "@/utilities/components/controls/btnText";
import LineWithLabel from "@/utilities/components/displays/lineWithLabel";
import AlertMessage from "@/utilities/components/displays/alertMessage";
export default {
    name: "pdfUpload",
    components: {AlertMessage, LineWithLabel, BtnText},
    props: ['value', 'uniqueId', 'nameOfCurrentlySavedPdf'],
    methods: {
        valueChanged(e){
            this.$emit('input', e); //e.target.value
        },
        triggerFileSelection() {
            this.$el.querySelector('input[name=' + this.uniqueId + ']').click();
        },
        deletePDF(){
            this.$emit('delete');
        }
    },
    watch: {
        nameOfCurrentlySavedPdf(to, from){
            if ( !to && from ) {
                this.$refs.pdfFileInput.value = null;
            }
        }
    }
}
</script>

<style scoped>
    .btnContainer {
        position: relative;
        width: 100%;
    }
    .inputAbsolute {
        position: absolute;
        top: 0;
        left: 0;
        //opacity: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
</style>