<template>
    <div>
        <image-upload-resize @selected="getImage" :hasImage="image.url" styleClasses="cropping"
                             :maxWidth=1080 :maxHeight=1080 :imageLoading="loader">
            <image-cropper :img="imageFile" :curr="currCoords" @getCrop="getCropped" @ready="ready">
            </image-cropper>
            <!--<img :src="imageFile" height="300">-->
            <div v-if="loader" class="pt-5">
                <p>{{$t('utilities.components.images.loading')}}</p>
                <div><v-progress-linear indeterminate color="primary"></v-progress-linear></div>
            </div>
            <alert-message config-package="error" class="text-left" style="width: 100%"
                           :config-prop="{show: invalidFileFormat}">
                {{$t('utilities.components.images.invalidFileFormat')}}
            </alert-message>
        </image-upload-resize>
        <div v-if="!loader">
            <div class="text-center mt-3">
                <v-btn v-if="image.url" rounded fab :color="deleteBtnColor"
                       class="elevation-0 d-inline-block ml-5"
                       @click="deleteImage()">
                    <v-icon color="secondary">mdi-delete</v-icon>
                </v-btn>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    // Version 1.1 2022-01-18
    import ImageUploadResize from "./sub/imageUploadResize"
    import ImageCropper from "./sub/imageCropper"
    import firebase from 'firebase/app'
    import 'firebase/storage'
    import AlertMessage from "../../displays/alertMessage";
    export default {
        name: "image-upload-crop",
        components: {
            AlertMessage,
            ImageCropper,
            ImageUploadResize},
        props: {
            image: { default: {url: '', urlMin: '', crop: ''}} ,
            collection: String,
            deleteBtnColor: {default: ''}
        },
        data: () => ({
            loader: false,
            imageFile: "",
            currCoords: null,
            invalidFileFormat: false,
        }),
        created(){
            this.loadImagePreview();
        },
        methods: {
            loadImagePreview() {
                if ( this.image.url ) {
                    this.loader = true;
                    this.currCoords = this.image.url && this.image.crop ? JSON.parse(this.image.crop) : null;
                    this.downloadImage();
                }
            },
            downloadImage(){
                const fileName = this.image.url.substring(0, this.image.url.indexOf('?alt='));
                console.log ("fileName: ", fileName);
                const storageRef = firebase.storage().ref(this.collection);
                console.log ("storageRef: ", storageRef);
                const imgRef = storageRef.child(fileName);
                console.log ("imgRef", imgRef);
                imgRef.getDownloadURL().then((url) => {
                    this.imageFile = url;
                    this.loader = false;
                })
                .catch(()=> { });
            },
            getImage(image){
                this.$emit("selected", image);
                this.imageFile = image;
            },
            getCropped(image, coordinates){
                this.$emit("getCropped", image, coordinates);
            },
            ready(){
                this.loader = false
            },
            deleteImage(){
                this.imageFile = null;
                this.$emit('imageDeleted');
            }
        },
        watch: {
            'image.url' (to) {
                this.invalidFileFormat = false;
                this.currCoords = null;
                if ( to === undefined ) {
                    this.invalidFileFormat = true;
                    this.loader = false;
                } else if ( to === '' ) {
                    this.imageFile = null;
                    this.loader = false;
                } else {
                    if ( to.indexOf('image/jpeg;base64') !== -1 ) {
                        this.imageFile = to;
                        this.loader = false;
                    } else if ( to.indexOf('image/') !== -1 ) {
                        this.imageFile = null;
                        this.loader = false;
                        this.invalidFileFormat = true;
                    } else {
                        this.imageFile = null;
                        this.loadImagePreview();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>