// Version 1.2 2022-03-21

import {db} from "@/utilities/services/firebase/init";
//import firebase from 'firebase/app'
//import 'firebase/functions'

import FirebaseVersion from "@/utilities/services/firebase/firebaseVersion";

export default class FirebaseRead {
    static readSingleDoc (collectionName, documentId) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseRead.readSingleDoc8(collectionName, documentId);
        } else {
            //
        }
    }

        static readSingleDoc8(collectionName, documentId){
            return new Promise( resolve => {
                db.collection(collectionName).doc(documentId)
                    .get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document loaded from " + collectionName );
                        let data = doc.data();
                        data.id = doc.id;
                        resolve(data);
                    } else {
                        console.log("No such document!");
                        resolve(null);
                    }
                }).catch((error) => {
                    console.log("Error getting document:",
                               "CollectionName: ", collectionName,
                                "documentId: ",documentId,
                                error);
                    resolve(null);
                });
            })
        }

    static readDocList (collectionName, queryParameter) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseRead.readDocList8(collectionName, queryParameter);
        } else {
            //
        }
    }

        static readDocList8(collectionName, queryParameter) {
            return new Promise(resolve => {
                db.collection(collectionName)
                    .where(queryParameter.field, queryParameter.operator, queryParameter.value)
                    .get()
                    .then((querySnapshot) => {
                        let list = [];
                        querySnapshot.forEach((doc) => {
                            let document = doc.data();
                            document.id = doc.id;
                            list.push(document);
                        });
                        resolve(list);
                    }).catch((error) => {
                    console.log("Error getting document:", error);
                    resolve(null);
                });
            })
        }
    static readDocListWithTwoWhere (collectionName, queryParameter1, queryParameter2) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseRead.readDocListWithTwoWhere8(collectionName, queryParameter1, queryParameter2);
        } else {
            //
        }
    }

        static readDocListWithTwoWhere8(collectionName, queryParameter1, queryParameter2) {
            return new Promise(resolve => {
                db.collection(collectionName)
                    .where(queryParameter1.field, queryParameter1.operator, queryParameter1.value)
                    .where(queryParameter2.field, queryParameter2.operator, queryParameter2.value)
                    .get()
                    .then((querySnapshot) => {
                        let list = [];
                        querySnapshot.forEach((doc) => {
                            let document = doc.data();
                            document.id = doc.id;
                            list.push(document);
                        });
                        resolve(list);
                    }).catch((error) => {
                    console.log("Error getting document:", error);
                    resolve(null);
                });
            })
        }
    static readFullList (collectionName) {
        if ( FirebaseVersion.getVersion() === 8 ) {
            return FirebaseRead.readFullList8(collectionName);
        } else {
            //
        }
    }

    static readFullList8(collectionName) {
        return new Promise(resolve => {
            db.collection(collectionName)
                //.where(queryParameter.field, queryParameter.operator, queryParameter.value)
                .get()
                .then((querySnapshot) => {
                    let list = [];
                    querySnapshot.forEach((doc) => {
                        let document = doc.data();
                        document.id = doc.id;
                        list.push(document);
                    });
                    resolve(list);
                }).catch((error) => {
                console.log("Error getting document:", error);
                resolve(null);
            });
        })
    }

    static readPagination(collectionName, queryParameter, orderField, limit, lastVisible){
        let path = lastVisible ?
                        db.collection(collectionName)
                            .where(queryParameter.field, queryParameter.operator, queryParameter.value)
                            .orderBy(orderField, 'desc')
                            .limit(limit)
                            .startAfter(lastVisible) :
                        db.collection(collectionName)
                            .where(queryParameter.field, queryParameter.operator, queryParameter.value)
                            .orderBy(orderField, 'desc')
                            .limit(limit)
        return new Promise(resolve => {
            path.get().then((documentSnapshots) => {
            // Get the last visible document
                let lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
                let list = []
                documentSnapshots.forEach((doc) => {
                    let document = doc.data();
                    document.id = doc.id;
                    list.push(document);
                });
                resolve({list: list, lastVisible: lastVisible});
            }).catch((error) => {
                console.log("Error getting document:", error);
                resolve(null);
            });
        })
    }

    static async getDocListByIdArray(collection, docIdList){
        const requiredDocs = await FirebaseRead.loadRequiredDocsById(collection, docIdList)
        const dataList = []
        requiredDocs.forEach( (requiredDoc) => {
            const dataObj = requiredDoc.data()
            if ( dataObj ) {
                dataObj.id = requiredDoc.id
                dataList.push(dataObj)
            }
        });
        return dataList
    }
        static async loadRequiredDocsById(collection, docIdList){
            const requiredDocs = []
            docIdList.forEach( (docId) => {
                const p = db.collection(collection).doc(docId).get();
                requiredDocs.push(p)
            });
            return Promise.all(requiredDocs)
        }
/*
    static async callFunction(functionName) {
        let callableFunction = firebase.app().functions('europe-west3').httpsCallable(functionName);
        return await callableFunction()
    }

 */
}