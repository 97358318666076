<template>
    <div>
        <div v-if="priceType!=='final'">
            <div class="text-no-wrap font-weight-bold lineHeight-1-2">
                {{basePrice.price | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
            </div>
            <div v-if="discount"
                 class="text-no-wrap font-weight-bold lineHeight-1-2 success--text">
                {{discount * -1 | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
            </div>
        </div>
        <div v-else-if="discount">
            <div class="text-no-wrap body-2 lineHeight-1-2 grey--text text-decoration-line-through">
                {{basePrice.price | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
            </div>
            <div v-if="discount"
                 class="text-no-wrap font-weight-bold lineHeight-1-2">
                {{basePrice.price - discount | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
            </div>
        </div>
        <div v-else>
            <div class="text-no-wrap font-weight-bold lineHeight-1-2">
                {{basePrice.price | formatIntToMoneyWithCurrencyTwoDigits(basePrice.currency)}}
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "shop-item-price",
        props: ['basePrice', 'discount', 'priceType'],
    }
</script>

<style scoped>

</style>