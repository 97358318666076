<template>
    <v-menu bottom left class="mr-1">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-badge color="error" v-if="showBadge" content="!" overlap>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-badge>
                <v-icon v-else>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list class="py-0">
            <span>
                <v-list-item v-for="(item, j) in menuActions" :key="j"
                             @click="route(item.route)">
                    <v-list-item-icon class="mr-3">
                        <v-badge color="error" v-if="item.showBadge"
                                 :content="item.badgeContent" overlap>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-badge>
                        <v-icon v-else>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{$t(item.i18n)}}
                    </v-list-item-title>
                </v-list-item>
            </span>
            <v-divider></v-divider>
                <v-list-item v-for="(item, i) in menuTerms" :key="i"
                             @click="routeExternal(item.route)">
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{$t(item.i18n)}}
                    </v-list-item-title>
                </v-list-item>
            <v-divider></v-divider>
            <logout :config-prop="logoutConfig"
                    :login-service="loginService">
            </logout>
            <!--<v-list-item @click="requestLogoutConfirm()">
                <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                <v-list-item-title class="mr-5">Logout</v-list-item-title>
            </v-list-item>-->
        </v-list>
    </v-menu>
</template>

<script>
    import Logout from "@/utilities/components/authentication/logout";

    export default {
        components: {Logout},
        name: "home-menue-user",
        computed: {
            loginService(){ return this.$store.state.loginService; },
            user(){ return this.$store.getters.user },
            masterUser(){ return this.$store.state.realtime.userMaster},
            sportsUser() { return this.masterUser ? this.masterUser : this.user?.uid.length === 28 ? this.user : null },
            userHasNoSport(){
                if ( !this.sportsUser?.sports  ) return true
                let selected = 0
                Object.keys(this.sportsUser.sports).forEach( (sportId) => {
                    if ( this.sportsUser.sports[sportId] ) selected++
                });
                return selected === 0
            },
            parkedMessages(){ return this.$store.state.messages.parked?.length > 0},
            showBadge() { return this.parkedMessages || this.userHasNoSport },
            menuActions(){
                return  [
                    {icon: 'mdi-cart', route: 'myPurchases',
                        i18n: 'myPurchases.menuItem'},
                    {icon: 'mdi-text-box-check-outline', route: 'myConsents',
                        i18n: 'consents.menuItem'},
                    {icon: 'mdi-run-fast', route: 'mySports',
                        i18n: 'sports.my', showBadge: this.userHasNoSport,
                        badgeContent: '!'
                    },
                    {icon: 'mdi-message-bulleted', route: 'myMessages',
                        i18n: 'messages.my', showBadge: this.parkedMessages,
                        badgeContent: this.$store.state.messages.parked?.length.toString()
                    },
                ]
            }
        },
        data: () => ({
            logoutConfig: {
                type: 'listItem'
            },
            menuTerms: [
                {icon: 'mdi-file-account', route: 'OYM_TermsV2.pdf', // terms
                    i18n: 'utilities.services.user.termsOfUse'},
                {icon: 'mdi-shield-lock', route: 'OYM_PrivacyV2.pdf', //privacy
                    i18n: 'utilities.components.common.privacy'},
                {icon: 'mdi-file-table', route: 'imprint',
                    i18n: 'utilities.components.common.imprint'},
            ]
        }),
        methods: {
            route(target){
                this.$router.push('/' + target);
            },
            routeExternal(target){
                if ( target === 'imprint' ) { this.route(target); return }
                //window.open('https://numberone.solutions/oym/terms/' + target, '_blank' )
                window.open('https://www.onyourmarks.cc/app/terms/' +
                    this.$i18n.locale + '/' + target, '_blank' )
            },
        },

    }
</script>

<style scoped>

</style>